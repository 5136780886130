<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />
        
    <div class="row">
      <div class="col-sm-12 col-md-8">

        <template v-if="form.created_by">
          <h3>{{ form.created_by }} commented {{ form.created_at | dateTimezone | timeago }}</h3>
        </template>

          <p>
            <router-link :to="{name: 'clients.index'}">Clients</router-link> /
            <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-end">
        <div class="btn-group">


            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">

                <a
                  v-if="!form.archived_at && !form.deleted_at"
                  class="dropdown-item"
                  @click="archiveRecord(form)"
                >Archive</a>

                <a
                  v-if="!form.deleted_at && !form.archived_at"
                  class="dropdown-item"
                  @click="trashRecord(form)"
                >Trash</a>

                <a
                  v-if="form.deleted_at || form.archived_at"
                  class="dropdown-item"
                  @click="restoreRecord(form)"
                >Restore</a>

              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-4">

        <div class="card">
          <div class="card-body">

            <p class="card-title">Edit</p>

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="content" class="form-label">Comment</label>
                <textarea id="content" class="form-control" v-model="form.content" rows="5" required></textarea>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <audit-logs :form="form" class="mt-3" />

      </div><!--Col-->
      <div class="col-sm-12 col-md-8">


        <div class="row">
          <div class="col-sm-12 col-md-6">

            <template v-if="form.client">
              <client-card
                :client="form.client"
              />
            </template>

            <template v-if="form.case">
                <div class="card">
                  <div class="card-body">
                    <p class="card-title">Case</p>

                    {{ form.case }}
                    
                  </div><!--Body-->
                </div><!--Card-->
            </template>

            <template v-if="form.content">
              <p class="outbound my-3">{{ form.content }}</p>
            </template>

          </div><!--Col-->

          <div class="col-sm-12 col-md-6">

            <p class="card-title">{{ records.length }} Related Comments</p>

            <template v-if="records && records.length > 0">

              <template v-for="record in records">

                <div class="mb-3">
                <p class="m-0" :class="setCommentClass(record)">
                
                  <template v-if="record.uuid === form.uuid">
                    {{ form.content }}
                  </template>
                  <template v-else>
                    {{ record.content }}
                  </template>
                </p>
                <small class="text-muted m-0">{{ record.created_by }} commented {{ record.created_at | dateTimezone | timeago }}</small>
                </div>

              </template>

            </template>


          </div><!--Col-->
        </div><!--Row-->
      </div><!--Col-->
    </div><!--Row-->
  </div><!--Container-->
</template>
<script>
import queries from '../mixins/queries';
export default {
    
    mixins: [ queries ],

    data () {
        return {
            processing: false,
            form: {},
            records: []
        }
    },

    created() {
      this.resetForm();
      this.getRecord();
    },


    methods: {

      resetForm() {
        this.form = {
          client: null,
          content: null
        }
      },

      setCommentClass(record) {
        return record.uuid === this.form.uuid ? 'outbound' : 'inbound';
      },

      getRecord() {
        this.processing = true;
        this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
        .then(response => {
          if(response && response.data) {
            this.form = response.data;
            this.processing = false;

            this.getRecords();
          }
        })
      },

      getRecords() {

        if(!this.form.client) {
          return;
        }

        var queries = {
          client: this.form.client,
          sort: 'created_at',
          sortDirection: 'desc',
          limit: 1000,
          slim: true
        }

        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
        .then(response => {
          if(response && response.data) {
            this.records = response.data;
          }
        })
      },

      postForm() {

        if(!this.form.uuid) {
          return;
        }

        this.processing = true;
        this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
        .then(response => {
          if(response && response.status) {


            if(response.data.updated_at) {
              this.$eventBus.$emit('show-alert', {
                text: this.$route.meta.title + ' updated'
              });
              this.form.updated_at = response.data.updated_at;
            }

            this.processing = false;

          }
        })
      },


    }
}
</script>
