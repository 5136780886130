<template>
  <div class="message-container mb-3">
    <!-- Client Info Section -->
    <div class="d-flex align-items-center mb-2">
      <div class="me-auto">
        <template v-if="message.client && $route.name != 'clients.edit'">
          <template v-if="message.first_name && message.last_name">
            <a :href="'/clients/'+message.client.uuid" class="text-decoration-none">
              <i class="fa fa-user-circle me-1"></i>
              {{ message.client_reference }} {{ message.first_name }} {{ message.last_name }}
            </a>
          </template>
          <template v-else>
            <client-button :uuid="message.client" />
          </template>
        </template>
      </div>
      <div v-if="message.language && message.language != 'en'">
        <button type="button" class="btn btn-link btn-sm text-muted" @click="postTranslate()" :disabled="processing">
          <i class="fa fa-language"></i> Auto Translate
        </button>
      </div>
    </div>

    <!-- Message Content Section -->
    <div class="message-content p-3 rounded" 
         :class="[getClass(message), isPrivacyMode ? 'privacy-mode' : '']"
         :dir="setLangDirection()">
      <template v-if="$route.params.id && $route.params.id == message.uuid">
        <template v-if="translated_content">
          <span class="badge bg-light text-dark me-2">Auto Translated</span>
          <p class="mb-0" v-html="filterContent(translated_content)"></p>
        </template>

        <template v-if="message.subject">
          <p class="fw-bold mb-1">{{ message.subject }}</p>
        </template>

        <template v-if="message.content">
          <p class="mb-0" v-html="filterContent(message.content)"></p>
        </template>
      </template>
      <template v-else>
        <button type="button" @click="goToMessage(message)"
                class="message-btn w-100 text-start border-0 bg-transparent p-0">
          <template v-if="translate && translate.content">
            <span class="text-white">{{ translate.content }}</span>
          </template>
          <template v-else>
            <template v-if="message.subject">
              <p class="fw-bold mb-1">{{ message.subject }}</p>
            </template>
            <template v-if="message.content">
              <p class="mb-0" v-html="filterContent(message.content)"></p>
            </template>
          </template>
        </button>
      </template>
    </div>

    <div class="row">

        <!-- From/To Information -->
        <div class="ms-auto d-flex flex-wrap align-items-center">
          <span class="text-muted">
            <strong class="me-2">From:</strong>
            <template v-if="message.direction == 'outbound' && message.channel == 'email'">
              <a :href="'/agency_emails/'+message.from" class="text-decoration-none">{{ message.from }}</a>
            </template>
            <template v-if="message.direction == 'outbound' && message.channel != 'email'">
              <a :href="'/agency_phones/'+message.from" class="text-decoration-none">{{ message.from }}</a>
            </template>
            <template v-if="message.direction == 'inbound'">
              <a :href="'/messages?search_field=from&search='+message.from" class="text-decoration-none">{{ message.from }}</a>
            </template>
          </span>

          <span class="text-muted ms-auto">
            <strong class="me-2">To:</strong>
            <template v-if="message.direction == 'inbound' && message.channel == 'email'">
              <a :href="'/agency_emails/'+message.to" class="text-decoration-none">{{ message.to }}</a>
            </template>
            <template v-if="message.direction == 'inbound' && message.channel != 'email'">
              <a :href="'/agency_phones/'+message.to" class="text-decoration-none">{{ message.to }}</a>
            </template>
            <template v-if="message.direction == 'outbound'">
              <a :href="'/messages?search_field=to&search='+message.to" 
                 class="text-decoration-none"
                 :class="isPrivacyMode ? 'privacy-mode' : ''">{{ message.to }}</a>
            </template>
          </span>

    </div>

    <!-- Message Meta Section -->
    <div class="message-meta">
      <div class="d-flex flex-wrap align-items-center gap-2">
        <!-- Status Badges -->
        <template v-if="message.last_status && !message.error_code">
          <a :href="'/messages/'+message.uuid"
             :class="setStatusClass(message.last_status)"
             class="badge rounded-pill text-decoration-none">
            {{ message.last_status }}
          </a>
        </template>

        <template v-if="message.last_status && message.error_code">
          <span class="badge rounded-pill bg-danger">
            <span class="text-uppercase">{{ message.last_status }}</span> 
            {{ setErrorCode(message.error_code) }}
          </span>
        </template>

        <!-- Loading Spinner -->
        <template v-if="message.uuid && !message.last_status && message.direction == 'outbound'">
          <div class="spinner-grow spinner-grow-sm text-primary">
            <span class="visually-hidden">Loading...</span>
          </div>
        </template>
        
        <template v-if="message.mms && message.mms > 0">
          <span class="badge bg-primary">
            <i class="fa fa-photo-video"></i> MEDIA
          </span>
        </template>

        <template v-if="message.last_open">
          <span class="badge rounded-pill bg-success">
            <i class="fa fa-eye"></i>
            OPENED
            {{ message.last_open | dateTimezone | timeago }}
          </span>
        </template>


          <span class="text-muted">
            {{ message.scheduled_at | dateTimezone | timeago }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

    props: [ 'message' ],

    data() {
    return {        
        processing: false,
        translate: '',
        detect_language: '',
        translated_content: '',
      }
    },

    mounted() {

      var self = this;
  
      this.$eventBus.$on('message_statuses', function (data) {
          self.updateRecord(data);
      });


      this.$eventBus.$on('opens', function(data) {
          self.addNewOpen(data);
      });

      },

      created() {
        this.detectLanguage();
      },

methods: {

    setStatusClass(status) {

       status = status.toLowerCase();

      //  var statuses = {
      //   'badge-danger': ['opted-out', 'undelivered', 'failed', 'duplicate', 'bounced', 'missed', 'invalid', 'busy', 'timeout', 'cancel', 'rejected', 'spam'],
      //   'badge-success': ['delivered', 'opened', 'answered', 'hangup'],
      //   'badge-primary': ['dispatched', 'sent']
      //  }

      var statuses = {
        'opted-out': 'badge-danger',
        'undelivered': 'badge-danger',
        'failed': 'badge-danger',
        'duplicate': 'badge-danger',
        'bounced': 'badge-danger',
        'missed': 'badge-danger',
        'invalid': 'badge-danger',
        'busy': 'badge-danger',
        'timeout': 'badge-danger',
        'cancel': 'badge-danger',
        'rejected': 'badge-danger',
        'spam': 'badge-danger',
        'delivered': 'badge-success',
        'opened': 'badge-success',
        'answered': 'badge-success',
        'hangup': 'badge-success',
        'dispatched': 'badge-primary',
        'sent': 'badge-primary'
      }

       if(statuses[status]) {
        return statuses[status];
       }

       return 'badge-secondary';
    }, 

    getClass(message) {

      var result = '';

      if(this.isPrivacyMode) {
        result += 'privacy-mode ';
      }

        if(message && message.direction) {
            result += message.direction;
        } 

        return result;
      },

    addNewOpen(data) {

      if(!data.message) {
        return;
      }

      if(data.message != this.message.uuid) {
        return;
      }

      if(data.created_at) {
        this.message.last_open = data.created_at;
      }
    },

    updateRecord(data) {

      if(!data.uuid) {
        return;
      }

      if(data.uuid != this.message.uuid) {
        return;
      }

      this.message = {...this.message, ...data};
      
    },

    setLangDirection() {

      let language = 'en';

      if(this.message && this.message.language) {
        language = this.message.language;
      }

      if(this.detect_language) {
        language = this.detect_language;
      }

      if(this.$root.rtl_langs.includes(language)) {
        return 'rtl';
      }

      return 'ltr';
    },

    setErrorCode(code) {

      if(!code) {
        return;
      }

      if(!this.$root['error_codes']) {
        return;
      }

      if(!this.$root['error_codes'][code]) {
        return;
      }

      return this.$root['error_codes'][code];
    },

    filterContent(content) {
        
        if(!content) {
          return;
        }
        
        return this.$DOMPurify.sanitize(content);
    },

    goToMessage(message) {

      if(this.$route.params.id && this.$route.params.id == message.uuid) {
        return;
      }

      window.location.href = '/messages/'+message.uuid;
    },

    detectLanguage() {

      this.resetTranslate();

      if(!this.message || !this.message.content) {
        return;
      }

      // if(this.message.language) {
      //   this.detect_language = this.message.language;
      //   this.setTranslation();
      //   return;
      // }

      if(this.$route.params.id && this.$route.params.id != this.message.uuid) {
        return;
      }

      this.setTranslation();

      this.processing = true;
      this.$http.post('/translator/detect', {content: this.translate})
      .then(response => {
        if(response && response.data) {
          this.processing = false;
          if(response.data.language) {
            this.detect_language = response.data.language;

            if(this.detect_language == 'en') {
              return;
            };

          }
        }
      })
    },

    setTranslation() {

      this.translate = this.message.content;

      if(this.message.subject) {
        this.translate = this.message.subject + ' ' + this.translate;
      }

      this.translate = this.translate.replace(/<[^>]*>?/gm, '');
    },

    postTranslate() {

      if(this.translate && this.translate.content) {
        this.detectLanguage();
        return;
      }

      this.processing = true;

      this.$http.post('/translator', {content: this.translate, source: this.detect_language, target: 'en'})
      .then(response => {
        if(response && response.data) {
          this.processing = false;

          if(response.data.content) {
            this.translate = response.data;
            this.translated_content = response.data.content;
            this.detect_language = response.data.language;
          }
        }
      })
    },

    resetTranslate() {
      this.translate = '';
      this.detect_language = this.message.language ? this.message.language : '';
      this.translated_content = '';
    }
}
}
</script>