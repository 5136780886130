<template>
    <div>
  
      <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
      />
          
      <div class="row mb-3">
        <div class="col-sm-12 col-md-4">
            <h1>{{ form.name || $route.meta.title }}</h1>
            <p>
              <router-link :to="{name: 'portals.index'}">Portals</router-link> /
              <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-4">

            <template v-if="portal && portal.uuid">
  
            <a :href="setPortalLink()" class="btn btn-outline-primary mr-3" target="_blank" :disabled="processing"><i class="fa fa-link"></i> Portal Link</a>
  
            <template v-if="qr_code">
              <a :href="setPortalLink()" target="_blank">
              <img
                :src="'data:image/svg+xml;base64,'+qr_code"
                width="50"
                alt="QR Code"
              >
              </a>
            </template>

            </template>
    
        </div><!--Col-->
  
  
        <div class="col-sm-12 col-md-4 text-end">
          <div class="btn-group">
  
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-outline-primary dropdown-toggle radius-right-0"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Tools
                </button>
                <div class="dropdown-menu">
                  <a
                    v-if="!form.archived_at && !form.deleted_at"
                    class="dropdown-item"
                    @click="archiveRecord(form)"
                  >Archive</a>
                  <a
                    v-if="form.archived_at || form.deleted_at"
                    class="dropdown-item"
                    @click="restoreRecord(form)"
                  >Restore</a>
                  <a
                    v-if="!form.deleted_at && !form.archived_at"
                    class="dropdown-item"
                    @click="trashRecord(form)"
                  >Trash</a>
                </div>
            </div><!--BtnGroup-->
              
            <button
              v-if="!form.archived_at && !form.deleted_at"
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm()"
            >
              Save
            </button>
  
            <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
            >
              Close
            </router-link>
  
          </div>
        </div><!--Col-->
      </div><!--Row-->
  
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <div class="card">
            <div class="card-body">

              <p class="card-title">
                  Edit {{ $route.meta.title }}
                </p>
  
              <form @submit.prevent="postForm()">
  
                <div class="form-group">
                  <label for="name">Name</label>
                  <input
                    id="name"
                    v-model="form.name"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="redirect_url">Redirect URL</label>
                  <input
                    id="redirect_url"
                    v-model="form.redirect_url"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                <label for="portal">Portal

                <template v-if="form.portal">
                  <a
                  :href="'/portals/'+form.portal"
                  target="_blank"
                  data-tooltip="Edit Portal">
                  <i class="fa fa-edit"></i> <span class="sr-only">Edit Portal</span>
                  </a>
                </template>
                
                </label>
                <select
                  id="portal"
                  v-model="form.portal"
                  class="form-control"
                  :disabled="processing"
                  required
                >
                  <option value="">
                    Choose Portal
                  </option>
                  <template v-for="portal in portals">
                    <option
                      :value="portal.uuid"
                    >
                      {{ portal.name }}
                    </option>
                  </template>
                </select>
              </div>

                <div class="form-group">
                  <label for="url">URL</label>
                  <input id="url" class="form-control" v-model="form.url" required>
                </div>

  
                <button type="submit" class="btn btn-success" :disabled="processing">Save</button>
    
              </form>
            </div><!--Body-->
          </div><!--Card-->
  
          <audit-logs
            class="mt-3"
            :form="form"
          />
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-9">
          <template v-if="message && message.uuid">
            <message :message="message" />
          </template>
        </div><!--Col-->

  
      </div><!--Row-->
    </div>
  </template>
  <script>
  import queries from '../mixins/queries';
  
  export default {
    
    mixins: [ queries ],
  
    data () {
      return {
        processing: false,
        total: 0,
        queries: {
          limit: 100,
          slim: true,
          fields: 'name,uuid,link,portal_reference,redirect,created_at',
          sort: 'name',
          sortDirection: 'asc'
        },
        records: [],
        form: {},
        qr_code: null,
        portals: [],
        portal: {},
        message: {}
      }
    },

  
    created() {
      this.resetForm();
      this.setURLParams();
      this.getRecord();
      this.getPortals();
    },
  
    methods: {
  
      setURLParams() {
  
          var searchParams = new URLSearchParams(window.location.search);
  
          var self = this;
  
          searchParams.forEach(function(value, key) {
              self.queries[key] = value;
          });
      },

  
      postForm() {
        this.processing = true;
  
        this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
        .then(response => {
          if(response && response.data) {
  
            this.processing = false;
  
            if(response.data.updated_at) {
              this.form.updated_at = response.data.updated_at;
              this.$eventBus.$emit('show-alert', {
                text: this.$route.meta.title + ' updated'
              });
              this.getPortal();
            }
  
          }
        })
      },
  
      resetForm() {
  
  
          this.form = {
            name: null,
            url: null,
            redirect_url: null,
            portal: null,
          }
      },

     getRecord() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
      .then(response => {
        if(response && response.data) {
  
          if(response.data.uuid) {
            this.form = response.data;
            this.getPortal();
            this.getMessage();
          }
  
          this.processing = false;
        }
      })
     },

     getMessage() {
      if(!this.form.message) {
        return;
      }

      this.message = {};

      this.processing = true;

      this.$http.get('/messages/'+this.form.message)
      .then(response => {
        if(response && response.status) {

          this.processing = false;
          this.message = response.data;
        }
      })
     },
  
      getPortals () {
          this.$http.get('/portals?slim=true&limit=1000&sort=name&sortDirection=asc&fields=uuid,name,link')
          .then(response => {
              if (response && response.data) {
                  this.portals = response.data;
              }
          })
      },
  
      getLocations () {
        this.$http.get('/locations?slim=true&limit=1000&sort=name&sortDirection=asc&fields=uuid,name,link')
            .then(response => {
              if (response && response.data) {
                this.locations = response.data;
              }
            })
      },
  
      getPortal() {
  
        if(!this.form.portal) {
          return;
        }
  
        if(this.portal && this.portal.uuid) {
          if(this.form.portal == this.portal.uuid) {
            return;
          }
        }
  
        this.$http.get('/portals/'+this.form.portal+'?slim=true&fields=domain,link,uuid')
        .then(response => {
          if(response && response.data) {
            this.portal = response.data;
            }
        })
      },

      setPortalLink() {
        if(!this.portal || !this.portal.link) {
          return;
        }
  
        if(!this.form || !this.form.url) {
          return;
        }
  
        return 'https://'+this.portal.link+'.'+this.portal.domain+'/r/'+this.form.url;
      },
  
    },
  }
  </script>