<template>
  <div>
    <div class="input-group mb-3">
      <input
        v-model="form.search"
        type="search"
        class="form-control"
        placeholder="Search agents"
        @input="searchAgents"
      >
      <div class="input-group-append">
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary radius-left-0 rounded-right"
            type="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-sort" />
          </button>
          <div class="dropdown-menu">
            <p class="dropdown-header">
              Sort By
            </p>
            <a
              v-for="sort_option in sorts"
              class="dropdown-item text-capitalize"
              :class="{ active: isActiveSort(sort_option.key) }" 
              @click="setSort(sort_option.key)"
            >{{ sort_option.title }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  data () {
    return {
      form: {},
      sort: '',
      sorts: [
      {key: 'firm_name_a_z', title: 'firm name a-z'},
      {key: 'firm_name_z_a', title: 'firm name z-a'},
      ]
    }
  },
  methods: {
    searchAgents: _.debounce(function () {
      this.$eventBus.$emit('searchAgents', this.form.search)
    }, 1000),
    isActiveSort (sort) {
      if(this.sort == sort) {
        return true
      }
    },
    setSort (sort) {
      if (sort == this.sort) {
        this.sort = ''
      } else {
        this.sort = sort
      }
      this.$eventBus.$emit('sortAgents', this.sort)
    }
  }
}
</script>
