<template>
<div>
  <page-tabs page="setup" />

  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12 mb-4">
        <div class="card shadow-sm border-0 rounded-lg">
          <div class="card-body text-center py-5">
            <h1 class="display-5 fw-bold text-primary mb-3">Get Help With eCourtDate</h1>
            <p class="lead mb-0">We're here to help. We offer live chat support, help center, and more.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-4">
      <!-- Live Chat Support Card -->
      <div class="col-md-6 mb-4">
        <div class="card shadow-sm border-0 rounded-lg h-100 support-card">
          <div class="card-body d-flex flex-column text-center p-4">
            <div class="icon-container mb-3">
              <i class="fa fa-comments text-primary fa-3x"></i>
            </div>
            <h3 class="card-title">Live Chat Support</h3>
            <p class="card-text">
                Click the chat icon in the bottom left corner of your screen.
            </p>
          </div>
        </div>
      </div>

      <!-- Support Ticket Card -->
      <div class="col-md-6 mb-4">
        <div class="card shadow-sm border-0 rounded-lg h-100 support-card">
          <div class="card-body d-flex flex-column text-center p-4">
            <div class="icon-container mb-3">
              <i class="fa fa-exclamation-circle text-danger fa-3x"></i>
            </div>
            <h3 class="card-title">Create a Support Ticket</h3>
            <p class="card-text flex-grow-1">
                Click the ticket icon in the bottom right corner of your screen.
            </p>
          </div>
        </div>
      </div>

      <!-- Help Center Card -->
      <div class="col-md-6 col-lg-4 mb-4">
        <div class="card shadow-sm border-0 rounded-lg h-100 support-card">
          <div class="card-body d-flex flex-column text-center p-4">
            <div class="icon-container mb-3">
              <i class="fa fa-book-open text-secondary fa-3x"></i>
            </div>
            <h3 class="card-title">Help Articles</h3>
            <p class="card-text flex-grow-1">
              Read articles to learn how to use eCourtDate.
            </p>
            <a href="https://help.ecourtdate.com" target="_blank" class="btn btn-outline-secondary mt-3">
              <i class="fa fa-external-link me-2"></i>Visit Help Center
            </a>
          </div>
        </div>
      </div>

      <!-- YouTube Channel Card -->
      <div class="col-md-6 col-lg-4 mb-4">
        <div class="card shadow-sm border-0 rounded-lg h-100 support-card">
          <div class="card-body d-flex flex-column text-center p-4">
            <div class="icon-container mb-3">
              <i class="fa fa-video text-secondary fa-3x"></i>
            </div>
            <h3 class="card-title">YouTube Channel</h3>
            <p class="card-text flex-grow-1">
              Watch videos to learn more about eCourtDate.
            </p>
            <a href="https://www.youtube.com/@eCourtDate" target="_blank" class="btn btn-outline-secondary mt-3">
              <i class="fa fa-video me-2"></i> Visit our YouTube Channel
            </a>
          </div>
        </div>
      </div>

      <!-- Schedule a Meeting Card -->
      <div class="col-md-6 col-lg-4 mb-4">
        <div class="card shadow-sm border-0 rounded-lg h-100 support-card">
          <div class="card-body d-flex flex-column text-center p-4">
            <div class="icon-container mb-3">
              <i class="fa fa-calendar-check text-secondary fa-3x"></i>
            </div>
            <h3 class="card-title">Schedule a Meeting</h3>
            <p class="card-text flex-grow-1">
              Book a virtual meeting with our help team for training or consultation.
            </p>
            <a href="https://portals.ecourtdate.com/schedule?template=d1cba08a-5beb-43f5-9214-a525b246a57d" target="_blank" class="btn btn-outline-secondary mt-3">
              <i class="fa fa-calendar me-2"></i>Book a Meeting
            </a>
          </div>
        </div>
      </div>

    </div><!--Row-->


    <div class="row">
        <div class="col-12">
            <h4>Resources</h4>
            <ul class="list-group">
                <a v-for="guide in guides" class="list-group-item list-group-item-action" :href="guide.url" target="_blank">
                    {{ guide.title }}
                </a>
            </ul>
        </div>
    </div>
  </div>
</div>
</template>

<script>
import queries from '../mixins/queries';
export default {
  mixins: [queries],

  data() {
    return {
      processing: false,
      guides: [
        { id: 'client-setup', title: 'Onboarding Guide', url: 'https://ecourtdate.com/onboarding' },
        { id: 'messaging', title: 'Implementation Plan', url: 'https://ecourtdate.com/implementation-plan' },
        { id: 'events', title: 'Technical Guides', url: 'https://ecourtdate.com/technical-guides' }
      ]
    }
  },

  created() {
    this.setDocTitle(this.$route.meta.title);
    },

  methods: {
    openLiveChat() {
      // This would trigger the chat widget to open
      if (window.$crisp) {
        window.$crisp.push(["do", "chat:open"]);
      } else {
        this.$eventBus.$emit('show-alert', {
          text: 'Chat support is currently unavailable. Please try another support option.',
          type: 'warning'
        });
      }
    },

    setCurrentURL() {
      return window.location.href;
    },

    showGuides() {
      this.$eventBus.$emit('show-modal', {
        title: 'Quick Start Guides',
        component: 'guides-modal',
        props: {
          guides: this.guides
        }
      });
    }
  }
}
</script>

<style scoped>
.support-card {
  transition: all 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
}

.support-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1) !important;
}

.icon-container {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.card-text {
  color: #6c757d;
}

.accordion-button:not(.collapsed) {
  background-color: rgba(13, 110, 253, 0.1);
  color: #0d6efd;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0,0,0,.125);
}

@media (max-width: 768px) {
  .icon-container {
    height: 60px;
    width: 60px;
  }
  
  .icon-container i {
    font-size: 1.8rem !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
</style>