<template>
  <thead>
    <tr>
      <template v-for="column in columns">
        <th>
          <template v-if="column.key">
            <span
              class="text-capitalize link-hover"
              @click="sortColumn(column)"
            >{{ column.label }}</span>
          </template>

          <template v-if="!column.key">
            <span class="text-capitalize">{{ column.label }}</span>
          </template>

          <template v-if="column.key == queries.sort">
            <button
              type="button"
              class="btn btn-outline-secondary btn-sm ml-1"
              :disabled="processing"
              @click="updateSortDirection()"
            >
              <template v-if="queries.sortDirection == 'asc'">
                <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
              </template>

              <template v-if="queries.sortDirection == 'desc'">
                <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
              </template>
            </button>
          </template>
        </th>
      </template>
    </tr>
  </thead>
</template>
<script>
export default {

props: ['columns', 'queries', 'processing', 'base_url'],

data() {
    return {
    }
},

created() {
},

methods: {

    updateSortDirection() {

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.$eventBus.$emit('refreshRecords', this.base_url);
    },

    sortColumn(column) {

      this.queries.sort = column.key;

      this.updateSortDirection();

    }

}

}
</script>