<template>
    <div>
    
    <page-tabs page="setup" />
    
          <div class="row">
            <div class="col-6 mx-auto">
              <div class="card">
                <div class="card-body">
    
                    <h1>Research Program</h1>

                    <p class="lead">Analyze your data and benchmark the results with comparable jurisdictions. Gain and provide insights in collaboration with our research partners.</p>

                    <p>
                        <a href="https://ecourtdate.com/research-program" target="_blank" class="btn btn-outline-primary btn-sm">Learn More</a>
                    </p>

                    <template v-if="form.status">
                        <p>Current Status: <span class="badge badge-pill text-uppercase" :class="setStatusClass(form.status)">{{ form.status }}</span></p>
                    </template>

                    <template v-else>
                        <p>Current Status: <span class="badge badge-pill badge-danger text-uppercase">Not Enrolled</span></p>
                    </template>

                    <p>Interested in joining the program? There is no cost to your agency.</p>

                    <form @submit.prevent="postForm">
                        <div class="form-group">
                            <label>What are your research goals?</label>
                            <div class="form-check" v-for="(goal, index) in goals" :key="'goal_'+index">
                                <input 
                                    class="form-check-input" 
                                    type="checkbox" 
                                    :id="'goal_'+index"
                                    v-model="form.goals" 
                                    :value="goal"
                                >
                                <label class="form-check-label" :for="'goal_'+index">
                                    {{ goal }}
                                </label>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="comments">Comments</label>
                            <textarea class="form-control" v-model="form.comments" rows="3" placeholder="Share any additional information"></textarea>
                        </div>

                        <p>One of our research partners will contact you to discuss your goals and how we can help.</p>

                        <button type="submit" class="btn btn-success" :disabled="processing">Submit</button>
                    </form>
                </div><!--Body-->
            </div><!--Card-->
    
            </div><!--Col-->
        </div><!--Row-->

    </div>
    </template>
    <script>    
    export default {
    
        data() {
            return {
              processing: false,
              form: {
                goals: [],
                comments: ''
              },
              goals: ['I want to benchmark my agency\'s performance against comparable jurisdictions.', 'I want to collaborate with researchers to improve my agency\'s performance.', 'I want to learn more about the research program and how it can benefit my agency.']
            }
        },
        


        mounted() {


        var self = this;

        this.$eventBus.$on('errors', function (errors) {
            self.errors = errors.data || errors;
            self.processing = false;
        });

        },


        created () {
            this.resetForm();
            this.getForm();
        },
    
        methods: {

            getForm() {
                this.processing = true;
                this.$http.get('/agencies/research')
                .then(response => {
                    if(response.data) {
                        const responseData = response.data;
                        this.form = {
                            ...responseData,
                            goals: Array.isArray(responseData.goals) ? responseData.goals : []
                        };
                        this.processing = false;
                    }
                });
            },

            resetForm() {
                this.form = {
                    goals: [],
                    comments: '',
                    status: null
                };
            },


            postForm() {
                this.processing = true;
                
                const formData = {
                    ...this.form,
                    status: 'pending'
                };

                this.$http.post('/agencies/research', formData)
                .then(response => {
                    if(response.data) {
                        this.processing = false;

                        if(response.data.agency) {
                            this.form = {
                                ...response.data,
                                goals: Array.isArray(response.data.goals) ? response.data.goals : []
                            };
                        }

                        this.$eventBus.$emit('show-alert', {
                            text: 'Thank you for your interest in the research program.',
                            type: 'success'
                        });
                    }
                });
            },

            setStatusClass(status) {
                if(status == 'pending') {
                    return 'badge-info';
                } else if(status == 'approved') {
                    return 'badge-success';
                } else if(status == 'rejected') {
                    return 'badge-danger';
                }
            }
        
        }
    }
    </script>