<template>
  <div>
    <!-- Legacy browser alert -->
    <template v-if="legacy === true">
      <div class="row">
        <div class="col-12">
          <div class="alert alert-warning alert-dismissible fade show">
            Legacy browser detected. Use a modern browser.
          </div>
        </div>
      </div>
    </template>

    <!-- Offline alert -->
    <template v-if="offline === true && !isOnline()">
      <div class="row">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissible fade show">
            No network detected. Refresh or <a @click="reloadPage()" class="alert-link link-hover">click here once back online</a>.
          </div>
        </div>
      </div>
    </template>


  </div>
</template>
<script>
export default {

data() {
    return {
        offline: false,
        legacy: false,
        errors: []
    }
},

    mounted() {

    this.$eventBus.$on('errors', (error) => {

    if(error.data && error.data.message) {

      //check if error is already in array
      if(this.errors.includes(error.data.message)) {
        return;
      }

      this.errors.unshift(error.data.message);
      
      this.$eventBus.$emit('show-alert', {
        text: error.data.message,
        type: 'danger'
      });
    }

    });

    window.addEventListener("offline", () => {
      this.offline = true;

    });


    },

    created() {
        this.isLegacy();
        this.isOffline();
    },

methods: {

    isLegacy() {

      if (navigator.userAgent.indexOf("MSIE") != -1 ) {
        this.legacy = true;
      }
    
      
    },

    isOffline() {
      
        if(navigator.onLine) {
            this.offline = false;
            return;
        }

        this.offline = true;
    },

    isOnline() {

      if(navigator.onLine) {
        return true;
      }

      return false;
    },

    reloadPage() {
        window.location.reload();
    }
}

}
</script>