<template>
  <div>

    <page-tabs page="users" />

    <div class="row">
      <div class="col-sm-12 col-md-3">

    <div class="card mb-3">
      <div class="card-body">
        <p class="card-title">
          Create {{ $route.meta.title }}
        </p>
        <form
          @submit.prevent="postForm()"
        >
          <div class="form-group">
            <label for="user_name">Name</label>
            <input
              id="user_name"
              v-model="form.name"
              type="text"
              class="form-control"
              required
            >
          </div>
          <div class="form-group">
            <label for="user_phone">Phone</label>
            <input
              id="user_phone"
              v-model="form.phone"
              type="tel"
              class="form-control"
            >
          </div>
          <div class="form-group">
            <label for="user_email">Email</label>
            <input
              id="user_email"
              v-model="form.email"
              type="email"
              class="form-control"
              required
            >
          </div>

          <div class="form-check">
            <input
              id="notify"
              v-model="form.notify"
              type="checkbox"
              class="form-check-input"
              :disabled="processing"
            >
            <label
              class="form-check-label"
              for="notify"
            >Notify User</label>
          </div>
          <div class="form-check">
            <input
              id="super_admin"
              v-model="form.super_admin"
              type="checkbox"
              class="form-check-input"
              :disabled="processing"
            >
            <label
              class="form-check-label"
              for="super_admin"
            >Super Admin</label>
          </div>

          <div class="form-group" :hidden="form.super_admin">
            <label for="roles">Role</label><br>
            <select id="roles" class="form-control" v-model="form.roles">
              <option value="" />
              <template v-for="role in roles">
                <option
                    :value="role"
                >
                  {{ role }}
                </option>
              </template>
            </select>
          </div>


          <button
            type="submit"
            class="btn btn-success"
            :disabled="processing"
          >
            Create
          </button>
        </form>
      </div><!--Body-->
    </div><!--Card-->

        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">Search</p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-end">
                <button type="button" class="btn btn-outline-secondary btn-sm" @click="resetQueries()"><i class="fa fa-eraser"></i> Reset</button>
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="searchRecords()">
              <div class="form-group">
                <label for="search">Name</label>
                <input
                  id="search"
                  v-model="queries.search"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_email">Email</label>
                <input id="search_email" type="search" class="form-control" v-model="queries.email">
              </div>

              <div class="form-group">
                <label for="search_phone">Phone</label>
                <input id="search_phone" type="search" class="form-control" v-model="queries.phone">
              </div>

              <div class="form-group">
                <label for="search_language">Language</label>
                <select
                    id="search_language"
                    v-model="queries.language"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="language in languages">
                    <option
                        :value="language.key"
                    >
                      {{ language.label }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="role">Role</label>
                <select
                  id="role"
                  v-model="queries.role"
                  class="form-control"
                  :disabled="processing"
                  @change="searchRecords()"
                >
                  <option value="" />
                  <template v-for="role in roles">
                    <option
                      :value="role"
                    >
                      {{ role }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-check">
                <input
                    id="search_super_admin"
                    v-model="queries.super_admin"
                    type="checkbox"
                    class="form-check-input"
                    :disabled="processing"
                    @change="searchRecords()"
                >
                <label
                    class="form-check-label"
                    for="search_super_admin"
                >Super Admin</label>
              </div>


              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->
      <div class="col-sm-12 col-md-6">

        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 d-flex justify-content-end">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary btn-sm" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary btn-sm" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <template v-for="column in columns">
                    <th>
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                          <i class="fa fa-edit"></i> Edit
                        </a>
                      </td>
                      <td>{{ record.name }}</td>
                      <td>{{ record.phone }}
                      <template v-if="record.phone_ext">
                        Ext: {{ record.phone_ext }}
                      </template>
                      </td>
                      <td>{{ record.email }}
                        <template v-if="record.email">
                          <button type="button" class="btn btn-secondary btn-sm" @click="copyToClipboard(record.email)" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.language">
                          {{ setLanguageName(record) }}
                        </template>
                      </td>
                      <td>
                        <template v-if="record.super_admin === 1">
                          <span class="badge badge-pill badge-success"><i class="fa fa-check"></i> Super Admin</span>
                        </template>
                        <template v-else>
                          <span class="badge badge-pill badge-secondary"><i class="fa fa-times"></i> Super Admin</span>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.roles && record.roles.length">
                          <template v-for="role in record.roles">
                            <span
                              class="badge badge-pill badge-primary me-1"
                            >{{ role }}</span>
                          </template>
                        </template>
                      </td>

                      <td><total-users :user="record.uuid" :url="'clients'"></total-users></td>

                      <td>
                        <template v-if="record.last_active">
                          {{ record.last_active | datetime }}
                          <br><span class="text-muted small">{{ record.last_active | dateTimezone | timeago }}</span>
                        </template>
                        <template v-else>
                          <span class="badge badge-pill badge-danger">Not Active</span>
                        </template>
                      </td>

                      <td>
                        <template v-if="record.created_at">
                          {{ record.created_at | datetime }}
                          <br><span class="text-muted small">{{ record.created_at | dateTimezone | timeago }}</span>
                        </template>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
            </div><!--Responsive-->

            <template v-if="!processing && (!records || !records.length)">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-outline-primary d-print-none mt-3"
              :disabled="processing"
              @click="getRecords"
            >
              Load more
            </button>
          </div>
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">

              <p class="card-title">Send Message to {{ total }} Users</p>

              <form @submit.prevent="postMessage()">

              <div class="form-group">
                <label for="subject">Subject</label>
                <input id="subject" type="text" class="form-control" v-model="messageForm.subject">
              </div>

              <div class="form-group">
                <label for="content">Message</label>
                <textarea id="content" class="form-control" v-model="messageForm.content" rows="5" required></textarea>
              </div>

              <button type="button" class="btn btn-sm btn-outline-success my-3" @click="setGoLiveMessage()" :disabled="processing">🚀 Announce Go Live</button>

              <div class="form-check">
                <input
                    id="message_test"
                    v-model="messageForm.test"
                    type="checkbox"
                    class="form-check-input"
                    :disabled="processing"
                >
                <label
                    class="form-check-label"
                    for="message_test"
                >Simulate Mode</label>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing || total < 1">Send</button>

              </form>

          </div>
        </div>


        <template v-if="messages && messages.length > 0">

          <p class="card-title mt-3">{{ messages.length }} Messages Scheduled</p>
          
          <template v-for="message in messages">
            
            <message :message="message" />
            
          </template>
          
        </template>

      </div><!--Col-->


    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from "lodash";

export default {

    mixins: [ queries ],

    data () {
        return {
            processing: false,
            downloading: false,
            records: [],
            downloads: [],
            messages: [],
            queries: {},
            current_user: {},
            total: 0,
            columns: [
              {label: 'Edit'},
              {label: 'name', key: 'name'},
              {label: 'phone', key: 'phone'},
              {label: 'email', key: 'email'},
              {label: 'language', key: 'language'},
              {label: 'Super Admin', key: 'super_admin'},
              {label: 'roles', key: 'roles'},
              {label: 'clients'},
              {label: 'last active', key: 'last_active'},
              {label: 'created', key: 'created_at'}
            ],
            roles: [],
            form: {},
            messageForm: {}
        }
    },

    created () {
      this.resetQueries();
      this.setURLparams();
      this.resetForm();
      this.resetMessageForm();
      this.getRoles();
      this.getRecords();
    },

    methods: {

      setURLparams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
          self.queries[key] = value;
        });

      },

      resetQueries() {
        this.queries = {
          search: null,
          email: null,
          phone: null,
          language: null,
          role: null,
          sort: 'last_active',
          sortDirection: 'desc',
          limit: 20,
          skip: 0,
          super_admin: false
        }
      },

        searchRecords() {
            this.resetRecords();
            this.getRecords();
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        getRecords () {

            this.processing = true;

            this.updateURL();
            this.getTotal();

            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if(response && response.data) {

                    if(response.data.length) {

                        var self = this;

                        response.data.forEach(function(data) {
                            self.records.push(data);
                        });
                    }

                    this.queries.skip = this.records.length;

                    this.processing = false;

                }
            })
        },

        resetRecords() {
            this.records = [];
            this.queries.skip = 0;
            this.total = 0;
        },

        resetMessageForm() {
          this.messageForm = {
            subject: null,
            content: null,
            test: false,
            type: 'oneoff:user',
          }
        },

        resetForm() {
          this.form = {
            name: null,
            email: null,
            phone: null,
            roles: null,
            language: 'en',
            super_admin: true,
            notify: true
          }
        },

        postForm () {
          this.processing = true;
          this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if (response && response.data) {

              if(response.status === 201) {
                this.records.unshift(response.data);
                this.$eventBus.$emit('show-alert', {
                  text: this.$route.meta.title + ' added'
                });
                this.total++;
                this.resetForm();
                this.setTitle();
              }

              this.processing = false
            }
          })
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
        },

        updateSortDirection() {

            if(this.queries.sortDirection == 'asc') {
            this.queries.sortDirection = 'desc';
            } else {
            this.queries.sortDirection = 'asc';
            }

            this.resetRecords();

            this.getRecords();
        },

        sortColumn(column) {

            this.queries.sort = column.key;

            this.updateSortDirection();
        },

        getRoles() {
            this.$http.get('/roles?slim=true&limit=100&fields=name&sort=name&sortdirection=asc')
            .then(response => {
                if(response && response.data) {
                    this.roles = response.data.map(r => r.name);
                }
            })
        },

      setLanguageName(record) {

        if(!record.language) {
          return;
        }

        var language = _.find(this.languages, {'key': record.language});

        if(!language || !language.label) {
          return record.language;
        }

        return language.label;
      },

      postMessage() {
          this.processing = true;

          var queries = this.queries;

          queries.skip = 0;

          queries.slim = true;

          queries.limit = 1000;

          queries.fields = 'email,phone,name';

          this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
              .then(response => {
                if(response && response.data) {
                  if(response.data.length) {
                    var self = this;
                    response.data.forEach(record => {

                      var post = self.messageForm;

                      post['to'] = null;

                      post['client_name'] = record.name;

                      post['created_by'] = self.$root.current_user.email;

                      if(record.email) {

                        post.to = record.email;

                        self.$http.post('/messages/oneoffs', post)
                            .then(response => {
                              if(response && response.data) {
                                if(!self.messages) {
                                  self.messages = [];
                                }

                                self.messages.unshift(response.data);
                              }
                            })
                      }

                      if(record.phone) {

                        post.to = record.phone;

                        self.$http.post('/messages/oneoffs', post).
                        then(response => {
                          if(response && response.data) {
                            if(!self.messages) {
                              self.messages = [];
                            }

                            self.messages.unshift(response.data);
                          }
                          
                        })
                      }

                    });
                    
                  }

                  if(response.data.length == this.total) {
                    this.resetMessageForm();
                    this.processing = false;
                    this.$eventBus.$emit('show-alert', {
                      text: 'Messages sent'
                    });
                  }
                }
              })
      },

      setGoLiveMessage() {
        this.messageForm.subject = 'eCourtDate is now live!';
        this.messageForm.content = 'eCourtDate is now live for [AgencyName]. Clients will automatically receive reminders for upcoming events and payment obligations via texts, emails, and calls. The FROM phone number is [AgencyPhone] and the FROM email address is [AgencyEmail]. This is an automated platform and no action is currently required on your part. <br>Go to eCourtDate https://ecourtdate.com';
      }

    }
}
</script>   