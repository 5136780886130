<template>
  <div>

    <template v-if="form.uuid">

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    </template>

    <div class="row mb-3">

      <div class="col-md-3">
        <h1>{{ form.title }}</h1>
        <template v-if="form.description">
          <p class="lead m-0">{{ form.description }}</p>
        </template>

        <p>
        <router-link :to="{name: 'reports.index'}">Reports</router-link> /
        <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}
        </p>
      </div><!--Col-->

      <div class="col-md-3">

      </div><!--Col-->

      <div class="col-md-3">

      </div><!--Col-->

      <div class="col-md-3 text-end">
        <div class="btn-group">

          <button
            type="button"
            class="btn btn-outline-primary dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :disabled="processing"
          >
            Tools
          </button>
          <div class="dropdown-menu">
                <a
                  v-if="form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord(form)"
                >Restore</a>
                <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord(form)"
                >Trash</a>
          </div>

          <button
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div><!--BtnGroup-->
      </div><!--Col-->
    </div><!--Row-->


    <div class="row mt-3">
      <div class="col-sm-12 col-md-3">
        <div class="card mb-3">
          <div class="card-body">
            <form @submit.prevent="postForm()">
              <template v-if="form.uuid">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input
                    id="name"
                    v-model="form.name"
                    class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="description">Description</label>
                  <textarea
                    id="description"
                    v-model="form.description"
                    class="form-control"
                    rows="3"
                  />
                </div>

                <div class="form-group">
                  <label for="data_source">Data Source</label>
                  <select
                    id="data_source"
                    v-model="form.data_source"
                    class="form-control"
                    :disabled="processing"
                    required
                  >
                    <option value=""></option>
                    <template v-for="(data_source, data_source_index) in data_sources">
                      <option :value="data_source">{{ data_source }}</option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="date_filter">Date Filter</label>
                  <select
                    id="date_filter"
                    v-model="form.date_filter"
                    class="form-control"
                    :disabled="processing"
                  >
                    <template v-if="date_filters[form.data_source] && date_filters[form.data_source].length">
                      <template v-for="(date_filter, index) in date_filters[form.data_source]">
                        <option
                          :value="date_filter"
                        >
                          {{ date_filter }}
                        </option>
                      </template>
                    </template>
                  </select>
                </div>
              </template>

              <div class="form-group">
                <label for="type">Type</label>
                <select id="type" class="form-control" v-model="form.type" :disabled="processing">
                  <template v-for="(datapoint_type, type_index) in datapoint_types">
                    <option :value="datapoint_type">{{ datapoint_type }}</option>
                  </template>
                </select>
              </div>

              <template v-if="form.type == 'rate'">

              <div class="form-group">
                <label for="data_compare">Data Compare</label>
                <input id="data_compare" class="form-control" v-model="form.data_compare">
              </div>

              </template>

              <div class="form-check">
                <input
                  id="simulate_data"
                  v-model="form.simulate"
                  type="checkbox"
                  class="form-check-input"
                  @change="postForm()"
                  :disabled="processing"
                >
                <label
                  class="form-check-label"
                  for="simulate_data"
                >Simulate Data</label>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="from_date">From Date</label>
                    <input
                      id="from_date"
                      v-model="form.from_date"
                      type="date"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->

                <div class="col-6">
                  <div class="form-group">
                    <label for="from_time">From Time</label>
                    <input
                      id="from_time"
                      v-model="form.from_time"
                      type="time"
                      class="form-control"
                      step="1"
                    >
                  </div>
                </div><!--Col-->
              </div><!--Row-->


              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="to_date">To Date</label>
                    <input
                      id="to_date"
                      v-model="form.to_date"
                      type="date"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->

                <div class="col-6">
                  <div class="form-group">
                    <label for="to_time">To Time</label>
                    <input
                      id="to_time"
                      v-model="form.to_time"
                      type="time"
                      class="form-control"
                      step="1"
                    >
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label for="interval">Interval</label>
                    <input
                      id="interval"
                      v-model="form.interval"
                      type="number"
                      class="form-control"
                      @change="postForm()"
                    >
                  </div>
                </div><!--Col-->

                <div class="col-6">
                  <div class="form-group">
                    <label for="period">Period</label>
                    <select
                      id="period"
                      v-model="form.period"
                      class="form-control text-capitalize"
                      @change="postForm()"
                      :disabled="processing"
                    >
                      <template v-for="period in periods">
                        <option
                          :value="period"
                        >
                          {{ period }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button
                type="submit"
                class="btn btn-success text-capitalize"
                :disabled="processing"
              >
                Save
              </button>
               
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Chart Style
            </p>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="type">Type</label>
                  <select
                    id="type"
                    v-model="chart_styles.type"
                    class="form-control"
                    :disabled="processing"
                    @change="updateChart()"
                  >
                    <option value="bar">
                      Bar
                    </option>
                    <option value="line">
                      Line
                    </option>
                    <option value="markers">
                      Markers
                    </option>
                    <option value="lines+markers">
                      Lines + Markers
                    </option>
                  </select>
                </div>
              </div><!--Col-->

              <div class="col-6">
                <div class="form-group">
                  <label for="color">Color</label>
                  <input
                    id="color"
                    v-model="chart_styles.color"
                    type="color"
                    class="form-control"
                    @change="updateChart()"
                  >
                </div>
              </div><!--Col-->
            </div><!--Row-->

            <template v-if="chart_styles.type == 'bar'">
              <div class="form-group">
                <label for="orientation">Orientation</label>
                <select
                  id="orientation"
                  v-model="chart_styles.orientation"
                  class="form-control"
                  :disabled="processing"
                  @change="updateChart()"
                >
                  <option value="v">
                    vertical
                  </option>
                  <option value="h">
                    horizontal
                  </option>
                </select>
              </div>
            </template>

            <div class="form-group">
              <label for="opacity">Opacity</label>
              <input
                id="opacity"
                v-model="chart_styles.opacity"
                type="range"
                class="form-control-range"
                min="0"
                max="1"
                step=".01"
                @change="updateChart()"
              >
            </div>

            <template v-if="chart_styles.type == 'markers' || chart_styles.type == 'lines+markers'">
              <div class="form-group">
                <label for="size">Marker Size</label>
                <input
                  id="size"
                  v-model="chart_styles.marker['size']"
                  type="range"
                  class="form-control-range"
                  @change="updateChart()"
                >
              </div>

              <div class="form-group">
                <label for="marker_color">Marker Color</label>
                <input
                  id="marker_color"
                  v-model="chart_styles.marker['color']"
                  type="color"
                  class="form-control"
                  @change="updateChart()"
                >
              </div>
            </template>

            <template v-if="chart_styles.type == 'line' || chart_styles.type == 'lines+markers'">
              <div class="form-group">
                <label for="line_shape">Line Shape</label>
                <select
                  id="line_shape"
                  v-model="chart_styles.line['shape']"
                  class="form-control"
                  :disabled="processing"
                  @change="updateChart()"
                >
                  <option value="linear">
                    linear
                  </option>
                  <option value="spline">
                    spline
                  </option>
                  <option value="hvh">
                    hvh
                  </option>
                  <option value="vh">
                    vh
                  </option>
                  <option value="hv">
                    hv
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="line_dash">Line Dash</label>
                <select
                  id="line_dash"
                  v-model="chart_styles.line['dash']"
                  class="form-control"
                  :disabled="processing"
                  @change="updateChart()"
                >
                  <option value="solid">
                    solid
                  </option>
                  <option value="dashdot">
                    dashdot
                  </option>
                  <option value="dot">
                    dot
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="line_width">Line Width</label>
                <input
                  id="line_width"
                  v-model="chart_styles.line['width']"
                  type="range"
                  class="form-control-range"
                  @change="updateChart()"
                >
              </div>                  

              <div class="form-group">
                <label for="line_color">Line Color</label>
                <input
                  id="line_color"
                  v-model="chart_styles.line['color']"
                  type="color"
                  class="form-control"
                  @change="updateChart()"
                >
              </div>

              <div class="form-check">
                <input
                  id="connect_gaps"
                  v-model="chart_styles.connectgaps"
                  type="checkbox"
                  class="form-check-input"
                  @change="updateChart()"
                >
                <label
                  class="form-check-label"
                  for="connect_gaps"
                >Connect Gaps</label>
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="form.uuid">
          <div class="card mt-3">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-sm-12 col-md-6">
                  <p class="card-title">
                    {{ form.filters.length }} Filters
                  </p>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6 text-end">
                  <template v-if="!show_filter_form">
                    <button
                      type="button"
                      class="btn btn-outline-success"
                      @click="addFilter"
                      data-tooltip="Add Filter"
                      :disabled="processing"
                    >
                      <i class="fa fa-plus"></i> <span class="sr-only">Add Filter</span>
                    </button>
                  </template>

                  <template v-if="show_filter_form && filter_action == 'add'">
                    <button
                      type="button"
                      class="btn btn-success"
                      @click="saveFilter"
                      data-tooltip="Save Filter"
                      :disabled="processing"
                    >
                      <i class="fa fa-save"></i> <span class="sr-only">Save Filter</span>
                    </button>
                  </template>

                  <template v-if="show_filter_form && filter_action == 'update'">
                    <button
                      type="button"
                      class="btn btn-success"
                      @click="updateFilter"
                      :disabled="processing"
                    >
                      Save
                    </button>
                  </template>
                </div><!--Col-->
              </div><!--Row-->

              <template v-if="!show_filter_form">
                <ul class="list-group">
                  <template v-for="filter in form.filters">
                    <li
                      class="list-group-item link-hover"
                      @click="setFilterForm(filter)"
                    >
                      {{ filter.field }} {{ filter.operator }} {{ filter.value }}
                    </li>
                  </template>
                </ul>
              </template>

              <template v-if="show_filter_form">
                <div class="form-group">
                  <label for="filter_field">Field</label>
                  <select
                    id="filter_field"
                    v-model="filter_form.field"
                    class="form-control"
                    :disabled="processing"
                  >
                    <template v-if="form.data_source && fields[form.data_source]">
                      <template v-for="field in fields[form.data_source]">
                        <option
                          :value="field"
                        >
                          {{ field }}
                        </option>
                      </template>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="filter_operator">Operator</label>
                  <select
                    id="filter_operator"
                    v-model="filter_form.operator"
                    class="form-control"
                    :disabled="processing"
                  >
                    <template v-for="operator in operators">
                      <option
                        :value="operator"
                      >
                        {{ operator }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="filter_value">Value</label>
                  <input
                    id="filter_value"
                    v-model="filter_form.value"
                    :type="setFieldType(filter_form)"
                    class="form-control"
                  >
                </div>

                <button
                  type="button"
                  class="btn btn-outline-danger"
                  :disabled="processing"
                  @click="trashFilter"
                >
                  <i class="fa fa-trash" /> <span class="sr-only">Delete</span>
                </button>
              </template>
            </div><!--Body-->
          </div><!--Card-->
        </template>

        <template v-if="form.uuid">
          <audit-logs
            class="mt-3"
            :form="form"
          />
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <div
              ref="chart"
              class="chart-xl"
            >
              <loader :is-loading="loading" />
            </div>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <p class="card-title">
              Calculations 

              <template v-if="form.interval && form.period">
                per {{ form.interval }} {{ form.period }}<template v-if="form.interval >= 2">
                  s
                </template>
              </template>
            </p>

            <template v-if="form.calculations">
              <ul class="list-group">
              <template v-for="(calculation, index) in form.calculations">
                <template v-if="calculation">
                  <li
                    class="list-group-item"
                  >
                    <span class="text-uppercase"><strong>{{ index }}</strong></span> {{ calculation }}
                  </li>
                </template>
              </template>
              </ul>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-for="result in datas">
          <div
            class="card mt-3"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <template v-if="result.name">
                    <p class="card-title text-capitalize m-0">
                      {{ result.name }}
                    </p>
                    <p class="text-muted">
                      Date Filter: {{ result.date_filter }}
                    </p>
                  </template>
                </div><!--Col-->

                <div class="col-md-4" /><!--Col-->

                <div class="col-md-4 text-end">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="downloadCSV()"
                  >
                    <i class="fa fa-file-csv" /> CSV
                  </button>
                </div><!--Col-->
              </div><!--Row-->

              <div class="table-responsive">
              <table class="table table-striped table-hover table-sm">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Value</th>
                  </tr>
                </thead>

                <template v-if="result.y">
                  <tbody>
                    <template v-for="(value, y_index) in result.y">
                      <tr>
                        <th>{{ result.x[y_index] | date }}</th>
                        <th>{{ value }}<sup v-if="result.symbol">{{ result.symbol }}</sup></th>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </table>
              </div><!--Responsive-->
            </div><!--Body-->
          </div><!--Card-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import Plotly from 'plotly.js-dist';
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      loading: false,
      processing: false,
      show_filter_form: false,
      chart_styles: {},
      form: {},
      queries: {},
      date_range: [],
      datas:[],
      periods: ['day', 'week', 'month', 'quarter', 'year'],
      chart_layout: {editable: true, displayLogo: false, responsive: true},
      operators: ['=', '!=', 'contains', 'not contains', '<', '<=', '>', '>='],
      filters: [],
      fields: {
        'events': ['date', 'time', 'judge_name', 'status', 'appeared', 'type', 'description', 'location_notes', 'event_reference', 'case_number', 'location', 'flow', 'created_at', 'updated_at', 'archived_at', 'deleted_at'],
        'messages': ['from', 'to', 'last_status', 'last_open', 'direction', 'channel', 'language', 'subject', 'content', 'sent', 'test', 'type', 'mms', 'error_code', 'segments', 'credits', 'scheduled_at', 'created_at'],
        'clients': ['client_reference', 'group', 'type', 'language', 'status', 'dob', 'gender', 'race', 'marital_status', 'birth_place', 'birth_country', 'citizenship', 'created_at', 'updated_at', 'archived_at', 'deleted_at'],
        'cases': ['case_number', 'arresting_agency', 'arrest_tracking', 'status', 'type', 'arrest_date', 'offense_date', 'file_date', 'judgement_date', 'codes', 'description', 'conditions', 'charge_number', 'warrant_status', 'appeared', 'created_at', 'updated_at', 'archived_at', 'deleted_at'],
        'contacts': ['value', 'channel', 'type', 'valid', 'notify', 'optin', 'primary', 'created_at', 'updated_at', 'archived_at', 'deleted_at']
      },
      date_filters: {
        'events': ['date', 'created_at'],
        'messages': ['scheduled_at', 'created_at'],
        'clients': ['created_at'],
        'cases': ['created_at'],
        'contacts': ['created_at'] 
      },
      datapoint_types: ['count', 'sum', 'rate', 'average'],
      filter_form: {
        field: null,
        operator: '=',
        value: null
      },
      filter_action: 'add',
      data_sources: ['messages', 'contacts', 'events', 'cases', 'payments']
    }
  },

  created () {
    this.resetForms();
    this.resetQueries();
    this.resetFilterForm();
    this.setURLParams();
    this.getRecord();
  },

  methods: {

    resetQueries() {
      this.queries = {
        data_source: null,
        interval: null,
        title: null,
        date_filter: null,
        data_type: null,
        filters: null
      }
    },

    resetForms() {

        this.chart_styles = {
            type: 'bar',
            connectgaps: true,
            mode: 'lines',
            orientation: 'v',
            size: 12,
            opacity: .9,
            width: 3,
            line: {
                width: 1,
                color: null,
                shape: 'linear',
                dash: 'solid'
            },
            marker: {
                size: 1,
                color: null
            }           
        }

        this.form = {
            title: null,
            description: null,
            key: null,
            data_filter: null,
            data_source: null,
            type: null,
            from_date: null,
            to_date: null,
            from_time: null,
            to_time: null,
            period: null,
            interval: null,
            simulate: 0
        }

    },

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.form[key] = value;
        });
    },

    setFieldType(filter_form) {

        if(!filter_form.field) {
            return 'text';
        }

        if(['date', 'dob'].includes(filter_form.field)) {
            return 'date';
        }

        if(['time'].includes(filter_form.field)) {
            return 'time';
        }

        if(['scheduled_at', 'created_at', 'updated_at', 'deleted_at', 'archived_at'].includes(filter_form.field)) {
            return 'datetime-local';
        }

        return 'text';
    },

    postForm() {
        this.processing = true;
        this.$http.patch('/'+this.$route.meta.base_url+'/'+this.$route.params.id, this.form)
        .then(response => {
            if(response && response.status === 200) {

                this.$eventBus.$emit('show-alert', {
                  text: 'Updating '+this.$route.meta.title
                });

                this.getRecord();
            }
        })
    },

    trashForm() {

        if(!this.form.uuid) {
            return;
        }

        this.processing = true;
        this.$http.delete('/'+this.$route.meta.base_url+'/'+this.form.uuid)
        .then(response => {
            if(response && response.status === 204) {

                this.$eventBus.$emit('show-alert', {
                  text: this.$route.meta.title + ' deleted',
                  type: 'danger'
                });

                window.location.href = '/'+this.$route.meta.base_url;
                this.processing = false;
            }
        })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url+'/'+this.$route.params.id, this.queries));
    },

    getRecord() {

        this.processing = true;

        this.updateURL();

        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/'+this.$route.params.id, this.form))
        .then(response => {
            if(response && response.data) {

                this.form = response.data;

                this.setTitle();
                this.getChart();

                this.processing = false;
                this.updateURL();
            }
        });

    },

    setTitle() {
      var name = this.form.title || this.form.name;
      document.title = name + ' | eCourtDate.com';
    },

    resetChart() {
        this.datas = [];
        this.form.values = [];
        this.form.calculations = [];
    },

    getChart() {
        this.resetChart();
        this.loading = true;
        this.$http.post('/'+this.$route.meta.base_url+'/'+this.$route.params.id, this.form)
        .then(response => {
            if(response && response.data) {
                if(response.data.values) {
                    this.form.values = response.data.values;
                    this.form.calculations = response.data.calculations;
                    this.pushChart();
                }

                this.loading = false;
            }
        })
    },

    updateChart() {
        this.datas = [];
        this.pushChart();
    },

    initChart() {

      if(!this.$refs['chart']) {
        return;
      }

      Plotly.newPlot(this.$refs['chart'], this.datas, {title: this.form.title || this.form.name, showlegend: false}, this.chart_layout);
    },

    setChartStyles() {

        this.chart_styles.color = this.chart_styles.color || this.genRandomColor();

        this.chart_styles.marker['color'] = this.chart_styles.marker['color'] || this.genRandomColor();

        this.chart_styles.line['color'] = this.chart_styles.line['color'] || this.genRandomColor();

        let chart = {
            color: this.chart_styles.color,
            date_filter: this.form.date_filter,
            from_date: this.form.from_date,
            from_time: this.form.from_time,
            to_date: this.form.to_date,
            to_time: this.form.to_time,
            interval: this.form.interval,
            period: this.form.period,
            x: this.form.dates,
            y: this.form.values,
            type: this.chart_styles.type,
            mode: this.chart_styles.mode,
            opacity: this.chart_styles.opacity,
            name: this.form.title || this.form.name
        };

        if(this.chart_styles.type == 'bar') {
            chart.orientation = this.chart_styles.orientation;
            chart.marker = {
                color: this.chart_styles.color
            }
        }

        if(this.chart_styles.type == 'line' || this.chart_styles.type == 'lines+markers') {

            chart.connectgaps = this.chart_styles.connectgaps;

            chart.line = {
                color: this.chart_styles['line'].color,
                width: this.chart_styles['line'].width,
                shape: this.chart_styles['line'].shape,
                dash: this.chart_styles['line'].dash
            }
        }

        if(this.chart_styles.type == 'markers' || this.chart_styles.type == 'lines+markers') {
            chart.marker = {
                color: this.chart_styles['marker'].color,
                width: this.chart_styles['marker'].width
            }
        }

        return chart;
    },

    pushChart() {

        this.datas.push(this.setChartStyles());

        this.initChart();

    },

    genRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    downloadCSV() {

      var csvFile, data, link;

      data = this.convertCSV()

      csvFile = new Blob([data], {type: "text/csv"});
      let fileName = this.form.title+'-'+this.form['from_date']+'-'+this.form['to_date']+'.csv';
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(csvFile, fileName);
      } else {
        link = document.createElement("a");
        link.download = fileName;
        link.href = window.URL.createObjectURL(csvFile);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
      }

    },

    convertCSV() {

        var csv = 'Date,Value\n';

        var self = this;
      
        this.datas[0]['x'].forEach(function(data,key) {  
            csv += data+','+self.datas[0]['y'][key];  
            csv += "\n";  
        });  

        return csv
    },

    saveFilter() {

        this.filter_form.index = this.form.filters.length + 1;

        this.form.filters.push(this.filter_form);
        this.postForm();
        this.show_filter_form = false;
        this.resetFilterForm();
    },

    updateFilter() {
        this.removeFilter();
        this.form.filters.push(this.filter_form);
        this.postForm();
        this.show_filter_form = false;
        this.resetFilterForm();
    },

    removeFilter() {

        if(!this.filter_form.index) {
            return;
        }

        this.form.filters.splice(this.form.filters.findIndex((obj => obj.index == this.filter_form.index)), 1);
    },

    resetFilterForm() {
        this.filter_form = {
            index: 0,
            field: null,
            operator: null,
            value: null
        }

        this.filter_action = 'add';
    },

    setFilterForm(filter) {
        this.show_filter_form = true;
        this.filter_form = filter;
        this.filter_action = 'update';
    },

    addFilter() {
        this.show_filter_form = true;
        this.filter_action = 'add';
    },

    trashFilter() {
        this.removeFilter();
        this.postForm();
        this.resetFilterForm();
        this.show_filter_form = false;
    },



  }
}
</script>