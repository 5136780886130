<template>
  <div>
  
    <page-tabs :page="$route.meta.base_url" />
    
    <div class="row">
      <div class="col-sm-12 col-lg-3 mb-4">
        <div class="card">
          <div class="card-body">

                <p class="card-title">
                  Create {{ $route.meta.title }}
                </p>

            <form @submit.prevent="postForm">
              <div class="form-group">
                <label for="name">Action Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="type">Action Type</label>
                <select
                  id="type"
                  v-model="form.type"
                  class="form-control text-capitalize"
                  :disabled="processing"
                >
                  <option
                    v-for="bulk_type in $root.bulk_action_types"
                    :value="bulk_type.value"
                  >
                    {{ bulk_type.label }}
                  </option>
                </select>
                <template v-if="descriptions && descriptions[form.type]">
                  <p class="form-text text-muted small">{{ descriptions[form.type] }}</p>
                </template>
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Create
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->



        <div class="card mt-3">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-9">

                  <p class="card-title">
                  Search {{ $route.meta.title }}s
                  </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-3 text-end">

                <button
                  type="button"
                  class="btn btn-outline-primary btn-sm"
                  @click="resetSearch()"
                  :disabled="processing"
                  data-bs-toggle="tooltip"
                  data-tooltip="Reset Search"
                >
                  <i class="fa fa-eraser"></i>
                </button>

              </div><!--Col-->

            </div>

            <form @submit.prevent="searchRecords">
              <div class="form-group">
                <label for="search_name">Action Name</label>
                <input
                  id="search_name"
                  v-model="queries.search"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_type">Action Type</label>
                <select
                  id="search_type"
                  v-model="queries.type"
                  class="form-control text-capitalize"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="search_type in $root.bulk_action_types">
                    <option
                      :value="search_type.value"
                      :disabled="processing"
                    >
                      {{ search_type.label }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="status">Action Status</label>
                <select
                  id="status"
                  v-model="queries.status"
                  class="form-control text-uppercase"
                  :disabled="processing"
                >
                  <option value="" />
                  <option
                    v-for="status in $root.bulk_action_statuses"
                    :value="status"
                  >
                    {{ status }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="search_creator">Creator</label>
                <select
                  id="search_creator"
                  v-model="queries.created_by"
                  class="form-control"
                  :disabled="processing"
                >
                  <template v-if="users && users.length > 0">
                    <option value="" />
                    <template v-for="user in users">
                      <option
                        :value="user.email"
                      >
                        {{ user.email }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="search_archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="form-check-input"
                      :disabled="processing"
                      @change="searchRecords"
                    >
                    <label
                      class="form-check-label"
                      for="search_archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="search_trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="form-check-input"
                      :disabled="processing"
                      @change="searchRecords"
                    >
                    <label
                      class="form-check-label"
                      for="search_trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="searching || processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">
            <p class="card-title">Interested in sending IPAWS alerts?</p>

            <p>Send IPAWS FEMA alerts with GovLink (coming soon).</p>

            <ul>
              <li>Send emergency alerts to the public</li>
              <li>Send alerts to mobile devices</li>
              <li>Send alerts to TV and radio</li>
              <li>Includes support for CAP, EAS, and WEA</li>
              <li>AMBER, Silver, and Blue alerts</li>
            </ul>

            <a href="https://govlink.ai" class="btn btn-primary" target="_blank">Learn More</a>

            </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->

      <div class="col-sm-12 col-lg-9">
        <div class="row">
          <div class="col-md-12">

            <div class="card">
              <div class="card-body">

                <div class="row">

                <div class="col-sm-12 col-md-6">

                  <p class="card-title">
                    {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                  </p>

                </div><!--Col-->

                <div class="col-sm-12 col-md-6 text-end">

                  <div class="btn-group d-print-none" :hidden="!total">
                    <button type="button" class="btn btn-secondary btn-sm m-0" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                    <button type="button" class="btn btn-secondary btn-sm m-0" @click="downloadCSV()" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  </div>
                </div><!--Col-->

                </div><!--Row-->

                <div class="table-responsive my-3">

                <table class="table table-hover table-striped">
                  <thead>
                    <tr>
                      <template v-for="column in columns">
                        <th>
                          <template v-if="column.key">
                            <span
                              class="text-capitalize link-hover"
                              @click="sortColumn(column)"
                            >{{ column.label }}</span>
                          </template>

                          <template v-if="!column.key">
                            <span class="text-capitalize">{{ column.label }}</span>
                          </template>

                          <template v-if="column.key == queries.sort">
                            <button
                              type="button"
                              class="btn btn-outline-secondary btn-sm ml-1"
                              :disabled="processing"
                              @click="updateSortDirection()"
                            >
                              <template v-if="queries.sortDirection == 'asc'">
                                <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                              </template>

                              <template v-if="queries.sortDirection == 'desc'">
                                <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                              </template>
                            </button>
                          </template>
                        </th>
                      </template>
                    </tr>
                  </thead>

                  <tbody>
                    <template v-if="records && records.length">
                      <template v-for="record in records">
                        <tr>
                          <td>
                            <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                              <i class="fa fa-edit"></i> Edit
                            </a>
                          </td>
                          <td>{{ record.name }}</td>
                          <td>{{ record.type }}</td>
                          <td>
                            <template v-if="record.status && record.status != '0'">
                              <span
                                class="badge badge-pill text-uppercase"
                                :class="setStatusClass(record.status)"
                              >{{ record.status }}</span>
                            </template>
                          </td>
                          <td>

                            <button
                              type="button"
                              class="btn btn-outline-secondary btn-sm"
                              @click="saveTemplate(record)"
                            >
                            <template v-if="record.template == 1">
                              <i class="fa fa-star"></i>
                            </template>
                            <template v-else>
                              <i class="fa fa-times"></i>
                            </template>
                            </button>

                          </td>
                          <td>
                            <template v-if="record.clients">
                              <span class="badge badge-pill badge-secondary">{{ record.clients }}</span>
                            </template>
                          </td>
                          <td>{{ record.created_by }}</td>
                          <td>
                            <template v-if="record.scheduled_at">
                              {{ record.scheduled_at | datetime }}
                            </template>
                          </td>
                          <td>{{ record.created_at | datetime }}

                            <template v-if="record.deleted_at">
                              <span class="badge badge-pill badge-danger">TRASHED</span>
                            </template>

                            <template v-if="record.archived_at">
                              <span class="badge badge-pill badge-warning">ARCHIVED</span>
                            </template>

                          </td>
                          <td>
                            <template v-if="record.updated_at">
                              {{ record.updated_at | datetime }}
                            </template>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
                </div><!--Responsive-->


                <template v-if="records && !records.length && !processing && !loading">
                  <div class="alert alert-warning">
                    No {{ $route.meta.title }}s found.
                  </div>
                </template>
              </div><!--Body-->
            </div><!--Card-->

          </div><!--Col-->
        </div><!--Row-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Col-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>


<script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {

mixins: [ queries ],

data() {

    return {
        processing: false,
        searching: false,
        loading: false,
        records: [],
        users: [],
        queries: {},
        form: {},
        search: {},
        total: 0,
        columns: [
        {label: 'Edit'},
        {label: 'name', key: 'name'},
        {label: 'type', key: 'type'},
        {label: 'status', key: 'status'},
        {label: 'template', key: 'template'},
        {label: '# clients', key: 'clients'},
        {label: 'creator', key: 'created_by'},
        {label: 'scheduled', key: 'scheduled_at'},
        {label: 'created', key: 'created_at'},
        {label: 'updated', key: 'updated_at'}
        ],
        descriptions: {
          'Send a Message': 'Send a bulk one-off message.',
          'Assign Users': 'Assign users to clients.',
          'Trash Clients': 'Move clients to the trash.',
          'Archive Clients': 'Archive clients.',
          'Restore Clients': 'Restore trashed or archived clients.',
          'Transfer Clients': 'Transfer clients to another agency.',
          'Update Clients': 'Update client information.',
          'Create Events': 'Create events for clients.',
        }
    }
},

mounted() {

  var self = this;


  this.$eventBus.$on(this.$route.meta.base_url, function (data) {
    self.addNewRecord(data);
  });
  
},

created() {

  this.resetQueries();
  this.resetForm();
  this.setURLParams();
  this.getRecords();
  this.getUsers();
  
},

methods: {

      setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
      },

      resetQueries() {
        this.queries = {
          skip: 0,
          limit: 10,
          search: null,
          created_by: null,
          type: null,
          status: null,
          trashed: false,
          archived: false,
          sort: 'created_at',
          sortDirection: 'desc'
        }
      },

      setURLParams() {
          var searchParams = new URLSearchParams(window.location.search);

          var self = this;

          searchParams.forEach(function(value, key) {
              self.queries[key] = value;
          });
      },

      resetSearch() {
        this.resetQueries();
        this.searchRecords();
      },

      searchRecords() {
        this.resetRecords();
        this.getRecords();
      },

      resetRecords() {
        this.records = [];
        this.queries.skip = 0;
        this.total = 0;
      },

      resetForm() {
        this.form = {
          'name': this.$route.meta.title,
          'type': this.$root.bulk_action_types[0]['value'],
          'search': {
            'limit': 250
          }
        }
      },

      postForm() {

        if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.settings) {
          this.form.search['limit'] = this.$root.current_user.current_agency.settings.default_bulk_actions_limit || 250;
        }

        this.processing = true;
        this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.data) {
            this.$eventBus.$emit('show-alert', {
              text: 'Creating '+this.$route.meta.title
            });
            this.resetForm();
            this.processing = false;
            if(response.data.uuid) {
              window.location.href = '/'+this.$route.meta.base_url + '/' + response.data.uuid;
            }
          }
          
        })
      },

      setStatusClass(status) {
        
          if(status == 'complete') {
              return 'badge-success';
          }

          if(status == 'draft') {
              return 'badge-secondary';
          }

          if(['unavailable action type', 'canceled'].includes(status)) {
              return 'badge-danger';
          }

          return 'badge-primary';
      },


      addNewRecord(data) {

        var index = _.findIndex(this.records, {'uuid': data.uuid});

        if(index == -1) {
          
          if(data.updated_at) {
            return;
          }

          this.total++;
          this.records.unshift(data);
          this.setTitle();
          return;
        }

        var records = [];

        this.records.forEach(function(record) {

          if(record.uuid == data.uuid) {
            record = {...record, ...data};
          }

          records.push(record);


        })

        this.records = records;

      },
        
      getRecords() {

        this.searching = true;

        this.updateURL();

        this.getTotal();

        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
          if(response && response.data) {
            
            if(response.data.length) {

              var self = this;

              response.data.forEach(function(data) {
                self.records.push(data);
              });
            }

            this.searching = false;

            this.queries.skip = this.records.length;
          }
        })
      },

      updateURL() {
        history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
      },

      getTotal() {
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
          if(response && response.status) {
            this.total = response.data;
            this.setTitle();
          }
        })
      },

      getUsers() {
        this.$http.get('/users?slim=true&fields=email&limit=1000&sort=email&sortDirection=asc')
        .then(response => {
          if(response && response.data) {
            this.users = response.data;
          }
        })
      },

    updateSortDirection() {

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();
      
      this.getRecords();
    },

    sortColumn(column) {

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

  setActionClass(value) {
        if(!this.form.type) {
          return;
        }

        if(this.form.type == value) {
          return 'active';
        }

    },

    updateAction(value) {
          this.form.type = value;
    },

    saveTemplate(record)
    {
      if(record.template == 1) {
        record.template = 0;
      } else {
        record.template = 1;
      }

      this.$http.patch('/'+this.$route.meta.base_url+'/'+record.uuid, record)
      .then(response => {
        if(response && response.data) {
          this.$eventBus.$emit('show-alert', {
            text: 'Template saved'
          });
        }
      })
    }
}
}
</script>