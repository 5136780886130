<template>
  <div>

    <page-tabs page="events" />
    
    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Search {{ title }}s
            </p>

            <form @submit.prevent="searchRecords">


              <div class="form-group">
                <label for="case_number">Case Number</label>
                <input
                  id="case_number"
                  v-model="queries.case_number"
                  type="search"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="event_reference">{{ setCustomField('events', 'event_reference') }}</label>
                <input
                  id="event_reference"
                  v-model="queries.event_reference"
                  type="search"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="from_date">Event From Date</label>
                <input
                  id="from_date"
                  v-model="queries.from_date"
                  type="date"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="to_date">Event To Date</label>
                <input
                  id="to_date"
                  v-model="queries.to_date"
                  type="date"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="status">Event Status</label>
                <select
                  id="status"
                  v-model="queries.status"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-if="event_statuses && event_statuses.length > 0">
                    <template v-for="event_status in event_statuses">
                      <template v-if="event_status.status">
                        <option
                          :value="event_status.status"
                        >
                          {{ event_status.status }}
                        </option>
                      </template>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="type">Event Type</label>
                <select
                  id="type"
                  v-model="queries.type"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>

                  <template v-for="event_type in event_types">
                    <option
                      :value="event_type.type"
                    >
                      {{ event_type.type }}
                    </option>
                  </template>
                </select>
              </div>


              <div class="form-group">
                <label for="reached">Reached Status</label>
                <select
                  id="reached"
                  v-model="queries.reached"
                  class="form-control text-capitalize"
                  :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>

                  <template v-for="(reason, index) in reasons">
                    <template v-if="index != 1">
                      <option
                        :value="index"
                      >
                        {{ reason }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="location">Location</label>
                <select
                  id="location"
                  v-model="queries.location"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>

                  <template v-for="location in locations">
                    <option
                      :value="location.uuid"
                    >
                      {{ location.name }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="row">

                <div class="col-sm-12 col-md-6">

                  <div class="form-check">
                    <input
                      id="archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="form-check-input"
                      :disabled="processing"
                    >
                    <label
                      class="form-check-label"
                      for="archived"
                    >Archived</label>
                  </div>

                </div><!--Col-->

                <div class="col-sm-12 col-md-6">

                  <div class="form-check">
                    <input
                      id="trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="form-check-input"
                      :disabled="processing"
                    >
                    <label
                      class="form-check-label"
                      for="trashed"
                    >Trashed</label>
                  </div>

                </div><!--Col-->

              </div><!--Row-->


                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="processing"
                >
                  Search
                </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-sm-12 col-md-4">
                <p class="card-title">
                  <span v-if="records && records.length >= 0">
                    {{ records.length.toLocaleString() }}
                  </span>

                  <template v-if="total >= 0">
                    of {{ total.toLocaleString() }}
                  </template>

                  {{ title }} Events
                </p>
              </div>

              <div class="col-sm-12 col-md-4">

                <div class="input-group-append text-end">
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    :disabled="processing"
                  >
                    Fields
                  </button>
                  <div class="dropdown-menu">
                    <p class="dropdown-header">
                      Fields
                    </p>
                    <a
                      v-for="enabled_field in columns"
                      class="dropdown-item text-capitalize"
                      :class="{'active': isEnabledField(enabled_field.key)}"
                      @click="setEnabledField(enabled_field)"
                    >{{ enabled_field.label }}</a>
                  </div>
                </div>

              </div><!--Col-->

              <div class="col-sm-12 col-md-4 text-end">

                  <button
                    v-if="!processing && !downloading"
                    type="button"
                    class="btn btn-outline-secondary m-0"
                    @click="downloadCSV"
                    :disabled="downloading"
                  >
                    CSV
                  </button>

                <template v-if="downloading">
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" :style="'width:'+downloadProgress()+'%'" :aria-valuenow="downloadProgress()" aria-valuemin="0" aria-valuemax="100">{{ downloadProgress().toLocaleString() }}%</div>
                  </div>
                </template>
              </div>
            </div><!--Row-->

            <div class="horizontal-scroll">
              <table class="table table-hover table-striped table-responsive">

                <thead>
                <tr>
                  <template v-for="column in columns">

                    <th :hidden="!isEnabledField(column.key)">
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key === queries.sort">
  
                          <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            @click="updateSortDirection()"
                          >
                          
                          <span v-if="queries.sortDirection === 'asc'">
                          <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </span>

                          <span v-if="queries.sortDirection === 'desc'">
                          <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </span>

                          </button>

                      </template>
                    </th>
                  </template>
                </tr>
                </thead>

                <tbody>
                  <template v-if="records && records.length > 0">
                    <template v-for="record in records">
                      <tr>

                        <template v-for="enabled_field_row in columns">
                        <th :hidden="!isEnabledField(enabled_field_row.key)">
                          
                          <template v-if="enabled_field_row['key'] === 'reached'">
                            <a :href="'/events/'+record.uuid">
                            <span class="badge badge-pill badge-danger text-uppercase">
                              {{ reasons[record[setFieldKey(enabled_field_row['key'])]] }}
                            </span>
                            </a>
                          </template>

                          <template v-else-if="isDateField(enabled_field_row['key'])">
                            {{ record[setFieldKey(enabled_field_row['key'])] | date }}
                          </template>

                          <template v-else-if="isDateTimeField(enabled_field_row['key'])">
                            {{ record[setFieldKey(enabled_field_row['key'])] | datetime }}
                          </template>

                          <template v-else-if="enabled_field_row['key'] === 'time'">
                            {{ record['time'] | time }}
                          </template>

                          <template v-else>
                              {{ record[setFieldKey(enabled_field_row['key'])]}}
                          </template>


                        </th>
                        </template>

                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>

              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No {{ title }}s found.
                </div>
              </template>

            </div>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">

          <div class="row mt-3">
            <div class="col-12 text-center">

                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    :disabled="processing"
                    @click="getRecords"
                  >
                    Load more
                  </button>                            

          </div><!--Col-->
        </div><!--Row-->

       </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

mixins: [ queries ],

data() {
    return {
        processing: false,
        searching: false,
        downloading: false,
        base_url: '/unreachables',
        title: 'Unreachable',
        event_types: [],
        locations: [],
        downloads: [],
        download_limit: 100,
        reasons: [],
        records: [],
        total: 0,
        queries: {},
        columns: [
            {key: 'reached', label: 'Reached Status'},
            {key: 'date', label: 'Event Date'},
            {key: 'time', label: 'Event Time'},
            {key: 'case_number', label: 'Case Number'},
            {key: 'event_reference', label: 'Event Reference'},
            {key: 'type', label: 'Event Type'},
            {key: 'description', label: 'Description'},
            {key: 'status', label: 'Event Status'},
            {key: 'appeared', label: 'Appeared'},
            {key: 'location', label: 'Location'},
            {key: 'location_notes', label: 'Location Notes'},
            {key: 'archived_at', label: 'Event Archived'},
            {key: 'deleted_at', label: 'Event Trashed'},
            {key: 'client_reference', label: 'Client Reference'},
            {key: 'first_name', label: 'First Name'},
            {key: 'last_name', label: 'Last Name'},
            {key: 'bond_number', label: 'Bond Number'},
            {key: 'collateral_type', label: 'Bond Collateral Type'}
        ],
        enabled_fields: [],
        event_statuses: []
    }
},

mounted() {

  var self = this;

  this.$eventBus.$on('refreshRecords', function(view) {

      if(view !== self.base_url) {
          return;
      }

      self.resetRecords();
      self.getRecords();

  });

},

created() {
    this.resetQueries();
    this.setURLParams();
    this.getReasons();
    this.getRecords();
    this.getLocations();
    this.getEventTypes();
    this.getEventStatuses();
},

methods: {

    downloadProgress() {
      return (this.downloads.length / this.total) * 100;
    },

    resetQueries() {
      this.queries = {
            limit: 100,
            skip: 0,
            from_date: '',
            to_date: '',
            reached: '',
            location: '',
            type: '',
            case_number: '',
            client_reference: '',
            event_reference: '',
            location_reference: '',
            status: '',
            group: '',
            archived: false,
            trashed: false,
            optout: false,
            invalid_contact: false,
            sort: 'date',
            sortDirection: 'desc',
            fields: 'reached,date,time,case_number,client_reference,type,location'
      }
    },

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });

    },

    getEventTypes() {
        this.$http.get('/event_types')
        .then(response => {
            if(response && response.data) {
                this.event_types = response.data;
            }
        })
    },

    getReasons() {
        this.$axios.get(this.$root.assets_url+'js/unreachables.json')
        .then(response => {
            if(response && response.data) {
                this.reasons = response.data;
            }
        })
    },

    isDateField(field) {
      const fields = ['date', 'dob'];
      return fields.includes(field);
    },

    isDateTimeField(field) {
      const fields = ['created_at', 'updated_at', 'archived_at', 'deleted_at'];
      return fields.includes(field);
    },

    setFieldKey(field) {

      if(field === 'location') {
        return 'location_name';
      }

      return field;
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    resetRecords() {
        this.records = [];
        this.downloads = [];
        this.total = 0;
        this.queries.skip = 0;
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries(this.base_url, this.queries));
    },

    getRecords() {

        this.updateFields();

        this.updateURL();

        this.getTotal();

        this.processing = true;
        
        this.$http.get(this.buildQueries(this.base_url, this.queries))
        .then(response => {
            if(response && response.status) {

                if(response.data && response.data.length) {
  
                  var self = this;
                    
                  response.data.forEach(function(record) {
                      self.records.push(record);
                  });

                }

                this.queries.skip = this.records.length;

                this.processing = false;
            }
        })
    },

    updateFields() {

      if(this.queries.archived) {
        this.queries.fields = this.queries.fields.concat(',archived_at');        
      } else {
        this.queries.fields = this.queries.fields.replace(',archived_at', '');
      }

      if(this.queries.trashed) {
        this.queries.fields = this.queries.fields.concat(',deleted_at');        
      } else {
        this.queries.fields = this.queries.fields.replace(',deleted_at', '');
      }

      if(this.queries.optout) {
        this.queries.fields = this.queries.fields.concat(',contacts.optin');        
      } else {
        this.queries.fields = this.queries.fields.replace(',contacts.optin', '');
      }

      if(this.queries.invalid_contact) {
        this.queries.fields = this.queries.fields.concat(',contacts.valid');        
      } else {
        this.queries.fields = this.queries.fields.replace(',contacts.valid', '');
      }

      this.enabled_fields = this.queries.fields.split(',');
    },

    getTotal() {
      this.$http.get(this.buildQueries(this.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    getLocations() {
        this.$http.get('/locations?limit=1000&slim=true&sort=name&sortDirection=asc&fields=name,uuid')
        .then(response => {
            if(response && response.data) {
                this.locations = response.data;
            }
        })
    },

    downloadCSV() {
        this.downloads = [];
        this.getExportData();
    },

    getExportData () {
        this.downloading = true;

        var queries = this.queries;

        queries.limit = this.download_limit;

        queries.skip = this.downloads.length;

        this.$http.get(this.buildQueries(this.base_url, queries))
        .then(response => {
            if(response && response.data && response.data) {
                this.downloads = this.downloads.concat(response.data);
                if (response.data.length < this.download_limit) {

                    var csvFile, data, link;

                    data = this.convertCSV(this.downloads);

                    var file = 'unreachables-'+this.downloads.length+'.csv';

                    csvFile = new Blob([data], {type: "text/csv"});
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveBlob(csvFile, file);
                    } else {
                        link = document.createElement("a");
                        link.download = file;
                        link.href = window.URL.createObjectURL(csvFile);
                        link.style.display = "none";
                        document.body.appendChild(link);
                        link.click();
                    }

                    this.downloading = false;

                } else {
                    this.getExportData();
                }

            }
        });
    },

    convertCSV(records) {

        var results = [];

        var headers = [];

        var self = this;

        this.columns.forEach(function(column) {

          if(column.label == 'Reached Status') {
            return;
          }

          if(!self.isEnabledField(column.key)) {
            return;
          }

          headers.push(column.label);

        });

        headers.unshift('Reached Status');

        results += headers.join(',');

        // results += this.columns.join(',')
        results += '\n';
        records.forEach(function(item) {
          var record = '';

          record += '"' + (item['reason'] ? item['reason'] : '') + '",';


          self.columns.forEach(function(column) {

            if(column.label == 'Reached Status') {
              return;
            }

            if(!self.isEnabledField(column.key)) {
              return;
            }

            var key = self.setFieldKey(column.key);

            record += '"' + (item[key] ? item[key] : '') + '",';
          });
          results += record
          results += '\n';
        });
        return results
    },

    isEnabledField(key) {
      return this.enabled_fields.includes(key);
    },

    setEnabledField(field) {
      
      this.toggleEnabledField(field);
      this.queries.fields = this.enabled_fields.join();
    },

    toggleEnabledField(field) {
      
      if(this.isEnabledField(field.key)) {
        this.enabled_fields.splice(this.enabled_fields.findIndex((obj => obj === field.key)), 1);
        return;
      }

      this.enabled_fields.push(field.key);
    },

    updateSortDirection() {

      if(this.queries.sortDirection === 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      this.queries.sort = column.key;

      this.updateSortDirection();

    },

    getEventStatuses() {
        this.$http.get('/event_statuses')
        .then(response => {
            if(response && response.data) {
                this.event_statuses = response.data;
            }
        })
    },

}

}
</script>