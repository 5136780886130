<template>
    <div>
  
      <page-tabs :page="$route.meta.tabs" />
  
      <div class="row">
        <div class="col-sm-12 col-md-3">
  
          <div class="card">
            <div class="card-body">
  
              <p class="card-title">Create {{ $route.meta.title }}</p>
         
              <form @submit.prevent="postForm()">
  
                <div class="form-group">
                  <label for="name" class="form-label">Name</label>
                  <input id="name" type="text" class="form-control" v-model="form.name" required>
                  <small class="form-text text-muted">You can change the name later.</small>
                </div>
  
                <button type="submit" class="btn btn-success" :disabled="processing">Create</button>
  
              </form>
  
            </div><!--Body-->
          </div><!--Card-->
  
  
          <div class="card mt-3">
            <div class="card-body">
  
              <p class="card-title">Search {{ $route.meta.title }}s</p>
  
              <form @submit.prevent="searchRecords">
                <div class="form-group">
                  <label for="search">Search by Name</label>
                  <input
                    id="search"
                    v-model="queries.search"
                    type="search"
                    class="form-control"
                    placeholder="Search..."
                  >
                </div>
  
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-check">
                      <input
                        id="archived"
                        v-model="queries.archived"
                        type="checkbox"
                        class="form-check-input"
                        @change="searchRecords"
                        :disabled="processing || queries.trashed"
                      >
                      <label
                        class="form-check-label"
                        for="archived"
                      >Archived</label>
                    </div>
                  </div><!--Col-->
  
                  <div class="col-sm-12 col-md-6">
                    <div class="form-check">
                      <input
                        id="trashed"
                        v-model="queries.trashed"
                        type="checkbox"
                        class="form-check-input"
                        @change="searchRecords"
                        :disabled="processing || queries.archived"
                      >
                      <label
                        class="form-check-label"
                        for="trashed"
                      >Trashed</label>
                    </div>
                  </div><!--Col-->
                </div><!--Row-->
  
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="processing"
                >
                  Search
                </button>
              </form>
            </div><!--Body-->
          </div><!--Card-->
  
        </div><!--Col-->
  
  
        <div class="col-sm-12 col-md-9">
  
          <div class="card">
            <div class="card-body">
  
              <div class="row">
  
                <div class="col-sm-12 col-md-6">
  
                  <p class="card-title">
                    {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                  </p>
  
                </div><!--Col-->
  
                <div class="col-sm-12 col-md-6 text-end">
  
                  <div class="btn-group d-print-none">
                    <button type="button" class="btn btn-secondary btn-sm m-0" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                    <button type="button" class="btn btn-secondary btn-sm m-0" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  </div>
                </div><!--Col-->
  
                </div><!--Row-->
  
              <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <sort-columns
                  :columns="columns"
                  :queries="queries"
                  :processing="processing"
                  :base_url="$route.meta.base_url"
                />
  
                <tbody>
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                          <i class="fa fa-edit"></i> Edit
                        </a>
                      </td>
                      <td>{{ record.name }}</td>
                        <td>{{ record.method }}</td>
                        <td>
                          <template v-if="record.file_format">
                            <span class="badge badge-primary text-uppercase">{{ record.file_format }}</span>
                          </template>
                        </td>
                        <td>
                          <template v-for="field in record.fields">
                            <span class="badge badge-primary">{{ field }}</span>
                          </template>
                        </td>
                        <td>{{ record.created_at | datetime }}</td>
                        <td>{{ record.created_by }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
              </div>
  
              <template v-if="!processing && records && !records.length">
                <div class="alert alert-warning">
                  No {{ $route.meta.title }}s found.
                </div>
              </template>
            </div><!--Body-->
          </div><!--Card-->
  
          <template v-if="records && records.length && total > records.length">
            <div class="row mt-3">
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="loadMore"
                >
                  Load more
                </button>
              </div><!--Col-->
            </div><!--Row-->
          </template>
        </div><!--Col-->
      </div><!--Row-->
    </div>
  </template>
  
  <script>
  import queries from '../mixins/queries';
  
  export default {
  
  mixins: [ queries ],
  
  data() {
      return {
          processing: false,
          show_load: false,
          searched: false,
          records: [],
          columns: [
              {label: 'Edit'},
              {key: 'name', label: 'Name'},
              {key: 'method', label: 'Method'},
              {key: 'file_format', label: 'File Format'},
              {label: 'Fields'},
              {key: 'created_at', label: 'Created'},
              {key: 'created_by', label: 'Creator'},
          ],
          queries: {},
          total: 0,
          form: {}
      }
  },
  
  
  mounted() {
  
  var self = this;
  
  this.$eventBus.$on('refreshRecords', function(view) {
  
      if(view !== self.$route.meta.base_url) {
          return;
      }
  
      self.resetRecords();
  
      self.getRecords();
  
  });
  
  },
  
  
  created() {
      this.resetForm();
      this.resetQueries();
      this.resetRecords();
      this.setURLParams();
      this.getRecords();
  },
  
  methods: {
  
      setURLParams() {
          var searchParams = new URLSearchParams(window.location.search);
  
          var self = this;
  
          searchParams.forEach(function(value, key) {
              self.queries[key] = value;
          });
      },
  
      resetForm() {
        this.form = {
          name: null,
          method: 'update_fields',
          file_format: 'auto'
        }
      },
  
      resetQueries() {
        this.queries = {
          skip: 0,
          limit: 10,
          sort: 'created_at',
          sortDirection: 'desc',
          redirect_url: null,
          url: null,
          search: null
        }
      },
  
      resetRecords() {
        this.records = [];
        this.queries.skip = 0;
        this.total = 0;      
      },
    
      postForm() {
        this.processing = true;
  
        this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.data) {
  
            this.processing = false;
  
            if(!response.data.uuid) {
              this.$eventBus.$emit('show-alert', {
                text: 'Error creating '+this.$route.meta.title,
                type: 'danger'
              });
              return;
            }
  
            this.$eventBus.$emit('show-alert', {
              text: this.$route.meta.title + ' created'
            });

            this.records.unshift(response.data);
            this.total++;
            this.resetForm();
            this.processing = false;
            this.$router.push({name: this.$route.meta.base_url+'.edit', params: {id: response.data.uuid}});
          }
        })
      },
  
      getRecords() {
  
        this.processing = true;
  
          this.getTotal();

          this.updateURL();
  
          this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
          .then(response => {
              if(response && response.data) {
  
                  this.records = response.data
  
                  if (response.data.length < this.queries.limit) {
                      this.show_load = false
                  } else {
                      this.show_load = true
                  }
  
                  this.processing = false
              }
          })
      },
  
      getTotal() {
          this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
              if(response && response.status) {
                  this.total = response.data;
                  this.setTitle();
              }
          })
      },
  
      setTitle() {
        document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
      },
  
      loadMore() {
        this.processing = true;
        this.show_load = false;
  
        this.queries.skip = this.records.length;
  
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {
  
                if(response.data.length < this.queries.limit) {
                  this.show_load = false;
                } else {
                  this.show_load = true;
                }
  
                if(response.data.length > 0) {
                  response.data.forEach(r => {
                      this.records.push(r);
                      this.total++;
                  })                
                }
  
                this.processing = false;
            } else {
              this.show_load = false;
            }
          });
      },
        
      searchRecords() {
        this.searched = true;
        this.resetRecords();
        this.getRecords();
      },
  
  
  }
  
  }
  </script>