<template>
    <div>
  
      <page-tabs :page="$route.meta.tabs" />
  
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <div class="card mb-3" v-if="!['opens', 'geolocations'].includes($route.meta.base_url)">
            <div class="card-body">
              <p class="card-title">
                Create {{ $route.meta.title }}
              </p>
  
              <form @submit.prevent="postForm()">

                <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" id="name" v-model="form.name" class="form-control" :disabled="processing" required>
                </div>

                <div class="form-group">
                    <label for="portal">Portal</label>
                    <select id="portal" v-model="form.portal" class="form-control" :disabled="processing">
                      <option value="">Select Portal</option>
                      <option v-for="portal in portals" :value="portal.uuid">{{ portal.name }}</option>
                    </select>
                </div>

                <div class="form-group">
                  <label for="location">Location</label>
                  <select id="location" v-model="form.location" class="form-control" :disabled="processing">
                    <option value="">Select Location</option>
                    <option v-for="location in locations" :value="location.uuid">{{ location.name }}</option>
                  </select>
                </div>
  
                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                  >
                    Create
                  </button>
  
              </form>
            </div><!--Body-->
          </div><!--Card-->

          <div class="card mb-3">
            <div class="card-body">

              <p class="card-title">Search {{ $route.meta.title }}s</p>

              <form @submit.prevent="searchRecords()">

                <div class="form-group">
                <label for="search">General Search</label>
                <input
                  id="search"
                  type="text"
                  v-model="queries.search"
                  class="form-control"
                  :disabled="processing"
                >
              </div>

              <div class="form-group">
                <label for="search_portal">Portal</label>
                <select id="search_portal" v-model="queries.portal" class="form-control" :disabled="processing">
                  <option value="">Select Portal</option>
                  <template v-for="portal in portals">
                    <option :value="portal.uuid">{{ portal.name }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_location">Location</label>
                <select id="search_location" v-model="queries.location" class="form-control" :disabled="processing">
                  <option value="">Select Location</option>
                  <template v-for="location in locations">
                    <option :value="location.uuid">{{ location.name }}</option>
                  </template>
                </select>
              </div>
              
              

                <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="search_archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="form-check-input"
                      @change="searchRecords"
                      :disabled="processing || queries.trashed"
                    >
                    <label
                      class="form-check-label"
                      for="search_archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="search_trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="form-check-input"
                      @change="searchRecords"
                      :disabled="processing || queries.archived"
                    >
                    <label
                      class="form-check-label"
                      for="search_trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

                    <button type="submit" class="btn btn-primary" :disabled="processing">
                      Search
                    </button>
               
              </form>
            </div>
          </div>


        </div><!--Col-->
  
        <div class="col-sm-12 col-md-9">

          <div class="card">
            <div class="card-body">

              <div class="row">

                <div class="col-sm-12 col-md-6">

                  <p class="card-title">
                    <template v-if="processing">
                      <i class="fa fa-spinner fa-spin text-primary"></i>
                    </template>
                    <template v-else>
                      {{ records.length }} of {{ total.toLocaleString() }} results
                    </template>
                  </p>

                </div><!--Col-->

                <div class="col-sm-12 col-md-6 text-end">

                  <div class="btn-group d-print-none">
                    <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                    <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  </div>
                </div><!--Col-->

                </div><!--Row-->
  
  
              <div class="table-responsive my-3">

                <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <template v-for="column in columns">
                      <th
                        class="text-uppercase"
                      >
                        <template v-if="column.key">
                          <span
                            class="text-capitalize link-hover"
                            @click="sortColumn(column)"
                          >{{ column.label }}</span>
                        </template>
  
                        <template v-if="!column.key">
                          <span class="text-capitalize">{{ column.label }}</span>
                        </template>
  
                        <template v-if="column.key == queries.sort">
                          <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            @click="updateSortDirection()"
                          >
                            <template v-if="queries.sortDirection == 'asc'">
                              <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                            </template>
  
                            <template v-if="queries.sortDirection == 'desc'">
                              <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                            </template>
                          </button>
                        </template>
                      </th>
                    </template>
                  </tr>
                </thead>
  
                <tbody>
                  <template v-if="records && records.length > 0">
                    <template v-for="record in records">
                      <tr>
                        <td>
                          <a :href="$route.meta.base_url+'/'+record.uuid">
                            <i class="fa fa-edit"></i> Edit
                          </a>
                        </td>
                        <td>
                          {{ record.name }}
                        </td>
                        <td>
                          <template v-if="record.portal">
                            {{  setPortalName(record) }}
                          </template>
                        </td>
                        <td>
                          <template v-if="record.location">
                            {{ setLocationName(record) }}
                          </template>
                        </td>
                        <td>
                          <template v-if="record.virtual">
                            <i class="fa fa-check"></i>
                          </template>
                          <template v-else>
                            <i class="fa fa-times"></i>
                          </template>
                        </td>
                        <td>
                          <template v-if="record.allow_manual">
                            <i class="fa fa-check"></i>
                          </template>
                          <template v-else>
                            <i class="fa fa-times"></i>
                          </template>
                        </td>
                        <td>{{ record.event_type }}</td>
                        <td>{{ record.event_status }}</td>
                        <td>{{ record.description }}</td>
                        <td>
                          {{ record.created_at | datetime }}
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>


              </div><!--Responsive-->
  
              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No results found.
                </div>
              </template>
            </div><!--Body-->
          </div><!--Card-->
  
          <template v-if="records && records.length && total > records.length">
            <div class="row">
              <div class="col-12 text-center">
                <button
                  type="button"
                  ref="loadMoreButton"
                  class="btn btn-outline-primary mt-3"
                  :disabled="processing"
                  @click="getRecords()"
                >
                  Load more
                </button>
              </div>
            </div>
          </template>
  
        </div><!--Col-->
      </div><!--Row-->

    </div>
  </template>
  <script>
  import _ from "lodash";
  import queries from '../mixins/queries';
  
  export default {
  
    mixins: [ queries ],
  
    data () {
      return {
        processing: false,
        downloading: false,
        show_search: false,
        errors: null,
        total: 0,
        queries: {},
        downloads: [],
        portals: [],
        locations: [],
        records: [],
        columns: [
        { label: 'Edit'},
        { key: 'name', label: 'Name'},
        { key: 'portal', label: 'Portal'},
        { key: 'location', label: 'Location'},
        { key: 'virtual', label: 'Virtual'},
        { key: 'allow_manual', label: 'Allow Manual'},
        { key: 'event_type', label: 'Event Type'},
        { key: 'event_status', label: 'Event Status'},
        { key: 'description', label: 'Description'},
        { key: 'created_at', label: 'Created'}
        ],
        form: {}
      }
    },

    mounted() {

    var self = this;

    this.$eventBus.$on('errors', function (errors) {
      self.processing = false;
      self.downloading = false;
      self.errors = errors.data || errors;
    });

    },

    created() {
      this.setDocTitle(this.$route.meta.title+'s');

      this.resetForm();
      this.resetQueries();
      this.resetRecords();
      this.setURLParams();
      this.getRecords();
      this.getPortals();
      this.getLocations();
    },

    
    methods: {
  
      resetQueries() {
        this.queries = {
          limit: 10,
          skip: 0,
          sort: 'created_at',
          sortDirection: 'desc',
          archived: false,
          trashed: false,
          type: null,
          status: null,
          location: null,
          portal: null,
        }
      },
  
      resetForm() {
        this.form = {
            name: '',
            portal: '',
            status: 'draft',
        };
      },

      resetSearch() {
        this.resetQueries();
        this.resetRecords();
        this.getRecords();
      },
  
      resetRecords() {
        this.records = [];
        this.total = 0;
        this.queries.skip = 0;
        this.queries.limit = 10;
      },
  
      updateSortDirection() {
  
        if(this.processing) {
          return;
        }
  
        if(this.queries.sortDirection == 'asc') {
          this.queries.sortDirection = 'desc';
        } else {
          this.queries.sortDirection = 'asc';
        }
  
        this.resetRecords();
  
        this.getRecords();
      },
  
      sortColumn(column) {
  
        if(this.processing) {
          return;
        }
  
        this.queries.sort = column.key;
  
        this.updateSortDirection();
      },

      searchRecords() {
        this.resetRecords();
        this.getRecords();
      },
  
      getRecords() {
  
        this.processing = true;
  
        this.updateURL();
        this.getTotal();
  
        this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
        .then(response => {
          if(response && response.data) {
              
              if(response.data.length) {
  
                  var self = this;
  
                  response.data.forEach(function(data) {
                      self.records.push(data);
                  });
              }
              
              this.queries.skip = this.records.length;
  
              this.processing = false;
          }
        })
      },
  
      getTotal() {
        this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
          if(response && response.status === 200) {
            this.total = response.data;
          }
        })
      },
  
      updateURL() {
        history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
      },

  
      postForm() {
        this.processing = true;

        this.errors = null;

        if(!this.form.name) {
            this.form.name = this.form.redirect_url;
        }

        this.$http.post(this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.data) {
  
            this.processing = false;
  
            if(response.status === 201) {
              this.resetForm();
              this.total++;
              this.records.unshift(response.data);
              this.$eventBus.$emit('show-alert', {
                text: this.$route.meta.title + ' created'
              });

              if(response.data.uuid) {
                window.location.href = this.$route.meta.base_url + '/' + response.data.uuid;
              }
            }
  
          }
        })
      },
  
      setPortalName(record) {
  
        if(!record.portal || !this.portals) {
          return;
        }
  
        var portal = _.find(this.portals, {'uuid': record.portal});
  
        if(!portal || !portal.name) {
          return;
        }
  
        return portal.name;
      },

      setLocationName(record) {
  
        if(!record.location || !this.locations) {
          return;
      }

      var location = _.find(this.locations, {'uuid': record.location});

      if(!location || !location.name) {
        return;
      }

        return location.name;
      },
  
      getPortals () {
        this.$http.get('/portals?slim=true&limit=1000&sort=default&sortDirection=desc&fields=uuid,name,link')
            .then(response => {
              if (response && response.data && response.data.length) {
                this.portals = response.data;

                if(this.portals[0] && !this.form.portal) {
                  this.form.portal = this.portals[0].uuid;
                }
              }
            })
      },

      getLocations () {
        this.$http.get('/locations?slim=true&limit=1000&sort=default&sortDirection=desc&fields=uuid,name')
            .then(response => {
              if (response && response.data && response.data.length) {
                this.locations = response.data;

                if(this.locations[0] && !this.form.location) {
                  this.form.location = this.locations[0].uuid;
                }
              }
            })
      },

    },
  }
  </script>