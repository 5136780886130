<template>
  <div>
    <template v-if="record.uuid">
      <router-link
        class="text-capitalize text-decoration-none"
        :to="{ 'name': 'clients.edit', 'params': {'id': record.uuid} }"
        :class="isPrivacyMode ? 'privacy-mode' : ''"
      >
        {{ record.client_reference }} 
        <span v-if="record.name">
          {{ record.name }}
        </span>
        <span v-else>
          {{ record.first_name }} {{ record.last_name }}
        </span>
        {{ record.group }}
        <template v-if="record.language">
          <br><small>{{ setLanguage(record.language) }}</small>
        </template>
      </router-link>
    </template>
  </div>
</template>
<script>
import queries from '../mixins/queries';
export default {

  mixins: [ queries ],

  props: {
    uuid: {
      type: String,
      default: null
    }
  },

data() {
    return {
        record: {},
        base_url: '/clients'
    }
},

created() {
    this.getRecord();
    this.getLanguages();
},

methods: {

        getRecord() {
            
            if(!this.uuid || !this.$options.filters.isValidUUID(this.uuid)) {
                return;
            }

            this.record = {};

            this.$http.get(this.base_url+'/'+this.uuid+'?slim=true')
            .then(response => {
                if(response && response.status) {

                  if(response.data) {
                    this.record = response.data;
                  }

                }
            });
        }

}

}
</script>