<template>
  <div>

    <page-tabs page="clients" />

    <div class="row">
      <div class="col-sm-12 col-md-3">

          <client-search />

        <div class="card mb-3">
          <div class="card-body">

              <p class="card-title">Create {{ title }}</p>

            <form @submit.prevent="postForm">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="contact">Phone or Email</label>
                <input
                  id="contact"
                  v-model="form.contact"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Create
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->


        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Search {{ title }}s
            </p>

            <form @submit.prevent="searchRecords">
              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  type="search"
                  class="form-control"
                  placeholder="Search..."
                >
              </div>

              <div class="form-group">
                <label for="search_name">Name</label>
                <input
                  id="search_name"
                  v-model="queries.name"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_channel">Channel</label>
                <select
                  id="search_channel"
                  v-model="queries.channel"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="" />
                  <option value="text">
                    Text
                  </option>
                  <option value="email">
                    Email
                  </option>
                  <option value="voice">
                    Voice
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="search_created_by">Creator</label>
                <select 
                  id="search_created_by"
                  v-model="queries.created_by"
                  class="form-control" 
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="user in users">
                    <option 
                      :value="user.email"
                    >
                      {{ user.name }} {{ user.email }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="row mt-3">
                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="search_opt_in"
                      v-model="queries.optin"
                      type="checkbox"
                      class="form-check-input"
                      :disabled="processing"
                    >
                    <label
                      class="form-check-label"
                      for="search_opt_in"
                    >Opted in</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="search_opt_out"
                      v-model="queries.optout"
                      type="checkbox"
                      class="form-check-input"
                      :disabled="processing"
                    >
                    <label
                      class="form-check-label"
                      for="search_opt_out"
                    >Opted out</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="search_archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="form-check-input"
                      :disabled="processing"
                      @change="searchRecords"
                    >
                    <label
                      class="form-check-label"
                      for="search_archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="search_trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="form-check-input"
                      :disabled="processing"
                      @change="searchRecords"
                    >
                    <label
                      class="form-check-label"
                      for="search_trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="search_valid"
                      v-model="queries.valid"
                      type="checkbox"
                      class="form-check-input"
                      :disabled="processing"
                    >
                    <label
                      class="form-check-label"
                      for="search_valid"
                    >Valid</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="search_invalid"
                      v-model="queries.invalid"
                      type="checkbox"
                      class="form-check-input"
                      :disabled="processing"
                    >
                    <label
                      class="form-check-label"
                      for="search_invalid"
                    >Invalid</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">

            <div class="row">

            <div class="col-sm-12 col-md-6">

              <p class="card-title">
                {{ records.length }} of {{ total.toLocaleString() }} {{ title }}s
              </p>

            </div><!--Col-->

            <div class="col-sm-12 col-md-6 d-flex justify-content-end">

              <div class="btn-group d-print-none">
                <button type="button" class="btn btn-secondary btn-sm m-0" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                <button type="button" class="btn btn-secondary btn-sm m-0" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
              </div>
            </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">

            <table class="table table-striped table-hover" style="overflow-x: auto !important;">
              <sort-columns
                :columns="columns"
                :queries="queries"
                :processing="processing"
                :base_url="$route.meta.base_url"
              />
              <tbody>
                <template v-for="record in records">
                  <tr>
                    <td>
                      <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                        <i class="fa fa-edit"></i> Edit
                      </a>
                    </td>
                    <td>
                      {{ record.value }}
                    </td>
                    <td>{{ record.channel }}</td>
                    <td>
                      <template v-if="record.name">
                        {{ record.name }}
                      </template>
                    </td>
                    <td>
                      <template v-if="record.client">
                        <client-button :uuid="record.client" />
                      </template>
                    </td>
                    <td>{{ record.type }}</td>
                    <td>
                      <template v-if="record.optin == 0">
                        <span class="badge badge-danger">OPTED-OUT</span>
                      </template>

                      <template v-if="record.optin == 1">
                        <span class="badge badge-success">OPTED-IN</span>
                      </template>
                    </td>
                    <td>
                      <template v-if="record.valid === 1">
                        <i class="fa fa-check text-success" /> <span class="sr-only">Valid</span>
                      </template>
                      <template v-else>
                        <i class="fa fa-times text-danger" /> <span class="sr-only">Invalid</span>
                      </template>
                    </td>
                    <td>
                      <template v-if="record.notify === 1">
                        <i class="fa fa-check text-success" /> <span class="sr-only">Notify</span>
                      </template>
                      <template v-else>
                        <i class="fa fa-times text-danger" /> <span class="sr-only">Don't Notify</span>
                      </template>
                    </td>
                    <td>
                      <template v-if="record.primary === 1">
                        <i class="fa fa-check text-success" /> <span class="sr-only">Primary</span>
                      </template>
                      <template v-else>
                        <i class="fa fa-times text-danger" /> <span class="sr-only">Not Primary</span>
                      </template>
                    </td>
                    <td>{{ record.created_by }}</td>
                    <td>
                      {{ record.created_at | datetime }}

                      <template v-if="record.deleted_at">
                        <span class="badge badge-danger">Trashed</span>
                      </template>

                      <template v-if="record.archived_at">
                        <span class="badge badge-warning">Archived</span>
                      </template>
                    </td>
                    <td>{{ record.updated_at | datetime }}</td>
                  </tr>
                </template>
              </tbody>
            </table>

            </div><!--Table-->

              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No {{ title }}s found.
                </div>
              </template>
            
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div>
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {

    mixins: [ queries ],

    data () {
        return {
            processing: false,
            title: null,
            records: [],
            form: {},
            queries: {},
            columns: [
            { label: 'Edit'},
            { key: 'value', label: 'Contact'},
            { key: 'channel', label: 'Channel'},
            { key: 'name', label: 'Name' },
            { key: 'client', label: 'Client'},
            { key: 'type', label: 'Type'},
            { key: 'optin', label: 'Opt-in'},
            { key: 'valid', label: 'Valid' },
            { key: 'notify', label: 'Notify' },
            { key: 'primary', label: 'Primary' },
            { key: 'created_by', label: 'Creator'},
            { key: 'created_at', label: 'Created'},
            { key: 'updated_at', label: 'Updated'},
            ],
            total: 0,
            users: []
        }
    },

    mounted() {

        var self = this;

        this.$eventBus.$on('refreshRecords', function(view) {

            if(view !== self.$route.meta.base_url) {
                return;
            }

            self.resetRecords();

            self.getRecords();

        });

        this.$eventBus.$on('search_clients', (uuid) => {

          if(uuid) {
            self.queries.client = uuid;
          } else {
            self.queries.client = null;
          }

          self.resetRecords();
          self.getRecords();
        });

    },

    created () {

        this.title = this.setCustomLabel(this.$route.meta.title);
        this.setDocTitle(this.title+'s');
        this.resetForm();
        this.resetQueries();
        this.setURLParams();
        this.getRecords();
        this.getUsers();

    },

    methods: {

        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);

            var self = this;

            searchParams.forEach(function(value, key) {
                self.queries[key] = value;
            });
        },

        resetRecords() {
            this.records = [];
            this.queries.skip = 0;
            this.total = 0;
        },

        resetQueries() {
          this.queries = {
              search: '',
              name: '',
              channel: '',
              client: null,
              limit: 10,
              skip: 0,
              upload: null,
              slim: true,
              archived: false,
              trashed: false,
              optin: false,
              optout: false,
              created_by: null,
              type: null,
              sortDirection: 'desc',
              sort: 'created_at'
            }
        },

        resetForm() {
            this.form = {
                contact: null,
                client: null,
                name: null
            }
        },

        searchRecords () {
            this.resetRecords();
            this.getRecords();
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        getRecords() {

            this.processing = true;

            this.updateURL();
            this.getTotal();

            this.filtered_url = this.buildQueries('/'+this.$route.meta.base_url, this.queries)
            this.$http.get(this.filtered_url)
            .then(response => {
                if(response && response.data) {

                    var self = this;

                    response.data.forEach(function(data) {
                        self.pushRecord(data);
                    });

                    this.queries.skip = this.records.length;
                    this.processing = false;
                }

            })
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status) {
                    this.total = response.data;
                }
            })
        },

        postForm() {
            
            this.processing = true;

            if(this.queries.client) {
                this.form.client = this.queries.client;
            }

            this.$http.post('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                if(response && response.data) {

                    this.processing = false;

                    if(response.status === 201) {
                        this.records.unshift(response.data);
                        this.total++;
                        this.resetForm();
                        this.$eventBus.$emit('show-alert', {
                          text: this.title + ' created'
                        });
                    }
                }
            })
        },

        getUsers() {
          this.$http.get('/users?slim=true&limit=1000&fields=name,email&sort=name&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.users = response.data;
            }
          })
        },


        pushRecord(data) {

          if(this.queries.client && !data.client) {
            return;
          }

          if(this.queries.client && data.client) {
            if(this.queries.client != data.client) {
              return;
            }
          }

          var count = this.records.length;

          if(this.records.length > count) {
            this.records.splice(-1);
          }

          var index = _.findIndex(this.records, {'uuid': data.uuid});

          if(index == -1) {
            this.records.push(data);        
            return;
          }

          var records = [];

          this.records.forEach(function(record) {

            if(record.uuid == data.uuid) {
              record = {...record, ...data};
            }

            records.push(record);


          })

          this.records = records;
        },

    }
}
</script>
