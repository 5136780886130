<template>
  <div>

    <template v-if="coordinates.latitude">
      <span class="badge badge-secondary link-hover me-1" @click="copyToClipboard(coordinates.latitude)">Latitude {{ coordinates.latitude }}</span>
    </template>

    <template v-if="coordinates.longitude">
      <span class="badge badge-secondary link-hover" @click="copyToClipboard(coordinates.longitude)">Longitude {{ coordinates.longitude }}</span>
    </template>

  </div>
</template>
<script>
export default {

  props: ['coordinates'],

data() {
    return {

    }
},

methods: {

    copyToClipboard(value) {
        navigator.clipboard.writeText(value);
        this.$eventBus.$emit('show-alert', {
          text: 'Copied to clipboard'
        });
    }

}

}
</script>