<template>
  <div>

    <page-tabs page="reports" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
            <div class="card">
              <div class="card-body">
                <p class="card-title">
                  Create
                </p>

                <form
                  @submit.prevent="postForm"
                >
                  <div class="form-group">
                    <label for="name">{{ $route.meta.title }} Name</label>
                    <input
                      id="name"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      required
                    >
                  </div>
                
                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                  >
                    Add
                  </button>
                </form>
              </div><!--Body-->
            </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">
            <p class="card-title">
              Search
            </p>

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  class="form-control"
                  type="search"
                >
              </div>

              <div class="form-group">
                <label for="search_export">Auto-Export Frequency</label>
                <select 
                  id="search_export"
                  v-model="queries.export"
                  class="form-control" 
                  :disabled="processing"
                  @change="searchRecords()"
                >
                  <option value="" />
                  <template v-for="(export_frequency, export_index) in export_frequencies">
                    <option 
                      :value="export_frequency['value']"
                    >
                      {{ export_frequency['label'] }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_created_by">Creator</label>
                <select 
                  id="search_created_by"
                  v-model="queries.created_by"
                  class="form-control" 
                  :disabled="processing"
                  @click="getUsers()"
                >
                  <option value="" />
                  <template v-for="user in users">
                    <option 
                      :value="user.email"
                    >
                      {{ user.name }} {{ user.email }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-check">
                <input
                  id="search_trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="form-check-input"
                  :disabled="processing"
                  @change="searchRecords()"
                >
                <label
                  class="form-check-label"
                  for="search_trashed"
                >Trashed</label>
              </div>


              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">

            <p class="card-title">Import Sample</p>

            <p class="card-text">Import sample reports to get started quickly.</p>

            <ul class="list-group">

              <template v-for="sample in samples">
                <li class="list-group-item list-group-item-action">
                  <h5 class="list-group-item-heading">{{ sample.name }}</h5>
                  <p class="list-group-item-text">{{ sample.description }}</p>

                  <button type="button" class="btn btn-success btn-sm" @click="importSample(sample)" :disabled="processing">Import</button>
                </li>
              </template>

            </ul>

        </div><!--Body-->
      </div><!--Card-->


      </div><!--Col-->
      <div class="col-sm-12 col-md-9">

        <div class="card">
          <div class="card-body">

            <div class="row">

            <div class="col-sm-12 col-md-6">

              <p class="card-title">
                {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
              </p>

            </div><!--Col-->

            <div class="col-sm-12 col-md-6 text-end">

              <div class="btn-group d-print-none">
                <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
              </div>
            </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <thead>
                  <template v-for="column in columns">
                    <th>
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </thead>
                <tbody>
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                          <i class="fa fa-edit"></i> Edit
                        </a>
                      </td>
                      <td>
                          {{ record.name }}
                      </td>
                      <td>{{ record.description }}</td>
                      <td>
                          <template v-if="record.export">
                            <span class="badge badge-pill badge-primary text-uppercase">{{ record.export }}</span>
                          </template>
                      </td>
                      <td>
                          <template v-if="record.status">
                            <span class="badge badge-pill badge-primary text-uppercase">{{ record.status }}</span>
                          </template>
                      </td>
                      <td>
                          
                          <template v-if="record.last_active">
                            {{ record.last_active | datetime }}
                            <br><span class="badge badge-pill badge-success">{{ record.last_active | dateTimezone | timeago }}</span>
                          </template>
                          <template v-else>
                            <span class="badge badge-pill badge-danger">Not Active</span>
                          </template>
                          
                      </td>
                      <td>{{ record.created_by }}</td>
                      <td>{{ record.created_at | datetime }}
                        <template v-if="record.deleted_at">
                          <span class="badge badge-pill badge-danger">
                          Trashed
                          </span>                        
                        </template>
                        <template v-if="record.archived_at">
                          <span class="badge badge-pill badge-warning">
                          Archived
                          </span>
                        </template>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>


            </div>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords()"
              >
                Load more
              </button>
            </div>
          </div><!--Row-->
        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data () {
        return {
            processing: false,
            records: [],
            form: {},
            queries: {},
            total: 0,
            export_frequencies: [],
            users: [],
            columns: [
            {label: 'Edit'},
            { key: 'name', label: 'Name'},
            { key: 'description', label: 'Description'},
            { key: 'export', label: 'Auto-Export'},
            { key: 'status', label: 'Status'},
            { key: 'last_active', label: 'Last Exported'},
            { key: 'created_by', label: 'Creator'},
            { key: 'created_at', label: 'Created'}
            ],
            samples: [
              { name: 'Monthly Messages', description: 'Report with all messaging on a monthly basis', export: 'month'}
            ]
        }
    },

    created () {

        this.resetForm();
        this.resetQueries();
        this.setURLParams();
        this.getRecords();
        this.getExportFrequencies();
    },

    methods: {
        
        resetQueries() {
            this.queries = {
                search: null,
                created_by: null,
                slim: true,
                limit: 10,
                trashed: false,
                export: null,
                sort: 'last_active',
                sortDirection: 'desc'
            }
        },

        resetForm() {
            this.form = {
                name: null
            }
        },

        resetRecords() {
            this.records = [];
            this.queries.skip = 0;
            this.total = 0;
        },

        getExportFrequencies () {
            this.$axios.get(this.$root.assets_url+'js/export_frequencies.json')
            .then(response => {
                if (response && response.data) {
                    this.export_frequencies = response.data;
                }
            })
        },
    
        getUsers() {
          this.$http.get('/users?slim=true&limit=1000&fields=name,email&sort=name&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.users = response.data;
            }
          })
        },

        searchRecords() {
            this.resetRecords();
            this.getRecords();
        },

        getRecords () {

            this.processing = true;

            this.updateURL();
            this.getTotal();

            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if(response && response.status) {

                    var self = this;

                    response.data.forEach(function(data) {
                        self.records.push(data);
                    });

                    this.queries.skip = this.records.length;
                    this.processing = false;
                }
            })
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status === 200) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
        },

        getExport (record, file) {
            this.$http.get('/'+this.$route.meta.base_url+'/export/'+record+'?file=' + file)
            .then(response => {
                if(response && response.data) {
                    window.open(response.data, '_blank');
                }
                this.processing = false;
            })
        },

        saveLastExport(record) {
            this.processing = true;
            this.$http.get('/'+this.$route.meta.base_url+'/'+record.uuid+'/last_export')
            .then(response => {
                if(response && response.data && !response.data.message) {
                    this.$eventBus.$emit('show-alert', {
                      text: 'Downloading '+this.$route.meta.title
                    });
                    window.open(response.data, '_blank');
                }

                if(response && response.data && response.data.message) {
                    this.$eventBus.$emit('show-alert', {
                      text: response.data.message
                    });
                }

                this.processing = false;
            })
        },

        updateSortDirection() {

          if(this.queries.sortDirection == 'asc') {
            this.queries.sortDirection = 'desc';
          } else {
            this.queries.sortDirection = 'asc';
          }

          this.resetRecords();
          this.getRecords();
        },

        sortColumn(column) {

          this.queries.sort = column.key;

          this.updateSortDirection();
        },

        postForm() {
            this.processing = true;
            this.$http.post('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                this.form = {}
                if(response && response.status === 201) {
                    this.records.unshift(response.data);
                    this.total++;
                    this.setTitle();
                    this.resetForm();
                    this.$eventBus.$emit('show-alert', {
                      text: this.$route.meta.title + ' added'
                    });
                    this.processing = false;
                }
            });
        },

        importSample(sample) {
          this.form = sample;

          this.form.attach_emails = true;

          this.form.emails = [
            this.$root.current_user.email
          ]

          this.form.preferences = {
            from_date: 'startlast_month',
            to_date: 'endlast_month',
            custom_from_time: '00:00:00',
            custom_to_time: '23:59:59',
          }

          this.form.datapoints = [
            {key: 'total messages', title: 'Total Messages'},
            {key: 'outbound messages', title: 'Outbound Messages'},
            {key: 'inbound messages', title: 'Inbound Messages'},
            {key: 'delivered messages', title: 'Delivered Messages'},
            {key: 'failed messages', title: 'Failed Messages'},
            {key: 'opened messages', title: 'Opened Messages'},
            {key: 'text messages', title: 'Text Messages'},
            {key: 'email messages', title: 'Email Messages'}
          ]

          this.processing = true;

          this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
              if(response && response.data && response.data.uuid) {

                if(!this.records) {
                  this.records = [];
                }

                  this.postDataset(response.data);

                  this.records.unshift(response.data);
                  this.total++;
                  this.setTitle();
                  this.resetForm();
                  this.$eventBus.$emit('show-alert', {
                    text: this.$route.meta.title + ' added'
                  });
                  this.processing = false;
              } else {
                  this.$eventBus.$emit('show-alert', {
                    text: 'Error importing sample'
                  });
                  this.processing = false;
              }
          });
        },

        postDataset(report) {
          this.$http.post('/datasets', {
            report: report.uuid,
            name: report.name,
          }).then(response => {
            if(response && response.data && response.data.uuid) {
              this.$http.post('/'+this.$route.meta.base_url+'/'+report.uuid+'/exports')
            }
          })
        },
    }
}
</script>
