<template>
  <div>

    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
    />
    <div class="row my-3">
      <!-- Header Section -->
      <div class="col-12 mb-4">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h4 class="mb-0">
              Supervision Check-in Report
            </h4>
            <p class="text-muted mb-0">
              <strong>Status:</strong> 
              <span class="badge text-uppercase" :class="setStatusClass(form.status)">
                {{ setStatusLabel(form.status) }}
              </span>
            </p>
          </div>
          <div class="btn-group">
            <button type="button" class="btn btn-outline-primary" @click="getPDF()" :disabled="processing">
              <i class="fa fa-file-pdf"></i> Export Report
            </button>
            <router-link :to="{ name: $route.meta.base_url+'.index' }" class="btn btn-outline-secondary">
              <i class="fa fa-times"></i> Close
            </router-link>
          </div>
        </div>
        <hr>
      </div>

      <!-- Main Content -->
      <div class="col-md-8">
        <!-- Identity Verification -->
        <div class="card mb-4 shadow-sm">
          <div class="card-header bg-primary text-white d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">
              <i class="fas fa-video me-2"></i>
              Video Verification
            </h5>
            <span class="badge bg-primary">
              <i class="fas fa-clock me-1"></i>
              {{ form.video_duration || '00:15' }}
            </span>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <dl class="row">
                  <dt class="col-sm-4">Name</dt>
                  <dd class="col-sm-8">{{ form.name }}</dd>

                  <dt class="col-sm-4">Check-in Time</dt>
                  <dd class="col-sm-8">{{ form.created_at | dateTimezone }}</dd>

                  <dt class="col-sm-4">Video Status</dt>
                  <dd class="col-sm-8">
                    <span class="badge" :class="form.video_verified ? 'bg-success' : 'bg-warning'">
                      {{ form.video_verified ? 'Verified' : 'Pending Review' }}
                    </span>
                  </dd>
                </dl>
              </div>
              <div class="col-md-6">
                <template v-if="files.length">
                  <div class="ratio ratio-16x9">
                    <video 
                      class="rounded"
                      controls
                      preload="metadata"
                      playsinline
                    >
                      <source :src="files[0]" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div class="d-flex justify-content-between align-items-center mt-2">
                    <small class="text-muted">
                      <i class="fas fa-shield-alt me-1"></i>
                      Secured Video Recording
                    </small>
                    <small class="text-muted">
                      {{ form.video_size || '2.4 MB' }}
                    </small>
                  </div>
                </template>
                <template v-else>
                  <div class="alert alert-warning mb-0">
                    <i class="fas fa-exclamation-triangle me-2"></i>
                    No verification video available
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <!-- Device & Network Information -->
        <div class="card mb-4 shadow-sm">
          <div class="card-header bg-primary text-white">
            <h5 class="card-title mb-0">
              <i class="fas fa-mobile-alt me-2"></i>
              Device & Network Information
            </h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h6 class="text-muted mb-3">Device Details</h6>
                <dl class="row">
                  <dt class="col-sm-4">Device Type</dt>
                  <dd class="col-sm-8">{{ form.device_type || 'Mobile' }}</dd>

                  <dt class="col-sm-4">Operating System</dt>
                  <dd class="col-sm-8">{{ form.os || 'iOS 15.0' }}</dd>

                  <dt class="col-sm-4">Browser</dt>
                  <dd class="col-sm-8">{{ form.browser || 'Safari Mobile' }}</dd>

                  <dt class="col-sm-4">Screen Size</dt>
                  <dd class="col-sm-8">{{ form.screen_size || '375x812' }}</dd>
                </dl>

                <template v-if="form.device && form.device.user_agent">
                  <user-agent :raw="form.device.user_agent" />
                </template>
              </div>
              <div class="col-md-6">
                <h6 class="text-muted mb-3">Network Details</h6>

                <template v-if="form.ip_data && form.ip_data.ip">
                  <ip-address-card :ip="form.ip_data.ip" />
                </template>
              </div>
            </div>
          </div>
        </div>

        <!-- Location Verification -->
        <div class="card mb-4 shadow-sm">
          <div class="card-header bg-primary text-white">
            <h5 class="card-title mb-0">
              <i class="fas fa-map-marker-alt me-2"></i>
              Location Verification
            </h5>
          </div>
          <div class="card-body">
            <div id="geo_map" class="map-lg rounded mb-3"></div>
            <dl class="row mb-0">
              <dt class="col-sm-3">GPS Coordinates</dt>
              <dd class="col-sm-9">
                {{ form.geo_data?.latitude }}, {{ form.geo_data?.longitude }}
              </dd>
              <dt class="col-sm-3">Accuracy</dt>
              <dd class="col-sm-9">Within {{ form.geo_data?.accuracy || '100' }} meters</dd>
            </dl>
          </div>
        </div>

        <!-- Comments Section -->
        <div class="card mb-4 shadow-sm">
          <div class="card-header bg-primary text-white">
            <h5 class="card-title mb-0">
              <i class="fas fa-comments me-2"></i>
              Check-in Notes
            </h5>
          </div>
          <div class="card-body">
            <p class="mb-0">{{ form.comments || 'No comments provided.' }}</p>
          </div>
        </div>
      </div>

      <!-- Sidebar -->
      <div class="col-md-4">
        <!-- Client Information -->
        <template v-if="form.client">
          <client-card :client="form.client" class="mb-4" />
        </template>

        <template v-if="!form.client">
            <div class="alert alert-warning">
                <i class="fas fa-exclamation-triangle me-2"></i>
                This check-in is not associated with a client.
            </div>
        </template>

        <!-- Audit Log -->
        <template v-if="form.uuid">
          <audit-logs :form="form" />
        </template>
      </div>
    </div>

    </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

    data () {
        return {
          processing: false,
          form: {},
          portal: {},
          files: []
        }
    },


    beforeDestroy() {
    if(this.map) {
        this.map.remove();
    }
    },

    created () {
        this.resetForm();
        this.getRecord();
    },
    methods: {

        resetForm() {
            this.form = {
              client: null,
              comments: null,
            }
        },

      setTitle() {
        document.title = this.form.name + ' | ' + this.$route.meta.title;
      },

        getRecord() {
            this.processing = true;
            this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
            .then(response => {
                if(response && response.data) {
                    this.form = response.data;
                    this.processing = false;

                    this.getFiles();
                    this.initMap();

                }
            })
        },

        getFiles() {
            if(!this.form.uuid) {
                return;
            }

            this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id+'/files')
            .then(response => {
                if(response && response.status) {
                    this.files = response.data;
                }
            })
        },

        postForm() {
          this.processing = true;
          this.$http.post('/'+this.$route.meta.base_url+'/'+this.$route.params.id, this.form)
          .then(response => {
            if(response && response.status) {
                this.processing = false;
                    this.form = {...this.form, ...response.data};
                    this.$eventBus.$emit('show-alert', {
                      text: this.$route.meta.title + ' saved'
                    });
            }
        });
        },


        initMap() {

        if(!this.form.geo_data || !this.form.geo_data.latitude || !this.form.geo_data.longitude) {
            return;
        }

        //check if geo_map is in the dom
        if(!document.getElementById('geo_map')) {
            return;
        }

        //check if map is already initialized
        if(this.map) {
            return;
        }

        this.map = window.L.map("geo_map").setView([this.form.geo_data.latitude, this.form.geo_data.longitude], 12);

        // window.L.tileLayer(this.$root.map_layer).addTo(this.map);

        //set satellite map
        window.L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
            attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        }).addTo(this.map);

        this.setMarker();
        },

        setMarker() {
            // Create the circle
            var circle = window.L.circle([this.form.geo_data.latitude, this.form.geo_data.longitude], {
                color: 'blue',
                fillColor: '#2196f3',
                fillOpacity: 0.2,
                radius: 100,
                className: 'map-circle' // Add a class for styling
            }).addTo(this.map);

            // Add click handler for the circle
            circle.on('click', (e) => {
                // First add a pulse animation to the circle
                circle.setStyle({
                    className: 'map-circle pulse'
                });

                // Get the clicked position
                const clickedLatLng = e.target.getLatLng();
                
                // Zoom animation
                this.map.flyTo(clickedLatLng, 18, {
                    duration: 1.5, // Animation duration in seconds
                    easeLinearity: 0.25,
                    animate: true
                });

                // Remove pulse class after animation
                setTimeout(() => {
                    circle.setStyle({
                        className: 'map-circle'
                    });
                }, 1000);
            });

            // Add hover effect
            circle.on('mouseover', () => {
                circle.setStyle({
                    fillOpacity: 0.4,
                    weight: 3
                });
            });

            circle.on('mouseout', () => {
                circle.setStyle({
                    fillOpacity: 0.2,
                    weight: 3
                });
            });
        },

        setStatusLabel(status) {
            return {
                0: 'Pending',
                1: 'Verified',
                2: 'Rejected',
                3: 'Failed',
                4: 'Invalid',
            }[status] || 'Unknown';
        },

        setStatusClass(status) {
            if (!status) {
                return 'bg-secondary';
            }

            status = status.toLowerCase();

            if (['failed', 'invalid', 'rejected'].includes(status)) {
                return 'bg-danger';
            }

            if (['completed', 'verified', 'validated'].includes(status)) {
                return 'bg-success';
            }

            if (['pending', 'processing'].includes(status)) {
                return 'bg-warning';
            }

            return 'bg-primary';
        }

    }
}
</script>

<style scoped>
.card {
  border: none;
  transition: transform 0.2s ease;
}

.card:hover {
  transform: translateY(-2px);
}

.card-header {
  border-bottom: none;
}

.map-lg {
  min-height: 300px;
  border: 1px solid var(--bs-border-color);
}

dt {
  font-weight: 500;
  color: var(--bs-gray-600);
}

dd {
  margin-bottom: 0.5rem;
}

.badge {
  font-weight: 500;
}
</style>
