<template>
  <div>
    <div class="card">
      <div class="card-body">
        <p class="card-title m-0">
          <a :href="base_url+'?client='+client_uuid">{{ records.length }} of {{ total }} {{ title }}s</a>
        </p>
                
        <form @submit.prevent="postForm()">
          <div class="input-group mb-3">
            <label
              class="sr-only"
              for="add_contact"
            >Create {{ title }}</label>
            <input
              id="add_contact"
              v-model="form.contact"
              type="text"
              class="form-control"
              placeholder="Add phone or email"
              required
            >
              <button
                class="btn btn-success btn-sm"
                type="submit"
                :disabled="processing"
              >
                Create {{ title }}
              </button>
          </div>
        </form>

        <ul id="client-contacts" class="list-group">
          <template v-for="record in records">
            <router-link
              :to="{ 'name': 'contacts.edit', 'params': {'id': record.uuid} }"
              class="list-group-item list-group-item-action"
            >

            <span :class="isPrivacyMode ? 'privacy-mode' : ''">

              <template v-if="record.name">
                {{ record.name }}
              </template>

              {{ record.value }}

            </span>

              <template v-if="record.type">
                <span class="badge badge-primary me-1">{{ record.type }}</span>
              </template>

              <template v-if="record.optin == 0">
                <span class="badge badge-danger me-1">OPTED-OUT</span>
              </template>
              <template v-if="record.valid == 0">
                <span class="badge badge-danger me-1">INVALID</span>
              </template>
              <template v-if="record.valid == 1">
                <span class="badge badge-success me-1">VALID</span>
              </template>
            </router-link>
          </template>
        </ul>
      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],
    
    props: ['client_uuid'],

    data () {
        return {
            processing: false,
            base_url: '/contacts',
            title: 'Contact',
            records: [],
            form: {},
            queries: {
                slim: true,
                client: null,
                fields: 'uuid,value,name,optin,valid,notify,type',
                limit: 100
            },
            total: 0
        }
    },

    created () {

        this.resetForm();

        if(this.client_uuid) {
            this.getRecords();
        }

    },

    methods: {
        

        getRecords () {

            this.processing = true;

            this.queries.client = this.client_uuid;

            this.getTotal();

            this.$http.get(this.buildQueries(this.base_url, this.queries))
            .then(response => {
                if (response && response.data) {
                    this.records = response.data;
                    this.processing = false;
                }
            })
        },

        getTotal() {
            this.$http.get(this.buildQueries(this.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status === 200) {
                    this.total = response.data;
                }                
            })
        },

        postForm() {

            if(!this.client_uuid) {
                return;
            }
            
            this.form.client = this.client_uuid;

            this.processing = true;
            this.$http.post(this.base_url, this.form)
            .then(response => {
                if (response && response.data) {
                    
                    if (response.data.uuid) {
                        this.records.unshift(response.data);
                        this.total++;
                        this.$eventBus.$emit('show-alert', {
                          text: this.title + ' added'
                        });
                        this.resetForm();
                    }
                    
                    this.processing = false;
                }
                
            })
        },

        resetForm() {

            this.form = {
                contact: null,
                client: null
            }

        },

    }
}
</script>
