<template>
  <div>
    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <h1><span v-if="form.bond_number">Bond {{ form.bond_number }}</span></h1>
        <p><router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4" /><!--Col-->

      <div class="col-md-4 text-end">
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-secondary dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Tools
          </button>
          <div class="dropdown-menu">
            <a
              v-if="!form.archived_at"
              class="dropdown-item"
              @click="archiveRecord(form)"
            >Archive</a>
            <a
              v-if="form.archived_at || form.deleted_at"
              class="dropdown-item"
              @click="restoreRecord(form)"
            >Restore</a>

            <template v-if="!form.deleted_at">
              <a
                class="dropdown-item"
                @click="trashRecord(form)"
              >Trash</a>
            </template>
          </div>

          <template v-if="form && !form.archived_at && !form.deleted_at">
            <button
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm"
            >
              Save
            </button>
          </template>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div><!--BtnGroup-->
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
             Edit {{ $route.meta.title }}
            </p>      
                               
            <form @submit.prevent="postForm">
              <div class="form-group">
                <label for="bond_number">Bond Number</label>
                <input
                  id="bond_number"
                  v-model="form.bond_number"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="case_number">Case Number</label>
                <input
                  id="case_number"
                  v-model="form.case_number"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="collateral_type">Collateral Type</label>
                <input
                  id="collateral_type"
                  v-model="form.collateral_type"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="status">Status</label>
                <input
                  id="status"
                  v-model="form.status"
                  type="text"
                  class="form-control"
                >
              </div>

              <template v-if="form && !form.archived_at && !form.deleted_at">
                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                >
                  Save
                </button>
              </template>
            </form>
          </div><!--Body-->
        </div><!--Card-->
                        

        <audit-logs
          :form="form"
          class="mt-3"
        />
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">
        <template v-if="form.client">
          <client-card
            :client="form.client"
            class="mb-3"
          />
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4" /><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data () {
        return {
            processing: false,
            searching: false,
            form: {},
            client: {}
        }
    },

    created () {
        this.getRecord();
    },
    methods: {

        getRecord () {

            this.processing = true;
            
            this.$http.get('/'+this.$route.meta.base_url + '/' + this.$route.params.id)
            .then(response => {
                if (response && response.data) {

                    this.form = response.data;
                    this.processing = false;

                    if(this.form.bond_number) {
                        document.title = this.$route.meta.title + ' ' + this.form.bond_number +  ' | eCourtDate.com';
                    }
                }
            })
        },

        postForm() {
            this.processing = true;
            this.$http.patch('/'+this.$route.meta.base_url + '/' + this.form.uuid, this.form)
            .then(response => {
                if(response && response.data) {

                    if(response.data.updated_at) {
                        this.form.updated_at = response.data.updated_at;
                        this.$eventBus.$emit('show-alert', {
                          text: this.$route.meta.title + ' updated'
                        });
                    }

                    this.processing = false;
                }
            })  
        },

    }
}
</script>
