<template>
  <div>

    <page-tabs page="cases" />

    <div class="row">
      <div class="col-sm-12 col-md-3 d-print-none">

        <client-search />

        <div class="card mb-3">
          <div class="card-body">

                <p class="card-title">Create {{ title }}</p>

            <form @submit.prevent="postForm">
              <div class="form-group">
                <label for="reference">{{ setCustomField('warrants', 'reference') }}</label>
                <input
                    id="reference"
                    v-model="form.reference"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="type">{{ setCustomField('warrants', 'type') }}</label>
                <select
                    id="type"
                    v-model="form.type"
                    class="form-control text-uppercase"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="record_type in record_types">
                    <option :value="record_type">{{ record_type }}</option>
                  </template>
                </select>
              </div>

              <template v-if="!case_options || !case_options.length">
              <div class="form-group">
                <label for="case_number">{{ setCustomField('warrants', 'case_number') }}</label>
                <input
                    id="case_number"
                    v-model="form.case_number"
                    type="text"
                    class="form-control"
                >
              </div>
              </template>

              <template v-if="case_options && case_options.length">
                <div class="form-group">
                  <label for="case_number">{{ setCustomField('warrants', 'case_number') }}</label>
                  <select
                      id="case_number"
                      v-model="form.case_number"
                      class="form-control"
                  >
                    <option value="" />
                    <template v-for="option in case_options">
                      <option :value="option.case_number">{{ option.case_number }}</option>
                    </template>
                  </select>
                </div>
              </template>

              <div class="form-group">
                <label for="issued_at">{{ setCustomField('warrants', 'issued_at') }}</label>
                <input
                    id="issued_at"
                    v-model="form.issued_at"
                    type="date"
                    class="form-control"
                >
              </div>

              <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
              >
                Add
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->


        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Search {{ title }}s
            </p>

            <form @submit.prevent="searchRecords">
              <div class="form-group">
                <label for="search">Search</label>
                <input
                    id="search"
                    v-model="queries.search"
                    type="search"
                    class="form-control"
                    placeholder="Search..."
                >
              </div>

              <div class="form-group">
                <label for="search_case_number">{{ setCustomField('warrants', 'case_number') }}</label>
                <input
                    id="search_case_number"
                    v-model="queries.case_number"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_bond_number">{{ setCustomField('warrants', 'bond_number') }}</label>
                <input
                    id="search_bond_number"
                    v-model="queries.bond_number"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_status">{{ setCustomField('warrants', 'status') }}</label>
                <select
                    id="search_status"
                    v-model="queries.status"
                    class="form-control"
                    @click="getStatuses"
                >
                  <option value="" />
                  <template v-for="status in statuses">
                    <option
                        :value="status.name"
                    >
                      {{ status.name }}
                    </option>
                  </template>
                </select>
              </div>


              <div class="form-group">
                <label for="search_type">{{ setCustomField('warrants', 'type') }}</label>
                <select
                    id="search_type"
                    v-model="queries.type"
                    class="form-control text-uppercase"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="record_type in record_types">
                    <option :value="record_type">{{ record_type }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="created_by">Creator</label>
                <select
                    id="created_by"
                    v-model="queries.created_by"
                    class="form-control"
                    @click="getUsers"
                    >
                  <option value="" />
                  <template v-for="user in users">
                    <option
                        :value="user.email"
                    >
                      {{ user.name }} {{ user.email }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="issued_from">Issued From</label>
                <input
                    id="issued_from"
                    v-model="queries.issued_from"
                    type="date"
                    class="form-control"
                    :disabled="processing"
                >
              </div>

              <div class="form-group">
                <label for="issued_to">Issued To</label>
                <input
                    id="issued_to"
                    v-model="queries.issued_to"
                    type="date"
                    class="form-control"
                    :disabled="processing"
                >
              </div>

                  <div class="form-check">
                    <input
                        id="archived"
                        v-model="queries.archived"
                        type="checkbox"
                        class="form-check-input"
                        :disabled="processing"
                    >
                    <label
                        class="form-check-label"
                        for="archived"
                    >Archived</label>
                  </div>

                  <div class="form-check">
                    <input
                        id="trashed"
                        v-model="queries.trashed"
                        type="checkbox"
                        class="form-check-input"
                        :disabled="processing"
                    >
                    <label
                        class="form-check-label"
                        for="trashed"
                    >Trashed</label>
                  </div>

              <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">

            <div class="row">

            <div class="col-sm-12 col-md-6">

              <p class="card-title">
                {{ records.length }} of {{ total.toLocaleString() }} {{  title }}s
              </p>

            </div><!--Col-->

            <div class="col-sm-12 col-md-6 text-end">

              <div class="btn-group d-print-none">
                <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
              </div>
            </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">

              <table class="table table-striped table-hover">
                <tr>
                  <template v-for="column in columns">
                    <th>
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ setCustomField('warrants', column.key) }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
                <tbody>
                <template v-for="record in records">
                  <tr>
                    <td>
                      <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                        <i class="fa fa-edit"></i> Edit
                      </a>
                    </td>
                    <td>
                      {{ record.reference }}
                    </td>
                    <td>{{ record.status }}</td>
                    <td>
                      <template v-if="record.type">
                        <span class="badge badge-primary text-uppercase">{{ record.type }}</span>
                      </template>
                    </td>
                    <td>
                      <template v-if="record.client">
                        <client-button :uuid="record.client" />
                      </template>
                    </td>
                    <td>{{ record.case_number }}</td>
                    <td>{{ record.bond_number }}</td>
                    <td>
                      <template v-if="record.issued_at">
                        {{ record.issued_at | date }}
                      </template>
                    </td>
                    <td>
                      {{ record.created_at | datetime }}

                      <template v-if="record.deleted_at">
                        <span class="badge badge-danger">Trashed</span>
                      </template>

                      <template v-if="record.archived_at">
                        <span class="badge badge-warning">Archived</span>
                      </template>

                      <template v-if="record.updated_at">
                        <br><span class="badge badge-secondary">Updated {{ record.updated_at | dateTimezone | timeago }}</span>
                      </template>
                    </td>
                    <td>{{ record.created_by }}</td>
                  </tr>
                </template>
                </tbody>
              </table>

            </div><!--Table-->

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
              >
                Load more
              </button>
            </div>
          </div><!--Row-->
        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      downloading: false,
      title: null,
      records: [],
      downloads: [],
      client: {},
      form: {},
      queries: {},
      columns: [
        { label: 'Edit'},
        { key: 'reference', label: 'Warrant Reference' },
        { key: 'status', label: 'Status'},
        { key: 'type', label: 'Type'},
        { key: 'client', label: 'Client'},
        { key: 'case_number', label: 'Case Number'},
        { key: 'bond_number', label: 'Bond Number'},
        { key: 'issued_at', label: 'Issue Date'},
        { key: 'created_at', label: 'Created'},
        { key: 'created_by', label: 'Creator'}
      ],
      total: 0,
      users: [],
      statuses: [],
      case_options: [],
      record_types: []
    }
  },

  mounted() {

    var self = this;

    this.$eventBus.$on('refreshRecords', function(view) {

      if(view !== self.$route.meta.base_url) {
        return;
      }

      self.resetRecords();

      self.getRecords();

    });

    this.$eventBus.$on('search_clients', (uuid) => {

    if(uuid) {
      self.queries.client = uuid;
    } else {
      self.queries.client = null;
    }

    self.resetRecords();
    self.getRecords();
    });

  },

  created () {

    this.title = this.setCustomLabel(this.$route.meta.title);
    this.setDocTitle(this.title+'s');
    this.resetForm();
    this.resetQueries();
    this.setURLParams();
    this.setRecordTypes();
    this.getRecords();
    this.getCases();

  },

  methods: {

    setURLParams() {
      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
      this.total = 0;
    },

    resetQueries() {
      this.queries = {
        search: '',
        client: null,
        limit: 10,
        skip: 0,
        upload: null,
        slim: true,
        archived: false,
        trashed: false,
        created_by: null,
        type: null,
        status: null,
        case_number: null,
        bond_number: null,
        sortDirection: 'desc',
        sort: 'created_at'
      }
    },

    resetForm() {
      this.form = {
        reference: null,
        type: this.record_types[0],
        case_number: null,
        bond_number: null,
        issued_at: null
      }
    },

    updateSortDirection() {

    if(this.queries.sortDirection == 'asc') {
      this.queries.sortDirection = 'desc';
    } else {
      this.queries.sortDirection = 'asc';
    }

    this.resetRecords();

    this.getRecords();
    },

    sortColumn(column) {

    this.queries.sort = column.key;

    this.updateSortDirection();

    },

    searchRecords () {
      this.resetRecords();
      this.getRecords();
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords() {

      this.processing = true;

      this.updateURL();
      this.getTotal();

      this.filtered_url = this.buildQueries('/'+this.$route.meta.base_url, this.queries)
      this.$http.get(this.filtered_url)
          .then(response => {
            if(response && response.data) {

              var self = this;

              response.data.forEach(function(data) {
                self.pushRecord(data);
              });

              this.queries.skip = this.records.length;
              this.processing = false;
            }

          })
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status) {
              this.total = response.data;
            }
          })
    },


    setRecordTypes() {
      // this.record_types = this.setCustomField('warrants', 'record_types');

      if(!this.settings || !this.settings.warrant_types) {
        return ['arrest', 'search', 'bench', 'extradition', 'alias', 'social media', 'isp', 'carrier'];
      }

      this.record_types = this.settings.warrant_types.split(',');

      this.record_types = this.record_types.map(type => type.trim());
    },

    getStatuses() {

      if(this.statuses && this.statuses.length) {
        return;
      }

      this.$http.get('/statuses?type=warrant&limit=100&sort=name&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.statuses = response.data;
            }
          })
    },

    postForm() {

      this.processing = true;

      if(this.queries.client && this.$options.filters.isValidUUID(this.queries.client)) {
        this.form.client = this.queries.client;
      }

      this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.status === 201) {
                this.records.unshift(response.data);
                this.total++;
                this.resetForm();
                this.$eventBus.$emit('show-alert', {
                  text: this.title + ' created'
                });
              }
            }
          })
    },

    getCases() {

      this.case_options = [];

      let queries = {
        client: this.queries.client || null,
        slim: true,
        fields: 'case_number',
        sort: 'case_number',
        sortDirection: 'asc'
      }

      this.$http.get('/cases', queries)
          .then(response => {
            if(response && response.data) {
              this.case_options = response.data;
            }
          })
    },

    getUsers() {

      if(this.users && this.users.length) {
        return;
      }

      this.$http.get('/users?slim=true&limit=100&fields=name,email&sort=name&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.users = response.data;
            }
          })
    },

    pushRecord(data) {

      if(this.queries.client && !data.client) {
        return;
      }

      if(this.queries.client && data.client) {
        if(this.queries.client != data.client) {
          return;
        }
      }

      var count = this.records.length;

      if(this.records.length > count) {
        this.records.splice(-1);
      }

      var index = _.findIndex(this.records, {'uuid': data.uuid});

      if(index == -1) {
        this.records.push(data);
        return;
      }

      var records = [];

      this.records.forEach(function(record) {

        if(record.uuid == data.uuid) {
          record = {...record, ...data};
        }

        records.push(record);


      })

      this.records = records;
    },

  }
}
</script>
