<template>
  <div>

    <page-tabs page="events" />

    <div class="row">
      <div class="col-sm-12 col-md-3">

        <div class="card">
          <div class="card-body">

            <p class="card-title">Create {{ $route.meta.title }}</p>

            <form @submit.prevent="postForm">

              <div class="form-group">
                <label for="name">Name *</label>
                <input id="name" type="text" class="form-control" v-model="form.name" required>
              </div>

              <div class="form-group">
                <label for="phone">Phone</label>
                <input id="phone" type="tel" class="form-control" v-model="form.phone">
              </div>

              <div class="form-group">
                <label for="email">Email</label>
                <input id="email" type="email" class="form-control" v-model="form.email">
              </div>

              <div class="form-group">
                <label for="status">Status</label>
                <select
                    id="status"
                    v-model="form.status"
                    class="form-control"
                    :disabled="processing">
                  <option value=""></option>
                  <option
                      v-for="(status, key) in $root.checkin_statuses"
                      :value="key"
                  >
                    {{ status }}
                  </option>
                  </select>
              </div>

              <div class="form-group">
                <label for="service">Service</label>
                <input id="service" type="text" class="form-control" v-model="form.service">
              </div>

              <div class="form-group">
                <label for="location">Location</label>
                <select
                    id="location"
                    v-model="form.location"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-if="locations && locations.length > 0">
                    <template v-for="location in locations">
                      <option
                          :value="location.uuid"
                      >
                        {{ location.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="language">Language</label>
                <select
                    id="language"
                    v-model="form.language"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value=""></option>
                  <template v-if="languages && languages.length > 0">
                    <template v-for="language in languages">
                      <option
                          :value="language.key"
                      >
                        {{ language.label }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-check">
                <input
                    id="virtual"
                    v-model="form.virtual"
                    type="checkbox"
                    class="form-check-input"
                >
                <label
                    class="form-check-label"
                    for="virtual"
                >Virtual</label>
              </div>

              <button type="submit" class="btn btn-success" :disabled="processing">Create</button>

            </form>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-9">
                <p class="card-title">
                  Search {{ $route.meta.title }}s
                </p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-3 text-end">
                <button
                    type="button"
                    data-tooltip="Reset search fields"
                    class="btn btn-outline-secondary"
                    :disabled="processing"
                    @click="resetSearch()"
                >
                  <i class="fa fa-eraser" /> <span class="sr-only">Reset Form</span>
                </button>
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search_name">Search by Name</label>
                <input
                    id="search_name"
                    v-model="queries.name"
                    type="search"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_data">Search Data</label>
                <input
                    id="search_data"
                    v-model="queries.search"
                    type="search"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_phone">Search by Phone</label>
                <input
                    id="search_phone"
                    v-model="queries.phone"
                    type="search"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_email">Search by Email</label>
                <input
                    id="search_email"
                    v-model="queries.email"
                    type="search"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="created_from">Created From</label>
                <input
                    id="created_from"
                    v-model="queries.created_from"
                    type="date"
                    class="form-control"
                >
                <button type="button" class="btn btn-secondary btn-sm my-1" @click="setDateToday()" :disabled="processing">Today</button>
              </div>

              <div class="form-group">
                <label for="created_to">Created To</label>
                <input
                    id="created_to"
                    v-model="queries.created_to"
                    type="date"
                    class="form-control"
                >

              </div>

              <div class="form-group">
                <label for="search_status">Status</label>
                <select
                    id="search_status"
                    v-model="queries.status"
                    class="form-control"
                    :disabled="processing"
                    @change="searchRecords()"
                >
                  <option value=""></option>
                  <option
                      v-for="status in $root.checkin_statuses"
                      :value="status"
                  >
                    {{ status }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="search_language">Language</label>
                <select
                    id="search_language"
                    v-model="queries.language"
                    class="form-control"
                    :disabled="processing"
                    @change="searchRecords()"
                >
                  <option value=""></option>
                  <template v-if="languages && languages.length > 0">
                    <template v-for="language in languages">
                      <option
                          :value="language.key"
                      >
                        {{ language.label }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_location">Location</label>
                <select
                    id="search_location"
                    v-model="queries.location"
                    class="form-control"
                    :disabled="processing"
                    @change="searchRecords()"
                >
                  <option value="">
                    Any
                  </option>
                  <template v-if="locations && locations.length > 0">
                    <template v-for="location in locations">
                      <option
                          :value="location.uuid"
                      >
                        {{ location.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-check">
                <input
                    id="search_virtual"
                    v-model="queries.virtual"
                    type="checkbox"
                    class="form-check-input"
                    :disabled="processing || queries.inperson"
                    @change="searchRecords()"
                >
                <label
                    class="form-check-label"
                    for="search_virtual"
                >Virtual</label>
              </div>

              <div class="form-check">
                <input
                    id="search_inperson"
                    v-model="queries.inperson"
                    type="checkbox"
                    class="form-check-input"
                    :disabled="processing || queries.virtual"
                    @change="searchRecords()"
                >
                <label
                    class="form-check-label"
                    for="search_inperson"
                >In-Person</label>
              </div>

              <div class="form-check">
                <input
                    id="search_archived"
                    v-model="queries.archived"
                    type="checkbox"
                    class="form-check-input"
                    :disabled="processing"
                    @change="searchRecords()"
                >
                <label
                    class="form-check-label"
                    for="search_archived"
                >Archived</label>
              </div>

              <div class="form-check">
                <input
                    id="search_trashed"
                    v-model="queries.trashed"
                    type="checkbox"
                    class="form-check-input"
                    :disabled="processing"
                    @change="searchRecords()"
                >
                <label
                    class="form-check-label"
                    for="search_trashed"
                >Trashed</label>
              </div>


              <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

        <template v-if="show_compensation">
          <div class="card mb-3">
            <div class="card-body">
                  <p class="card-title">Compensation Report</p>
                  
                  <p class="card-text">
                    Generate a compensation report to download a CSV file with the total compensation.
                  </p>

                  <form @submit.prevent="generateCompensation()">
                    <div class="form-group">
                      <label for="rate_type">Rate Type</label>
                      <select
                          id="rate_type"
                          v-model="compensationForm.rate_type"
                          class="form-control"
                          :disabled="processing"
                        >
                        <option value="flat">Flat</option>
                        <option value="daily">Daily</option>
                        <option value="hourly">Hourly</option>
                        <option value="mileage">Mileage</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="rate">Rate</label>
                      <input
                          id="rate"
                          type="number"
                          class="form-control"
                          v-model="compensationForm.rate"
                          :disabled="processing"
                      >
                    </div>

                    <div class="form-group">
                      <label for="search_completed_from_date">Completed From</label>
                      <input
                          id="search_completed_from_date"
                          v-model="compensationForm.completed_from"
                          type="date"
                          class="form-control"
                      >
                    </div>

                    <div class="form-group">
                      <label for="search_completed_to_date">Completed To</label>
                      <input
                          id="search_completed_to_date"
                          v-model="compensationForm.completed_to"
                          type="date"
                          class="form-control"
                      >
                    </div>

                    <button type="submit" class="btn btn-success" :disabled="processing || downloading">Generate</button>

                    </form>

            </div><!--Body-->
          </div><!--Card-->
        </template>

                

        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-sm-12 col-md-4">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-4">
                <template v-if="current_portal && current_portal.link">
                  <a
                  :href="'https://'+current_portal.link+'.'+current_portal.domain+'/checkin'"
                  target="_blank"
                  class="btn btn-outline-primary"
                      :disabled="processing"
                  >
                    <i class="fa fa-link"></i> Open Portal
                  </a>
                </template>

              </div><!--Col-->

              <div class="col-sm-12 col-md-4 text-end">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary btn-sm m-0" @click="show_compensation = !show_compensation" :disabled="processing"><i class="fa fa-dollar-sign"></i> Compensation</button>
                  <button type="button" class="btn btn-secondary btn-sm m-0" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary btn-sm m-0" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
              <table class="table table-hover table-striped">
                <sort-columns
                    :columns="columns"
                    :queries="queries"
                    :processing="processing"
                    :base_url="$route.meta.base_url"
                />
                <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                          <i class="fa fa-edit"></i> Edit
                        </a>
                      </td>
                      <td>
                          {{ record.name }}
                      </td>
                      <td>{{ record.phone }}</td>
                      <td>{{ record.email }}</td>
                      <td>
                        <template v-if="record.status">
                            <span class="badge text-uppercase" :class="setStatusClass(record.status)">{{ record.status | rmDashes }}</span>
                        </template>
                      </td>
                      <td>
                        <template v-if="record.language">
                          {{ setLanguageName(record.language) }}
                        </template>
                      </td>
                      <td>{{ record.department }}</td>
                      <td>{{ record.service }}</td>
                      <td>
                        <template v-if="record.location">
                          {{ setLocationName(record.location) }}
                        </template>
                      </td>
                      <td>
                        <template v-if="record.virtual == 1">
                          <span class="badge badge-pill badge-info">Virtual</span>
                        </template>
                        <template v-else>
                          <span class="badge badge-pill badge-secondary">In-Person</span>
                        </template>
                      </td>
                      <td>{{ record.notes }}</td>
                      <td>{{ record.created_by }}</td>
                      <td>
                        <template v-if="record.created_at">
                          {{ record.created_at | datetime }}
                        </template>

                        <template v-if="record.deleted_at">
                          <span class="badge badge-pill badge-danger">Trashed {{ record.deleted_at | datetime }}</span>
                        </template>

                        <template v-if="record.archived_at">
                          <span class="badge badge-pill badge-danger">Archived {{ record.archived_at | datetime }}</span>
                        </template>

                      </td>
                      <td>
                        <template v-if="record.updated_at">
                          {{ record.updated_at | datetime }}
                        </template>
                      </td>
                      <td>
                        <template v-if="record.completed_at">
                          {{ record.completed_at | datetime }}
                        </template>
                      </td>

                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div><!--Responsive-->


            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      searched: false,
      downloads: [],
      records: [],
      queries: {},
      options: {},
      total: 0,
      form: {},
      client: {},
      columns: [
        {label: 'Edit'},
        {label: 'Name', key: 'name'},
        {label: 'Phone', key: 'phone'},
        {label: 'Email', key: 'email'},
        {label: 'Status', key: 'status'},
        {label: 'Language', key: 'language'},
        {label: 'Department', key: 'department'},
        {label: 'Service', key: 'service'},
        {label: 'Location', key: 'location'},
        {label: 'Virtual', key: 'virtual'},
        {label: 'Notes', key: 'notes'},
        {label: 'Creator', key: 'created_by'},
        {label: 'Created', key: 'created_at'},
        {label: 'Updated', key: 'updated_at'},
        {label: 'Completed', key: 'completed_at'}
      ],
      locations: [],
      portals: [],
      current_portal: {},
      show_compensation: false,
      compensationForm: {
        rate_type: 'daily',
        rate: 10
      }
    }
  },

  mounted() {

    let self = this;

    this.$eventBus.$on('refreshRecords', function(view) {

      if(view !== self.$route.meta.base_url) {
        return;
      }

      self.searchRecords();

    });


    this.$eventBus.$on(this.$route.meta.base_url, function(data) {
        self.pushRecord(data);
    });

  },

  created() {

    this.resetForm();
    this.resetQueries();
    this.setURLParams();
    this.resetRecords();
    this.getRecords();
    this.getLocations();
    this.getPortals();
  },

  methods: {

    resetSearch() {
      this.resetQueries();
      this.searchRecords();
    },

    resetQueries() {
      this.queries = {
        search: '',
        status: null,
        client: null,
        event: null,
        virtual: null,
        inperson: null,
        sort: 'created_at',
        sortDirection: 'desc',
        limit: 10,
        archived: false,
        trashed: false,
        skip: 0,
        created_from: null,
        created_to: null,
        phone: null,
        email: null
      }
    },

    setURLParams() {
      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {

        if(!value || value == 'null') {
          return;
        }

        self.queries[key] = value;
      });
    },

    searchRecords() {
      this.searched = true;

      this.resetRecords();
      this.getRecords();
    },

    resetRecords() {
      this.queries.skip = 0;
      this.total = 0;
      this.records = [];
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
              this.setTitle();
            }
          })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords() {

      this.processing = true;

      this.updateURL();
      this.getTotal();



      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {

              if(response.data.length) {

                var self = this;

                response.data.forEach(function(data) {
                  self.records.push(data);
                });

              }

              this.queries.skip = this.records.length;

              this.processing = false;
            }
          })
    },

    resetForm() {
      this.form = {
        name: null,
        status: 'waiting',
        language: 'en',
        notes: null,
        service: null,
        location: null,
        virtual: false
      }

      if(this.locations.length > 0 && !this.form.location) {
        this.form.location = this.locations[0].uuid;
      }
    },

    postForm() {

      this.processing = true;

      this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.data) {

              if(response.data.uuid) {
                this.$eventBus.$emit('show-alert', {
                  text: this.$route.meta.title + ' created'
                });

                if(!this.records || !this.records.length) {
                  this.records = [];
                }

                this.records.unshift(response.data);
                this.resetForm();
                window.scrollTo(0,0);
                window.location.href = '/'+this.$route.meta.base_url+'/'+response.data.uuid;
              }

              this.processing = false;

            }
          })
    },

    getLocations () {
      this.$http.get(this.buildQueries('/locations', {limit: 1000, fields: 'uuid,name', slim: true, sort: 'default', sortDirection: 'desc'}))
          .then(response => {
            if (response && response.data) {
              this.locations = response.data;

              if(this.locations.length > 0 && !this.form.location) {
                this.form.location = this.locations[0].uuid;
              }
            }
          })
    },

    setStatusClass(status) {
          if(!status) {
            return 'badge-secondary';
          }

          if(status == 'waiting') {
            return 'badge-danger';
          }

          if(status == 'ready') {
            return 'badge-success';
          }

          if(status == 'in-progress') {
            return 'badge-primary';
          }

          if(status == 'complete') {
            return 'badge-success';
          }

          return 'badge-secondary';
    },

    setLocationName(location) {

      if(!location) {
        return;
      }

      var result = _.find(this.locations, {'uuid': location});

      if(!result || !result.name) {
        return;
      }

      return result.name;
    },

    setLanguageName(language) {

      if(!language) {
        return;
      }

      var result = _.find(this.languages, {'key': language});

      if(!result || !result.label) {
        return language;
      }

      return result.label;
    },

    setDateToday () {
      this.queries.created_from = this.$moment().tz(this.timezone).format('YYYY-MM-DD');
      this.searchRecords();
    },

    pushRecord(data) {

      if(!data || !data.uuid) {
        return;
      }

      if(this.processing) {
        return;
      }

      if(!this.records || !this.records.length) {
        this.records = [];
      }

      var index = _.findIndex(this.records, {'uuid': data.uuid});

      if(index != -1) {
        this.records[index] = data;
        return;
      }


      this.total++;
      this.records.unshift(data);

      this.setTitle();
      },

      getPortals() {
        this.current_portal = {};
        this.portals = [];
        this.$http.get('/portals?limit=1&sort=default&sortDirection=desc&slim=true&fields=uuid,name,link,domain&domain=ecase.io')
            .then(response => {
              if(response && response.data) {
                this.portals = response.data;
                
                if(this.portals.length > 0 && !this.current_portal.uuid) {
                  this.current_portal = this.portals[0];
                }
              }
            })
      },


      updateSettings(key, value) {
      this.processing = true;

      this.settings[key] = value;

        this.$http.patch('/settings?'+key+'='+value)
            .then(response => {
                if(response && response.status === 200) {
                    this.$eventBus.$emit('show-alert', {
                      text: key + ' updated.'
                    });
                    this.processing = false;
                }
            })
      },

      generateCompensation () {
        this.downloading = true;
    
        var queries = this.queries;
    
        queries.limit = this.total;

        queries.status = 'checked_out';
    
        queries.skip = this.downloads.length;
    
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
            .then(response => {
              if(response && response.data && response.data) {
                this.downloads = this.downloads.concat(response.data);
    
                var csvFile, data, link;
    
                data = this.convertCompensation(this.downloads);
    
                var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';
    
                csvFile = new Blob([data], {type: "text/csv"});
                if (window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveBlob(csvFile, file);
                } else {
                  link = document.createElement("a");
                  link.download = file;
                  link.href = window.URL.createObjectURL(csvFile);
                  link.style.display = "none";
                  document.body.appendChild(link);
                  link.click();
                }
    
                this.downloading = false;
    
              } else {
                this.generateCompensation();
              }
    
            });
      },
    
      convertCompensation(records) {

        if(!records || !records.length) {
          return;
        }
    
        var results = [];

        const fields = ['name', 'phone', 'email', 'status', 'created_at', 'completed_at', 'compensation'];
        
        results += fields.join(',');
    
        results += '\n';

        var self = this;
    
        records.forEach(function(item) {
          let record = '';
    
          if(!item) {
            return;
          }
    

          fields.forEach(function(field) {

            if(field == 'compensation') {

              let rate = self.compensationForm.rate;

              let rate_type = self.compensationForm.rate_type;

              let completed_at = self.$moment(item['completed_at'] || item['created_at']).format('YYYY-MM-DD HH:mm:ss');

              let created_at = self.$moment(item['created_at']).format('YYYY-MM-DD HH:mm:ss');

              let total_time = self.$moment(completed_at).diff(created_at, 'minutes');

              let total_hours = total_time / 60;

              if(total_hours < 1) {
                total_hours = 1;
              }

              let total_compensation = total_hours * rate;

              if(rate_type == 'daily') {
                total_compensation = rate;
              }

              total_compensation = total_compensation.toFixed(2);

              record += total_compensation + ','; 
              
              return;
            }

            if(!item[field]) {
              record += ',';
              return;
            }
    
            if(field == 'status') {
              record += self.$root.checkin_statuses[item[field]] + ',';
              return;
            }
    
            if(field == 'created_at' || field == 'completed_at') {
              record += self.$moment(item[field]).format('YYYY-MM-DD HH:mm:ss') + ',';
              return;
            }
    
            record += item[field] + ',';
          });
    
          results += record;
          results += '\n';
        });
    
        return results
      },

  }

}
</script>