<template>
  <div>

    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="$route.meta.title"
    />

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <h1>{{ form.name }}</h1>
        <p>
          <router-link :to="{name: 'settings.index'}">Settings</router-link> /
          <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}
        </p>

      </div><!--Col-->

      <div class="col-sm-12 col-md-2">
        <template v-if="portal && portal.uuid">
          <a :href="'https://'+portal.link+'.'+portal.domain+'/supervisions'" target="_blank" class="btn btn-primary"><i class="fa fa-external-link-alt"></i> Open Portal Link</a>
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-6 text-end">
        <div class="btn-group">

          <div class="btn-group">
            <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Tools
            </button>
            <div class="dropdown-menu">
              <a
                  v-if="!form.archived_at"
                  class="dropdown-item"
                  @click="archiveRecord(form)"
              >Archive</a>
              <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord(form)"
              >Restore</a>
              <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord(form)"
              >Trash</a>
            </div>
          </div><!--BtnGroup-->

          <button
              v-if="!form.archived_at && !form.deleted_at"
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm()"
          >
            Save
          </button>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title">
                  Edit {{ $route.meta.title }}
                </p>
              </div><!--Col-->

              <div class="col-6 text-end">
              </div><!--Col-->
            </div><!--Row-->

            <form
                @submit.prevent="postForm"
            >

              <div class="form-group">
                <label for="name">Name</label>
                <input
                    id="name"
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    required
                >
              </div>


              <div class="form-group">
                <label for="description">Description</label>
                <textarea
                    v-if="form"
                    id="description"
                    v-model="form.description"
                    type="text"
                    class="form-control"
                ></textarea>
              </div>

              <div class="form-group">
                <label for="risk">Risk</label>
                <input
                    id="risk"
                    v-model="form.risk"
                    type="number"
                    class="form-control"
                    step="1"
                >
                <small class="form-text text-muted">Lower equals less risk</small>
              </div>

              <div class="form-group">
                <label for="monitoring">Monitoring Frequency</label>
                <select
                    id="monitoring"
                    v-model="form.monitoring"
                    type="text"
                    class="form-control"
                >
                <option value="">Disabled</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                </select>
                <p class="form-text text-muted">Randomly require clients to check-in online</p>
              </div>

              <template v-if="form.monitoring">
              <div class="form-group">
                <label for="monitoring_interval">Monitoring Interval</label>
                <input id="monitoring_interval" v-model="form.monitoring_interval" type="number" class="form-control" />
                <p class="form-text text-muted">How many required {{ form.monitoring }} check-ins. Defaults to 1 if not set.</p>
              </div>
              </template>

                <div class="form-group">
                    <label for="conditions">Conditions</label>
                    <div class="form-check">
                        <template v-for="condition in conditions">

                            <div class="form-check">
                                <input
                                :id="condition"
                                :value="condition"
                                v-model="form.conditions"
                                type="checkbox"
                                class="form-check-input"
                                >
                                <label
                                class="form-check-label"
                                :for="condition"
                                >{{ condition }}</label>
                            </div>

                        </template>
                    </div>
                </div>

                <div class="input-group mb-3">
                    <label
                    class="sr-only"
                    for="add_condition"
                    >Add Condition</label>
                    <input
                    id="add_condition"
                    v-model="add_condition"
                    type="text"
                    class="form-control"
                    placeholder="Add condition"
                    >
                    <div class="input-group-append">
                    <button
                        class="btn btn-success btn-sm"
                        type="button"
                        @click="addCondition"
                        :disabled="processing"
                    >
                        Add
                    </button>
                    </div>
                </div>


              <button type="submit" class="btn btn-success" :disabled="processing">Save</button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs
            class="mt-3"
            :form="form"
        />
      </div><!--Col-->


      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ clients.length }} Clients</h5>

            <template v-if="loading">
              <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="status" style="width: 100%"></div>
              </div>
            </template>

            <template v-if="clients && clients.length > 0">
              <ul class="list-group">
                <li class="list-group-item" v-for="client in clients">
                  <client-button :uuid="client.client" />
                </li>
              </ul>
            </template>

            <template v-else>
              <div class="alert alert-warning m-0" v-if="!loading">No clients found</div>
            </template>

          </div>
        </div>
      </div><!--Col-->

      <div class="col-sm-12 col-md-3">

        <div class="card">
          <div class="card-body">
            <p class="card-title">Send a Message</p>

            <form @submit.prevent="sendMessage">
              <div class="form-group">
                <label for="subject">Subject</label>
                <input id="subject" v-model="messageForm.subject" type="text" class="form-control" />
              </div>

              <div class="form-group">
                <label for="content">Content *</label>
                <textarea id="content" v-model="messageForm.content" class="form-control" rows="5" required></textarea>
              </div>

              <template v-if="clients && clients.length > 0 && !sending">
                <button type="submit" class="btn btn-success" :disabled="processing">Send</button>
              </template>

              <template v-if="sending">
                <div class="progress">
                  <div class="progress-bar progress-bar-striped progress-bar-animated" role="status" style="width: 100%"></div>
                </div>
              </template>
            </form>


          </div><!--Body-->
        </div><!--Card-->

        <p class="mt-3">{{ messages.length }} Messages</p>

        <template v-for="message in messages">
          <message :message="message" />
        </template>

      </div><!--Col-->

    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from "lodash";

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      loading: false,
      sending: false,
      form: {},
      add_condition: '',
      conditions: [
        'Complete Intake Form',
        'Drug Testing Required',
        'Assign Supervision Officer',
        'Monthly Criminal History Check',
        'Agree to Supervision Pay Plan',
        'Weekly Virtual Check-In',
        'Monthly Virtual Check-In',
        'Monthly In-Person Check-In',
        'Random Virtual Check-In',
        'Random Home Visit',
      ],
      clients: [],
      messageForm: {
        subject: '',
        content: ''
      },
      messages: [],
      portal: {}
    }
  },


  created() {
    this.resetForm();
    this.getRecord();
    this.getPortal();
  },

  methods: {

    postForm() {
      this.processing = true;

      this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
          .then(response => {
            if(response && response.data) {

              this.processing = false;

              if(response.data.updated_at) {
                this.form = {...this.form, ...response.data};
                this.setTitle();
                this.$eventBus.$emit('show-alert', {
                  text: this.$route.meta.title + ' updated'
                });
              }

            }
          })
    },

    resetForm() {
      this.form = {
        name: null,
        description: null,
        risk: 0,
        conditions: [],
      }
    },

    addCondition() {
      if(this.add_condition && !this.conditions.includes(this.add_condition)) {
        this.conditions.push(this.add_condition);
        this.add_condition = '';
      }
    },

    getRecord() {
      this.processing = true;
      this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
          .then(response => {
            if(response && response.data) {

              if(response.data.uuid) {
                this.form = response.data;

                this.setConditions();
                this.setTitle();
                this.getClients();
                this.getMessages();
              }

              this.processing = false;
            }
          })
    },

    setConditions() {
        if(!this.form.conditions || this.form.conditions.length === 0) {
            this.form.conditions = [];
        }

        if(this.form.conditions && this.form.conditions.length > 0) {
            this.conditions = _.union(this.conditions, this.form.conditions);
            
        }
    },

    setTitle() {
      document.title = this.form.name + ' | ' + this.$route.meta.title;
    },

    getClients() {
      this.loading = true;
      this.$http.get(this.$route.meta.base_url+'/'+this.$route.params.id+'/clients')
          .then(response => {
            if(response && response.status) {

              if(response.data && response.data.length > 0) {
                this.clients = response.data;
              }

              this.loading = false;
            }
          })
    },

    getMessages() {
      if(!this.form.name) {
        return;
      }

      this.$http.get('/messages?slim=true&fields=subject,content,scheduled_at&type=supervision:'+this.form.name)
        .then(response => {
          if(response && response.data && response.data.data) {
            this.messages = response.data.data;
          }
        })
    },

    sendMessage() {

      if(!this.clients || this.clients.length === 0) {
        this.$eventBus.$emit('show-alert', {
          text: 'No clients selected',
          type: 'danger'
        });
        return;
      }

      this.sending = true;
      
      let sent = 0;
      const total = this.clients.length;

      this.clients.forEach(client => {
        var post = {
          subject: this.messageForm.subject,
          content: this.messageForm.content,
          type: 'supervision:'+this.form.name,
          client: client.client
        }

        this.$http.post('/messages', post)
          .then(response => {
            if(response && response.status) {
              sent++;
              
              if (sent === total) {
                this.$eventBus.$emit('show-alert', {
                  text:  `Message sent to ${total} clients`,
                  type: 'success'
                });
                
                this.resetSendMessage();
              }
            }
          })
      });
    },

    resetSendMessage() {
      this.messageForm = {
        subject: '',
        content: ''
      }

      this.sending = false;
    },

    getPortal() {
      this.$http.get('/portals?sort=default&sortDirection=desc&limit=1&fields=uuid,name,link,domain')
        .then(response => {
          if(response && response.data && response.data[0]) {
            this.portal = response.data[0];
          }
        })
    }


  },
}
</script>