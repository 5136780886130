<template>
  <div>
    <page-tabs page="portals" />

    <div class="submissions-spreadsheet">
        <div class="row mb-3">
          <div class="col-sm-12 col-md-4">
              <template v-if="current_form && current_form.name">
                <h4>{{ current_form.name }}</h4>
              </template>
              <p class="d-print-none">
                  <router-link :to="{name: 'portals.index'}">Portals</router-link> /
                  {{ $route.meta.title }}s
              </p>
              <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} Total {{ $route.meta.title }}s
              </p>
          </div>

          <div class="col-sm-12 col-md-4">
              <div class="btn-group d-print-none">
                <button class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  
                  <template v-if="current_form && current_form.name">
                    {{ current_form.name }}
                  </template>
                  <template v-else>
                    Choose Form
                  </template>
                </button>
                <div class="dropdown-menu">
                  <template v-for="form in forms">
                    <li class="dropdown-item" @click="setCurrentForm(form)">
                      {{ form.name }}
                    </li>
                  </template>
                </div>
              <template v-if="current_form && current_form.uuid">
                <a :href="'/forms/'+current_form.uuid" class="btn btn-outline-primary" target="_blank">View Form</a>
                <button class="btn btn-outline-primary" @click="resetCurrentForm()" :disabled="processing">Clear Form</button>
              </template>
            </div>

          </div>

          <div class="col-sm-12 col-md-4 text-end">
              <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
              </div>
          </div>
      </div>

      <div class="row mb-3 d-print-none">
        <div class="col-sm-12 col-md-12">
          <form @submit.prevent="getRecords()">
            <div class="input-group">
              <input type="search" class="form-control" v-model="queries.search" placeholder="Search">
              <button class="btn btn-outline-primary" type="submit" :disabled="processing">Search</button>
            </div>
          </form>
        </div>
      </div>

      <div class="spreadsheet-container">
          <!-- Loading indicator -->
          <div v-if="processing && !editingCell" class="spreadsheet-loading">
              <loader :isLoading="true"></loader>
          </div>

          <table class="table table-bordered spreadsheet-table" v-if="records && records.length">
              <thead>
                  <tr class="spreadsheet-header">
                      <th style="width:5%" class="action-column d-print-none">Edit</th>
                      <th style="width:5%" class="action-column d-print-none">Form</th>
                      <th v-for="key in tableColumns" 
                          :key="key" 
                          :hidden="isHiddenColumn(key)"
                          class="spreadsheet-header-cell">
                          {{ key }}
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(row, rowIndex) in records" :key="'row-'+rowIndex" class="spreadsheet-row">
                      <td class="action-column d-print-none">
                        <template v-if="!processing && current_row === rowIndex">
                          <span class="ms-2">
                              <loader :isLoading="true" size="sm"></loader>
                          </span>
                        </template>
                        <template v-else>
                          <router-link 
                              :to="{'name': 'submissions.edit', 'params': { 'id': row.uuid } }" 
                              class="btn btn-sm btn-outline-primary" 
                              :disabled="processing && current_row === rowIndex">
                              <i class="fa fa-edit"></i>
                          </router-link>
                        </template>
                      </td>
                      <td class="action-column d-print-none">
                        <template v-if="row.form">
                          <a :href="'/forms/'+row.form" target="_blank">{{ setFormName(row) }}</a>
                        </template>
                      </td>
                      <td v-for="field in tableColumns" 
                          :key="'cell-'+rowIndex+'-'+field" 
                          :hidden="isHiddenColumn(field)"
                          :class="{'cell-editing': isEditing(rowIndex, field), 'spreadsheet-cell': true}"
                          @click="startEditing(rowIndex, field, row[field])">
                          <template v-if="isEditing(rowIndex, field)">
                              <input
                                  v-if="!isFileField(row[field])"
                                  ref="cellInput"
                                  type="text"
                                  v-model="editValue"
                                  class="form-control cell-editor"
                                  @blur="finishEditing(rowIndex, field)"
                                  @keyup.enter="finishEditing(rowIndex, field)"
                                  @keyup.esc="cancelEditing()"
                                  @keydown.tab.prevent="moveToNextCell(rowIndex, field, $event)"
                                  v-focus
                              />
                          </template>
                          <template v-else>
                              <template v-if="isFileField(row[field])">
                                <button class="btn btn-sm btn-outline-primary" @click="downloadFile(row, field)">
                                  <i class="fa fa-file"></i> {{ row[field] }}
                                </button>
                              </template>
                              <template v-else>
                                  <span class="cell-content">{{ row[field] }}</span>
                              </template>
                          </template>
                      </td>
                  </tr>
              </tbody>
          </table>

      </div>

          <template v-if="!processing && records && !records.length">
            <div class="alert alert-warning mt-3" v-if="current_form && current_form.uuid">
              No {{ $route.meta.title }}s found.
            </div>
          </template>

          <template v-if="!processing && current_form && !current_form.uuid">
            <div class="alert alert-warning mt-3">
              Choose a form to view submissions.
            </div>
          </template>
  </div>
  </div>
</template>

<script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {
  mixins: [queries],
  
  directives: {
      focus: {
          inserted(el) {
              el.focus();
              if (el.value) {
                  const val = el.value;
                  el.value = '';
                  el.value = val;
              }
          }
      }
  },
  
  data() {
      return {
          processing: false,
          current_form: {},
          queries: {
            search: ''
          },
          forms: [],
          records: [],
          total: 0,
          current_row: null,
          editingCell: null,
          editValue: '',
          originalValue: ''
      }
  },
  
  computed: {
      tableColumns: function() {
          if(!this.records || !this.records.length) {
              return [];
          }

          
          // return Object.keys(this.records[0]);

          //get all keys from every record and remove duplicates
          var keys = [];
          this.records.forEach(record => {
            keys = keys.concat(Object.keys(record));
          });

          //remove duplicates
          keys = keys.filter((value, index, self) => self.indexOf(value) === index);

          //trim keys
          keys = keys.map(key => key.trim());

          return keys;
      }
  },

  mounted() {
      var self = this;

      this.$eventBus.$on(this.$route.meta.base_url, function(data) {
          if(!data.uuid || !data.form) {
              return;
          }

          if(data.form !== self.current_form.uuid) {
              return;
          }

          if(!self.records || !self.records.length) {
              self.records = [];
          }

          var index = self.records.findIndex(function(record) {
              return record.uuid === data.uuid;
          });

          if(index > -1) {
              self.records[index] = self.mapRecord(data);
          } else {
              self.records.unshift(self.mapRecord(data));
              self.total++;
          }
      });
  },
  
  created() {
      this.getForms();
  },
  
  methods: {

      setCurrentForm(form) {
        this.current_form = form;
        this.getRecords();
      },

      resetCurrentForm() {
        this.current_form = {
          name: null
        };
        this.records = [];
        this.total = 0;
        this.queries.search = '';
        //remove form from url
        var url = new URL(window.location.href);
        url.searchParams.delete('form');
        window.history.pushState({}, '', url);
      },

      getForms() {
        this.$http.get('/forms?limit=20&sort=name&fields=uuid,name')
          .then(response => {
            if(response && response.data) {
              this.forms = response.data;

              if(this.$route.query.form) {
                this.setCurrentForm(this.forms.find(form => form.uuid === this.$route.query.form));
              }
            }
          })
      },

      setFormName(record) {
        if(!record.form || !this.forms) {
          return;
        }

        var result = _.find(this.forms, {'uuid': record.form});

        if(!result || !result.name) {
          return;
        }

        return result.name;
      },

      mapRecord(record) {
          var data = record.data;
          data.uuid = record.uuid;
          data.form = record.form;
          return data;
      },
      
      getRecords() {
          this.processing = true;
          this.records = [];

          if(this.current_form && this.current_form.uuid) {
            this.queries.form = this.current_form.uuid;
          }

          this.getTotal();
          
          this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
              .then(response => {
                  if(response && response.data) {
                      this.records = response.data.map(this.mapRecord);
                      this.processing = false;
                  }
              })
      },
      
      getTotal() {
          this.total = 0;
          
          this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
              .then(response => {
                  if(response && response.status === 200) {
                      this.total = response.data;
                  }
              });
      },
      
      isEditing(rowIndex, key) {
          return this.editingCell && 
                this.editingCell.rowIndex === rowIndex && 
                this.editingCell.key === key;
      },
      
      startEditing(rowIndex, key, value) {
          if (this.isFileField(value)) {
              return;
          }
          
          this.editingCell = { rowIndex, key };
          this.editValue = value;
          this.originalValue = value;
      },
      
      finishEditing(rowIndex, key) {
          if (!this.editingCell) return;
          
          // Only update if value has changed
          if (this.editValue !== this.originalValue) {
              this.records[rowIndex][key] = this.editValue;
              this.updateSubmission(rowIndex);
          }
          
          this.editingCell = null;
      },
      
      cancelEditing() {
          this.editingCell = null;
      },
      
      moveToNextCell(rowIndex, key, event) {
          // First, save the current cell
          if (this.editValue !== this.originalValue) {
              this.records[rowIndex][key] = this.editValue;
              this.updateSubmission(rowIndex);
          }
          
          // Get all visible columns
          const visibleColumns = this.tableColumns.filter(col => !this.isHiddenColumn(col));
          
          // Find current column index
          const currentColIndex = visibleColumns.indexOf(key);
          
          // Determine next cell coordinates
          let nextRowIndex = rowIndex;
          let nextColIndex = currentColIndex;
          
          if (event.shiftKey) {
              // Move backwards with Shift+Tab
              nextColIndex--;
              if (nextColIndex < 0) {
                  // Move to previous row, last column
                  if (nextRowIndex > 0) {
                      nextRowIndex--;
                      nextColIndex = visibleColumns.length - 1;
                  } else {
                      // We're at the first cell, do nothing or loop to the last cell
                      nextRowIndex = this.records.length - 1;
                      nextColIndex = visibleColumns.length - 1;
                  }
              }
          } else {
              // Move forwards with Tab
              nextColIndex++;
              if (nextColIndex >= visibleColumns.length) {
                  // Move to next row, first column
                  if (nextRowIndex < this.records.length - 1) {
                      nextRowIndex++;
                      nextColIndex = 0;
                  } else {
                      // We're at the last cell, do nothing or loop to the first cell
                      nextRowIndex = 0;
                      nextColIndex = 0;
                  }
              }
          }
          
          // Get the next column key
          const nextKey = visibleColumns[nextColIndex];
          
          // Reset current cell edit mode
          this.editingCell = null;
          
          // After a brief timeout to allow Vue to update the DOM
          this.$nextTick(() => {
              // Start editing the next cell if it's not a file field
              if (!this.isFileField(this.records[nextRowIndex][nextKey])) {
                  this.startEditing(nextRowIndex, nextKey, this.records[nextRowIndex][nextKey]);
              } else {
                  // If next cell is a file field, find the next editable cell
                  this.findAndMoveToNextEditableCell(nextRowIndex, nextColIndex, visibleColumns, event.shiftKey);
              }
          });
      },
      
      findAndMoveToNextEditableCell(rowIndex, colIndex, visibleColumns, isBackward = false) {
          let nextRowIndex = rowIndex;
          let nextColIndex = colIndex;
          let foundEditableCell = false;
          
          // Maximum number of attempts to prevent infinite loop
          const maxAttempts = this.records.length * visibleColumns.length;
          let attempts = 0;
          
          while (!foundEditableCell && attempts < maxAttempts) {
              attempts++;
              
              if (isBackward) {
                  // Move backwards
                  nextColIndex--;
                  if (nextColIndex < 0) {
                      // Move to previous row, last column
                      if (nextRowIndex > 0) {
                          nextRowIndex--;
                          nextColIndex = visibleColumns.length - 1;
                      } else {
                          // Wrap to last row
                          nextRowIndex = this.records.length - 1;
                          nextColIndex = visibleColumns.length - 1;
                      }
                  }
              } else {
                  // Move forwards
                  nextColIndex++;
                  if (nextColIndex >= visibleColumns.length) {
                      // Move to next row, first column
                      if (nextRowIndex < this.records.length - 1) {
                          nextRowIndex++;
                          nextColIndex = 0;
                      } else {
                          // Wrap to first row
                          nextRowIndex = 0;
                          nextColIndex = 0;
                      }
                  }
              }
              
              // Get the next column key
              const nextKey = visibleColumns[nextColIndex];
              
              // Check if this cell is editable
              if (!this.isFileField(this.records[nextRowIndex][nextKey])) {
                  foundEditableCell = true;
                  this.startEditing(nextRowIndex, nextKey, this.records[nextRowIndex][nextKey]);
              }
          }
      },
      
      updateSubmission: _.debounce(function (index) {
          this.postSubmission(index);
      }, 600),

      postSubmission(index) {
          var form = {
              data: this.records[index]
          };

          this.current_row = index;
          this.processing = true;
          
          this.$http.patch('/'+this.$route.meta.base_url+'/'+this.records[index].uuid, form)
              .then(response => {
                  if(response && response.data) {
                      this.processing = false;
                      this.records[index].updated_at = response.data.updated_at;
                      this.current_row = null;
                      this.$eventBus.$emit('show-alert', {
                          text: this.$route.meta.title + ' updated',
                          type: 'success'
                      });
                  }
              })
      },
      
      isHiddenColumn(key) {
          var hiddenColumns = ['uuid', 'agency', 'region', 'portal', 'location', 'created_at', 'updated_at', 'archived_at', 'deleted_at', 'form'];
          return hiddenColumns.indexOf(key) > -1;
      },

      downloadFile(row, key) {
          this.processing = true;
          this.$http.get('/'+this.$route.meta.base_url+'/'+row.uuid+'/files?file='+row[key])
              .then(response => {
                  if(response && response.data && response.data[0]) {
                      this.processing = false;
                      if(response.data[0].url) {
                          window.open(response.data[0].url, '_blank');
                      }
                  }
              })
      },

      isFileField(value) {
          if(!value) {
              return false;
          }
          
          if(typeof value !== 'string') {
              return false;
          }

          //if includes any extension from the array, return true
          var extensions = ['png', 'jpg', 'jpeg', 'gif', 'svg', 'webp', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
          if(extensions.some(extension => value.includes(extension))) {
              return true;
          }

          return false;
      },
  }
}
</script>

<style scoped>
.submissions-spreadsheet {
  position: relative;
  padding: 10px;
}

.spreadsheet-container {
  position: relative;
  overflow-x: auto;
}

.spreadsheet-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,0.7);
  z-index: 1;
}

.spreadsheet-table {
  margin-bottom: 0;
  table-layout: auto;
  width: 100%;
}

.spreadsheet-header {
  background-color: #f9f9f9;
  position: sticky;
  top: 0;
  z-index: 1;
}

.spreadsheet-header-cell {
  font-weight: 600;
  padding: 10px;
  border-bottom: 2px solid #dee2e6;
  white-space: nowrap;
}

.spreadsheet-row:hover {
  background-color: rgba(0,123,255,0.05);
}

.spreadsheet-cell {
  position: relative;
  min-height: 40px;
  padding: 6px 10px;
  vertical-align: middle;
  cursor: pointer;
}

.cell-editing {
  padding: 0;
  outline: 2px solid #007bff;
  outline-offset: -2px;
  background-color: #fff;
  z-index: 2;
}

.cell-editor {
  width: 100%;
  height: 100%;
  padding: 6px 10px;
  border: none;
  box-shadow: none;
  background: transparent;
}

.cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 24px;
}

.action-column {
  text-align: center;
  vertical-align: middle;
  background-color: #f9f9f9;
  position: sticky;
  left: 0;
  z-index: 1;
}

.spreadsheet-image {
  max-height: 100px;
  display: block;
  margin: 0 auto;
}
</style>