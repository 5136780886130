<template>
    <div>
  
        <page-tabs page="setup" />
  
        <div class="row">
          <div class="col-md-6 mx-auto">
    
            <div class="card">
              <div class="card-body">

                <p class="lead m-0">Manage eCourtDate Onboarding</p>

                <p class="card-text m-0 mb-3">Help us get you onboarded!</p>

                <template v-if="!form || !form.uuid && !processing">
                  <button @click="createForm" class="btn btn-success" :disabled="processing">Start Onboarding</button>
                </template>
  
                <template v-if="form && form.uuid">
    
                  <template v-if="form && form.status && form.status != 'active'">
                    <div class="alert alert-info">Your onboarding is currently {{ form.status }}. You can activate it below.</div>
                  </template>
  
                  <form @submit.prevent="postForm">
  
                    <div class="form-group">
                      <label for="go_live">Target Go Live Date</label>
                      <input type="date" class="form-control" id="go_live" v-model="form.go_live" required>
                      <p class="form-text text-muted">When do you want to be live with eCourtDate for this agency?</p>
                    </div>
  
                    <div class="form-group">
                      <label for="referral_source">How did you hear about eCourtDate?</label>
                      <select class="form-control" id="referral_source" v-model="form.referral_source" required>
                        <option value="">Select</option>
                        <option v-for="source in referral_sources" :value="source">{{ source }}</option>
                      </select>
                    </div>
  
                    <div class="form-group">
                      <label for="cms">Which Case Management System do you use?</label>
                      <select class="form-control" id="cms" v-model="form.cms">
                        <option value="">Select</option>
                        <option v-for="cms in cms_systems" :value="cms.key">{{ cms.name }}
  
                          <template v-if="cms.vendor != cms.name">
                            ({{ cms.vendor }})
                          </template>
                        </option>
                      </select>
                    </div>
  
                    <template v-if="form.cms">
                      <div class="form-group">
                        <label for="cms_version">CMS Version</label>
                        <input type="text" class="form-control" id="cms_version" v-model="form.data['cms_version']">
                      </div>
                    </template>
  
                    <template v-if="form.cms">
                      <div class="form-group">
                        <label for="cms_hosting">CMS Hosting</label>
                        <select class="form-control" id="cms_hosting" v-model="form.data['cms_hosting']">
                          <option value="">Select</option>
                          <option value="self-hosted">Self Hosted on Premises</option>
                          <option value="cloud-hosted">Self Hosted on Cloud</option>
                          <option value="managed-hosted">Managed Hosting by Vendor</option>
                          <option value="saas-hosted">SaaS Hosted by Vendor</option>
                        </select>
                      </div>
                    </template>

                    <div class="form-group">
                      <label for="data_sources">Data Sources</label>
                      <div class="mt-2">
                        <div class="form-check" v-for="data_source in data_sources" :key="data_source">
                          <input 
                            class="form-check-input" 
                            type="checkbox" 
                            :id="'data-source-' + data_source.toLowerCase().replace(/\s+/g, '-')" 
                            :value="data_source"
                            v-model="form.data.data_sources"
                          >
                          <label 
                            class="form-check-label" 
                            :for="'data-source-' + data_source.toLowerCase().replace(/\s+/g, '-')"
                          >
                            {{ data_source }}
                          </label>
                        </div>
                        
                        <div class="mt-2" v-if="form.data.data_sources && form.data.data_sources.includes('Other')">
                          <input 
                            type="text" 
                            class="form-control" 
                            placeholder="Please specify other data source"
                            v-model="form.data.custom_data_source"
                          >
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="phase">Onboarding Phase</label>
                      <select class="form-control" id="phase" v-model="form.data['phase']">
                        <option value="">Select</option>
                        <option value="Evaluating">Evaluating</option>
                        <option value="Planning">Planning</option>
                        <option value="Implementation">Implementation</option>
                        <option value="Testing">Testing</option>
                        <option value="Training">Training</option>
                        <option value="Go Live">Go Live</option>
                      </select>
                    </div>

                    <div class="form-group">
                        <label for="status">Onboarding Status</label>
                        <select id="status" class="form-control" v-model="form.status">
                          <option value="active">Active</option>
                          <option value="paused">Paused</option>
                          <option value="canceled">Canceled</option>
                          <option value="complete">Complete</option>
                        </select>
                    </div>
  
                    <div class="form-group">
                      <label for="notes">Onboarding Notes</label>
                      <textarea class="form-control" id="notes" v-model="form.notes" rows="5"></textarea>
                    </div>
  
                    <button type="submit" class="btn btn-success" :disabled="processing">Save</button>
  
                    </form>
    
                </template>
  
  
              </div><!--Body-->
            </div><!--Card-->
          </div><!--Col-->
        </div><!--Row-->
  
    </div>
  </template>
  
  <script>
  import queries from '../mixins/queries';
  
  export default {
  
    mixins: [ queries ],
  
  data() {
      return {
          processing: false,
          form: {},
          cms_systems: [],
          referral_sources: ['Search Engine', 'Social Media', 'Referral', 'Conference', 'RFP', 'Partner', 'Other'],
          data_sources: ['SFTP', 'API', 'Manual Entry', 'Self-Service Portal', 'Other']
      }
  },
  

  
  created() {
      document.title = this.$route.meta.title + ' | eCourtDate.com';
      this.resetForm();
      this.getCaseSystems();
      this.getOnboarding();
  },
  
  methods: {
  
    resetForm() {
      this.form = {
        go_live: null,
        notes: null,
        referral_source: null,
        cms: null,
        status: null,
        data: {
          use_cases: [],
          custom_use_case: '',
          data_sources: [],
          custom_data_source: '',
          cms_version: '',
          cms_hosting: '',
          phase: 'Qualifying'
        },
        phases: ['Qualifying', 'Planning', 'Implementation', 'Testing', 'Training', 'Go Live']
      }
    },
  
      getOnboarding() {
        this.processing = true;
        this.$http.get('/agencies/onboarding')
        .then(response => {
          if(response && response.status) {
            if(response.data.uuid) {
              this.form = response.data;

              if(!this.form.data) {
                this.form.data = {
                  use_cases: [],
                  custom_use_case: '',
                  data_sources: [],
                  custom_data_source: '',
                  cms_version: '',
                  cms_hosting: ''
                }
              }

              if(!this.form.data.data_sources) {
                this.form.data.data_sources = [];
              }
            }
  
            this.processing = false;
          }
        })
      },
  
  
      getCaseSystems() {
        this.$axios.get('https://assets.ecourtdate.com/js/cms-systems.json')
        .then(response => {
          if(response && response.data) {
            this.cms_systems = response.data;
  
            this.cms_systems.push({
              name: 'In-House',
              vendor: 'In-House',
              key: 'in-house'
            });
  
            this.cms_systems.push({
              name: 'Other',
              vendor: 'Other',
              key: 'other'
            });
  
            this.cms_systems.push({
              name: 'None',
              vendor: 'None',
              key: 'none'
            });
  
          }
        })
      },
    
  
      postForm() {
        this.processing = true;
  
        if(this.form.data && this.form.data.use_cases) {
          this.form.use_cases = this.form.data.use_cases;
        }
  
        if(this.form.data && this.form.data.data_sources) {
          this.form.data_sources = this.form.data.data_sources;
        }
  
        if(!this.form.status) {
          this.form.status = 'active';
        }

        if(this.form.data.custom_data_source) {
         this.form.custom_data_source = this.form.data.custom_data_source;
        }
  
        this.$http.post('/agencies/onboarding', this.form)
        .then(response => {
          if(response && response.data) {
            this.form = response.data;
            this.processing = false;
            this.$eventBus.$emit('show-alert', {
              text: 'Saved!'
            });
          }
        })
      },
  
      createForm() {
        this.processing = true;
  
        this.form = {
          go_live: this.$moment().add(30, 'days').format('YYYY-MM-DD'),
          notes: null,
          referral_source: null,
          status: 'active'
        }
  
        this.$http.post('/agencies/onboarding', this.form)
        .then(response => {
          if(response && response.data) {
            this.form = response.data;
            this.processing = false;
            this.$eventBus.$emit('show-alert', {
              text: 'Saved!'
            });
          }
        })
      },
  

  
  
  }
  
  }
  </script>