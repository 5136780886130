<template>
  <div>

    <page-tabs page="message_templates" />

    <div class="row">

      <div class="col-9 mx-auto">

        <h4>Sample Auto Messages</h4>
        <p class="lead text-muted">Click <em>Import</em> to copy the template to your auto messages.</p>
        <auto-templates />

        <h4>Sample Flows</h4>
        <p class="lead text-muted">Click <em>Import</em> to copy the template to your flows.</p>
        <flow-templates />

      </div>

    </div>



  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {

    }
  },

  created () {
    this.setDocTitle(this.$route.meta.title+'s');
  },

  methods: {

  }
}
</script>
