import Vue from 'vue';
import axios from 'axios';

const host = window.location.host;
const parts = host.split('.');

let subdomain = 'us-east';

if (parts.length === 3 && parts[0] !== 'www' && parts[0] !== 'app') {
  subdomain = parts[0];
}

let apiURL = 'https://' + subdomain + '.api.ecourtdate.com/';

var localURL = process.env.VUE_APP_API_URL;

if(localURL != null) {
  apiURL = localURL
}

if(parts[0] === 'app') {
  apiURL = 'https://api.ecourtdate.com/';
}

Vue.prototype.$apiURL = apiURL;

const token = localStorage.getItem('access_token');

if(!localStorage.getItem('current_region')) {
    localStorage.setItem('current_region', subdomain);
}


const http = axios.create({
  baseURL: apiURL+'v1',
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
});

http.defaults.headers.common.Authorization = 'Bearer ' + token;

Vue.prototype.$eventBus = new Vue();
Vue.prototype.$http = http;

Vue.prototype.$http.interceptors.response.use(
  function (response) {
    if (!localStorage.getItem('access_token')) {
      window.location.href = '/logout';
    }

    return response;
  },
  function (error) {

    if (error.response && error.response.data && error.response.data.message) {
        if(error.response.status != 403 && error.response.status != 404) {
          Vue.prototype.$eventBus.$emit('errors', error.response || error);
          // Vue.prototype.$eventBus.$emit('show-alert', {
          //   text: error.response.data.message,
          //   type: 'danger'
          // });

        }
    }

    if(!localStorage.getItem('access_token')) {
      window.location.href = '/logout';
    }

  }
)

export default http