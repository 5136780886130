<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      title="Availability"
    />

    <div class="row mb-3">
      <div class="col-sm-12 col-md-3">
        <h1>{{ form.name }}</h1>
        <p><router-link :to="{name: 'settings.index'}">Settings</router-link> /
          <router-link :to="{name: 'availability_templates.index'}">Availability Templates</router-link> / Edit Availability</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-3">
        <template v-if="form.last_active">
          <a :href="'/geolocations?room='+form.uuid"><span class="badge badge-pill badge-success text-uppercase me-1">Last Active {{ form.last_active | dateTimezone | timeago }}</span></a>
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-6 text-end">
        <div class="btn-group">

          <template v-if="!form.deleted_at && !form.archived_at">

          <template v-if="portal && portal.link">
            <a :href="'https://'+portal.link+'.'+portal.domain+'/schedule?template='+form.uuid" class="btn btn-outline-primary" target="_blank"><i class="fa fa-link"></i> Portal Link</a>
          </template>

          <button
            type="submit"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          </template>

          <button
            type="button"
            class="btn btn-outline-secondary dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Tools
          </button>
          <div class="dropdown-menu">
                <a
                  v-if="form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord(form)"
                >Restore</a>
                <a
                  v-if="!form.deleted_at"
                  class="dropdown-item"
                  @click="trashRecord(form)"
                >Trash</a>
          </div>

          <router-link
            :to="{ name: 'availability_templates.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>
        </div><!--BtnGroup-->
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Edit Availability
            </p>

            <form @submit.prevent="postForm()">

              <div class="form-group mt-3">
                <label for="name">Event Name *</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="portal">Portal

                  <template v-if="form.portal">
                    <router-link :to="{name: 'portals.edit', params: {id: form.portal}}"><i class="fa fa-edit"></i> <span class="sr-only">Edit</span></router-link>
                  </template>
                </label>
                <select
                  id="portal"
                  v-model="form.portal"
                  class="form-control"
                  required
                  :disabled="processing"
                >
                <option value=""></option>
                  <template v-for="portal_option in portals">
                    <option
                      :value="portal_option.uuid"
                    >
                      {{ portal_option.name }} ({{ portal_option.link }}.{{ portal_option.domain }})
                    </option>
                  </template>
                </select>

              </div>

              <div class="form-group">
                <label for="location">Location

                  <template v-if="form.location">
                    <a :href="'/locations/'+form.location" target="_blank"><i class="fa fa-edit"></i> <span class="sr-only">Edit</span></a>
                  </template>
                </label>
                <select
                  id="location"
                  v-model="form.location"
                  class="form-control"
                  :disabled="processing"
                >
                <option value=""></option>
                  <template v-for="(location, index) in locations">
                    <option
                      :value="location.uuid"
                    >
                      {{ location.name }}
                    </option>
                  </template>
                </select>

              </div>

              <div class="form-check">
                <input
                  id="virtual"
                  v-model="form.virtual"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for="virtual"
                >Virtual</label>
              </div>

              <div class="form-check">
                <input
                  id="allow_manual"
                  v-model="form.allow_manual"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for="allow_manual"
                >Allow Manual Bookings</label>
              </div>
      
              <div class="form-group">
                <label for="event_type" class="form-label">Event Type</label>
                <input id="event_type" type="text" class="form-control" v-model="form.event_type">
              </div>

              <div class="form-group">
                <label for="event_status" class="form-label">Event Status</label>
                <input id="event_status" type="text" class="form-control" v-model="form.event_status">
              </div>

              <div class="form-group">
                <label for="description">Description</label>
                <textarea id="description" class="form-control" rows="3" v-model="form.description"></textarea>
              </div>

            <div class="form-group">
              <label for="duration">Event Duration</label>
              <input id="duration" type="number" class="form-control" v-model="form.duration">
              <small class="text-muted form-text">In minutes</small>
            </div>

            <div class="form-group">
              <label for="increment">Start Time Increments</label>
              <input id="increment" type="number" class="form-control" v-model="form.increment">
              <small class="text-muted form-text">In minutes</small>
            </div>

            <div class="form-group">
              <label for="min_notice">Minimum Notice</label>
              <input id="min_notice" type="number" class="form-control" v-model="form.min_notice" min="0" step="1">
              <small class="text-muted form-text">In hours</small>
            </div>

            <div class="form-group">
              <label for="max_daily">Max Daily Events</label>
              <input id="max_daily" type="number" class="form-control" v-model="form.max_daily" min="0" step="1">
            </div>

            <div class="form-group">
              <label for="max_future">Max Days Ahead</label>
              <input id="max_future" type="number" class="form-control" v-model="form.max_future" min="0" step="1">
            </div>

            <div class="form-group">
              <label for="pre_buffer">Buffer Before Event</label>
              <input id="pre_buffer" type="number" class="form-control" v-model="form.pre_buffer" min="0" step="1">
              <small class="text-muted form-text">In minutes</small>
            </div>

            <div class="form-group">
              <label for="post_buffer">Buffer After Event</label>
              <input id="post_buffer" type="number" class="form-control" v-model="form.post_buffer" min="0" step="1">
              <small class="text-muted form-text">In minutes</small>
            </div>

            <div class="form-group">
              <label for="enabled_days">Enabled Days</label>
              <input id="enabled_days" type="text" class="form-control" v-model="form.enabled_days">
            </div>

            <div class="form-group">
              <label for="notify_bookings">Notify Bookings</label>
              <input id="notify_bookings" type="text" class="form-control" v-model="form.notify_bookings">
              <small class="text-muted form-text">Comma-separated emails and phones</small>
            </div>

            <div class="form-group">
              <label for="webhook">Notify Webhook</label>
              <input id="webhook" type="text" class="form-control" v-model="form.webhook">
              <small class="text-muted form-text">Notify a webhook URL (such as Teams, Slack, or a Kiosk).</small>
            </div>

            <div class="form-group">
              <label for="confirm_message">Confirmation Message

                  <template v-if="form.confirm_message">
                    <a :href="'/auto_messages/'+form.confirm_message" target="_blank"><i class="fa fa-edit"></i> <span class="sr-only">Edit</span></a>
                  </template>
              </label>
              <select id="confirm_message" class="form-control" v-model="form.confirm_message">
                <option value=""></option>
                <template v-for="(auto_message, index) in auto_messages">
                  <option :value="auto_message.uuid">{{ auto_message.name }}</option>
                </template>
              </select>
            </div>

                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                >
                  Save
                </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs :form="form" class="mt-3"></audit-logs>

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

        <div class="card mb-3">
          <div class="card-body">
            <p class="card-title">Fields</p>

            <template v-if="form.fields && form.fields.length > 0">
            
            <p class="my-3">{{ form.fields.length }} of {{ availableFields.length }} Enabled Fields</p>

            <draggable
                v-model="form.fields"
                group="fields"
                @start="drag=true"
                @end="drag=dragEnd($event)"
              >
            <template v-for="(field, field_index) in form.fields">
               <li class="list-group-item">
                <input type="text" class="form-control" v-model="form.fields[field_index].name">
                <button class="btn btn-primary btn-sm my-3" @click="toggleField(field)" :disabled="processing"><i class="fa fa-arrows"></i></button>
                <button class="btn btn-danger btn-sm my-3" @click="form.fields.splice(field_index, 1)" :disabled="processing"><i class="fa fa-trash"></i></button>
                <span class="badge badge-pill badge-secondary float-end my-3">{{ form.fields[field_index].key }}</span>

              </li>
            </template>
            </draggable>

            </template>

            <template v-if="availableFields && availableFields.length > 0">

              <p class="my-3">Available Fields</p>

            <template v-for="field in availableFields">
              <button class="btn btn-light btn-sm" @click="toggleField(field)" :hidden="form.fields && form.fields.includes(field)" :disabled="processing">{{ field }}</button>
            </template>

            </template>

          </div><!--Body-->
        </div><!--Card-->

        <p class="card-title">Next {{ availabilities.length }} Availabilities</p>

        <ul class="list-group">

          <template v-for="availability in availabilities">

            <li class="list-group-item">{{ availability }}</li>

          </template>

        </ul>
        
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';
import draggable from 'vuedraggable';

export default {

components: { draggable },

mixins: [queries],

  data () {
    return {
      processing: false,
      base_url: '/availability_templates',
      form: {},
      auto_messages: [],
      availabilities: [],
      portal: {},
      portals: [],
      locations: [],
      availableFields: ['events.case_number', 'events.event_reference', 'events.judge_name', 'clients.client_reference', 'clients.group']
    }
  },

  created() {
    this.resetForm();
    this.getRecord();
    this.getAutoMessages();
    this.getPortals();
    this.getLocations();
  },

  methods: {

    resetForm() {
      this.form = {
        name: null,
        portal: null,
        location: null,
        virtual: false,
        allow_manual: false,
        description: null,
        event_type: null,
        event_status: null,
        duration: null,
        increment: null,
        min_notice: null,
        max_daily: null,
        max_future: null,
        pre_buffer: null,
        post_buffer: null,
        notify_bookings: null,
        confirm_message: null,
        webhook: null,
        enabled_days: null
      }
    },

    getAutoMessages() {
      this.$http.get('/auto_messages?limit=1000&slim=true&fields=uuid,name&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.auto_messages = response.data;
        }
      })
    },

    getRecord() {
      this.processing = true;
      this.$http.get(this.base_url+'/'+this.$route.params.id)
      .then(response => {
        if(response && response.data) {
          this.form = response.data;
          this.processing = false;
          this.setTitle();
          this.getAvailabilities();

          if(this.form.portal) {
            this.getPortal(this.form.portal);
          }
        }
      })
    },

    setTitle() {
      document.title = this.form.name + ' | ' + this.$route.meta.title + ' | eCourtDate.com';
    },

    getAvailabilities() {
      var queries = this.form;
      queries.limit = 100;

      queries.from_date = new Date().toISOString();
      queries.to_date = new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString();

      this.$http.get(this.buildQueries('/availabilities', queries))
      .then(response => {
        if(response && response.data) {
          this.availabilities = response.data;
        }
      })
    },

    postForm() {

      this.processing = true;
      this.$http.patch(this.base_url+'/'+this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.data.updated_at) {
            this.$eventBus.$emit('show-alert', {
              text: 'Availability updated'
            });
            this.getAvailabilities();
          }

        }
      })
    },

    getPortal(uuid) {
      console.log('getPortal', uuid);
        this.$http.get('/portals/'+uuid)
        .then(response => {
            if(response && response.data) {
                this.portal = response.data;
            }
        })
    },

    getPortals() {
        this.$http.get('/portals?limit=1000&slim=true&fields=name,uuid,link,domain')
        .then(response => {
            if(response && response.data) {
              
                this.portals = [];

                var self = this;

                if(response.data.length > 0) {
                  response.data.forEach(portal => {
                    self.portals.push(portal);
                  });
                }

                if(this.portals.length > 0 && !this.form.portal) {
                    this.form.portal = this.portals[0];
                }
            }
        })
    },

    getLocations() {
        this.$http.get('/locations?limit=1000&slim=true&fields=name,uuid')
        .then(response => {
            if(response && response.data) {
                this.locations = response.data;
            }
        })
    },

    toggleField(field) {

      if(!this.form['fields']) {
        this.form['fields'] = [];
      }

      // if(this.form['fields'].includes(field)) {
      //   var index = this.form['fields'].indexOf(field);
      //   this.form['fields'].splice(index, 1);
      // } else {
      //   this.form['fields'].push(field);
      // }

      field = {
        name: field,
        key: field,
      }

      this.form['fields'].push(field);

    },

      dragEnd(event) {
          this.postForm();
      },

  },
}
</script>