<template>
  <div>

    <template v-if="$route.meta.requiresAuth">

        <div id="agencySwitcher" class="btn-group d-print-none me-3 position-relative">

          <div class="dropdown" id="searchAgencies">

              <button id="searchAgenciesButton" class="btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false"
              @click="getAgencies"
              >
                <i class="fa fa-landmark-dome"></i> 
                  {{ setAgencyName() }}
              </button>

                <div
                  class="dropdown-menu p-2"
                  :class="{ 'show': isDropdownOpen }"
                  aria-labelledby="searchAgenciesButton"
                >
                  <p class="dropdown-header">Switch Agency</p>

                  <form @submit.prevent="handleSubmit">
                    <div class="input-group">
                      <label
                        class="visually-hidden"
                        for="search_agencies"
                      >Search</label>
                      <input
                        id="search_agencies"
                        v-model="search_agencies"
                        type="text"
                        class="form-control"
                        placeholder="Search agencies..."
                        @keydown.down.prevent="navigateList('down')"
                        @keydown.up.prevent="navigateList('up')"
                        @keydown.enter.prevent="handleEnter"
                        @keydown.esc="closeDropdown"
                        @blur="onInputBlur"
                      >
                      <button
                        class="btn btn-outline-primary"
                        type="submit"
                        :disabled="processing"
                        @click.stop
                      >
                        <template v-if="!processing">
                          <i class="fa fa-search"></i> <span class="visually-hidden">Search</span>
                        </template>
                        <template v-if="processing">
                          <loader :isLoading="processing" />
                        </template>
                      </button>
                    </div>

                  </form>

                  <a href="/agencies" class="dropdown-item"><i class="fa fa-university me-1"></i> View All Agencies</a>

                  <div class="agency-list">
                    <template v-if="agencies && agencies.length > 0">
                      <a
                        v-for="(agency, index) in agencies"
                        :key="agency.uuid"
                        class="dropdown-item d-flex align-items-center justify-content-between"
                        :class="{ 
                          'active': isActiveAgency(agency),
                          'keyboard-selected': currentIndex === index 
                        }"
                        @click="switchAgency(agency)"
                        ref="agencyItems"
                      >
                        <span :class="isPrivacyMode ? 'privacy-mode' : ''">
                          {{ agency.name }}<br>
                          <small class="text-muted">{{ agency.reference }} {{ agency.region }}</small>
                        </span>
                        <template v-if="agency.status == 0">
                          <span class="badge bg-danger rounded-pill ms-2">INACTIVE</span>
                        </template>
                        <template v-if="agency.status == 2">
                          <span class="badge bg-success rounded-pill ms-2">NEW</span>
                        </template>
                      </a>
                    </template>
                  </div>
                </div>

              </div><!--AgencyDropdown-->

          <div class="dropdown">
            <button class="btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-globe"></i>
            </button>

            <div class="dropdown-menu dropdown-menu-end">
              <p class="dropdown-header">Switch Region</p>
              <template v-for="region in regions">
                <template v-if="region != $root.current_region">
                <a
                    class="dropdown-item"
                    :href="'https://'+region+'.ecourtdate.com'"
                    target="_blank"
                >
                  <template v-if="region == 'app'">
                    main
                  </template>
                  {{ region }}</a>
                </template>
              </template>
            </div>
          </div>

        </div><!--Group-->

      </template>
</div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data () {
        return {
            agencies: [],
            processing: false,
            search_agencies: '',
            regions: ['app', 'us-east', 'us-west', 'us-south', 'us-texas', 'staging'],
            isDropdownOpen: false,
            currentIndex: -1,
        }
    },

    computed: {

    isGlobalDomain() {

        // var localURL = process.env.VUE_APP_API_URL;

        // if(localURL != null) {
        //     return false;
        // }

        const host = window.location.host;
        const parts = host.split('.');

        if (parts[0] === 'app') {
          return true;
        }

        return false;
    },

    },

    created() {

      // Close dropdown when clicking outside
      document.addEventListener('click', (e) => {
        if (!this.$el.contains(e.target)) {
          this.closeDropdown();
        }
      });

      window.addEventListener('keydown', (e) => {
        if (e.ctrlKey && e.key === 'k' || e.metaKey && e.key === 'k') {
          e.preventDefault();
          document.getElementById('searchAgenciesButton').click();
          document.getElementById('search_agencies').focus();
          this.isDropdownOpen = true;
        }
      });
    },


    methods: {

        setAgencyName() {
          if(this.settings && this.settings.name) {
            return this.settings.name;
          }

          if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.name) {
            return this.$root.current_user.current_agency.name;
          }

          this.$http.get('/settings')
          .then(response => {
            if(response && response.data && response.data.name) {
              return response.data.name;
            }
          })
          
        },

        getAgencies() {
            this.processing = true;
            this.isDropdownOpen = true;
            this.currentIndex = -1;
            this.agencies = [];

            var url = '/agencies?limit=25&slim=y&fields=uuid,name,reference,region,status&search='+this.search_agencies+'&sort=created_at&sortDirection=desc';

            if(this.isGlobalDomain) {
              url += '&not_region=staging';
            } else {
              url += '&region='+this.$root.current_region;
            }

            this.$http.get(url)
            .then(response => {
                if(response && response.data) {
                    this.processing = false;
                    this.agencies = response.data;
                }
            })
        },

        switchAgency(agency) {

          this.clearAgencySession();

          this.$http.post('switch_agency/'+agency.uuid+'?region='+agency.region)
          .then(response => {
            if (response && response.status === 200) {
              location.reload();
            }
          })
        },

        clearAgencySession() {
          
          var fields = ['fields', 'timezone', 'agency', 'send_mode', 'settings'];

          fields.forEach(field => {
            localStorage.removeItem(field);
          })

        },

        isActiveAgency(agency) {

            if(agency.uuid === localStorage.getItem('agency')) {
                return true;
            }

            return false;
        },

        handleSubmit(e) {
          e.preventDefault();
          e.stopPropagation();
          this.getAgencies();
          this.isDropdownOpen = true;
          this.$nextTick(() => {
            document.getElementById('search_agencies').focus();
          });
        },

        navigateList(direction) {
          if (this.agencies.length === 0) return;
          
          if (direction === 'down') {
            if (this.currentIndex === -1 || this.currentIndex === this.agencies.length - 1) {
              this.currentIndex = 0;
            } else {
              this.currentIndex++;
            }
          } else {
            if (this.currentIndex === -1 || this.currentIndex === 0) {
              this.currentIndex = this.agencies.length - 1;
            } else {
              this.currentIndex--;
            }
          }
          
          // Scroll item into view if needed
          if (this.currentIndex >= 0 && this.$refs.agencyItems) {
            this.$refs.agencyItems[this.currentIndex]?.scrollIntoView({
              block: 'nearest',
              behavior: 'smooth'
            });
          }
        },

        handleEnter() {
          if (this.currentIndex >= 0 && this.agencies[this.currentIndex]) {
            this.switchAgency(this.agencies[this.currentIndex]);
          } else {
            this.getAgencies();
            this.isDropdownOpen = true;
            this.$nextTick(() => {
              document.getElementById('search_agencies').focus();
            });
          }
        },

        closeDropdown() {
          this.isDropdownOpen = false;
          this.currentIndex = -1;
        },

        onInputBlur(e) {
          // Don't close dropdown if clicking inside the dropdown
          if (e.relatedTarget && this.$el.contains(e.relatedTarget)) {
            return;
          }
        },

    },
    
}
</script>

<style scoped>
.position-relative {
  position: relative !important;
}

#searchAgencies {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  width: 400px;
  max-width: calc(100vw - 30px);
  max-height: 600px;
  overflow-y: auto;
  right: auto;
  left: 0;
  top: 100%;
  margin-top: 2px;
  transform: none !important;
}

.agency-list {
  max-height: 300px;
  overflow-y: auto;
}

.keyboard-selected {
  background-color: var(--bs-primary);
  color: white !important;
}

.keyboard-selected small {
  color: #f8f9fa !important;
}
</style>