<template>
  <div>

<template v-if="currentTabs && currentTabs.length">

<nav class="nav nav-tabs mb-3 d-print-none">

    <template v-for="tab in currentTabs">

      <router-link
        :to="{ name: tab+'.index' }"
        class="nav-link text-capitalize"
        :class="setTabStyle(tab)"
      >
      <template v-if="tab != 'qrs'">
        {{ setTabLabel(tab) }}
      </template>
      <template v-else>
        QR Codes
      </template>
      </router-link>

    </template>

</nav>

</template>

  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

mixins: [queries],

props: ['page'],

data() {
    return {
      tabs: {
        "events": ['events', 'calendars', 'waitlists', 'checkins', 'unreachables', 'schedules'],
        "clients": ['clients', 'contacts', 'lookups', 'comments', 'documents', 'sent_files', 'addresses'],
        "payments": ['payments'],
        "cases": ['cases', 'assessments', 'attorneys', 'bonds', 'warrants', 'comments'],
        "uploads": ['uploads', 'upload_templates', 'file_templates'],
        "portals": ['portals', 'files', 'posts', 'forms', 'submissions', 'qrs', 'redirects'],
        "users": ['users', 'roles'],
        "message_templates": ['message_templates', 'auto_messages', 'flows', 'sample_templates', 'preview', 'merge_tags', 'generator'],
        "auto_messages": ['auto_messages', 'auto_templates'],
        "messages": ['dashboard', 'messages', 'geolocations'],
        "bulk_actions": ['bulk_actions', 'recurring_actions', 'bulk_templates'],
        "tests": ['tests', 'test_templates'],
        "reports": ['reports', 'dashboards', 'datapoints'],
        "settings": ['settings', 'agency_phones', 'agency_emails', 'chatbots', 'statuses', 'locations', 'organizations', 'judges', 'availability_templates', 'supervisions'],
        "setup": ['setup', 'onboarding', 'billing', 'carriers', 'checklist', 'training', 'help', 'referrals']
      },
      current_tab: ""
    }
},

watch: {
  $route(to, from) {
    this.current_tab = to.name;
  }
},

computed: {

  currentTab: function() {
    var location = window.location;

    return location.pathname.replace("/", "");
  },

  currentTabs: function() {
    if(this.tabs[this.page]) {
      return this.tabs[this.page]
    }

    return "";
  }

},

created() {
  this.current_tab = this.currentTab;
},

methods: {

  setTabStyle(tab) {
    if(this.current_tab == tab) {
      return 'active';
    }
  },

  setTabLabel(tab) {

    tab = tab.replace('_', ' ');

    if(this.tabs['setup'].includes(tab)) {
      return tab;
    }

    if(!this.settings || !this.settings['labels']) {
      return tab;
    }

    if(tab.slice(-1) == 's') {
      tab = tab.slice(0, -1);
    }

    var result = this.settings['labels'][tab] || tab;

    return result+'s';

    },


}

}
</script>