<template>
  <div class="toast-container position-fixed top-0 end-0 p-3">
    <transition-group name="toast">
      <div 
        v-for="alert in alerts" 
        :key="alert.id"
        class="toast show"
        :class="alert.type ? `text-bg-${alert.type}` : 'text-bg-success'"
        role="alert" 
        aria-live="assertive" 
        aria-atomic="true"
      >
        <div class="toast-header">
          <i 
            class="fa" 
            :class="getIcon(alert.type)"
            :aria-label="alert.type"
          ></i>
          <strong class="me-auto ms-2">{{ alert.title || 'Notification' }}</strong>
          <button 
            type="button" 
            class="btn-close" 
            @click="removeAlert(alert.id)"
            aria-label="Close"
          ></button>
        </div>
        <div class="toast-body">
          {{ alert.text }}
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      alerts: [],
    }
  },

  mounted() {
    this.$eventBus.$on('show-alert', this.showAlert);
  },

  methods: {
    showAlert(options) {
      const alert = {
        text: options.text || '',
        type: options.type || 'success',
        title: options.title || '',
        timeout: options.timeout || 3000,
        id: Date.now()
      };

      this.alerts.push(alert);
      
      setTimeout(() => {
        this.removeAlert(alert.id);
      }, alert.timeout);
    },

    removeAlert(id) {
      const index = this.alerts.findIndex(alert => alert.id === id);
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
    },

    getIcon(type) {
      const icons = {
        success: 'fa-check',
        danger: 'fa-exclamation-triangle',
        warning: 'fa-exclamation',
        info: 'fa-info-circle'
      };
      return icons[type] || icons.success;
    }
  }
}
</script>

<style scoped>
.toast-enter-active, .toast-leave-active {
  transition: all 0.3s ease;
}
.toast-enter, .toast-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>