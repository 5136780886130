<template>
    <div>
    
    <page-tabs page="setup" />
    
          <div class="row">
            <div class="col-6 mx-auto">
              <div class="card">
                <div class="card-body">
    
                  <p class="lead">Billing Profile</p>

                  <template v-if="customer && customer.uuid">

                    <ul class="list-group">

                      <template v-if="customer.name">
                        <li class="list-group-item">Customer Name: {{ customer.name }}</li>
                      </template>

                      <template v-if="customer.address_1">
                        <li class="list-group-item">Address: {{ customer.address_1 }}</li>
                      </template>

                      <template v-if="customer.address_2">
                        <li class="list-group-item">Address 2: {{ customer.address_2 }}</li>
                      </template>

                      <template v-if="customer.city">
                        <li class="list-group-item">City: {{ customer.city }}</li>
                      </template>

                      <template v-if="customer.state">
                        <li class="list-group-item">State: {{ customer.state }}</li>
                      </template>

                      <template v-if="customer.zip">
                        <li class="list-group-item">Zip: {{ customer.zip }}</li>
                      </template>

                      <template v-if="customer.website">
                        <li class="list-group-item">Website: {{ customer.website }}</li>
                      </template>

                    </ul>

                  </template>

                  <template v-if="partner.name">
                    <h2 class="mt-3">Partner Integration</h2>

                    <template v-if="partner.icon">
                      <img :src="partner.icon" class="img-fluid" width="200" :alt="partner.name + ' logo'" />
                    </template>

                    <ul class="list-group">
                      <li class="list-group-item">Name: {{ partner.name }}</li>
                    </ul>

                  </template>

                  <template v-if="customer.contacts && customer.contacts.length > 0">
  
                    <h2 class="mt-3">Billing Contacts</h2>

                    <template v-if="partner && partner.name">
                      <div class="alert alert-info mt-3">
                        To update your billing profile, please contact your partner.
                      </div>
                    </template>

                    <ul class="list-group">
                    <template v-for="contact in customer.contacts">
                      <li class="list-group-item">
                          {{ contact.name }}
                          <small class="text-muted">{{ contact.email }}</small>
                          <template v-if="contact.notify">
                            <span class="badge badge-pill badge-secondary float-end">{{ contact.notify }}</span>
                          </template>
                          <template v-else>
                            <span class="badge badge-pill badge-secondary float-end">No Notification</span>
                          </template>
                      </li>
                    </template>
                    </ul>

                    </template>

                    <template v-if="customer.uuid">
                      <p><a :href="'https://billing.ecourtdate.com?customer='+customer.uuid" target="_blank" class="btn btn-primary mt-3">Billing Portal</a></p>
                    </template>

    
                </div><!--Body-->
            </div><!--Card-->
    
            </div><!--Col-->
        </div><!--Row-->

    </div>
    </template>
    <script>    
    export default {
    
        data() {
            return {
              processing: false,
              customer: {},
              partner: {},
              states: []
            }
        },
        
        created () {
            this.getStates();
            this.getCustomer();
        },
    
        methods: {

        getStates() {
            this.$axios.get(this.$root.assets_url+'js/states.json')
            .then(response => {
                if(response && response.data) {
                    this.states = response.data;
                }
            })
        },
    
          getCustomer() {
            this.customer = {};
            this.processing = true;
            this.$http.get('/agencies/customer')
                .then(response => {
                  if(response && response.data) {
                    this.customer = response.data;

                    this.getPartner();

                    this.processing = false;
                  }
                })
          },

          getPartner() {

            if(!this.customer.uuid || !this.customer.partner) {
              return;
            }

            this.$http.get('/partners/'+this.customer.partner)
            .then(response => {
              if(response && response.data) {
                this.partner = response.data;
              }
            })
          }
    
    
        }
    
    }
    </script>