<template>
    <div>
    
      <div class="row">
  
        <template v-for="record in records">
  
          <div class="col-sm-12 col-md-4" :hidden="!record.messages || record.messages.length === 0">
  
          <div class="card mb-3">
            <div class="card-body">
  
              <div class="row mb-3">
  
                <div class="col-sm-12 col-md-9">
                  <p class="card-title fw-bold">{{ record.name }}</p>
                </div><!--Col-->
  
                <div class="col-sm-12 col-md-3 text-end">
                    <button type="button" class="btn btn-success m-0" @click="importRecord(record)" :disabled="processing"><i class="fa fa-download"></i> Import</button>
                </div><!--Col-->
  
              </div><!--Row-->
  
              <template v-if="record.messages">
  
                <template v-for="message in record.messages">
  
                  <div class="my-3">
  
                    <p class="sms outbound">{{ message.templates['en'] }}</p>
  
                       <p class="small text-muted m-0">{{ setMessageName(message) }}
  
                        <template v-if="message.trigger == 'event_date' || message.trigger == 'payment_issue_date' || message.trigger == 'payment_due_date'">
                          {{ message.difference }} {{ message.unit }} 
                          <template v-if="message.operation === '-'">
                            before
                          </template>
                          <template v-if="message.operation === '+'">
                            after
                          </template>
                        </template>
                      
                      </p>
  
                    <div class="btn-group">
                      <a :href="'/preview?content='+message.templates['en']" class="btn btn-secondary" :disabled="processing"><i class="fal fa-mobile"></i> Preview</a>
                      <button type="button" class="btn btn-primary" @click="copyToClipboard(message.templates['en'])" :disabled="processing"><i class="fa fa-copy"></i> Copy</button>
                    </div>
  
                    </div>
  
                </template>
              </template>
  
            </div><!--Body-->
          </div><!--Card-->
  
        </div><!--Col-->
  
        </template>
  
      </div><!--Row--> 
  
    </div>
  </template>
  <script>
  import _ from 'lodash';
  import queries from '../mixins/queries';
  
  export default {
    
    mixins: [ queries ],
  
    data () {
      return {
        processing: false,
        records: [],
        queries: {},
        triggers: []
      }
    },
  
    created () {
      this.resetQueries();
      this.getTriggers();
      this.getRecords();
    },
  
    methods: {
  
      resetQueries() {
        this.queries = {
          slim: false
        }
      },
  
      getTriggers() {
          
          this.$axios.get(this.$root.assets_url+'js/options.json')
          .then(response => {
            if(response && response.data) {
              this.triggers = response.data.flow_message_triggers;
            }
          })
  
      },
  
      setMessageName(message) {
          var trigger = _.find(this.triggers, {'value': message.trigger});
  
          if(trigger && trigger.label) {
            return trigger.label;
          }
  
          return message.trigger;
      },

      getRecords() {
      this.processing = true;
      this.$axios.get('https://assets.ecourtdate.com/demo/flows.json')
      .then(response => {
        if(response && response.data) {
          this.records = response.data;
          this.processing = false;
        }
      })
    },
  
      importRecord(record) {
  
        this.processing = true;
        this.$http.post('/flows', this.setFlowForm(record))
        .then(response => {
          if(response && response.data) {
  
            if(response.data.uuid) {
              this.importMessages(record, response.data);

              this.$eventBus.$emit('show-alert', {
                text: 'Flow imported'
              });
  
              var self = this;
  
              setTimeout(function() {
                  self.$router.push('/flows/'+response.data.uuid);
              }, 3000);
  
            }
          }
        })
      },
  
      setFlowForm(record) {
        return {
          "name": record.name,
          "flow_reference": record.flow_reference,
          "type": record.type || 'event'
        }
      },
  
      importMessages(record, flow) {
          
          if(!record.messages) {
            return;
          }
  
          var self = this;
  
          record.messages.forEach(message => {
            self.importMessage(self.setFlowMessage(flow, message));
          });
      },
  
      setFlowMessage(flow, message) {
        return {
          "flow": flow.uuid,
          "trigger": message.trigger,
          "difference": message.difference,
          "unit": message.unit,
          "operation": message.operation,
          "status": message.status,
          "send_time": message.send_time,
          "templates": message.templates,
          "subjects": message.subjects,
          "delay_business": message.delay_business,
          "delay_min": message.delay_min,
          "file_url": message.file_url,
        }
      },
  
      importMessage(form) {
        this.$http.post('/flow_messages', form)
      }
    }
  }
  </script>
  