import { render, staticRenderFns } from "./EventsIndex.vue?vue&type=template&id=3ce17256"
import script from "./EventsIndex.vue?vue&type=script&lang=js"
export * from "./EventsIndex.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports