<template>
  <div>

      <page-tabs page="setup" />

      <div class="row">
        <div class="col-md-6 mx-auto">

            <h1>Data Sources</h1>

            <p class="lead">Use eCourtDate with any combination of data sources. Operate 100% automatically or mix manual and automated data entry.</p>


                  <div class="row" v-if="!current_source">

                    <template v-for="data_source in data_sources">
                      <div class="col-sm-12 col-md-6 my-3">

                        <div class="card link-hover" @click="updateDataSource(data_source)">
                          <div class="card-body">

                            <h4 class="text-capitalize">{{ data_source['title'] | rmDashes }}</h4>

                            <p>{{ data_source['description'] }}</p>

                          </div><!--Body-->
                        </div><!--Card-->

                      </div><!--Col-->
                    </template>

                  </div><!--Row-->

                  <p v-show="!current_source">Don't see a data source or integration that you're interested in? <a href="https://ecourtdate.com/schedule" target="_blank">Schedule a meeting to discuss with our team.</a></p>


                  <template v-if="current_source">


                      <div class="card">
                        <div class="card-body">

                            <div class="row">

                                <div class="col-sm-12 col-md-9">
                                    <h3 class="text-capitalize">{{ current_source['title'] | rmDashes }}</h3>
                                </div><!--Col-->

                                <div class="col-sm-12 col-md-3 text-end">
                                    <button type="button" class="btn btn-danger" @click="closeDataSource()"><i class="fa fa-times"></i> Close</button>
                                </div><!--Col-->

                            </div><!--Row-->

                          <template v-if="current_source['description']">
                            <p>{{ current_source['description'] }}</p>                        
                          </template>

                          <template v-if="current_source['suggestions']">
                            <h5>Suggestions</h5>
                            <ol>
                              <template v-for="suggestion in current_source['suggestions']">
                                <li v-html="suggestion"></li>
                            </template>
                            </ol>
                        </template>


                        </div><!--Body-->

                      </div><!--Card-->


                  </template>

        </div><!--Col-->
      </div><!--Row-->

  </div>
</template>

<script>

export default {

data() {
    return {
        processing: false,
        current_source: null,
        data_sources: [
        {
            title: 'Manual Entry',
            description: 'Add clients and related data manually one at a time through this application.',
            suggestions: [
                'Ensure the agency checklist is complete',
                'Review any CLIENT level settings in Admin > Settings tab',
                'Consider whether users who create clients should be auto-assigned to the client',
                'Create limited Roles for users who will only manage clients',
                'Ensure any DEFAULT/PRIMARY settings are configured to minimize manual data entry by users',
                'Create Flows/Auto Messages to minimize the need to create one-off messages by users',
                'Create Bulk Action Templates to streamline sending a bulk one-off message to clients',
                'Review Auto-Archive, Auto-Trash, and Duplicate Checks settings to minimize manual and duplicate data entry',
                'Schedule a training session for users who will manage clients',
                'Invite users to a staging agency to practice managing clients (or ensure that your production agency is in Simulate Mode)',
                'Start adding clients and related data manually one at a time through the CLIENTS tab',
                'Provide a dedicated phone number and email address per user to communicate with clients',
                'Consider using the Portal to allow clients to self-enroll and manage their own contact preferences',
            ]
          },
          {
            title: 'Client Self Enroll',
            description: 'Allow clients to self-enroll through QR codes, web forms, and inbound messages.',
            suggestions: [
                'Ensure the agency checklist is complete',
                'In Admin > Portals, setup at least one Portal to allow clients to self-enroll',
                'Enable Opt In keywords to allow clients to self-enroll through inbound messages',
                'Configure Auto Messages to send a welcome message to clients who self-enroll',
                'Enable Client Authentication in the Portal to allow clients to manage their own contact preferences',
                'Review default client GROUPS and STATUS settings to ensure that clients who self-enroll are managed properly',
                'Create QR codes to allow clients to self-enroll with pre-filled data'
            ]
          },
          {
            title: 'Manual Bulk Uploads',
            description: 'Add clients and related data by manually uploading flat files (CSV and Excel).',
            suggestions: [
                'Ensure the agency checklist is complete',
                'Decide on which data fields to include in your flat file. Check Uploads > File Templates for sample file templates',
                'Get a sample file from your data source (e.g. Case Management System) to use as a starting point',
                'Ensure that you are on staging or in Simulate Mode to avoid sending messages to real clients',
                'Create an Upload Template to map your flat file to the eCourtDate fields and to set processing options',
                'Start uploading flat files through the UPLOADS tab',
                'Review the results of the upload in the UPLOADS tab',
                'Configure Flows/Auto Messages to trigger messages automatically based on the data you uploaded',
                'Run a Bulk Action to send a one-off message to the clients you uploaded',
          ]
          },
          {
            title: 'Automated Uploads',
            description: 'Upload flat files automatically through our SFTP Gateway.',
            suggestions: [
                'Ensure the agency checklist is complete',
                'Follow the steps in the Manual Bulk Uploads data source to prepare your flat file',
                'Contact our team to setup an SFTP Gateway server. If you already have one, go to <a href="https://console.ecourtdate.com/sftps" target="_blank">Console > SFTPs</a> to configure your server',
                'Create an Upload Template to map your flat file to the eCourtDate fields and to set processing options',
                'Start uploading flat files through the SFTP Gateway',
                '<a href="https://sftp.ecourtdate.com" target="_blank">Read our SFTP Gateway Guide here</a>',
            ]
          },
          {
            title: 'API Integration',
            description: 'Integrate with our API to create and update data and send and receive messages.',
            suggestions: [
                'Ensure the agency checklist is complete',
                'Review the <a href="https://devs.ecourtdate.com" target="_blank">Developer Guide</a> to get started with our API',
                'Manage your API keys in the <a href="https://console.ecourtdate.com/apis" target="_blank">Console > APIs</a>',
                'Use the API with a tool like Postman or Insomnia to test your requests',
                '<a href="https://webhooks.ecourtdate.com" target="_blank">Create Webhooks</a> to receive messages, check-ins, and status changes from eCourtDate',
            ]
          },
          {
            title: 'Partner Integrations',
            description: 'Use one of our partner integrations to integrate eCourtDate with your system.',
            suggestions: [
                'Ensure the agency checklist is complete',
                'Review the <a href="https://ecourtdate.com/partners" target="_blank">Justice System Partners</a> and <a href="https://ecourtdate.com/integrations" target="_blank">One-Click Integrations</a> pages on our website to see if we have a partner integration that fits your system',
                '<a href="https://ecourtdate.com/schedule" target="_blank">Schedule a meeting</a> with our team to discuss the best partner integration for your system',
            ]
          }
        ]
    }
},

mounted() {
},

created() {
    document.title = this.$route.meta.title + ' | eCourtDate.com';
},

methods: {

    closeDataSource() {
      this.current_source = null;
    },

    updateDataSource(source) {

      if(this.current_source && this.current_source.title == source.title) {
        this.current_source = null;
        return;
      }

      this.current_source = source;
    },


}

}
</script>