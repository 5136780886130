<template>
    <div>
  
      <page-tabs page="clients" />
  
      <div class="row">
        <div class="col-sm-12 col-md-3">
  
          <client-search />
  
          <div class="card">
            <div class="card-body">
              <p class="card-title">
                Create {{ $route.meta.title }}
              </p>
  
              <form @submit.prevent="postForm()">

                <template v-if="processing && uploadProgress">
                  <div class="progress">
                    <div
                        class="progress-bar"
                        role="progressbar"
                        :style="'width:'+uploadProgress+'%'"
                    >
                      {{ uploadProgress }}%
                    </div>
                  </div>
                </template>

                <div class="form-group">
                  <div class="custom-file">
                    <input
                        id="fileUpload"
                        ref="file"
                        type="file"
                        class="custom-file-input"
                        aria-describedby="fileUpload"
                        :disabled="processing"
                        required
                    >
                    <label
                        class="custom-file-label"
                        for="fileUpload"
                    >Choose File</label>
                  </div>
                </div>
  
                <div class="form-group">
                  <label for="type">Type</label>
                  <select
                    id="type"
                    v-model="form.type"
                    class="form-control"
                    :disabled="processing"
                    required>
                    <template v-for="form_type in types">
                      <option
                        :value="form_type"
                      >{{ form_type }}
                      </option>
                    </template>
                  </select>
                </div>
  
                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                  >
                    Create
                  </button>
  
              </form>
            </div><!--Body-->
          </div><!--Card-->
  
          <div class="card my-3">
            <div class="card-body">
              <p class="card-title">
                Search {{  $route.meta.title }}s
              </p>
  
              <form @submit.prevent="searchRecords">
  
                <div class="form-group">
                  <label for="search">Search</label>
                  <input
                      id="search"
                      v-model="queries.search"
                      type="search"
                      class="form-control"
                      placeholder="Search..."
                  >
                </div>
  
                <div class="form-group">
                  <label for="type">Type</label>
                  <select
                      id="type"
                      v-model="queries.type"
                      class="form-control"
                      :disabled="processing"
                  >
                    <option value="" />
                    <template v-for="search_type in types">
                      <option :value="search_type">{{ search_type }}</option>
                    </template>
                  </select>
                </div>
  
                <div class="form-group">
                  <label for="created_by">Creator</label>
                  <select
                      id="created_by"
                      v-model="queries.created_by"
                      class="form-control"
                      :disabled="processing"
                  >
                    <option value="" />
                    <template v-for="user in users">
                      <option
                          :value="user.email"
                      >
                        {{ user.name }} {{ user.email }}
                      </option>
                    </template>
                  </select>
                </div>
  
  
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-check">
                      <input
                          id="archived"
                          v-model="queries.archived"
                          type="checkbox"
                          class="form-check-input"
                          :disabled="processing"
                      >
                      <label
                          class="form-check-label"
                          for="archived"
                      >Archived</label>
                    </div>
                  </div><!--Col-->
  
                  <div class="col-sm-12 col-md-6">
                    <div class="form-check">
                      <input
                          id="trashed"
                          v-model="queries.trashed"
                          type="checkbox"
                          class="form-check-input"
                          :disabled="processing"
                      >
                      <label
                          class="form-check-label"
                          for="trashed"
                      >Trashed</label>
                    </div>
                  </div><!--Col-->
                </div><!--Row-->
  
                <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="processing"
                >
                  Search
                </button>
              </form>
            </div><!--Body-->
          </div><!--Card-->
  
        </div><!--Col-->
  
        <div class="col-sm-12 col-md-9">
  
          <div class="card">
            <div class="card-body">
  
              <div class="row">
  
                <div class="col-sm-12 col-md-6">
  
                  <p class="card-title">
                    {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                  </p>
  
                </div><!--Col-->
  
                <div class="col-sm-12 col-md-6 text-end">
  
                  <div class="btn-group d-print-none">
                    <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                    <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  </div>
                </div><!--Col-->
  
                </div><!--Row-->
  
              <div class="table-responsive my-3">
  
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <template v-for="column in columns">
                      <th
                        class="text-uppercase"
                      >
                        <template v-if="column.key">
                          <span
                            class="text-capitalize link-hover"
                            @click="sortColumn(column)"
                          >{{ column.label }}</span>
                        </template>
  
                        <template v-if="!column.key">
                          <span class="text-capitalize">{{ column.label }}</span>
                        </template>
  
                        <template v-if="column.key == queries.sort">
                          <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            @click="updateSortDirection()"
                          >
                            <template v-if="queries.sortDirection == 'asc'">
                              <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                            </template>
  
                            <template v-if="queries.sortDirection == 'desc'">
                              <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                            </template>
                          </button>
                        </template>
                      </th>
                    </template>
                  </tr>
                </thead>
  
                <tbody>
                  <template v-if="records && records.length > 0">
                    <template v-for="record in records">
                      <tr>
                        <td>
                          <a :href="$route.meta.base_url+'/'+record.uuid">
                            <i class="fa fa-edit"></i> Edit
                          </a>
                        </td>
                        <td>
                          <template v-if="record.file">
                            <button type="button" class="btn btn-outline-primary" @click="downloadFile(record)" :disabled="processing">{{ record.name }}</button>
                          </template>
                          <template v-else>
                            {{ record.name }}
                          </template>
                        </td>
                        <td>{{ record.type }}</td>
                        <td><span class="badge badge-pill text-uppercase" :class="setStatusClass(record)">{{ record.status }}</span></td>
                        <td>{{ record.description }}</td>
                        <td>
                            <template v-if="record.expires_on">
                                {{ record.expires_on | datetime }}
                            </template>
                        </td>
                        <td>{{ record.created_by }}</td>
                        <td>{{ record.created_at | datetime }}</td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
  
                </div><!--Responsive-->
  
              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No results found <template v-if="client && client.uuid">for {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }} {{ client.client_reference }}</template>
                </div>
              </template>
            </div><!--Body-->
          </div><!--Card-->
  
          <template v-if="records && records.length && total > records.length">
  
          <div class="row mt-3">
            <div class="col-12 text-center">
  
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
                >
                  Load more
                </button>
  
            </div><!--Col-->
          </div><!--Row-->
  
          </template>
  
        </div><!--Col-->
      </div><!--Row-->
    </div>
  </template>
  <script>
  import queries from '../mixins/queries';
  import _ from "lodash";
  
  export default {
  
    mixins: [ queries ],
  
    data () {
      return {
        processing: false,
        downloading: false,
        total: 0,
        queries: {},
        records: [],
        downloads: [],
        uploadProgress: null,
        form: {},
        client: {},
        columns: [
        { label: 'Edit'},
        { key: 'name', label: 'Name'},
        { key: 'type', label: 'Type'},
        { key: 'status', label: 'Status'},
        { key: 'description', label: 'Description'},
        { key: 'expires_on', label: 'Expires'},
        { key: 'created_by', label: 'Creator'},
        { key: 'created_at', label: 'Created'}
        ],
        users: [],
        types: ['Driver License', 'Proof of Address', 'State ID', 'Passport', 'Passport Card', 'Social Security Card', 'Birth Certificate', 'Marriage Certificate', 'Divorce Certificate', 'Death Certificate', 'Adoption Certificate', 'Naturalization Certificate', 'Military Service', 'Other']
      }
    },
  
    mounted() {
  
      var self = this;
  
  
      this.$eventBus.$on('search_clients', (uuid) => {

        if(uuid) {
          self.queries.client = uuid;
        } else {
          self.queries.client = null;
        }

        self.resetRecords();
        self.getRecords();
        });
  
  
  
    },
  
    created() {
      this.resetForm();
      this.resetQueries();
      this.resetRecords();
      this.setURLParams();
      this.setDocTitle(this.$route.meta.title+'s');
      
      this.getRecords();
      this.getUsers();
    },
  
    methods: {
  
  
      setURLParams() {
  
          var searchParams = new URLSearchParams(window.location.search);
  
          var self = this;
  
          searchParams.forEach(function(value, key) {
              self.queries[key] = value;
          });
      },
  
      setStatusClass(record) {
  
        if(record.status == 'invalid') {
          return 'badge-danger';
        }
  
        if(record.status == 'valid') {
          return 'badge-success';
        }
  
        return 'badge-secondary';
      },
  
      resetQueries() {
        this.queries = {
          limit: 10,
          slim: false,
          client: null,
          sort: 'created_at',
          sortDirection: 'desc'
        }
      },
  
      resetForm() {
        this.form = {
          name: null,
          file: null,
          type: 'Driver License',
          client: null,
          notes: null,
          expires_on: null,
          status: 'pending',
        }
      },
  
      resetRecords() {
        this.records = [];
        this.total = 0;
        this.queries.skip = 0;
      },
  
      updateSortDirection() {
  
        if(this.processing) {
          return;
        }
  
        if(this.queries.sortDirection == 'asc') {
          this.queries.sortDirection = 'desc';
        } else {
          this.queries.sortDirection = 'asc';
        }
  
        this.resetRecords();
  
        this.getRecords();
      },
  
      sortColumn(column) {
  
        if(this.processing) {
          return;
        }
  
        this.queries.sort = column.key;
  
        this.updateSortDirection();
      },

      downloadFile(record) {
        this.processing = true;
        this.$http.get('/'+this.$route.meta.base_url+'/'+record.uuid+'/download')
          .then(response => {
            if(response && response.data && response.data.url) {
              window.open(response.data.url, '_blank');
              this.processing = false;
            }
          })
      },
  
      getRecords() {
  
        this.processing = true;
  
        this.updateURL();
        this.getTotal();
  
        this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
        .then(response => {
          if(response && response.data) {
              
              if(response.data.length) {
  
                  var self = this;
  
                  response.data.forEach(function(data) {
                      self.records.push(data);
                  });
              }
              
              this.queries.skip = this.records.length;
  
              this.processing = false;
          }
        })
      },
  
      getTotal() {
        this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
          if(response && response.status === 200) {
            this.total = response.data;
          }
        })
      },
  
      updateURL() {
        history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
      },
    
      postForm() {
        this.processing = true;
  
        if(this.queries.client && this.$options.filters.isValidUUID(this.queries.client)) {
        this.form.client = this.queries.client;
        }
  
        this.$http.post(this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.data) {
  
            this.processing = false;
  
            if(response.status === 201) {
              this.resetForm();
              this.total++;
              this.records.unshift(response.data);
              this.$eventBus.$emit('show-alert', {
                text: this.$route.meta.title + ' created'
              });
              
              this.storeFile(response.data);

            }
  
          }
        })
      },
  
      getUsers() {
        this.$http.get('/users?slim=true&limit=1000&fields=name,email&sort=name&sortDirection=asc')
            .then(response => {
              if(response && response.data) {
                this.users = response.data;
              }
            })
      },
  
      searchRecords () {
        this.resetRecords();
        this.getRecords();
      },

      storeFile (data) {
  
      if(!this.$refs.file || !this.$refs.file.files[0]) {
        this.$router.push({name: this.$route.meta.base_url+'.edit', params: {id: data.uuid}});
        return;
      }


        this.processing = true;
        this.storeUpload(this.$refs.file.files[0], data, {
          progress: progress => {
            this.uploadProgress = Math.round(progress * 100);
          }
        }).then(file_response => {
          if(file_response && file_response.key) {

            this.$http.patch('/'+this.$route.meta.base_url + '/' + data.uuid, {
              file: file_response.key,
              name: this.$refs.file.files[0].name
            }).then(response => {
              if(response && response.data) {
                this.processing = false;

                this.$router.push({name: this.$route.meta.base_url+'.edit', params: {id: data.uuid}});
              }
            })

          }

        })
    },

    async storeUpload(file, data, options = {}) {
      const response = await this.$http.post(this.$apiURL + 'v1/signed_url', {
        'file_path': data.uuid,
        'bucket': 'ecdids',
        'name': file.name,
        'size': file.size,
        'type': file.type,
        'content_length': file.size,
        'extension': file.name.split('.').pop(),
        'content_type': options.contentType || file.type,
        'expires': options.expires || '',
        'visibility': options.visibility || ''
      }, {
        baseURL: options.baseURL || null,
        headers: options.headers || {},
        ...options.options
      });
      if (response && response.data) {
        let headers = response.data.headers;

        if ('Host' in headers) {
          delete headers.Host;
        }

        if (typeof options.progress === 'undefined') {
          options.progress = () => {};
        }

        const cancelToken = options.cancelToken || ''

        await this.$axios.put(response.data.url, file, {
          cancelToken: cancelToken,
          headers: headers,
          onUploadProgress: (progressEvent) => {
            options.progress(progressEvent.loaded / progressEvent.total);
          }
        }).catch(function (error) {
          if(error.response) {
            console.log(error.response.data);
          }
        });

        response.data.extension = file.name.split('.').pop();

        return response.data;
      }
      return null;
    },
  
    },
  }
  </script>