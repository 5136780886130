<template>
  <div>
    <archived-trashed-status
        :form="form"
        :processing="processing"
        :title="title"
    />

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <h1>{{ form.reference }}</h1>
        <p class="text-capitalize">
          <a href="/cases">Cases</a> /
          <router-link :to="{name: $route.meta.base_url+'.index'}">{{ title }}s</router-link> / Edit {{ title }}
        </p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <template v-if="portal && portal.link && portal.domain && form.url">
          <a :href="'https://'+portal.link+'.'+portal.domain+'/wr/'+form.url" class="btn btn-outline-primary" target="_blank"><i class="fa fa-link"></i> View in Portal</a>
        </template>

        <template v-if="form && form.uuid">
          <button type="button" class="btn btn-secondary" @click="getPDF()" :disabled="processing"><i class="fa fa-file-pdf"></i> PDF </button>
        </template>

      </div>

      <div class="col-sm-12 col-md-4 text-end">
        <div class="btn-group">

          <div class="btn-group">
            <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Tools
            </button>
            <div class="dropdown-menu">

              <a
                  v-if="!form.archived_at && !form.deleted_at"
                  class="dropdown-item"
                  @click="syncRecord(form)"
              >Sync Messages</a>

              <a
                  v-if="!form.archived_at && !form.deleted_at"
                  class="dropdown-item"
                  @click="archiveRecord(form)"
              >Archive</a>
              <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord(form)"
              >Restore</a>
              <a
                  v-if="!form.deleted_at && !form.archived_at"
                  class="dropdown-item"
                  @click="trashRecord(form)"
              >Trash</a>
            </div>
          </div>

          <button
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="postForm"
          >
            Save
          </button>

          <router-link
              :to="{ name: $route.meta.base_url+'.index' }"
              class="btn btn-danger"
          >
            Close
          </router-link>
        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <div class="card mb-3">
          <div class="card-body">

                <p class="card-title">
                  Edit {{ title }}
                </p>

            <form @submit.prevent="postForm">

              <div class="form-group mt-3">
                <label for="reference">{{ setCustomField('warrants', 'reference') }}</label>
                <input
                    id="reference"
                    type="text"
                    v-model="form.reference"
                    class="form-control"
                    required
                >
              </div>

              <div class="form-group">
                <label for="flow">Flow</label>
                <select
                    id="flow"
                    v-model="form.flow"
                    class="form-control"
                    :disabled="processing"
                    @change="postForm"
                >
                  <option value="" />
                  <template v-for="flow in flows">
                    <option :value="flow.uuid">{{ flow.name }}</option>
                  </template>
                </select>
                <template v-if="form.flow">
                  <a :href="'/flows/'+form.flow" target="_blank">View Flow</a>
                </template>
              </div>

              <div class="form-group">
                <label for="status">{{ setCustomField('warrants', 'status') }}</label>
                <select
                    id="status"
                    v-model="form.status"
                    class="form-control"
                    :disabled="processing"
                    @change="postForm"
                >
                  <option value="" />
                  <template v-for="status in statuses">
                    <option :value="status.name">{{ status.name }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="type">{{ setCustomField('warrants', 'type') }}</label>
                <select
                    id="type"
                    v-model="form.type"
                    class="form-control text-uppercase"
                    :disabled="processing"
                    @change="postForm"
                >
                  <option value="" />
                  <template v-for="record_type in record_types">
                    <option :value="record_type">{{ record_type }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="case_number">{{ setCustomField('warrants', 'case_number') }}</label>
                <input
                    id="case_number"
                    type="text"
                    v-model="form.case_number"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="bond_number">{{ setCustomField('warrants', 'bond_number') }}</label>
                <input
                    id="bond_number"
                    type="text"
                    v-model="form.bond_number"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="issued_at">{{ setCustomField('warrants', 'issued_at') }}</label>
                <input
                    id="issued_at"
                    type="date"
                    v-model="form.issued_at"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="url">{{ setCustomField('warrants', 'url') }}</label>
                <input
                    id="url"
                    type="text"
                    v-model="form.url"
                    class="form-control"
                >
              </div>

              <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
              >
                Save
              </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs :form="form" />
      </div><!--Col-->
      <div class="col-sm-12 col-md-4">

        <div class="card">
            <div class="card-body">
              <form @submit.prevent="storeFiles()">
                <div class="form-group">
                  <label for="upload_files">Upload Files</label>
                  <input
                    id="upload_files"
                    ref="files"
                    type="file"
                    class="form-control"
                    multiple="true"
                    :disabled="processing"
                  >
                </div>

                <template v-if="!uploadProgress">
                  <button
                    type="submit"
                    class="btn btn-success btn-block"
                    :disabled="processing"
                  >
                    Upload
                  </button>
                </template>

                <template v-if="processing && uploadProgress">
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      :style="'width:'+uploadProgress+'%'"
                    >
                      {{ uploadProgress }}%
                    </div>
                  </div>
                </template>
              </form>

              <template v-if="files && files.length">

                <p class="card-title mt-3 mb-0">{{ files.length }} Files</p>

                <ul class="list-group">

                  <template v-for="file in files">
                    <li class="list-group-item">
                      <a :href="'/files/'+file.uuid" target="_blank">{{ file.name }}</a>

                      <button class="btn btn-danger btn-sm float-end" @click="deleteFile(file)" :disabled="processing">Delete</button>
                    </li>
                  </template>

                </ul>

              </template>

            </div><!--Body-->
          </div><!--Card-->

        <div class="card my-3" v-if="form.type == 'social media'">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-9">
                <p class="card-title">Social Media</p>
                <p class="text-muted">Generate warrant requests for social media profiles</p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-3 text-end">
                <button class="btn btn-success btn-sm">Run Lookups</button>
              </div><!--Col-->

            </div><!--Row-->

            <template v-for="social_media in social_medias">
              <button
                  class="btn btn-outline-primary btn-sm"
                  :class="{'active': current_social === social_media}"
                  @click="current_social = social_media"
                  >{{ social_media }}</button>
            </template>

            <template v-if="current_social">

              <form @submit.prevent="generateSocialWarrant" class="my-3">
              
              <div class="form-group">
                <label for="social_media_username">Username</label>
                <input
                    id="social_media_username"
                    type="text"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="social_media_url">URL</label>
                <input
                    id="social_media_url"
                    type="text"
                    class="form-control"
                >
              </div>

              <button type="submit" class="btn btn-success btn-sm" :disabled="processing">Generate</button>

              </form>
            
            </template>


          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3" v-if="form.type == 'isp'">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-9">
                <p class="card-title">ISP</p>
                <p class="text-muted">Generate warrant requests for internet service providers</p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-3 text-end">
                <button class="btn btn-success btn-sm">Run Lookups</button>
              </div><!--Col-->

            </div><!--Row-->

            <template v-for="isp in isps">
              <button
                  class="btn btn-outline-primary btn-sm"
                  :class="{'active': current_isp === isp}"
                  @click="current_isp = isp"
                  >{{ isp }}</button>
            </template>

            <template v-if="current_isp">

              <form @submit.prevent="generateSocialWarrant" class="my-3">
              
              <div class="form-group">
                <label for="ip_address">IP Address</label>
                <input
                    id="ip_address"
                    type="text"
                    class="form-control"
                >
              </div>

              <button type="submit" class="btn btn-success btn-sm" :disabled="processing">Generate</button>

              </form>

            </template>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3" v-if="form.type == 'carrier'">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-9">
                <p class="card-title">Carrier</p>
                <p class="text-muted">Generate warrant requests for cell phone carriers</p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-3 text-end">
                <button class="btn btn-success btn-sm">Run Lookups</button>
              </div><!--Col-->

            </div><!--Row-->

            <template v-for="carrier in carriers">
              <button
                  class="btn btn-outline-primary btn-sm"
                  :class="{'active': current_carrier === carrier}"
                  @click="current_carrier = carrier"
                  >{{ carrier }}</button>
            </template>


            <template v-if="current_carrier">

              <form @submit.prevent="generateSocialWarrant" class="my-3">
              
              <div class="form-group">
                <label for="phone_number">Phone Number</label>
                <input
                    id="phone_number"
                    type="text"
                    class="form-control"
                >
              </div>

              </form>

            </template>


          </div><!--Body-->
        </div><!--Card-->

        <template v-if="!form.client">
          <div class="card my-3">
            <div class="card-body">
              <p class="card-title">Assign to Client</p>

              <form @submit.prevent="getClients">

                <div class="input-group mb-3">
                  <input
                      type="text"
                      class="form-control"
                      v-model="search_clients"
                      placeholder="Search Clients"
                  >
                  <div class="input-group-append">
                    <button
                        class="btn btn-outline-primary"
                        type="submit"
                        @click="getClients"
                        :disabled="processing"
                    >Search</button>
                  </div>
                </div>

              </form>

              <template v-if="clients && clients.length">
                <p class="text-muted">Select a client to assign</p>

                <ul class="list-group">

                  <template v-for="client in clients">
                    <li class="list-group-item list-group-item-action" @click="assignClient(client)" :disabled="processing">{{ client.name }}</li>
                  </template>

                </ul>

              </template>

            </div><!--Body-->
          </div><!--Card-->
        </template>

        <template v-if="form.client">
          <client-card
              :client="form.client"
              class="my-3"
          />

          <button class="btn btn-danger" @click="form.client = ''">Unassign</button>
        </template>

        <template v-if="contacts && contacts.length">
          <div class="card my-3">
            <div class="card-body">
              <p class="card-title">{{ contacts.length }} Contacts</p>

              <ul class="list-group">

                <template v-for="contact in contacts">
                  <li class="list-group-item list-group-item-action">{{ contact.name }} {{ contact.value }} {{ contact.channel }}
                  
                    <a :href="'/contacts/'+contact.uuid" class="btn btn-secondary btn-sm float-end" target="_blank">View</a>

                  </li>
                </template>

              </ul>

            </div><!--Body-->

          </div><!--Card-->

        </template>

        <template v-if="addresses && addresses.length">
          <div class="card my-3">
            <div class="card-body">
              <p class="card-title">{{ addresses.length }} Addresses</p>

              <ul class="list-group">

                <template v-for="address in addresses">
                  <li class="list-group-item list-group-item-action">
                    {{ address.address_1 }} {{ address.address_2 }} {{ address.city }} {{ address.state }} {{ address.zip }}
                    
                    <a :href="'/addresses/'+address.uuid" class="btn btn-secondary btn-sm float-end" target="_blank">View</a>
                  </li>
                </template>

              </ul>

            </div><!--Body-->

          </div><!--Card-->

        </template>


      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <div class="card">
          <div class="card-body">

            <template v-if="!current_judge">

            <p class="card-title">Assign to Judge</p>

            <form @submit.prevent="getJudges">

            <div class="input-group mb-3">
              <input
                  type="text"
                  class="form-control"
                  v-model="search_judges"
                  placeholder="Search Judges"
              >
              <div class="input-group-append">
                <button
                    class="btn btn-outline-primary"
                    type="submit"
                    @click="getJudges"
                    :disabled="processing"
                >Search</button>
              </div>
            </div>
            </form>

            </template>


            <template v-if="current_judge && current_judge.name">

              <div class="row mb-3">

                <div class="col-sm-12 col-md-6">

                  <p class="card-title">Assigned Judge</p>

                  <template v-if="current_judge.photo">
                    <img :src="current_judge.photo" class="img-fluid mb-3" width="100" :alt="current_judge.name + ' photo'" />
                  </template>

                  <h4>{{ current_judge.name }}</h4>

                </div><!--Col-->

                <div class="col-sm-12 col-md-6 text-end">
                  <div class="btn-group">

                    <a :href="'/judges/'+current_judge.uuid" class="btn btn-secondary btn-sm" target="_blank">View Profile</a>

                    <button type="button" class="btn btn-primary btn-sm">Schedule Virtual Meeting</button>

                    <button class="btn btn-danger btn-sm" @click="current_judge = ''">Unassign</button>

                    </div>
                </div><!--Col-->

              </div><!--Row-->


              <p class="m-0">Last Active {{ current_judge.created_at | datetime }}</p>

              <template v-if="current_judge.online">
                <p class="m-0">Online Status <span class="badge badge-success">Online</span></p>              
              </template>
              <template v-else>
                <p class="m-0">Online Status <span class="badge badge-danger">Offline</span></p>
              </template>

              <template v-if="current_judge.email">
                <p class="m-0">Email {{ current_judge.email }}</p>
              </template>

              <template v-if="current_judge.phone">
                <p class="m-0">Phone {{ current_judge.phone }}</p>
              </template>

            </template>

            <template v-if="judges && judges.length && !current_judge">
              <p class="text-muted">Select a judge to assign</p>

              <ul class="list-group">

                <template v-for="judge in judges">
                  
                  <li class="list-group-item list-group-item-action" @click="assignJudge(judge)">{{ judge.name }}

                    <span class="badge badge-danger">OFFLINE</span>

                  </li>
                  
                </template>

              </ul>

            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="current_judge && current_judge.name">
          
          <div class="card my-3">
            <div class="card-body">
              <p class="card-title">Send a Message</p>

              <p class="mt-0 mb-3">Warrant details and attachments will be included in the message to the judge.</p>

              <form @submit.prevent="postMessage">

              <div class="form-group">
                <label for="content">Message</label>
                <textarea
                    id="content"
                    class="form-control"
                    v-model="messageForm.content"
                    rows="3"
                ></textarea>
              </div>

                <button type="submit" class="btn btn-success mt-3" :disabled="processing">Send</button>

              </form>

            </div><!--Body-->
          </div><!--Card-->
        
        </template>

        <template v-if="messages && messages.length">

        <p class="card-title mt-3">{{ messages.length }} Messages</p>
        
          <template v-for="message in messages">
            <message :message="message" />
          </template>
          
        </template>


      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      searching: false,
      title: null,
      form: {},
      record_types: ['arrest', 'search', 'bench', 'extradition', 'alias', 'social media', 'isp', 'carrier'],
      social_medias: ['google', 'facebook', 'twitter', 'instagram', 'linkedin', 'snapchat', 'tiktok'],
      isps: ['AT&T', 'Charter', 'Comcast', 'Cox', 'Frontier', 'Spectrum', 'Verizon', 'Xfinity'],
      carriers: ['AT&T', 'Boost Mobile', 'Cricket Wireless', 'MetroPCS', 'Sprint', 'T-Mobile', 'Verizon', 'Virgin Mobile'],
      current_isp: '',
      current_carrier: '',
      current_social: '',
      current_judge: '',
      current_client: '',
      search_judges: '',
      search_clients: '',
      judges: [],
      clients: [],
      addresses: [],
      contacts: [],
      messageForm: {
        content: ''
      },
      messages: [],
      portal: {},
      uploadProgress: null,
      files: [],
      statuses: [],
      flows: []
    }
  },

  created () {

    this.title = this.setCustomLabel(this.$route.meta.title);

    this.getRecord();
    this.getJudges();
    this.getPortal();
    this.getStatuses();
    this.getFlows();
  },

  methods: {

    getStatuses() {
      this.$http.get('/statuses?type=warrant&limit=100&sort=name&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.statuses = response.data;
            }
          })
    },

    getRecord () {
      this.processing = true;
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url + '/' + this.$route.params.id, this.queries))
          .then(response => {
            if (response && response.data) {
              this.form = response.data;
              this.processing = false;

              this.getJudge();
              this.getClient();
              this.getMessages();
              this.getFiles();
            }
          })
    },


    postForm() {
      this.processing = true;
      this.$http.patch('/'+this.$route.meta.base_url + '/' + this.form.uuid, this.form)
          .then(response => {
            if(response && response.data) {

              if(response.data.updated_at) {
                this.$eventBus.$emit('show-alert', {
                  text: this.title + ' updated'
                });
                this.form.updated_at = response.data.updated_at;
              }

              this.processing = false;
            }
          })
    },

    getClient() {
      if(!this.form.client) {
        return;
      }

      this.current_client = '';

      this.$http.get('/clients/'+this.form.client)
          .then(response => {
            if(response && response.data) {
              this.current_client = response.data;

              this.getContacts();
              this.getAddresses();
            }
          })
    },

    getContacts() {
      if(!this.current_client) {
        return;
      }

      this.$http.get('/contacts?client='+this.current_client.uuid)
          .then(response => {
            if(response && response.data) {
              this.contacts = response.data;
            }
          })
    },

    getAddresses() {
      if(!this.current_client) {
        return;
      }

      this.$http.get('/addresses?client='+this.current_client.uuid)
          .then(response => {
            if(response && response.data) {
              this.addresses = response.data;
            }
          })
    },

    getJudge() {
      if(!this.form.judge) {
        return;
      }

      this.$http.get('/judges/'+this.form.judge)
          .then(response => {
            if(response && response.data) {
              this.current_judge = response.data;
            }
          })
    },

    assignJudge(judge) {
      this.form.judge = '';
      this.current_judge = '';
      this.processing = true;
      this.$http.get('/judges/'+judge.uuid)
          .then(response => {
            if(response && response.data) {
              this.processing = false;
              this.current_judge = response.data;
              this.form.judge = response.data.uuid;
              this.postForm();
            }
          })
    },

    getJudges() {

      var queries = {
        sort: 'name',
        sortDirection: 'asc',
        search: this.search_judges
      };

      this.$http.get(this.buildQueries('/judges', queries))
          .then(response => {
            if(response && response.data) {
              this.judges = response.data;
            }
          })
    },

    getFiles() {
      if(!this.form.uuid) {
        return;
      }

      this.$http.get('/files?reference=warrant-'+this.form.uuid)
          .then(response => {
            if(response && response.data) {
              this.files = response.data;
            }
          })
    },

    assignClient(client) {

      this.client = '';
      this.form.client = null;
      this.processing = true;

      this.$http.get('/clients/'+client.uuid)
          .then(response => {
            if(response && response.data) {
              this.processing = false;
              this.form.client = response.data.uuid;
              this.current_client = response.data;
              this.postForm();

              this.getContacts();
              this.getAddresses();
            }
          })
    },

    getClients() {
        
        var queries = {
          sort: 'name',
          sortDirection: 'asc',
          search: this.search_clients
        };
  
        this.$http.get(this.buildQueries('/clients', queries))
            .then(response => {
              if(response && response.data) {
                this.clients = response.data;
              }
            })
    },

    sendMessage(contact) {

      var post = {
        to: contact,
        subject: 'Warrant Request from '+this.$root.current_user.current_agency.name,
        content: this.messageForm.content,
        form: this.form.uuid
      };

      this.processing = true;

      this.$http.post('/messages/oneoffs', post)
          .then(response => {
            if(response && response.data) {
              this.$eventBus.$emit('show-alert', {
                text: 'Message sent'
              });

              this.processing = false;

              if(response.data.uuid) {
                if(!this.messages) {
                  this.messages = [];
                }

                this.messages.unshift(response.data);
              }
            }
          })
    },

    getMessages() {
      this.messages = [];
      this.$http.get('/messages?form='+this.form.uuid+'&limit=100&sort=scheduled_at&sortDirection=desc')
          .then(response => {
            if(response && response.data && response.data.data) {
              this.messages = response.data.data;
            }
          })
    },

    postMessage() {

      if(!this.current_judge) {
        return;
      }

      if(!this.current_judge.email && !this.current_judge.phone) {
        this.$eventBus.$emit('show-alert', {
          text: 'Judge has no contact information',
          type: 'danger'
        });
        return;
      }

      if(this.current_judge.email) {
        this.sendMessage(this.current_judge.email);
      }

      if(this.current_judge.phone) {
        this.sendMessage(this.current_judge.phone);
      }

      this.messageForm.content = '';

    },


    generateSocialWarrant() {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/pdf')
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(!response.data.pdf) {
            return;
          }

          var binary = atob(response.data.pdf);

          var array = [];

          for(var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
          }

          var blob = new Blob([new Uint8Array(array)], {type: 'application/pdf'});

          var link = document.createElement('a');

          link.href = window.URL.createObjectURL(blob);

          link.download = 'Warrant Request.pdf'

          link.click();


        }
      })
    },

    getPortal() {
      this.$http.get('/portals?sort=default&sortDirection=desc&limit=1')
          .then(response => {
            if(response && response.data && response.data[0]) {
              this.portal = response.data[0];
            }
          })
          
    },

    deleteFile(file) {
      this.processing = true;
      this.$http.delete('/files/'+file.uuid)
          .then(response => {
            if(response && response.status === 204) {
              this.$eventBus.$emit('show-alert', {
                text: 'File deleted',
                type: 'danger'
              });
              this.files = this.files.filter(item => item.uuid !== file.uuid);
              this.processing = false;
            }
          })
    },

    storeFiles() {

      const files = this.$refs.files.files;

      if(files && files.length > 0) {

          this.processing = true;

          for (let i = 0; i < files.length; i++) {
              
              let file = files.item(i);

              this.uploadFiles(file, {
                  progress: progress => {
                      this.uploadProgress = Math.round(progress * 100);

                      if(this.uploadProgress === 100) {
                        this.$eventBus.$emit('show-alert', {
                          text: this.title + ' uploaded'
                        });
                        this.uploadProgress = null;
                      }
                  }
              }).then(response => {
                this.processing = false;

                if(response.uuid) {
                  this.$eventBus.$emit('show-alert', {
                    text: 'Adding '+this.title
                  });
                }
              })

          }


      }
      },

    async uploadFiles(file, options = {}) {
        const response = await this.$http.post(this.$apiURL + 'v1/files', {
            'bucket': 'ecdfiles',
            'name': file.name,
            'size': file.size,
            'type': file.type,
            'reference': 'warrant-'+this.form.uuid,
            'language': 'en',
            'attach_emails': true,
            'content_length': file.size,
            'extension': file.name.split('.').pop(),
            'content_type': options.contentType || file.type,
            'expires': options.expires || '',
            'visibility': options.visibility || ''
        }, {
            baseURL: options.baseURL || null,
            headers: options.headers || {},
            ...options.options
        });
        if (response && response.data) {

            let headers = response.data.upload_headers;

            if ('Host' in headers) {
                delete headers.Host;
            }

            if (typeof options.progress === 'undefined') {
                options.progress = () => {};
            }

            const cancelToken = options.cancelToken || '';

            await this.$axios.put(response.data.upload_url, file, {
                cancelToken: cancelToken,
                headers: headers,
                onUploadProgress: (progressEvent) => {
                    options.progress(progressEvent.loaded / progressEvent.total);
                }
            })

            response.data.extension = file.name.split('.').pop();

            if(!this.files) {
              this.files = [];
            }

            this.files.unshift(response.data);

            return response.data;
        }
        return null;
    },

    getFlows () {
      this.$http.get('flows?limit=1000&slim=true&fields=name,uuid&type=warrant')
      .then(response => {
        if(response && response.data) {
          this.flows = response.data;
        }
      })
    },

    getPDF() {
          this.processing = true;
          this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/pdf', {responseType: 'blob'})
          .then(response => {
            if(response && response.data) {
              this.processing = true;

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', this.$route.meta.base_url+'-'+this.form.uuid+'.pdf');
              document.body.appendChild(link);
              link.click();

              link.parentNode.removeChild(link);
              window.URL.revokeObjectURL(url);

              this.processing = false;
            }
          })
        },

  }
}
</script>
