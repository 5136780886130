<template>
  <div>

    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />


    <template v-if="form.uuid && form.agencies.length === 0 && !processing">
      <div class="alert alert-danger"><i class="fa fa-circle-exclamation"></i> No agencies found. Assign agencies to this dashboard below.</div>
    </template>
        
    <div class="row">
      <div class="col-sm-12 col-md-4">
          <h1>{{ form.name }}</h1>
          <p>
          <router-link :to="{name: 'reports.index'}">Reports</router-link> /
          <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}
          </p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <template v-if="form.status && form.status != 'draft'">

          <template v-if="qr_code">
            <a :href="dashboardLink" target="_blank">
              <img
                  :src="'data:image/svg+xml;base64,'+qr_code"
                  width="40"
              >
            </a>
          </template>

          <a :href="dashboardLink" target="_blank" class="btn btn-outline-primary text-capitalize">
            <i class="fa fa-link"></i>
            {{ $route.meta.title }} Link
          </a>
        </template>

      </div>

      <div class="col-sm-12 col-md-4 text-end">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">

                <a
                    v-if="!form.archived_at && !form.deleted_at"
                    class="dropdown-item"
                    @click="archiveRecord(form)"
                >Archive</a>

                <a
                    v-if="form.archived_at || form.deleted_at"
                    class="dropdown-item"
                    @click="restoreRecord(form)"
                >Restore</a>

                <a
                  v-if="!form.deleted_at && !form.archived_at"
                  class="dropdown-item"
                  @click="trashRecord(form)"
                >Trash</a>


              </div>
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Edit {{ $route.meta.title }}
            </p>

            <form @submit.prevent="postForm()">
              <div class="form-group">
                <label for="name">{{ $route.meta.title }} Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="subdomain">Subdomain</label>
                <input
                  id="subdomain"
                  v-model="form.subdomain"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="domain">Domain</label>
                <select id="domain" class="form-control" v-model="form.domain">
                  <template v-for="domain in domains">
                    <option :value="domain.domain">{{ domain.domain }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="description">Description</label>
                <textarea
                  id="description"
                  v-model="form.description"
                  class="form-control"
                  rows="3"
                />
              </div>

              <div class="form-group">
                <label for="logo">Logo URL</label>
                <input
                  id="logo"
                  v-model="form.logo"
                  class="form-control"
                >
              </div>


              <div class="form-group">
                <label for="icon">Icon URL</label>
                <input
                  id="icon"
                  v-model="form.icon"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="contact_email">Contact Email</label>
                <input id="contact_email" type="email" class="form-control" v-model="form.contact_email">
              </div>

              <div class="form-group">
                <label for="data_source">Data Source</label>
                <select id="data_source" class="form-control" v-model="form.data_source" :disabled="processing">
                  <option value=""></option>
                  <template v-for="data_source in $root.data_sources">
                    <option :value="data_source">{{ data_source }}</option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="status">Status</label>
                <select
                  id="status"
                  v-model="form.status"
                  class="form-control"
                  :disabled="processing"
                >
                  <template v-for="status in statuses">
                    <option
                      :value="status"
                    >
                      {{ status }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-check">
                <input
                  id="simulate"
                  v-model="form.simulate"
                  type="checkbox"
                  class="form-check-input"
                  :disabled="processing"
                >
                <label
                  class="form-check-label"
                  for="simulate"
                >Simulate Data</label>
              </div>

              <div class="form-check">
                <input
                  id="hide_pii"
                  v-model="form.hide_pii"
                  type="checkbox"
                  class="form-check-input"
                  :disabled="processing"
                >
                <label
                  class="form-check-label"
                  for="hide_pii"
                > <span data-tooltip="Hide personally identifiable information from source data such as client age or reference">Hide PII</span></label>
              </div>

              <div class="form-check">
                <input
                    id="read_only"
                    v-model="form.read_only"
                    type="checkbox"
                    class="form-check-input"
                    :disabled="processing"
                >
                <label
                    class="form-check-label"
                    for="read_only"
                > Read Only</label>
              </div>

              <div class="form-check">
                <input
                  id="allow_downloads"
                  v-model="form.allow_downloads"
                  type="checkbox"
                  class="form-check-input"
                  :disabled="processing"
                >
                <label
                  class="form-check-label"
                  for="allow_downloads"
                > Allow Downloads</label>
              </div>

              <div class="form-group" v-if="form.allow_downloads == 1">
                <label for="restrict_downloads">Restrict Source Data Downloads to Domains and Emails</label>
                <textarea id="restrict_downloads" class="form-control" rows="3" v-model="form.restrict_downloads"></textarea>
                <span class="form-text text-muted">Comma-separated list of domains and emails to restrict downloads to. Leave blank to allow any email to download.</span>
              </div>

              <div class="form-group mt-3">
                <label for="custom_css">Custom CSS</label>
                <textarea
                  id="custom_css"
                  v-model="form.custom_css"
                  class="form-control"
                />
              </div>

              <div class="form-group">
                <label for="theme">Theme</label>
                <select
                  id="theme"
                  v-model="form.theme"
                  class="form-control"
                  :disabled="processing"
                >
                  <template v-for="theme in themes">
                    <option
                      :value="theme"
                    >
                      {{ theme }}
                    </option>
                  </template>
                </select>
              </div>


              <button type="button" class="btn btn-primary btn-block" @click="toggleAgencies()" :disabled="processing">{{ form.agencies.length }} Agencies</button>

              <template v-show="show_agencies">

              <div class="form-group" :hidden="!show_agencies">
                <label for="agencies">Choose Agencies</label>
                <select id="agencies" class="form-control" v-model="form.agencies" size="20" multiple>
                  <template v-for="agency in agencies">
                    <option :value="agency.uuid">{{ agency.name }} {{ agency.region }}</option>
                  </template>
                </select>
              </div>


              </template>

              <button type="submit" class="btn btn-success my-3" :disabled="processing">Save</button>

            
            </form>


          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
              <div class="card-body">

                <p class="card-title"><i class="fa fa-share"></i> Embed and Share</p>

                <p class="card-text">Use the embed code in an external website, dashboard, or mobile app.</p>


             <div class="form-group">
              <label for="embed_code">iFrame Embed Code</label>
              <textarea
                  id="embed_code"
                  class="form-control"
                  :value="embedCode()"
                  rows="3"
                  @click="copyToClipboard(embedCode())"
                  readonly
              />
            </div>

              </div><!--Body-->
        </div><!--Card-->

        <audit-logs
          :form="form"
          class="mt-3"
        />
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <div class="card mb-3">
          <div class="card-body">
            <p class="card-title">
              Add Component
            </p>

            <form @submit.prevent="postComponent()">

              <div class="form-group">
                <label for="component_name">Component Name</label>
                <input id="component_name" type="text" class="form-control" v-model="componentForm.name" required>
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Add
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->


        <div class="row">
          <div class="col-12">
            <p class="card-title">
              <template v-if="components && components.length >= 0">{{ components.length }}</template> Components
            </p>
          </div><!--Col-->
        </div><!--Row-->

        <template v-if="components && components.length > 0">
          <template v-for="component in components">
            <li class="list-group-item list-group-item-action">
              <span class="fw-bold">{{ component.name }}</span>

              <div class="btn-group float-end">
                <a :href="'/components/'+component.uuid" class="btn btn-primary btn-sm" target="_blank">Edit</a>
              </div>

              <template v-if="component.data_source">
                <br><span class="badge badge-pill bg-secondary">{{ component.data_source }}</span>
              </template>
            </li>
          </template>
        </template>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <div class="card">
          <div class="card-body">
            <p class="card-title m-0">Add Data Files</p>

            <p>Upload data files to dashboard.</p>

            <form @submit.prevent="storeFiles()">
              <div class="form-group">
                <input
                    id="upload_files"
                    ref="files"
                    type="file"
                    class="form-control"
                    multiple="true"
                    :disabled="processing || uploadProgress"
                >
              </div>

                <button
                    type="submit"
                    class="btn btn-success btn-block"
                    :disabled="processing || uploadProgress"
                >
                  Upload
                </button>

                <div class="progress my-3" :hidden="!uploadProgress">
                  <div
                      class="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      :style="'width:'+uploadProgress+'%'"
                  >
                    {{ uploadProgress }}%
                  </div>
                </div>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="files && !files.length && !processing">
          <div class="alert alert-warning my-3">No files found</div>
        </template>

        <template v-if="files && files.length > 0">

          <p class="font-weight-bold m-0 mt-3">{{ files.length }} Files</p>

          <ul class="list-group">
          <template v-for="(file, file_index) in files">
            <li class="list-group-item list-group-item-action">{{ file.name }}</li>
          </template>
          </ul>
        </template>

        <div class="row mt-3">

          <div class="col-sm-12 col-md-6">
            <p class="font-weight-bold">{{ downloads.length }} Dataset Downloads</p>
          </div><!--Col-->

          <div class="col-sm-12 col-md-6 text-end">
            <button type="button" class="btn btn-primary btn-sm btn-secondary tooltip-left" @click="getDownloads()" data-bs-toggle="tooltip" data-tooltip="Refresh Downloads" :disabled="processing || downloading"><i class="fa fa-sync"></i> <span class="sr-only">Refresh Downloads</span></button>
          </div><!--Col-->

        </div><!--Row--->


            <template v-if="downloads && downloads.length > 0">
              
              <ul class="list-group">

                <template v-for="(download, download_index) in downloads">
                  <li class="list-group-item list-group-item-action">
                    <a :href="setDownloadURL(download)" target="_blank"><i class="fa fa-file-csv"></i> {{ download.file || 'Download File' }}</a>

                    <template v-if="download.email">
                      <p class="m-0">Email: {{ download.email }}</p>
                    </template>

                    <span class="badge badge-pill" :class="setDownloadClass(download.status)">Status {{ setDownloadStatus(download.status) }}</span>

                    <span class="badge badge-pill bg-secondary">Created {{ download.created_at | dateTimezone | timeago }}</span>

                    <span class="badge badge-pill bg-secondary">Expires {{ download.expires_at | dateTimezone | timeago }}</span>

                    <button type="button" class="btn btn-outline-primary btn-sm" data-bs-toggle="tooltip" data-tooltip="Sync Download" @click="syncDownload(download, download_index)" :disabled="processing"><i class="fa fa-refresh"></i></button>
                  </li>
                </template>

              </ul>
              
            </template>


      </div><!--Col-->


    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

    mixins: [ queries ],

    data() {
        return {
        processing: false,
        downloading: false,
        uploadProgress: null,
        form: {},
        componentForm: {},
        components: [],
        statuses: ['draft', 'private', 'public'],
        themes: ['auto', 'light', 'dark'],
        agencies: [],
        domains: [],
        datasets: [],
        datapoints: [],
        show_agencies: false,
        qr_code: null,
        files: [],
        downloads: []
        }
    },

      mounted() {
        this.$eventBus.$on('errors', function () {
            self.processing = false;
        });
      },

    computed: {

      dashboardLink: function() {
        if(!this.form.subdomain || !this.form.url) {
          return;
        }

        return 'https://'+this.form.subdomain+'.'+this.form.domain+'?preview='+this.form.url;
      }

    },
    created () {
        this.resetForm();
        this.resetComponentForm();
        this.getRecord();
        this.getAgencies();
        this.getDatapoints();
    },

    methods: {

        resetComponentForm() {
          this.componentForm = {
            name: null,
            dashboard: null,
            data: [],
            show_legend: 1,
            source_data: 1,
            sort: 0
          }
        },

        resetForm() {
            this.form = {
                name: null,
                agencies: [],
                description: null,
                subdomain: null,
                domain: null,
                theme: 'auto',
                logo: null,
                icon: null,
                website: null,
                facebook: null,
                twiter: null,
                youtube: null,
                linkedin: null,
                simulate: null,
                status: null,
                allow_downloads: true,
                hide_pii: true,
                restrict_downloads: null,
                data_source: null,
                contact_email: null,
                custom_css: null,
                read_only: false
            }

            this.qr_code = null;
        },

        getRecord() {
            this.processing = true;
            this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
            .then(response => {
                if(response && response.data) {
                    this.processing = false;

                    if(response.data.uuid) {

                        if(!response.data.agencies) {
                          response.data.agencies = [];
                        }

                        this.form = response.data;

                        this.setTitle();

                        this.getQRCode();

                        this.getComponents();

                        this.getFiles();
                        
                        this.getDownloads();

                        this.getDomains();

                    }
                }
            })
        },

        setTitle() {
          document.title = this.form.name + ' | ' + this.$route.meta.title;
        },

        postForm() {

          if(this.form.status && this.form.status == 'public') {
            this.form.hide_pii = true;
          }

          this.setDashboardDomain();

            this.processing = true;
            this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
            .then(response => {
                if(response && response.data) {

                    if(response.data.updated_at) {
                        this.form.updated_at = response.data.updated_at;
                        this.form.url = response.data.url;
                        this.$eventBus.$emit('show-alert', {
                          text: this.$route.meta.title + ' updated'
                        });
                    }

                    this.processing = false;
                }
            })
        },

        setDashboardDomain() {
          if(this.form.domain) {
            
            var domain = _.find(this.domains, {'domain': this.form.domain});

            if(domain && domain.alias) {
              this.form.domain = this.setApexDomain(domain.domain);
              this.form.subdomain = this.setSubdomain(domain.domain);
            }
          }
        },

        setApexDomain(value) {
          if(value) {
            var parts = value.split('.');
            if(parts.length > 2) {
              return parts[parts.length - 2] + '.' + parts[parts.length - 1];
            }
          }
          return value;
        },

        setSubdomain(value) {
          if(value) {
            var parts = value.split('.');
            if(parts.length > 2) {
              return parts[0];
            }
          }
          return value;
        },


      getFiles() {
        this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/files')
            .then(response => {
              if(response && response.data) {
                this.files = response.data;
              }
            })
      },

      getDownloads() {
        this.downloading = true;
        this.downloads = [];
        this.$http.get('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/downloads')
            .then(response => {
              if(response && response.data) {
                this.downloads = response.data;
                this.downloading = false;
              }
            })
      },

        getComponents() {
          this.components = [];
          this.processing = true;
            this.$http.get('/components?dashboard='+this.form.uuid+'&limit=1000&sort=sort&sortDirection=asc&fields=uuid,dashboard,data_source,sort,name')
            .then(response => {
                if(response && response.status === 200) {
                    this.processing = false;

                    if(response.data) {
                        this.components = response.data;
                    }
                }
            })
        },

        postComponent() {
            
            this.processing = true;

            this.componentForm.dashboard = this.form.uuid;

            if(this.components && this.components.length > 0) {
                this.componentForm.sort = this.components.length + 1;
            }

            this.$http.post('/components', this.componentForm)
            .then(response => {
                if(response && response.data) {

                    this.processing = false;

                    if(response.status === 201) {

                        this.resetComponentForm();

                        if(!this.components) {
                            this.components = [];
                        }

                        this.components.unshift(response.data);

                        this.$eventBus.$emit('show-alert', {
                          text: 'Component created'
                        });

                    }
                }
            })
        },

        getDatapoint() {

            var queries = {
                slim: true,
                simulate: true,
                datapoints: ['fta_rate']
            }

            this.$http.post('/datapoints', queries)
            .then(response => {
                if(response && response.data) {
                    return response.data;
                }
            })
        },


        getAgencies() {

            var url = '/agencies?limit=1000&slim=y&status=1&sort=name&sortDirection=asc&fields=uuid,name,reference,region';

            if(this.$root.current_region != 'staging') {
              url += '&not_region=staging';
            }

            this.processing = true;
            this.$http.get(url)
            .then(response => {
                if(response && response.data) {
                    this.processing = false;
                    this.agencies = response.data;
                }
            })
        },

        getDatapoints() {
          this.datapoints = [];
          this.$http.get('/datapoints')
          .then(response => {
            if(response && response.data) {
              this.datapoints = response.data;
            }
          })
        },

        toggleAgencies() {
          if(this.show_agencies === true) {
            this.show_agencies = false;
            return;
          }

          this.show_agencies = true;
        },

        getDomains() {
          this.$http.get('/domains?fields=domain,alias&slim=true&limit=100&sortDirection=asc&sort=domain&alias=courtdashboards.com')
          .then(response => {
            if(response && response.status) {
              this.domains = response.data;

              this.domains.unshift({'domain': 'courtdashboards.com'});

              if(this.form.domain && this.form.domain != 'courtdashboards.com') {
                this.domains.unshift({'domain': this.form.domain});
              }
            }
          })
        },

      getQRCode() {
        this.$http.post('/qrs?content='+this.dashboardLink)
        .then(response => {
            if(response && response.data) {
                this.qr_code = response.data;
            }
        })
      },

      storeFiles() {

        const files = this.$refs.files.files;

        if(files && files.length > 0) {

          this.processing = true;

          for (let i = 0; i < files.length; i++) {

            let file = files.item(i);

            this.uploadFiles(file, {
              progress: progress => {
                this.uploadProgress = Math.round(progress * 100);

                if(this.uploadProgress === 100) {
                  this.$eventBus.$emit('show-alert', {
                    text: 'Uploading Data File'
                  });
                  this.uploadProgress = null;
                }
              }
            }).then(response => {
              this.processing = false;

              if(response.uuid) {
                this.$eventBus.$emit('show-alert', {
                  text: 'Data File Uploaded'
                });
              }
            })

          }

        }
      },

      async uploadFiles(file, options = {}) {
        const response = await this.$http.post(this.$apiURL + 'v1/signed_url', {
          'bucket': 'ecddashboards',
          'file_path': this.form.uuid+'/'+file.name,
          'name': file.name,
          'size': file.size,
          'type': file.type,
          'content_length': file.size,
          'extension': file.name.split('.').pop(),
          'content_type': options.contentType || file.type,
          'expires': options.expires || '',
          'visibility': options.visibility || ''
        }, {
          baseURL: options.baseURL || null,
          headers: options.headers || {},
          ...options.options
        });
        if (response && response.data) {

          let headers = response.data.headers;

          if ('Host' in headers) {
            delete headers.Host;
          }

          if (typeof options.progress === 'undefined') {
            options.progress = () => {};
          }

          const cancelToken = options.cancelToken || '';

          await this.$axios.put(response.data.url, file, {
            cancelToken: cancelToken,
            headers: headers,
            onUploadProgress: (progressEvent) => {
              options.progress(progressEvent.loaded / progressEvent.total);
            }
          })

          response.data.extension = file.name.split('.').pop();

          return response.data;
        }
        return null;
      },

      setDownloadStatus(status) {

        if(status == 0) {
          return 'Pending';
        }

        if(status == 1) {
          return 'Complete';
        }

        if(status == 2) {
          return 'Processing';
        }

        return 'Error';
      },

      setDownloadClass(status) {
        
        if(status == 0) {
          return 'badge-secondary';
        }

        if(status == 1) {
          return 'badge-success';
        }

        if(status == 2) {
          return 'badge-warning';
        }

        return 'badge-danger';
      },

      setDownloadURL(download) {

        if(!this.form.subdomain || !this.form.url) {
          return;
        }

        return 'https://'+this.form.subdomain+'.'+this.form.domain+'/downloads/'+download.token+'?preview='+this.form.url;
      },

      syncDownload(download, index) {
        this.processing = true;
        this.$http.post('/'+this.$route.meta.base_url+'/'+this.form.uuid+'/downloads/'+download.uuid)
        .then(response => {
          if(response && response.data) {
            this.processing = false;

            if(response.data.uuid) {
              this.$eventBus.$emit('show-alert', {
                text: 'Syncing Download'
              });
              this.downloads[index] = {...this.downloads[index], ...response.data};
            }
          }
        })
      },

      embedCode() {
          if(!this.form || !this.form.subdomain) {
            return;
          }

          return '<iframe src="https://'+this.form.subdomain+'.'+this.form.domain+'" title="'+this.form.name+'" width="100%" height="100%" frameborder="0" border="0" loading="eager" allowfullscreen="true"></iframe>';
        },
    
    }

}
</script>