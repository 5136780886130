<template>
  <div>
    <div class="card">
      <div class="card-body">
        <p class="card-title">
          Create {{ $route.meta.title }}
        </p>

        <form
          ref="addReport"
          @submit.prevent="addReport"
        >
          <div class="form-group">
            <label for="report_name">Report Name</label>
            <input
              id="report_name"
              v-model="form.name"
              type="text"
              class="form-control"
              required
            >
          </div>
        
          <button
            type="submit"
            class="btn btn-success"
            :disabled="processing"
          >
            Create
        </button>
        </form>
      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>
<script>
export default {
data() {
    return {
        processing: false,
        form: {}
    }
},

methods: {
    
    addReport() {
        this.processing = true;
        this.$http.post('reports', this.form)
        .then(response => {
            this.form = {}
            if(response && response.data) {
                this.$eventBus.$emit("addReport", response.data);
                this.$eventBus.$emit('show-alert', {
                  text: 'Report added'
                });
                this.processing = false;
            }
        });
    }

}
}
</script>