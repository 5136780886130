<template>
  <div>
    <div class="card">
      <div class="card-body">
        <template v-if="processing && !form.uuid">
          <div class="alert alert-warning m-0">
            No {{ setCustomLabel('event') }} found
          </div>
        </template>

        <template v-if="!processing && form.uuid">
          <p class="card-title fw-bold">
            {{ setCustomLabel('event') }}
          </p>

    
          <ul class="list-group">

            <template v-if="form.date">
              <li class="list-group-item">
                {{ setCustomField('events', 'date') }} {{ form.date | date }}
              </li>
            </template>

            <template v-if="form.time">
              <li class="list-group-item">
                {{ setCustomField('events', 'time') }} {{ form.time | time }}
              </li>
            </template>

            <template v-if="form.end_date">
              <li class="list-group-item">
                {{ setCustomField('events', 'end_date') }} {{ form.end_date | date }}
              </li>
            </template>

            <template v-if="form.end_time">
              <li class="list-group-item">
                {{ setCustomField('events', 'end_time') }} {{ form.end_time | time }}
              </li>
            </template>

            <template v-for="field in fields">
              <template v-if="form[field]">
                <li class="list-group-item">
                  {{ setCustomField('events', field) }} {{ form[field] }}
                </li>
              </template>
            </template>


            <template v-if="location.name">
            
              <li class="list-group-item">
                {{ setCustomField('events', 'location') }}
                <a :href="'/locations/'+location.uuid">{{ location.name }}</a>                
              </li>
            </template>


          </ul>

          <a :href="base_url+'/'+form.uuid" class="btn btn-outline-primary w-100 mt-3 text-capitalize">View {{ setCustomLabel('event') }}</a>

        </template>
      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

mixins: [ queries ],

props: ['event'],

data() {
    return {
        processing: false,
        form: {},
        location: {},
        base_url: '/events',
        fields: ['event_reference', 'case_number', 'description', 'type', 'status', 'notes', 'location_notes', 'judge_name']
    }
},

created() {
    this.getForm()
},

methods: {

        getForm() {
            
            if(!this.event) {
                return;
            }

            this.processing = true;
            this.form = {};

            this.$http.get(this.base_url+'/'+this.event+'?slim=true')
            .then(response => {
                if(response && response.data) {
                    this.form = response.data
                    this.processing = false

                    this.getLocation();
                }
            })
        },

        getLocation() {

          if(!this.form.location) {
            return;
          }

            this.$http.get('/locations/'+this.form.location)
            .then(response => {
                if(response && response.data) {
                    this.location = response.data
                }
            })
        }

}

}
</script>