<template>
  <div>
    <template v-if="form && form.uuid">
      <div class="card">
        <div class="card-body">
          <p class="card-title fw-bold">
            {{ setCustomLabel('location') }}
          </p>

          <ul class="list-group">
            
            <template v-for="field in fields">
              <template v-if="form[field]">
                <li class="list-group-item">
                  {{ setCustomField('locations', field) }} {{ form[field] }}
                </li>
              </template>
            </template>

          </ul>

          <template v-if="form.lat && form.lng">
            <location-maps :record="form" />
          </template>

          <a :href="base_url+'/'+form.uuid" class="btn btn-outline-primary w-100 mt-3 text-capitalize">View {{ setCustomLabel('location') }}</a>

        </div><!--Body-->
      </div><!--Card-->
    </template>
  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {

mixins: [ queries ],

props: ['uuid'],

data() {
    return {
        form: {},
        base_url: '/locations',
        fields: ['name', 'location_reference', 'address', 'address_2', 'phone', 'email', 'description']
    }
},

created() {
    this.getRecord();
},

methods: {

    getRecord() {

        if(!this.uuid) {
            return;
        }

        this.$http.get(this.base_url+'/'+this.uuid)
        .then(response => {
            if(response && response.data) {
                this.form = response.data;
            }
        })
    }

}

}
</script>