<template>
    <div>
  
      <page-tabs :page="$route.meta.tabs" />
  
      <div class="row">
        <div class="col-sm-12 col-md-3 d-print-none">
          <div class="card mb-3">
            <div class="card-body">
              <p class="card-title">
                Create {{ $route.meta.title }}
              </p>
              
              <form @submit.prevent="postForm()">

                <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" v-model="form.name" placeholder="Level 1" required>
                </div>

  
                  <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                  >
                    Create
                  </button>
  
              </form>
            </div><!--Body-->
          </div><!--Card-->

          <div class="card mb-3">
            <div class="card-body">

              <p class="card-title">Search {{ $route.meta.title }}s</p>

              <form @submit.prevent="searchRecords()">

                <div class="form-group">
                <label for="search">General Search</label>
                <input
                  id="search"
                  type="text"
                  v-model="queries.search"
                  class="form-control"
                  :disabled="processing"
                >
              </div>

              <div class="form-group">
                <label for="search_risk">Risk</label>
                <input
                  id="search_risk"
                  type="number"
                  v-model="queries.risk"
                  class="form-control"
                  step="1"
                  :disabled="processing"
                >
              </div>

              <div class="form-group">
                <label for="search_monitoring">Monitoring</label>
                <select
                  id="search_monitoring"
                  v-model="queries.monitoring"
                  class="form-control"
                  @change="searchRecords"
                  :disabled="processing"
                >
                  <option value="">All</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>


                <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="search_archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="form-check-input"
                      @change="searchRecords"
                      :disabled="processing || queries.trashed"
                    >
                    <label
                      class="form-check-label"
                      for="search_archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="search_trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="form-check-input"
                      @change="searchRecords"
                      :disabled="processing || queries.archived"
                    >
                    <label
                      class="form-check-label"
                      for="search_trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

                    <button type="submit" class="btn btn-primary" :disabled="processing">
                      Search
                    </button>


              </form>
            </div>
          </div>


        </div><!--Col-->
  
        <div class="col-sm-12 col-md-9">

          <div class="card">
            <div class="card-body">

              <div class="row">

                <div class="col-sm-12 col-md-6">

                  <p class="card-title">
                    <template v-if="processing">
                      <i class="fa fa-spinner fa-spin text-primary"></i>
                    </template>
                    <template v-else>
                      {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
                    </template>
                  </p>

                </div><!--Col-->

                <div class="col-sm-12 col-md-6 text-end">

                  <div class="btn-group d-print-none">
                    <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                    <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  </div>
                </div><!--Col-->

                </div><!--Row-->
  
  
              <div class="table-responsive my-3">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th class="d-print-none">Edit</th>
                    <template v-for="column in columns">
                      <th
                        class="text-uppercase"
                      >
                        <template v-if="column.key">
                          <span
                            class="text-capitalize link-hover"
                            @click="sortColumn(column)"
                          >{{ column.label }}</span>
                        </template>
  
                        <template v-if="!column.key">
                          <span class="text-capitalize">{{ column.label }}</span>
                        </template>
  
                        <template v-if="column.key == queries.sort">
                          <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            :aria-label="queries.sortDirection == 'asc' ? 'Sort Descending' : 'Sort Ascending'"
                            @click="updateSortDirection()"
                          >
                            <template v-if="queries.sortDirection == 'asc'">
                              <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                            </template>
  
                            <template v-if="queries.sortDirection == 'desc'">
                              <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                            </template>
                          </button>
                        </template>
                      </th>
                    </template>
                  </tr>
                </thead>
  
                <tbody>
                  <template v-if="records && records.length > 0">
                    <template v-for="record in records">
                      <tr>
                        <td class="d-print-none">
                          <router-link :to="{'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid}}">
                            <i class="fa fa-edit"></i> Edit
                          </router-link>
                        </td>
                        <td>{{ record.name }}</td>
                        <td>{{ record.risk }}</td>
                        <td>
                            <template v-if="record.monitoring">
                                <span class="badge bg-secondary">{{ record.monitoring_interval }} {{ record.monitoring }}</span>
                            </template>
                        </td>
                        <td>{{ record.description }}</td>
                        <td>{{ record.created_by }}</td>
                        <td>{{ record.created_at | datetime }}</td>
                        <td>{{ record.updated_at | datetime }}</td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
              </div><!--Responsive-->
  
              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No {{ $route.meta.title }}s found.
                </div>
              </template>
            </div><!--Body-->
          </div><!--Card-->
  
          <template v-if="records && records.length && total > records.length">
            <div class="row">
              <div class="col-12 text-center">
                <button
                  type="button"
                  ref="loadMoreButton"
                  class="btn btn-outline-primary mt-3"
                  :disabled="processing"
                  @click="getRecords()"
                >
                  Load more
                </button>
              </div>
            </div>
          </template>
  
        </div><!--Col-->
      </div><!--Row-->

    </div>
  </template>
  <script>
  import _ from "lodash";
  import queries from '../mixins/queries';
  
  export default {
  
    mixins: [ queries ],
  
    data () {
      return {
        processing: false,
        downloading: false,
        show_search: false,
        errors: null,
        total: 0,
        queries: {},
        downloads: [],
        records: [],
        fields: {},
        form: {},
        columns: [
        { key: 'name', label: 'Name'},
        { key: 'risk', label: 'Risk'},
        { key: 'monitoring', label: 'Monitoring'},
        { key: 'description', label: 'Description'},
        { key: 'created_by', label: 'Creator'},
        { key: 'created_at', label: 'Created'},
        { key: 'updated_at', label: 'Updated'}
        ],
        options: {
          portals: [],
          statuses: [],
          locations: []
        }
      }
    },

    mounted() {

    var self = this;

    this.$eventBus.$on('errors', function (errors) {
      self.processing = false;
      self.downloading = false;
      self.errors = errors.data || errors;
    });

    },

    created() {
      this.resetForm();
      this.resetQueries();

      this.setDocTitle(this.$route.meta.title+'s');

      this.resetRecords();
      this.setURLParams();
      this.getRecords();
    },

    methods: {
  
  
      setURLParams() {
  
          var searchParams = new URLSearchParams(window.location.search);
  
          var self = this;
  
          searchParams.forEach(function(value, key) {
              self.queries[key] = value;
          });

      },


  
      resetQueries() {
        this.queries = {
          limit: 10,
          skip: 0,
          risk: null,
          monitoring: null,
          sort: 'created_at',
          sortDirection: 'desc',
          archived: false,
          trashed: false
        }
      },
  
      resetForm() {
        this.form = {
            name: '',
            risk: 1,
            monitoring_interval: 1,
            monitoring: 'daily',
        }
      },

      resetSearch() {
        this.resetQueries();
        this.resetRecords();
        this.getRecords();
      },
  
      resetRecords() {
        this.records = [];
        this.total = 0;
        this.queries.skip = 0;
        this.queries.limit = 10;
      },
  
      updateSortDirection() {
  
        if(this.processing) {
          return;
        }
  
        if(this.queries.sortDirection == 'asc') {
          this.queries.sortDirection = 'desc';
        } else {
          this.queries.sortDirection = 'asc';
        }
  
        this.resetRecords();
  
        this.getRecords();
      },
  
      sortColumn(column) {
  
        if(this.processing) {
          return;
        }
  
        this.queries.sort = column.key;
  
        this.updateSortDirection();
      },

      searchRecords() {
        this.resetRecords();
        this.getRecords();
      },
  
      getRecords() {
  
        this.processing = true;
  
        this.updateURL();
        this.getTotal();
  
        this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
        .then(response => {
          if(response && response.data) {
              
              if(response.data.length) {
  
                  var self = this;
  
                  response.data.forEach(function(data) {
                      self.records.push(data);
                  });
              }
              
              this.queries.skip = this.records.length;
  
              this.processing = false;
          }
        })
      },
  
      getTotal() {
        this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
          if(response && response.status === 200) {
            this.total = response.data;
          }
        })
      },
  
      updateURL() {
        history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
      },
    
      postForm() {
        this.processing = true;

        this.errors = null;

        this.$http.post(this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.data) {
  
            this.processing = false;
  
            if(response.status === 201) {
              this.resetForm();
              this.total++;
              this.records.unshift(response.data);
              window.location.href = this.$route.meta.base_url + '/' + response.data.uuid;
            }
  
          }
        })
      },
    

    },
  }
  </script>