<template>
  <div>
    <template v-if="form && form.created_at">
      <div class="card my-3">
        <div class="card-body">

          <div class="d-flex justify-content-between align-items-center mb-3">

            <p class="card-title">
              Audit Logs
            </p>


          <template v-if="form.upload">
            <a :href="'/uploads/'+form.upload" class="btn btn-sm btn-outline-secondary" target="_blank"><i class="fa fa-file-csv"></i> Created by upload</a>
          </template>

          <template v-if="$root.current_user && $root.current_user.super_admin">
            <a :href="console_url" class="btn btn-sm btn-outline-secondary" target="_blank"><i class="fa fa-list"></i> View in Console</a>
          </template>

          </div>

          <ul class="list-group">

            <template v-for="field in fields">
              <template v-if="form[field['key']]">
                <li
                  class="list-group-item"
                >
                  <strong>{{ field['title'] }}</strong> 

                  <template v-if="field['type'] === 'datetime'">
                    {{ form[field['key']] | datetime }}
                  </template>

                  <template v-else>
                    {{ form[field['key']] }}
                  </template>
                </li>
              </template>
            </template>
          </ul>

        </div><!--Body-->
      </div><!--Card-->
    </template>

  </div>
</template>
<script>
export default {

props: [ 'form' ],

data() {

    return {
        processing: false,
        fields: [
            {key: 'created_by', title: 'Created By', type: 'string'},
            {key: 'updated_by', title: 'Updated By', type: 'string'},
            {key: 'created_at', title: 'Created', type: 'datetime'},
            {key: 'updated_at', title: 'Updated', type: 'datetime'},
            {key: 'deleted_at', title: 'Trashed', type: 'datetime'},
            {key: 'archived_at', title: 'Archived', type: 'datetime'},
            {key: 'region', title: 'Region', type: 'string'}
        ],
    }
},

computed: {
  console_url() {
    if(!this.$root.current_user || !this.$root.current_user.super_admin || !this.$root.current_user.current_agency) {
      return '';
    }
    return 'https://console.ecourtdate.com/logs?agency='+this.$root.current_user.current_agency.uuid+'&url='+this.$route.path;
  }
}

}
</script>