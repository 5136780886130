<template>
  <div>
    <div class="help-container d-print-none">
      <div class="chat-button-container" v-if="close_help">
        <button type="button" class="btn btn-primary tooltip-right" @click="openHelp()" aria-label="Ask a question" data-bs-toggle="tooltip" data-tooltip="Ask a question" :disabled="processing">
          <i class="fa fa-message-question mr-2"></i> 
        </button>
      </div>
      
      <div class="chat-window" v-if="!close_help">
        <div class="chat-header">
          <a href="https://help.ecourtdate.com" target="_blank"><img src="/images/icon.png" style="width:20px;height:20px;border-radius:50%;"></a> Help Chat
          <button type="button" class="btn btn-sm btn-icon" @click="closeHelp()" :disabled="processing">
            <i class="fa fa-times"></i>
          </button>
        </div>
        
        <div class="chat-body" ref="helpFeed">
          <div class="chat-messages">
            <template v-if="records && records.length > 0">
              <template v-for="record in records">
                <div class="message-container" :class="{'message-outbound': record.direction === 'outbound', 'message-inbound': record.direction !== 'outbound'}">
                  <div class="message-bubble">
                    <div class="message-content">{{ record.content }}</div>
                    <template v-if="record.file">
                      <div class="message-attachment">
                        <i class="fa fa-paperclip mr-1"></i>
                        <a :href="'/help_chats/'+record.uuid" class="attachment-link">{{ record.file }}</a>
                      </div>
                    </template>
                  </div>
                  <div class="message-meta">
                    <span class="message-author">{{ record.direction === 'outbound' ? 'You' : record.created_by }}</span>
                    <span class="message-time">{{ record.created_at | dateTimezone | timeago }}</span>
                  </div>
                </div>
              </template>
            </template>
            
            <template v-if="records && records.length == 0 && !processing">
              <div class="empty-state">
                <div class="empty-icon">
                  <i class="fa fa-comments"></i>
                </div>
                <p>No messages yet. Start a conversation!</p>
              </div>
            </template>
          </div>
        </div>
        
        <div class="chat-footer">
          <form @submit.prevent="postForm()" class="message-form">
            <div class="input-group">
              <textarea 
                id="help_message" 
                class="form-control message-input" 
                v-model="form.content" 
                placeholder="Type your message here..." 
                rows="2"
                required
                @keydown.enter.prevent="postForm()"
              ></textarea>
              
              <div class="input-group-append">
                <label for="help_file" class="btn btn-outline-secondary attachment-btn" :disabled="processing">
                  <i class="fa fa-paperclip"></i>
                </label>
                <input 
                  id="help_file"
                  type="file" 
                  ref="fileInput"
                  class="d-none"
                />
                
                <button type="submit" class="btn btn-primary send-btn" :disabled="processing || !form.content">
                  <i class="fa fa-paper-plane"></i>
                </button>
              </div>
            </div>
            
            <div class="file-preview mt-2" v-if="$refs.fileInput && $refs.fileInput.files && $refs.fileInput.files.length">
              <div class="selected-file">
                <i class="fa fa-file mr-1"></i>
                <span>{{ $refs.fileInput.files[0].name }}</span>
                <button type="button" class="btn btn-sm btn-icon" @click="clearFile()">
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.help-container {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 1000;
  width: 350px;
  max-width: 90vw;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.chat-button-container {
  display: flex;
  justify-content: flex-start;
}

.chat-button {
  border-radius: 24px;
  padding: 10px 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease;
}

.chat-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.chat-window {
  display: flex;
  flex-direction: column;
  height: 500px;
  max-height: 80vh;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  transition: all 0.3s ease;
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.chat-body {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #f8f9fa;
}

.chat-messages {
  display: flex;
  flex-direction: column;
}

.message-container {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin-bottom: 15px;
}

.message-outbound {
  align-self: flex-end;
}

.message-inbound {
  align-self: flex-start;
}

.message-bubble {
  padding: 12px 16px;
  border-radius: 18px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.message-outbound .message-bubble {
  background-color: #007bff;
  color: white;
  border-bottom-right-radius: 4px;
}

.message-inbound .message-bubble {
  background-color: white;
  color: #212529;
  border-bottom-left-radius: 4px;
}

.message-content {
  word-break: break-word;
  line-height: 1.4;
}

.message-attachment {
  margin-top: 8px;
  font-size: 0.85rem;
}

.message-outbound .attachment-link {
  color: #e6f2ff;
}

.message-meta {
  display: flex;
  font-size: 0.75rem;
  margin-top: 5px;
  color: #6c757d;
}

.message-author {
  font-weight: 600;
  margin-right: 8px;
}

.chat-footer {
  padding: 15px;
  background-color: #fff;
  border-top: 1px solid #e9ecef;
}

.message-form {
  display: flex;
  flex-direction: column;
}

.message-input {
  resize: none;
  border-radius: 20px;
  padding: 10px 15px;
  transition: all 0.2s ease;
}

.message-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.attachment-btn, .send-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  margin-left: 8px;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  color: #6c757d;
  text-align: center;
}

.empty-icon {
  font-size: 3rem;
  margin-bottom: 15px;
  color: #dee2e6;
}

.file-preview {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 8px 12px;
}

.selected-file {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
}

.selected-file span {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 8px;
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
  background: transparent;
  border: none;
  color: #6c757d;
  transition: all 0.2s ease;
}

.btn-icon:hover {
  background-color: rgba(108, 117, 125, 0.1);
}
</style>

<script>
import queries from '../mixins/queries';
export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      close_help: true,
      base_url: 'help_chats',
      queries: {},
      form: {},
      records: [],
      articles: [],
      uploadProgress: 0
    }
  },

  mounted() {
    var self = this;

    this.$eventBus.$on(this.base_url, function(data) {
      if(!data.uuid) {
        return;
      }

      self.pushRecord(data);
    });
  },

  created() {
    var help_status = localStorage.getItem('help_status');

    if(help_status && help_status == 'opened') {
      this.close_help = false;
    }

    this.resetForm();
    this.resetRecords();
    this.resetQueries();
    this.getRecords();
  },

  methods: {
    resetForm() {
      this.form = {
        content: null,
        direction: 'outbound'
      }
    },

    resetQueries() {
      this.queries = {
        search: null,
        skip: 0,
        sort: 'created_at',
        sortDirection: 'asc'
      }
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    resetRecords() {
      this.records = [];
      this.queries.skip = 0;
    },

    pushRecord(record) {
      if(!record || !record.uuid) {
        return;
      }

      if(!this.records) {
        this.records = [];
      }

      //check if record already exists
      if(this.records.find(r => r.uuid == record.uuid)) {
        return;
      }

      this.records.push(record);

      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },

    scrollToBottom() {
      const helpFeed = this.$refs.helpFeed;
      if(helpFeed) {
        helpFeed.scrollTop = helpFeed.scrollHeight;
      }
    },

    searchArticles() {
      this.articles = [];
      this.queries.limit = 5;

      this.processing = true;
      this.$http.get('/help_articles?search='+this.queries.search)
          .then(response => {
            if(response && response.data) {
              this.articles = response.data;
              this.processing = false;
            }
          })
    },

    getRecords() {
      if(this.close_help) {
        return;
      }

      this.queries.limit = 20;

      this.processing = true;
      this.$http.get(this.buildQueries('/'+this.base_url, this.queries))
          .then(response => {
            if(response && response.data) {
              this.processing = false;

              if(response.data.length) {
                var self = this;
                response.data.forEach(record => {
                  self.pushRecord(record);
                });
              }
              
              this.$nextTick(() => {
                this.scrollToBottom();
              });
            }
          })
    },

    setHelpClass(record) {
      if(!record.direction) {
        return;
      }

      return record.direction;
    },

    clearFile() {
      if(this.$refs.fileInput) {
        this.$refs.fileInput.value = '';
      }
    },

    postForm() {
      if(!this.form.content) {
        return;
      }
      
      this.processing = true;

      var form = this.form;

      form.url = window.location.href;

      if(this.$refs.fileInput && this.$refs.fileInput.files[0]) {
        form.file = this.$refs.fileInput.files[0].name;

        form.file = form.file.replace(/\s+/g, '-');
        form.file = form.file.replace(/[^a-zA-Z0-9.-]/g, '');

        this.storeUpload(this.$refs.fileInput.files[0], {fileName: form.file});
      }

      this.postChat(form);
    },

    postChat(form) {
      this.$http.post('/'+this.base_url, form)
          .then(response => {
            if(response && response.data) {
              this.pushRecord(response.data);
              this.resetForm();
              this.clearFile();
              this.processing = false;
            }
          })
    },

    closeHelp() {
      localStorage.setItem('help_status', 'closed');
      this.close_help = true;
      this.resetRecords();
    },

    openHelp() {
      localStorage.setItem('help_status', 'opened');
      this.close_help = false;
      this.getRecords();
    },

    setEmailCreator(email) {
      var parts = email.split("@");

      if (parts.length > 1) {
        return parts[0]+'@';
      } else {
        return email;
      }
    },

    async storeUpload(file, options = {}) {
      const response = await this.$http.post(this.$apiURL + 'v1/signed_url', {
        'file_path': options.fileName || file.name,
        'bucket': 'ecdhelp',
        'name': file.name,
        'size': file.size,
        'type': file.type,
        'content_length': file.size,
        'extension': file.name.split('.').pop(),
        'content_type': options.contentType || file.type,
        'expires': options.expires || '',
        'visibility': options.visibility || ''
      }, {
        baseURL: options.baseURL || null,
        headers: options.headers || {},
        ...options.options
      });
      
      if (response && response.data) {
        let headers = response.data.headers;

        if ('Host' in headers) {
          delete headers.Host;
        }

        if (typeof options.progress === 'undefined') {
          options.progress = () => {};
        }

        const cancelToken = options.cancelToken || ''

        await this.$axios.put(response.data.url, file, {
          cancelToken: cancelToken,
          headers: headers,
          onUploadProgress: (progressEvent) => {
            options.progress(progressEvent.loaded / progressEvent.total);
          }
        }).catch(function (error) {
          if(error.response) {
            console.log(error.response.data);
          }
        });

        response.data.extension = file.name.split('.').pop();

        return response.data;
      }

      return null;
    }
  }
}
</script>
