<template>
  <div>

    <page-tabs page="events" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">
                  Create {{ $route.meta.title }}
                </p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-end">

                <template v-if="form.uuid && !processing">
                  <button
                    type="button"
                    class="btn btn-outline-danger"
                    @click="resetForm()"
                  >
                    <i class="fa fa-times" /> <span class="sr-only">Close</span>
                  </button>
                </template>
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="postForm">

              <div class="form-group">
                <label for="color">Color</label>
                <select
                  id="color"
                  v-model="form.color"
                  class="form-control text-uppercase"
                  :disabled="processing"
                >
                  <option value=""></option>
                  <template v-for="color in colorOptions">
                    <option
                      :value="color"
                    >
                      {{ color }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="description">Description</label>
                <textarea
                    id="description"
                    v-model="form.description"
                    class="form-control"
                />
              </div>

              <div class="form-group">
                <label for="group">Group</label>
                <select
                    id="group"
                    v-model="form.group"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="group in clientGroups">
                    <option
                        :value="group"
                    >
                      {{ group }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group mt-3">
                <label for="start_date">Start Date</label>
                <input
                  id="start_date"
                  v-model="form.start_date"
                  type="date"
                  class="form-control"
                  required
                  :disabled="processing"
                >
              </div>

              <div class="form-group mt-3">
                <label for="end_date">End Date</label>
                <input
                  id="end_date"
                  v-model="form.end_date"
                  type="date"
                  class="form-control"
                  required
                  :disabled="processing"
                >
              </div>

              <div class="form-group">
                <label for="start_time">Start Time</label>
                <input
                  id="start_time"
                  v-model="form.start_time"
                  type="time"
                  class="form-control"
                  :disabled="processing"
                >
              </div>

              <div class="form-group">
                <label for="end_time">End Time</label>
                <input
                  id="end_time"
                  v-model="form.end_time"
                  type="time"
                  class="form-control"
                  :disabled="processing"
                >
              </div>

              <div class="form-group">
                <label for="location">Location</label>
                <select
                    id="location"
                    v-model="form.location"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="location in locations">
                    <option
                        :value="location.uuid"
                    >
                      {{ location.name }}
                    </option>
                  </template>
                </select>
              </div>

                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                >
                    Create
                </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs
          :form="form"
          class="mt-3"
        />

        <div class="card">
          <div class="card-body">

            <p class="card-title">Add Random {{  $route.meta.title }}s</p>

            <form @submit.prevent="postRandomForm()">

              <p class="m-0">{{ colorOptions.length }} Available Colors</p>

              <template v-for="color in colorOptions">
                  <span
                    class="badge badge-pill badge-secondary link-hover btn-outline-secondary text-uppercase"
                    @click="addRandomColor(color)"
                    :disabled="processing"
                  >
                    <i
                      class="fa fa-circle"
                      :style="'color:' + color"
                    /> {{ color }}
                  </span>
              </template>


                <hr>

                <p class="card-title">{{ randomForm.colors.length }} Enabled Colors</p>

                <template v-for="(randomColor, randomColorIndex) in randomForm.colors">

                  <div>

                  <div class="form-group">
                    <label :for="'color_'+randomColorIndex" class="form-label text-uppercase">

                    <i
                      class="fa fa-circle"
                      :style="'color:' + randomColor.color"
                    /> {{ randomColor.color }} Frequency

                    </label>
                    <select :id="'color_'+randomColorIndex" class="form-control" v-model="randomForm.colors[randomColorIndex].frequency">
                      <option value="4">Weekly</option>
                      <option value="1">Monthly</option>
                      <option value="2">Biweekly</option>
                    </select>
                  </div>

                  <button
                    class="btn btn-danger btn-sm mb-3"
                    @click="removeRandomColor(randomColorIndex)"
                    :disabled="processing"
                  >
                    <i
                      class="fa fa-times"
                    /> <span class="sr-only">Remove</span>
                  </button>

                  </div><!--EnabledColor-->


              </template>

              <div class="form-group mt-3">
                <label for="randomForm_description">Description</label>
                <textarea id="randomForm_description" class="form-control" v-model="randomForm.description"></textarea>
              </div>

              <div class="form-group mt-3">
                <label for="randomForm_start_date">Start Date</label>
                <input
                  id="randomForm_start_date"
                  v-model="randomForm.start_date"
                  type="date"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="randomForm_end_date" class="form-label">End Date</label>
                <select id="randomForm_end_date" class="form-control" v-model="randomForm.end_date" required>
                  <option value="1 year">1 year</option>
                  <option value="1 month">1 month</option>
                  <option value="3 months">3 months</option>
                  <option value="6 months">6 months</option>
                  <option value="9 months">9 months</option>
                </select>
              </div>

              <div class="form-check">
                <input
                    id="randomForm_random_times"
                    v-model="randomForm.random_times"
                    type="checkbox"
                    class="form-check-input"
                >
                <label
                    class="form-check-label"
                    for="randomForm_random_times"
                > Random Time Slots</label>
              </div>

              <template v-if="!randomForm.random_times">

              <div class="form-group">
                <label for="randomForm_start_time" class="form-label">Start Time</label>
                <input id="randomForm_start_time" type="time" class="form-control" v-model="randomForm.start_time">
              </div>

              <div class="form-group">
                <label for="randomForm_end_time" class="form-label">End Time</label>
                <input id="randomForm_end_time" type="time" class="form-control" v-model="randomForm.end_time">
              </div>

              </template>

              <div class="form-check">
                <input
                  id="randomForm_default_schedules"
                  v-model="randomForm.default_schedules"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for="randomForm_default_schedules"
                > Create Default {{  $route.meta.title }}s</label>
              </div>

              <div class="form-group" :hidden="!randomForm.default_schedules">
                <label for="randomForm_default_colors" class="form-label">Default Colors</label>
                <input id="randomForm_default_colors" type="text" class="form-control" v-model="randomForm.default_colors">
              </div>

              <div class="form-group">
                <label for="randomForm_location">Location</label>
                <select
                    id="randomForm_location"
                    v-model="randomForm.location"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <option value="random">Random</option>
                  <template v-for="location in locations">
                    <option
                        :value="location.uuid"
                    >
                      {{ location.name }}
                    </option>
                  </template>
                </select>
              </div>

            <button type="submit" class="btn btn-success" :disabled="processing">Create</button>

            </form>

            <button type="button" class="btn btn-outline-secondary mt-3" @click="resetRandomForm()" :disabled="processing"><i class="fa fa-eraser"></i> Reset</button>


            <template v-if="randomFormAlert && randomFormAlert.length >= 1">
              <div class="alert alert-success mt-3" role="alert">
                {{ randomFormAlert }}
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">

            <p class="card-title">Search</p>

            <form @submit.prevent="searchRecords">

            <div class="form-group">
              <label for="search">Search</label>
              <input
                id="search"
                v-model="queries.search"
                type="text"
                class="form-control"
                :disabled="processing"
              >
            </div>

            <div class="form-group">
              <label for="search_from_date">From Date</label>
              <input
                id="search_from_date"
                v-model="queries.from_date"
                type="date"
                class="form-control"
                :disabled="processing"
              >
            </div>

            <div class="form-group">
              <label for="search_to_date">To Date</label>
              <input
                id="search_to_date"
                v-model="queries.to_date"
                type="date"
                class="form-control"
                :disabled="processing"
              >
            </div>

            <div class="form-group">
              <label for="search_color">Color</label>
              <select
                id="search_color"
                v-model="queries.color"
                class="form-control text-uppercase"
                :disabled="processing"
              >
                <option value=""></option>
                <template v-for="color in colorOptions">
                  <option
                    :value="color"
                  >
                    {{ color }}
                  </option>
                </template>
              </select>
            </div>

            <div class="form-group">
              <label for="search_group">Group</label>
              <select
                id="search_group"
                v-model="queries.group"
                class="form-control"
                :disabled="processing"
              >
                <option value=""></option>
                <template v-for="group in clientGroups">
                  <option
                    :value="group"
                  >
                    {{ group }}
                  </option>
                </template>
              </select>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="search_archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="form-check-input"
                      @change="searchRecords"
                      :disabled="processing || queries.trashed"
                    >
                    <label
                      class="form-check-label"
                      for="search_archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="search_trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="form-check-input"
                      @change="searchRecords"
                      :disabled="processing || queries.archived"
                    >
                    <label
                      class="form-check-label"
                      for="search_trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>

            </form>



          </div><!--Body-->
        </div><!--Card-->


      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

        <template v-if="portal && portal.link">
          <div class="btn-group d-print-none">
          <a :href="'https://'+portal.link+'.'+portal.domain+'/current'" target="_blank" class="btn btn-primary btn-sm mb-3"><i class="fa fa-external-link-alt"></i> Open Portal Link</a>
          <a :href="'/qrs?title=View Current Schedule&link=https://'+portal.link+'.'+portal.domain+'/current'" target="_blank" class="btn btn-primary btn-sm mb-3"><i class="fa fa-qrcode"></i> Create QR Code</a>
          </div>
        </template>

        <div class="card my-3">
          <div class="card-body">

            <div class="row mb-3">
              <div class="col-12">

                <h4>{{ currentMonthName }} {{ currentYear }}</h4>

                <div class="btn-group">
                <button @click="prevMonth" class="btn btn-light" :disabled="processing">&lt; Prev</button>
                <button @click="nextMonth" class="btn btn-light" :disabled="processing">&gt; Next</button>
                </div><!--BtnGroup-->

                </div><!--Col-->
              </div><!--Row--->


            
            <div class="row week" v-for="week in computedWeeks">
                <div class="col-sm-12 col-md day" v-for="day in week" :class="setDayClass(day)">
                      {{ day.short_date }}
                  <div class="events">
                    <template v-for="event in day.events">
                    <a class="event w-100 text-decoration-none" :href="'/'+$route.meta.base_url+'/'+event.uuid">
                        {{ event.description }} {{ event.group }}

                        <template v-if="!event.description && !event.color">
                          <span class="badge badge-secondary">No Description</span>
                        </template>

                        <template v-if="event.color" class="text-uppercase">
                          <i
                            class="fa fa-circle"
                            :style="'color:' + event.color"
                          /> {{ event.color }}
                        </template>
                        <template v-if="event.location">
                          <span class="badge badge-secondary">{{ setLocationName(event) }}</span>
                        </template>

                    </a>
                    </template>
                  </div>
                </div>
            </div>
            
          </div><!--Body-->
        </div><!--Card-->

    
        <div class="card mt-3">
          <div class="card-body">

            <div class="row mb-3">
              <div class="col-sm-12 col-md-6">
                <p class="card-title m-0">
                  {{ total }} of {{ records.length }} {{ $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-end">
                <template v-if="queries.from_date && queries.to_date">
                  <span class="text-muted"><strong>FROM:</strong> {{ queries.from_date | datetime }} <strong>TO:</strong> {{ queries.to_date | datetime }}</span>
                </template>

  
                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>

              </div><!--Col-->

              </div><!--Row-->

                <div class="table-responsive">
                <table class="table table-striped table-hover my-3">
                  <thead>
                    <template v-for="column in columns">
                      <th>
                        <template v-if="column.key">
                          <span
                            class="text-capitalize link-hover"
                            @click="sortColumn(column)"
                          >{{ column.label }}</span>
                        </template>

                        <template v-if="!column.key">
                          <span class="text-capitalize">{{ column.label }}</span>
                        </template>

                        <template v-if="column.key == queries.sort">
                          <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            @click="updateSortDirection()"
                          >
                            <template v-if="queries.sortDirection == 'asc'">
                              <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                            </template>

                            <template v-if="queries.sortDirection == 'desc'">
                              <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                            </template>
                          </button>
                        </template>
                      </th>
                    </template>
                    </thead>

                  <tbody>
                    <template v-if="records && records.length > 0">
                      <template v-for="record in records">
                        <tr>
                          <td>
                            <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                              <i class="fa fa-edit"></i> Edit
                            </a>
                          </td>
                          <td class="text-uppercase">
                            <template v-if="record.color">
                              <i
                                class="fa fa-circle"
                                :style="'color:' + record.color"
                              /> {{ record.color }}
                            </template>
                          </td>
                          <td>{{ record.description }}</td>
                          <td>{{ record.group }}</td>
                          <td>
                            <template v-if="record.starts">
                              <span class="badge badge-primary">{{ record.starts }}</span>
                            </template>
                          </td>
                          <td>{{ record.start_date | date }}</td>
                          <td>{{ record.end_date | date }}</td>
                          <td>{{ record.start_time | time }}</td>
                          <td>{{ record.end_time | time }}</td>
                          <td>
                            <template v-if="record.location">
                              <router-link :to="{'name': 'locations.edit', 'params': {'id': record.location}}">
                                {{ setLocationName(record) }}
                              </router-link>
                            </template>
                          </td>
                          <td>{{ record.created_by }}</td>
                          <td>{{ record.created_at | datetime }}</td>
                          <td>{{ record.updated_at | datetime }}
                          
                            <template v-if="record.archived_at">
                              <span class="badge badge-warning">Archived</span>
                            </template>

                            <template v-if="record.deleted_at">
                              <span class="badge badge-danger">Trashed</span>
                            </template>
                          </td>
                          <td>
                            <div class="dropdown">
                              <button class="btn btn-secondary dropdown-toggle" type="button" :id="'dropdownMenuButton_'+record.uuid"
                              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Tools
                              </button>
                              <div class="dropdown-menu" :aria-labelledby="'dropdownMenuButton_'+record.uuid">
                                <a class="dropdown-item" @click="trashRecord(record.uuid)" :disabled="processing"><i class="fa fa-trash"></i> Trash</a>
                                <a class="dropdown-item" @click="archiveRecord(record.uuid)" :disabled="processing"><i class="fa fa-archive"></i> Archive</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
                </div><!--Responsive-->

                <template v-if="records && !records.length">
                  <div class="alert alert-warning" role="alert">
                    No {{ $route.meta.title }}s found.
                  </div>
                </template>

              </div><!--Body-->
            </div><!--Card-->

          </div>
        </div>

            <div class="row mt-3" :hidden="!records || !records.length">
              <div class="col-12 text-end">
                <button type="button" class="btn btn-danger" @click="resetSchedules()" :disabled="processing">Reset All
                  {{  $route.meta.title }}s</button>
              </div><!--Col-->
            </div><!--Row-->

  </div>
</template>

<script>
import moment from 'moment-timezone';
import queries from '../mixins/queries';
import _ from "lodash";

export default {

    mixins: [ queries ],

    data() {
        return {
            processing: false,
            loadMore: true,
            queries: {},
            form: {},
            colorOptions: ['aquamarine', 'beige', 'black', 'blue', 'brown', 'cyan', 'fuchsia', 'gray', 'green', 'lime', 'orange', 'pink', 'purple', 'red', 'turquoise', 'violet', 'white', 'yellow'],
            currentYear: null,
            currentMonth: null,
            records: [],
            total: 0,
            columns: [
              {label: 'Edit'},
              {label: 'Color', key: 'color'},
              {label: 'Description', key: 'description'},
              {label: 'Group', key: 'group'},
              {label: 'Starts', key: 'starts'},
              {label: 'Start Date', key: 'start_date'},
              {label: 'End Date', key: 'end_date'},
              {label: 'Start Time', key: 'start_time'},
              {label: 'End Time', key: 'end_time'},
              {label: 'Location', key: 'location'},
              {label: 'Creator', key: 'created_by'},
              {label: 'Created', key: 'created_at'},
              {label: 'Updated', key: 'updated_at'},
              {label: 'Tools'}
            ],
            randomForm: {
              colors: {}
            },
            randomFormAlert: null,
            bufferIntervals: ['days', 'weeks', 'months'],
            portal: {},
            locations: [],
            errors: null,
            clientGroups: []
        }
    },

    computed: {

      currentMonthName() {
      return this.$moment().month(this.currentMonth).format('MMMM');
      },

    computedWeeks() {
      const days = this.createMonth();
      const weeks = [];
      let week = [];
      days.forEach(day => {
        if (new Date(day.year, day.month, day.date).getDay() === 0 && week.length > 0) {
          weeks.push(week);
          week = [];
        }
        week.push(day);
      });
      if (week.length > 0) {
        weeks.push(week);
      }
      return weeks;
    }

    },

    mounted() {

      var self = this;

      this.$eventBus.$on('errors', function (errors) {
        self.processing = false;
        self.errors = errors.data || errors;
      });

      this.$eventBus.$on(this.$route.meta.base_url, function(data) {

        if(!data.uuid) {
          return;
        }

        self.pushEvent(data);

        });

    },

    created() {

      this.currentYear = this.$moment().year();

      this.currentMonth = this.$moment().month();

      this.resetQueries();
      this.resetForm();
      this.resetRandomForm();
      this.resetRecords();
      this.setURLParams();
      this.getRecords();
      this.getPortal();
      this.getLocations();
      this.setClientGroups();
    },

    methods: {

        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);

            var self = this;

            searchParams.forEach(function(value, key) {
                self.queries[key] = value;
            });

        },

        resetQueries() {
          this.queries = {
            search: '',
            limit: 1000,
            skip: 0,
            start_date: null,
            end_date: null,
            sort: 'start_date',
            sortDirection: 'desc',
            archived: false,
            trashed: false,
            color: null,
            group: null
          }
        },

        resetForm() {
          this.form = {
            color: null,
            start_date: null,
            end_date: null,
            start_time: '00:00',
            end_time: '18:00',
            description: null,
            location: null
          }

          this.errors = null;
        },

        resetRandomForm() {
          this.randomForm = {
            colors: [],
            description: null,
            location: null,
            start_date: null,
            frequency: null,
            random_times: true,
            end_date: '1 year',
            start_time: '00:00',
            end_time: '18:00',
            max: 1,
            buffer: 1,
            buffer_interval: 'days',
            default_schedules: true,
            default_colors: 'aquamarine, beige, black, blue, brown, cyan, fuchsia, gray, lime, orange, pink, purple, turquoise, violet, white'
          }
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
        },

        resetRecords() {
          this.records = [];
          this.queries.skip = 0;
          this.total = 0;      
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },

        searchRecords() {
          this.resetRecords();
          this.getRecords();
          this.total = 0;
          this.queries.skip = 0;
        },

        getRecords() {

            this.updateURL();

            this.getTotal();

            this.processing = true;

            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if(response && response.data) {

                    if(response.data.length > 0) {

                      var self = this;

                        response.data.forEach(r => {
                          self.pushEvent(r);
                        });
                    }

                    this.processing = false;
                }
            })
        },


        pushEvent(record) {
            this.records.push(record);
        },


        formatDate(value) {
          if (value && moment(value).isValid()) {

            var timezone = moment.tz.guess() || localStorage.getItem('timezone');

            var date = moment(value).tz(timezone);

            return date.tz(timezone).format('YYYY-MM-DD');
          }
        },


        postForm () {

            this.processing = true;

            this.errors = null;

            if(!this.form.uuid) {
                this.createForm();
                return;
            }


            this.updateForm(this.form.uuid);
            this.processing = true;
        },

        createForm() {
            this.$http.post('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                if(response && response.data) {

                    this.processing = false;

                    if(response.status === 201) {
                        this.form.color = null;
                        this.$eventBus.$emit('show-alert', {
                          text: this.$route.meta.title + ' created'
                        });
                    }
                    
                }
            })
        },

        updateForm(uuid) {
            this.$http.patch('/'+this.$route.meta.base_url+'/'+uuid, this.form)
            .then(response => {
                if(response && response.data) {


                    if(response.data.updated_at) {
                        this.resetForm();
                        this.$eventBus.$emit('show-alert', {
                          text: 'Updating '+ this.$route.meta.title
                        });
                        this.records.splice(this.records.findIndex((obj => obj.uuid == uuid)), 1);
                    }

                    this.processing = false;
                }
            })
        },

        trashRecord (uuid) {
            this.processing = true;
            this.$http.delete('/'+this.$route.meta.base_url+'/'+uuid)
            .then(response => {
                if(response && response.status) {

                    this.processing = false;

                    if(response.status === 204) {
                        this.$eventBus.$emit('show-alert', {
                          text: 'Deleting '+this.$route.meta.title
                        });
                        this.resetForm();
                        this.total--;
                        this.records.splice(this.records.findIndex((obj => obj.uuid == uuid)), 1);
                        this.setTitle();
                    }
                }
            })
        },

        archiveRecord (uuid) {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url+'/'+uuid+'/archive')
            .then(response => {
                if(response && response.status) {

                    this.processing = false;

                    if(response.status === 200) {
                        this.$eventBus.$emit('show-alert', {
                          text: 'Archiving '+this.$route.meta.title,
                          type: 'danger'
                        });
                        this.resetForm();
                        this.total--;
                        this.records.splice(this.records.findIndex((obj => obj.uuid == uuid)), 1);
                        this.setTitle();
                    }
                }
            })
        },

        updateSortDirection() {

          if(this.queries.sortDirection == 'asc') {
            this.queries.sortDirection = 'desc';
          } else {
            this.queries.sortDirection = 'asc';
          }

          this.resetRecords();

          this.getRecords();
        },

        sortColumn(column) {

          this.queries.sort = column.key;

          this.updateSortDirection();

        },

        postRandomForm() {

          this.randomFormAlert = null;

          this.processing = true;
          this.$http.post('/'+this.$route.meta.base_url+'/random', this.randomForm)
          .then(response => {
            if(response && response.status) {
              this.processing = false;

              this.resetRandomForm();

              if(response.status === 202 && response.data) {

                this.randomFormAlert = 'Generating random schedules. We\'ll email you once complete.';

                this.$eventBus.$emit('show-alert', {
                  text: this.randomFormAlert
                });

              }
            }
          })
        },

        resetSchedules() {
          this.processing = true;
          this.$http.delete('/'+this.$route.meta.base_url+'/reset')
          .then(response => {
            if(response && response.status) {

              this.processing = false;

              if(response.status === 204) {
                location.reload();
              }

            }
          })
        },

        addRandomColor(color) {
          this.randomForm.colors.unshift({'color': color, 'frequency': 1});
        },

        removeRandomColor(index) {
          this.randomForm.colors.splice(index, 1);
        },

      getPortal() {
          this.$http.get('/portals?limit=1&sort=default&sortDirection=desc')
              .then(response => {
                if(response && response.data && response.data.length > 0) {
                  this.portal = response.data[0];
                }
              })
      },

      getLocations() {
          this.$http.get('/locations?limit=100&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
              .then(response => {
                if(response && response.data && response.data.length > 0) {
                  this.locations = response.data;
                }
              })
      },

      setLocationName(record) {

        if(!record.location) {
          return;
        }

        let location = _.find(this.locations, {'uuid': record.location});

        if(!location || !location.name) {
          return;
        }

        return location.name;
      },


      prevMonth() {
          if (this.currentMonth === 0) {
            this.currentMonth = 11;
            this.currentYear -= 1;
          } else {
            this.currentMonth -= 1;
          }
        },

        nextMonth() {
          if (this.currentMonth === 11) {
            this.currentMonth = 0;
            this.currentYear += 1;
          } else {
            this.currentMonth += 1;
          }
        },

        getDaysInMonth(month, year) {
          return this.$moment().tz(this.timezone).month(month).daysInMonth();
        },

        createMonth() {
          const daysInMonth = this.getDaysInMonth(this.currentMonth, this.currentYear);
          const days = [];
          for (let i = 1; i <= daysInMonth; i++) {
            const date = new Date(this.currentYear, this.currentMonth, i);
            const dayEvents = this.records.filter(event => {
            // if multi-day event
            if(event.start_date != event.end_date) {
              var start = this.$moment(event.start_date).tz(this.timezone).toDate();
              var end = this.$moment(event.end_date).tz(this.timezone).toDate();

              if(date >= start && date <= end) {
                return true;
              }
            } else {
            const eventDate = this.$moment(event.start_date).tz(this.timezone).toDate();
            return eventDate.getFullYear() === this.currentYear && eventDate.getMonth() === this.currentMonth && eventDate.getDate() === i;
            }
            });

            dayEvents && dayEvents.sort((a, b) => {
                return this.$moment(a.start).toDate() - this.$moment(b.start).toDate();
            });

            days.push({
              date: i,
              short_date: this.$moment(date).tz(this.timezone).format('ddd Do'),
              month: this.currentMonth,
              year: this.currentYear,
              events: dayEvents
            });
          }
          return days;
        },

        convertToTimezone(dateString, timezone) {
            return this.$moment(dateString).format('YYYY-MM-DD HH:mm');
        },


        setDayClass(day) {
          var today = this.$moment().tz(this.timezone).format('YYYY-MM-DD');

          var date = this.$moment(day).format('YYYY-MM-DD');

          if(today === date) {
              return 'bg-primary text-white';
          }

          return '';
        },

        setClientGroups() {
          if(!this.settings || !this.settings.default_groups) {
            return;
          }

          this.clientGroups = this.settings.default_groups.split(',');
        },

    }
}
</script>