<template>
  <div>

    <template v-if="!processing && record.uuid">

    <div class="card">
      <div class="card-body">

        <div class="row">

            <div class="col-6">

              <p class="card-title">
                Sent File 
                <router-link :to="{ 'name': 'sent_files.edit', 'params': {'id': record.uuid} }">
                  {{ record.name }}
                </router-link>
              </p>

            </div><!--Col-->

            <div class="col-3 text-end">
                <template v-if="record.status">
                    <file-status :status="record.status" />
                </template>
            </div><!--Col-->

        </div><!--Row-->


          <file-embed :uuid="record.file" />

        <template v-if="!processing && !record.uuid">
            <div class="alert alert-warning">Sent file not found</div>
        </template>

      </div><!--Body-->
    </div><!--Card-->

    </template>
    
  </div>
</template>
<script>
export default {

props: ['uuid'],

data() {
    return {
        processing: false,
        record: {},
        base_url: '/sent_files'
    }
},

created() {
    this.getRecord();
},

methods: {

        getRecord() {
            
            if(!this.uuid) {
                return;
            }

            this.processing = true;
            this.record = {};

            this.$http.get(this.base_url+'/'+this.uuid+'?slim=true&fields=name,uuid,type,file')
            .then(response => {
                if(response && response.data) {
                    this.record = response.data;
                    this.processing = false;
                }
            })
        },

}

}
</script>