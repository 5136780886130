<template>
    <div class="container py-5">
        <div class="row">
          <div class="col-9 mx-auto">
            <div class="card">
              <div class="card-body">
  
                <h1 class="text-center"><i class="fa fa-exclamation-circle text-danger"></i> Create a {{ $route.meta.title }}</h1>

                <template v-if="!show_form">

                <h4>Ticket Type</h4>
                    
                    <template v-for="ticket_type in ticket_types">
                    <div class="form-check mb-1">
                        <input
                        :id="ticket_type"
                        type="radio"
                        class="form-check-input"
                        :value="ticket_type"
                        v-model="current_issue"
                        required
                        >
                        <label
                        class="form-check-label"
                        :for="ticket_type"
                        >{{ ticket_type }}</label>
                    </div>
                    </template>

                <template v-if="current_issue && issues[current_issue]">
                <p class="lead mt-3">{{ issues[current_issue] }}</p>

                </template>

                </template>

                <div class="btn-group" v-if="current_issue">
                <button type="button" class="btn btn-success" @click="proceedForm()" :hidden="show_form">Continue</button>
                </div>

                <button type="button" class="btn btn-secondary" @click="toggleForm()" :hidden="!show_form">Go Back</button>
                
                <form @submit.prevent="postForm()" v-if="show_form">

                <div class="form-group mt-3">
                <label for="description" class="lead">Issue Description</label>
                <textarea id="description" class="form-control" v-model="form.description" rows="10" required></textarea>
                </div>

                <div class="form-group">
                    <label>Priority</label>
                    <div class="priority-options">
                        <template v-for="priority in priorities">
                            <div class="form-check mb-2">
                                <input
                                    :id="'priority-' + priority"
                                    type="radio"
                                    class="form-check-input"
                                    :value="priority"
                                    v-model="form.priority"
                                    name="priority"
                                >
                                <label
                                    class="form-check-label"
                                    :for="'priority-' + priority"
                                    :class="'priority-label-' + priority"
                                >
                                    {{ priority.charAt(0).toUpperCase() + priority.slice(1) }}
                                </label>
                            </div>
                        </template>
                    </div>
                </div>
                
                <p class="text-muted" v-if="form.priority == 'high'">We investigate high priority tickets within 2 hours (during business hours).
                If the system is incorrectly sending messages, please switch to <a href="/settings?search=send%20mode" target="_blank">Simulate Mode</a> before reporting the issue.
                </p>

                <p class="text-muted" v-if="form.priority == 'standard'">Standard priority tickets are investigated within 1 business day.</p>

                <p class="text-muted" v-if="form.priority == 'low'">Low priority tickets are investigated within 5 business days.</p>

                <div class="form-group">
                <label for="notify">Notify User</label>
                <input
                    id="notify"
                    type="text"
                    class="form-control"
                    v-model="form.notify"
                    placeholder="Email"
                    @input="searchUsers(form.notify)"
                    >
                <div class="list-group">
                    <a
                    v-for="user in users"
                    class="list-group-item list-group-item-action link-hover"
                    @click="setUserNotify(user)"
                    >
                    <template v-if="user.name != user.email">
                        {{ user.name }}
                    </template>
                    {{ user.email }}
                    </a>
                </div>
                <template v-if="$root.current_user && $root.current_user.email">
                    <p class="text-muted m-0">{{ $root.current_user.email }} will be auto-notified. Add another email to include additional users in the ticket.</p>
                </template>
                </div>

                <button type="submit" class="btn btn-success my-3" :disabled="processing">Create</button>

                </form>
                
              </div><!--Body-->
            </div><!--Card-->

                <div class="card mt-3">
                    <div class="card-body">
                        <h3 class="text-center">Reported Tickets</h3>

                        <form @submit.prevent="getRecords()">

                        <div class="form-group">
                            <label for="search" class="form-label">Search Tickets</label>
                            <input
                                id="search"
                                type="text"
                                class="form-control"
                                v-model="search"
                                placeholder="Search"
                            >
                        </div>

                        <div class="form-group">
                            <label for="status" class="form-label">Filter by Status</label>
                            <select
                                id="status"
                                class="form-control"
                                v-model="search_status"
                            >
                                <option value="">Any status</option>
                                <option
                                    v-for="status in statuses"
                                    :value="status"
                                >{{ status }}</option>
                            </select>
                        </div>

                        <button type="submit" class="btn btn-primary my-3" :disabled="processing">Search</button>

                        </form>

                        <template v-if="tickets && tickets.length > 0">

                        <div class="list-group">
                            <a
                                v-for="ticket in tickets"
                                class="list-group-item list-group-item-action"
                                :href="'/help_tickets/'+ticket.uuid""
                            >
                            <div class="d-flex justify-content-between">
                            <span class="badge badge-primary text-uppercase">{{ ticket.status }}</span>

                            <template v-if="ticket.priority">
                                <span class="badge text-uppercase" :class="setPriorityClass(ticket.priority)">{{ ticket.priority }}</span>
                            </template>
                            </div>

                            <p class="mb-0 mt-1">{{ ticket.title }}</p>

                            <template v-if="ticket.type">
                                <p class="m-0">{{ ticket.type }}</p>
                            </template>

                            <p class="m-0">{{ ticket.description }}</p>
                            <p class="m-0 text-muted">{{ ticket.created_at | datetime }}</p>
                             <p class="m-0">Creator: {{ ticket.created_by }}</p>
                             <template v-if="ticket.notify">
                                <p class="m-0">Notify: {{ ticket.notify }}</p>
                            </template>
                            </a>
                        </div>

                        </template>

                        <template v-if="!tickets || tickets.length === 0 && !processing">
                            <p class="text-center">No tickets found</p>
                        </template>

                    </div><!--Body-->
                </div><!--Card-->
  
  
          </div>
      </div>
    </div>
  </template>
  <script>
  import queries from '../mixins/queries';

  export default {

    mixins: [queries],
  
      data() {
          return {
            processing: false,
            show_form: false,
            form: {},
            users: [],
            ticket_types: [],
            current_issue: '',
            issues: {
              'Message Didn\'t Trigger': 'Check the client to verify that they are not disabled and there is at least one valid contact. Verify that the source record is assigned to the correct flow/auto message, is still valid (ex: event date in the future), and doesn\'t have any disabling statuses.',
                'Message Sent Incorrectly': 'Click on the message to review the Type and Audit Log. If the message was a one-off, check the Audit Log for the user who made the change. If the message was sent by a flow, verify the flow is configured correctly. If the message was sent by an auto message, verify the source record is assigned to the correct auto message.',
              'Incorrect Data Processing': 'Please provide an example data record and the expected result. If the record was manually entered, check the Audit Log for the user who made the change.',
              'Configuration Change': 'Please provide the change you would like to make and the expected result. Our help team is only authorized to provide guidance on configuration changes. Any changes to the system will need to be made by an authorized user. We advise to test changes in your staging agency before making changes in your production agency.',
              'Bug Report': 'Please provide a detailed description of the issue you are experiencing. If possible, provide steps to reproduce the issue. If the issue is urgent, mark the ticket as high priority.',
              'Data Request': 'Please provide the data you would like to request. If available, we will create a report and send it to the email provided.',
              'Feature Request': 'We ❤️ feature requests! If you prefer, you may want to schedule a meeting to discuss the feature. Otherwise, feel free to submit a report and we will review it.',
              'Other': 'Please provide a detailed description of the request you would like to make.'
            },
            templates: {
                'Bug Report': 'I am experiencing the following issue:\n\nSteps to reproduce:\n\nExpected result:\n\nActual result:\n\nAdditional information:\n\n',
                'Message Didn\'t Trigger': 'I want to report the following message did not trigger:\n\n',
                'Message Sent Incorrectly': 'I want to report the following message was sent incorrectly\n\nThe message was sent to the following phone or email:\n\n',
                'Data Request': 'I want to request the following data:\n\n',
                'Feature Request': 'I want to request the following feature:\n\n',
                'Other': 'I want to make the following request:\n\n'
            },
            tickets: [],
            search: '',
            search_status: '',
            statuses: ['reported', 'investigating', 'engineering', 'testing', 'resolved', 'backlog', 'canceled'],
            priorities: ['low', 'standard', 'high']
          }
      },  
  
      created () {
        this.ticket_types = Object.keys(this.issues);
        this.resetForm();
        this.getRecords();
      },
  
      methods: {


        toggleForm() {
            this.show_form = !this.show_form;
        },

        setPriorityClass(priority) {

            if(priority == 'high') {
                return 'badge-danger';
            }

            if(priority == 'low') {
                return 'badge-success';
            }

            return 'badge-secondary';
        },

        proceedForm() {
            this.show_form = true;

            if(!this.current_issue) {
                this.current_issue = ''
            }

            this.form.description = this.current_issue;

            if(this.templates[this.current_issue]) {
                this.form.description = this.templates[this.current_issue];
            }

            if(this.current_issue == 'Bug Report') {
                this.form.priority = true;
            }
        },
  
        resetForm() {
            this.form = {
                description: "",
                type: "Message Didn't Trigger",
                priority: "standard",
                notify: "",
                ticket_url: ""
            }

            this.current_issue = '';

            this.show_form = false;

        },

        postForm() {

            this.form.ticket_url = window.location.href;

            if(this.$route.query.url) {
                this.form.ticket_url = this.$route.query.url;
            }

            this.processing = true;

            this.$http.post('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                if(response && response.data) {
                    this.processing = false;
                    this.resetForm();
                    this.$eventBus.$emit('show-alert', {
                        text: 'Ticket reported successfully'
                    });
                    if(response.data.uuid) {
                        window.location.href = '/help_tickets/'+response.data.uuid;
                    }
                }
            })
        },

        searchUsers(search) {
            if (search.length > 2) {
                this.$http.get('/users?slim=true&fields=name,email,uuid&search=' + search)
                    .then(response => {
                        if (response && response.data) {
                            this.users = response.data;
                        }
                    })
            }
        },

        setUserNotify(user) {
            this.form.notify = user.email;
            this.users = [];
        },

        getRecords() {

            let queries = {
                limit: 20,
                search: this.search,
                status: this.search_status
            }

            this.processing = true;

            this.tickets = [];
            this.$http.get(this.buildQueries(this.$route.meta.base_url, queries))
            .then(response => {
                if(response && response.status === 200) {
                    this.tickets = response.data;
                    this.processing = false;
                }
            })
        }
  
      }
  
  }
  </script>