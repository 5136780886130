<template>
  <div>

    <page-tabs page="clients" />

    <div class="row">
      <div class="col-sm-12 col-md-3">

        <client-search />

        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Create {{ $route.meta.title }}
            </p>

            <form @submit.prevent="postForm()">
 
              <div class="form-group mt-3">
                <label for="address_1">Address *</label>
                <input
                  id="address_1"
                  v-model="form.address_1"
                  type="text"
                  class="form-control"
                  required
                  autocomplete="street-address"
                >
              </div>

              <div class="form-group">
                <label for="address_2">Address 2</label>
                <input
                  id="address_2"
                  v-model="form.address_2"
                  type="text"
                  class="form-control"
                  autocomplete="address-line2"
                >
              </div>

              <div class="form-group">
                <label for="city">City</label>
                <input
                  id="city"
                  v-model="form.city"
                  type="text"
                  class="form-control"
                  autocomplete="address-level2"
                >
              </div>

              <div class="form-group">
                <label for="state">State</label>
                <select
                  id="state"
                  v-model="form.state"
                  class="form-control"
                  autocomplete="address-level1"
                  :disabled="processing"
                >
                  <template v-if="states && states.length > 0">
                    <template v-for="state in states">
                      <option
                        :value="state.key"
                      >
                        {{ state.label }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="postal_code">Zip</label>
                <input
                  id="postal_code"
                  v-model="form.postal_code"
                  class="form-control"
                  autocomplete="postal-code"
                >
              </div>

              <div class="form-group">
                <label for="country">Country</label>
                <select
                  id="country"
                  v-model="form.country"
                  class="form-control"
                  autocomplete="country"
                  :disabled="processing"
                >
                  <template v-if="countries && countries.length > 0">
                    <template v-for="country in countries">
                      <option
                        :value="country.code"
                      >
                        {{ country.code }} - {{ country.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="address_type">Type</label>
                <select
                    id="address_type"
                    v-model="form.type"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="address_type in $root.address_types">
                    <option
                        :value="address_type"
                    >
                      {{ address_type }}
                    </option>
                  </template>
                </select>
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Create
              </button>
            </form>

            <template v-if="!processing && new_form && new_form.address_1">
              <div class="alert alert-success mt-3">
                <a :href="'/'+$route.meta.base_url+'/'+new_form.uuid" class="alert-link">Edit new {{ $route.meta.title }} here</a>
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->            


        <div class="card mt-3">
          <div class="card-body">
            <form @submit.prevent="searchRecords()">

              <p class="card-title">Search {{ $route.meta.title }}es</p>

              <div class="form-group">
                <label for="search">Street Address</label>
                <input
                  id="search"
                  v-model="queries.search"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_city">City</label>
                <input
                  id="search_city"
                  v-model="queries.city"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_state">State</label>

                  <select
                    id="search_state"
                    v-model="queries.state"
                    class="form-control"
                    @change="searchRecords()"
                    :disabled="processing"
                  >
                    <option value=""></option>
                    <template v-if="states && states.length > 0">
                      <template v-for="state in states">
                        <option
                          :value="state.key"
                        >
                          {{ state.label }}
                        </option>
                      </template>
                    </template>
                  </select>
                </div>

                <template v-if="queries.state">
                  <div class="form-group">
                    <label for="search_county">County</label>
                    <select
                      id="search_county"
                      class="form-control"
                      @change="searchRecords()"
                      :disabled="processing"
                    >
                      <option value="" />
                      <template v-for="county in counties">
                        <template v-if="county.state == queries.state">
                          <option :value="county.county">{{ county.county }}</option>
                        </template>
                      </template>
                    </select>
                  </div>
                </template>

                <div class="form-group mt-3">
                  <label for="search_postal_code">Zip</label>
                  <input
                      id="search_postal_code"
                      v-model="queries.postal_code"
                      type="text"
                      class="form-control"
                  >
                </div>

                <div class="form-group">
                  <label for="search_type">Type</label>
                  <select
                      id="search_type"
                      v-model="queries.type"
                      class="form-control"
                      :disabled="processing"
                  >
                    <option value="" />
                    <template v-for="address_type in $root.address_types">
                      <option
                          :value="address_type"
                      >
                        {{ address_type }}
                      </option>
                    </template>
                  </select>
                </div>


              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">


        <div
          id="map"
          class="map-xl mb-3"
        />

        <template v-if="actions && actions.records && actions.records.length > 0">

          <p class="text-success">
            {{ actions.records.length }} selected
          </p>

          <div class="row">

            <div class="col-sm-12 col-md-6">
              <div class="btn-group">
              <button type="button" class="btn btn-danger" @click="deselectAll()" :disabled="processing"><i class="fa fa-times"></i> Deselect All</button>
              <template v-if="actions.records.length < this.total">
                <button type="button" class="btn btn-primary" @click="selectAll()" :disabled="processing"><i class="fa fa-check"></i> Select All</button>                    
              </template>
              </div>

            </div><!--Col-->

          </div>

          </template>

        <div class="card">
          <div class="card-body">

            <div class="row">

            <div class="col-sm-12 col-md-6">

              <p class="card-title">
                {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}es
              </p>

            </div><!--Col-->

            <div class="col-sm-12 col-md-6 text-end">

              <div class="btn-group d-print-none">
                <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
              </div>
            </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
              <table class="table table-hover table-striped">
              <thead>
                <tr>
                  <th>Edit</th>
                  <th @click="toggleSelect()" class="link-hover">Select</th>
                  <template v-for="column in columns">
                    <th>
                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ column.label }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>

                <tbody>
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                          <i class="fa fa-edit"></i> Edit
                        </a>
                      </td>
                      <td>
                      <label
                        class="sr-only"
                        :for="'record-'+record.uuid"
                        aria-label="Select"
                      >Select</label>
                      <input
                        :id="'record-'+record.uuid"
                        type="checkbox"
                        :value="record.uuid"
                        v-model="actions.records"
                      />
                    </td>
                      <td>
                          {{ record.address_1 }}
                      </td>
                      <td>
                        <template v-if="record.client">
                          <client-button :uuid="record.client" />
                        </template>
                      </td>
                      <td>{{ record.address_2 }}</td>
                      <td>{{ record.city }}</td>
                      <td>{{ record.county }}</td>
                      <td>{{ record.state }}</td>
                      <td>{{ record.postal_code }}</td>
                      <td>{{ record.type }}</td>
                      <td>{{ record.created_at | datetime }}</td>
                    </tr>
                    </template>
                </tbody>
              </table>

              <template v-if="!processing && records && !records.length">
                <div class="alert alert-warning">
                  No {{ $route.meta.title }}es found.
                </div>
              </template>
            </div>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import { autofill } from '@mapbox/search-js-web';
import queries from '../mixins/queries';

export default {
    mixins: [ queries ],
    data () {
        return {
            processing: false,
            downloading: false,
            form: {},
            new_form: {},
            total: 0,
            records: [],
            downloads: [],
            states: [],
            countries: [],
            counties: [],
            address: {},
            queries: {
                city: '',
                search: '',
                client: null,
                limit: 10,
                skip: 0,
                sort: 'created_at',
                sortDirection: 'desc'
            },
            columns: [
                { key: 'address_1', label: 'Address 1'},
                { key: 'client', label: 'Client'},
                { key: 'address_2', label: 'Address 2'},
                { key: 'city', label: 'City'},
                { key: 'county', label: 'County'},
                { key: 'state', label: 'State'},
                { key: 'postal_code', label: 'Zip'},
                { key: 'type', label: 'Type'},
                { key: 'created_at', label: 'Created'}
            ],
            map: {},
            markers: [],
            autofill: {},
            actions: {
              action: null,
              records: []
            }
        }
    },

    mounted() {

    this.autoFill();
    
    this.initMap();

    var self = this;

    this.$eventBus.$on('refreshRecords', function(view) {

        if(view !== self.$route.meta.base_url) {
            return;
        }

        self.resetRecords();
        self.getRecords();

    });

    this.$eventBus.$on('search_clients', (uuid) => {

      if(uuid) {
        self.queries.client = uuid;
      } else {
        self.queries.client = null;
      }

      self.resetRecords();
      self.getRecords();
      });

    },

    created () {
        this.resetRecords();
        this.resetQueries();
        this.resetForm();
        this.setURLParams();
        this.getRecords();
        this.getStates();
        this.getCountries();
        this.getCounties();
    },

    methods: {

        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);

            var self = this;

            searchParams.forEach(function(value, key) {
                self.queries[key] = value;
            });

        },

      resetQueries() {
        this.queries = {
          sortDirection: 'desc',
          sort: 'created_at',
          skip: 0,
          limit: 100,
          client: null,
          archived: false,
          trashed: false,
          search: null,
          type: null,
          city: null,
          state: null,
          postal_code: null
        }
      },

      updateSortDirection() {

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();
      this.getRecords();
      },

      sortColumn(column) {

      this.queries.sort = column.key;

      this.updateSortDirection();
      },

        searchRecords() {
            this.resetRecords();
            this.getRecords();
        },

        resetForm() {
          this.form = {
            client: null,
            address: null,
            address_2: null,
            city: null,
            state: null,
            postal_code: null,
            country: 'US',
            type: 'home'
          }
        },

        resetRecords() {
            this.records = [];
            this.markers = [];
            this.total = 0;
            this.queries.skip = 0;
        },

        getRecords() {

            this.processing = true;

            this.updateURL();
            this.getTotal();

            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
            .then(response => {
                if(response && response.data) {

                    this.markers = window.L.markerClusterGroup({
                        spiderfyOnMaxZoom: true,
                        showCoverageOnHover: true,
                        zoomToBoundsOnClick: true
                    });

                    if(response.data.length > 0) {

                        var self = this;

                        response.data.forEach(r => {
                            self.records.push(r);
                            self.setMarker(r);
                        });
                    }

                    this.queries.skip = this.records.length;

                    this.processing = false;

                    if(this.map) {
                      this.map.addLayer(this.markers);
                    }
                }
            })
        },

        getTotal() {
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url + '/total', this.queries))
            .then(response => {
                if(response && response.status) {
                    this.total = response.data;
                    this.setTitle();
                }
            })
        },

        setTitle() {
          document.title = this.total + ' ' + this.$route.meta.title + 'es | eCourtDate.com';
        },

        updateURL() {
          history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
        },


        postForm() {

          if(this.queries.client && this.$options.filters.isValidUUID(this.queries.client)) {
           this.form.client = this.queries.client;
          }

            this.processing = true;

            this.new_form = {};

            this.$http.post('/'+this.$route.meta.base_url, this.form)
            .then(response => {
                if(response && response.data) {

                    if(response.data.uuid) {
                        this.records.unshift(response.data);
                        this.setMarker(response.data);
                        this.total++;
                        this.resetForm();
                        this.new_form = response.data;
                        this.$eventBus.$emit('show-alert', {
                          text: this.$route.meta.title + ' created'
                        });
                    }

                    this.processing = false;
                }
            })
        },

      initMap() {

      this.map = window.L.map("map").setView(this.$root.coordinates, 4);

      window.L.tileLayer(this.$root.map_layer).addTo(this.map);

    },

        setMarker(record) {

        if(!record.latitude || !record.longitude) {
            return;
        }

        var popup = '<p class="m-0 lead">Address <a href='+'/'+this.$route.meta.base_url+'/'+record.uuid+'>'+record.address_1+'</a></p>';

            var icon_aliases = {
              "work": "building",
              "mail": "mailbox",
              "jail": "handcuffs",
              "parents": "people-roof",
              "other": "map-marker"
            }

            var icons = [];

            this.$root.address_types.forEach(type => {

              if(icon_aliases[type]) {
                icons[type] = icon_aliases[type];
                return;
              }

              icons[type] = type;
            })

            const icon = icons[this.form.type] || 'map-marker';

          const markerIcon = window.L.divIcon({
            html: '<i class="fa text-primary fa-2x fa-'+icon+'"></i>',
            iconSize: [20, 20],
            className: 'marker-icon'
          });

        var marker = window.L.marker([record.latitude, record.longitude], {icon: markerIcon}).bindPopup(popup);

        this.markers.addLayer(marker);

    },

    autoFill() {

      var options = { language: 'en', country: 'us' };

      if(this.settings && this.settings['country']) {
        options['country'] = this.settings['country'] || 'us';

        if(this.settings && this.settings['latitude'] && this.settings['longitude']) {
          options['proximity'] = [this.settings['longitude'], this.settings['latitude']];
        }
      }

      this.autofill = new autofill({
        accessToken: this.$root.mapbox_token,
        options: options
      });

      var self = this;

      this.autofill.addEventListener('retrieve', (event) => {

        if (!event.detail || !event.detail.features || !event.detail.features.length) {
          return;            
        }

        const features = event.detail.features[0];

        if(features['geometry'] && features['geometry']['coordinates']) {
          self.form.latitude = features['geometry']['coordinates'][1] || null;
          self.form.longitude = features['geometry']['coordinates'][0] || null;
        }

        if(!features['properties']) {
          return;
        }

        if(features['properties']['country_code']) {
          self.form.country = features['properties']['country_code'];
        }

        if(features['properties']['address_line2']) {
            self.form.address_2 = features['properties']['address_line2'];
        }

        if(features['properties']['postcode']) {
            self.form.postal_code = features['properties']['postcode'];
        }

        if(features['properties']['country']) {
          self.form.country = features['properties']['country'];
        }

      });

      },

      resetActionsForm() {
        this.actions = {
          action: null,
          records: []
        }
      },

      deselectAll() {
        this.actions.records = [];
      },

      selectAll() {
        this.actions.records = this.records.map(function(record) {
          return record.uuid;
        });
      },

      toggleSelect() {
        if(this.actions.records.length == this.total) {
          this.deselectAll();
          return;
        }

        this.selectAll();
      },

      getCounties() {
        this.$axios.get('https://ecdassets.s3.amazonaws.com/js/counties.json')
        .then(response => {
          if(response && response.data) {
            this.counties = response.data;
          }
        })
      }


    }

}
</script>
