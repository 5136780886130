<template>
<div>
  <button
    type="button"
    class="btn btn-outline-primary btn-sm"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    id="mergeTagButton"
    :disabled="!merge_tags.length"
  >
    Merge Tags
  </button>
  <div class="dropdown-menu" aria-labelledby="mergeTagButton" style="max-height:400px; overflow-y:auto">
    
    <div class="p-2">
      <label for="search_merge_tags">Search {{ mergeTags.length }} Merge Tags</label>
      <input id="search_merge_tags" class="form-control" type="text" placeholder="Search Merge Tags" v-model="search">
    </div>

    <div class="dropdown-divider"></div>

    <template v-for="merge_tag in mergeTags">
        <a
          class="dropdown-item"
          @click="selectTag(merge_tag.value)"
        >{{ merge_tag.value }}</a>
    </template>
  </div>
</div>
</template>
<script>
export default {
  
  data() {
      return {
        merge_tags: [],
        search: ""
      }
  },

  mounted () {
    this.getMergeTags();
  },

  computed: {

    mergeTags: function() {

      if(!this.merge_tags) {
        return;
      }


      if(!this.search) {
        return this.merge_tags;
      }

      return this.merge_tags.filter(merge_tag => {
        return merge_tag.value.toLowerCase().includes(this.search.toLowerCase());
      });
    }

  },

  methods: {

    selectTag (value) {
      this.value = '';
      this.$emit("add-tag-to-message", value);
    },

    getMergeTags () {
      this.$axios.get(this.$root.assets_url+'js/mergetags.json')
      .then(response => {
          if(response && response.data) {
            this.merge_tags = response.data;
          }
      })
    }
  }
}
</script>
