<template>
    <div>
    
    <page-tabs page="setup" />
    
          <div class="row">
            <div class="col-6 mx-auto">
              <div class="card">
                <div class="card-body">

                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <p class="lead">Carrier Registration Status</p>
                            <p class="lead">Learn more about <a href="https://help.ecourtdate.com/how-to-complete-carrier-registration" target="_blank">carrier registration here</a>.</p>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <img src="https://assets.ecourtdate.com/images/campaign-registry.png" alt="Carrier Registration" class="img-fluid">
                        </div>
                    </div>
    
                  <template v-if="brand && brand.referenceId">


                    <ul class="list-group">

                    <template v-if="brand.brandId">
                        <li class="list-group-item">Brand ID: {{ brand.brandId }}</li>
                    </template>

                    <template v-if="brand.companyName">
                        <li class="list-group-item">Entity Name: {{ brand.companyName }}</li>
                    </template>

                    <template v-if="brand.entityType">
                        <li class="list-group-item">Entity Type: {{ brand.entityType }}</li>
                    </template>

                    <template v-if="brand.identityStatus">
                        <li class="list-group-item">Verification Status: <span class="badge badge-pill badge-success" :class="setStatusClass(brand.identityStatus)">{{ brand.identityStatus | rmDashes }}</span></li>
                    </template>

                    <template v-if="brand.optionalAttributes && brand.optionalAttributes.governmentEntity">
                        <li class="list-group-item">Government Entity: <span class="badge badge-pill badge-success">{{ brand.optionalAttributes.governmentEntity }}</span></li>
                    </template>

                    <template v-else>
                        <li class="list-group-item">Government Entity: Not a government entity</li>
                    </template>

                    </ul>


                    <h4 class="mt-3">Campaigns</h4>

                    <template v-if="builder">
                        <button class="btn btn-danger mb-3" @click="toggleCampaignBuilder" :disabled="processing">Close Campaign Builder</button>
                    </template>

                    <template v-else>
                        <button class="btn btn-success mb-3" @click="toggleCampaignBuilder" :disabled="processing">Create a Campaign</button>
                    </template>

                    <template v-if="!processing && campaigns && campaigns.length === 0 && !campaign.use_case && use_cases.length === 0">
                        <div class="alert alert-danger">
                           <i class="fa fa-exclamation-triangle"></i> No campaigns found. Click <em>Create a Campaign</em> to create one.
                        </div>
                    </template>

                    <template v-if="campaigns && campaigns.length > 0 && !campaign.use_case && use_cases.length === 0">

                    <template v-for="campaign in campaigns">

                        <h4>Campaign ID {{ campaign.campaignId }}</h4>
                        <p>Status <span class="badge badge-pill badge-success" :class="setCampaignClass(campaign.status)">{{ campaign.status }}</span></p>
                        <p>Use Case {{ campaign.usecase }} 
                            <template v-if="campaign.subUsecases && campaign.subUsecases.length > 0">
                                <template v-for="subUsecase in campaign.subUsecases">
                                    <span class="badge badge-pill badge-secondary">{{ subUsecase }}</span>
                                </template>
                            </template>
                        </p>
                        <p>Description {{ campaign.description }}</p>

                        <h5>Gateway Status</h5>

                        <template v-if="campaign.gateway">
                            <template v-if="campaign.gateway.sharingStatus">
                                <p>Status: <span class="badge badge-pill" :class="setGatewayStatus(campaign.gateway.sharingStatus)">{{ campaign.gateway.sharingStatus }}</span></p>
                            </template>
                            <template v-if="campaign.gateway.explanation">
                                <div class="alert alert-danger">
                                    {{ campaign.gateway.explanation }}
                                </div>
                            </template>
                        </template>

                        <template v-if="campaign.carriers && campaign.carriers.length > 0">
                            <ul class="list-group">
                            <template v-for="carrier in campaign.carriers">
                                <li class="list-group-item">
                                    <template v-if="carrier.mno">
                                        <img :src="setCarrierLogo(carrier.mno)" :alt="carrier.mno + ' logo'" width="100" class="img-fluid">
                                    </template>
                                    <template v-if="carrier.msgClass">
                                        <span class="badge badge-pill badge-secondary">Message Class: {{ carrier.msgClass }}</span>
                                    </template>
                                    <template v-if="carrier.brandTier">
                                        <span class="badge badge-pill badge-secondary">Brand Tier: {{ carrier.brandTier }}</span>
                                    </template>
                                    <template v-if="carrier.qualify">
                                        <span class="badge badge-pill badge-success float-end">Qualified</span>
                                    </template>
                                    <template v-else>
                                        <span class="badge badge-pill badge-danger float-end">Not Qualified</span>
                                    </template>
                                    <template v-if="carrier.tpm">
                                        <span class="badge badge-pill badge-secondary float-end">Messages/Min: {{ carrier.tpm }}</span>
                                    </template>
                                    <template v-if="carrier.brandDailyCap">
                                        <span class="badge badge-pill badge-secondary float-end">Daily Cap: {{ carrier.brandDailyCap }}</span>
                                    </template>
                                </li>
                            </template>
                            </ul>

                            <p class="text-muted small">Created: {{ campaign.createDate }}</p>
                        </template>

                    </template>

                    </template>

                    </template>

                    <template v-if="!brand || !brand.referenceId">

                    <p class="lead">Please fill out the form below to register with U.S. telecom carriers.</p>

                    <p>Carrier registration is required to send and receive texts and calls through the platform.</p>

                    <template v-if="brand && !brand.brandId">

                    <div class="form-group">
                        <label for="tax_id">Employer Identification Number (EIN) *</label>
                        <input id="tax_id" type="text" class="form-control" v-model="form.tax_id" required>
                        <p class="help-block">Enter the EIN associated with your entity name without dashes.</p>
                    </div>

                    <button class="btn btn-primary mb-3" @click="lookupEINs" :disabled="processing || !form.tax_id">Lookup EIN</button>

                    <template v-if="eins && eins.length > 0">
                        <ul class="list-group">
                            <template v-for="ein in eins">
                                <li class="list-group-item">
                                    <strong>Name:</strong> {{ ein.companyName }}

                                    <button type="button" class="btn btn-success btn-sm float-end" @click="chooseEntity(ein)" :disabled="processing">Select Entity</button>
                                </li>
                            </template>
                        </ul>
                    </template>

                    </template>


                    <form @submit.prevent="postForm">

                    <template v-if="form.tax_id">

                    <div class="form-group mt-3">
                        <label for="name">Entity Name *</label>
                        <input id="name" type="text" class="form-control" v-model="form.name" required>
                        <p class="help-block">Must be the same name associated with your EIN.</p>
                    </div>
                    
                    <div class="form-group">
                        <label for="website">Website</label>
                        <input id="website" type="url" class="form-control" v-model="form.website">
                    </div>

                    <div class="form-group">
                        <label for="address_1">Address *</label>
                        <input id="address_1" type="text" class="form-control" v-model="form.address_1" required>
                    </div>

                    <div class="form-group">
                        <label for="address_2">Address 2</label>
                        <input id="address_2" type="text" class="form-control" v-model="form.address_2">
                    </div>

                    <div class="form-group">
                        <label for="city">City *</label>
                        <input id="city" type="text" class="form-control" v-model="form.city" required>
                    </div>

                    <div class="form-group">
                        <label for="state">State *</label>
                        <select
                            id="state"
                            v-model="form.state"
                            class="form-control"
                            :disabled="processing"
                            required
                        >
                            <template v-if="states && states.length > 0">
                                <template v-for="state in states">
                                    <option
                                        :value="state.key"
                                    >
                                        {{ state.label }}
                                    </option>
                                </template>
                            </template>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="zip">Zip *</label>
                        <input id="zip" type="text" class="form-control" v-model="form.zip" required>
                    </div>

                    <div class="form-group">
                        <label for="first_name">Contact First Name *</label>
                        <input id="first_name" type="text" class="form-control" v-model="form.first_name" required>
                    </div>

                    <div class="form-group">
                        <label for="last_name">Contact Last Name *</label>
                        <input id="last_name" type="text" class="form-control" v-model="form.last_name" required>
                    </div>

                    <div class="form-group">
                        <label for="email">Contact Email *</label>
                        <input id="email" type="email" class="form-control" v-model="form.email" required>
                    </div>

                    <div class="form-group">
                        <label for="phone">Contact Phone *</label>
                        <input id="phone" type="text" class="form-control" v-model="form.phone" required>
                    </div>

                    <button type="submit" class="btn btn-success" :disabled="processing">
                        <template v-if="processing">
                            <i class="fa fa-spinner fa-spin"></i> Processing
                        </template>
                        <template v-else>
                            Register Brand
                        </template>
                    </button>

                    </template>


                    </form>

                    </template>

                    <template v-if="builder && !campaign.use_case">

                        <h4 class="mt-3">Choose a Use Case</h4>
                        <p class="lead">Create a campaign to send texts and calls to your clients.</p>


                        <ul class="list-group">
                            <template v-for="(use_case, use_case_key) in use_cases">
                                <li class="list-group-item">
                                    <h5>{{ use_case.displayName }}</h5>
                                    <span class="badge badge-primary bg-primary">{{ use_case.classification }}</span>
                                    <p>{{ use_case.description }}</p>
                                    <button class="btn btn-success" @click="chooseUseCase(use_case_key)" :disabled="processing">Choose Use Case</button>
                                </li>
                            </template>
                        </ul>

                    </template>



                    <template v-if="campaign.use_case">
                        <h4 class="mt-3">Register Campaign</h4>

                        <p>Use Case: {{ campaign.use_case }}</p>

                        <button class="btn btn-success mb-3" @click="autoFillForm" :disabled="processing"><i class="fa fa-check"></i> Auto Fill Form</button>

                        <form @submit.prevent="postCampaign">
                            <template v-for="(field, field_key) in campaignFields">

                                <template v-if="!isMessageField(field_key)">
                                <div class="form-group">
                                    <label for="field_key" class="text-capitalize">{{ field_key | rmDashes }}</label>
                                    <input id="field_key" :type="setFieldType(field_key)" class="form-control" :class="setValidateClass(field_key)" v-model="campaign[field_key]" required>
                                </div>
                                </template>

                                <template v-else>
                                    <div class="form-group">
                                        <label for="field_key" class="text-capitalize">{{ field_key | rmDashes }}</label>
                                        <textarea id="field_key" class="form-control" :class="setValidateClass(field_key)" v-model="campaign[field_key]" rows="5" required></textarea>
                                    </div>
                                </template>

                            </template>

                            <button type="submit" class="btn btn-success" :disabled="processing">
                                <template v-if="processing">
                                    <i class="fa fa-spinner fa-spin"></i> Processing
                                </template>
                                <template v-else>
                                    Register Campaign
                                </template>
                            </button>
                        </form>
                    </template>

    
                </div><!--Body-->
            </div><!--Card-->
    
            </div><!--Col-->
        </div><!--Row-->

    </div>
    </template>
    <script>    
    export default {
    
        data() {
            return {
              processing: false,
              builder: false,
              form: {},
              customer: {},
              brand: {},
              campaigns: [],
              states: [],
              use_cases: [],
              campaign: {
                use_case: ''
              },
              campaignFields: {},
              eins: []
            }
        },
        


        mounted() {


        var self = this;

        this.$eventBus.$on('errors', function (errors) {
            self.errors = errors.data || errors;
            self.processing = false;
        });

        },


        created () {
            this.resetForm();
            this.getBrand();
            this.getStates();
        },
    
        methods: {

        setStatusClass(status) {
            return ['VERIFIED', 'VETTED_VERIFIED'].includes(status) ? 'badge-success' : 'badge-danger';
        },

        setCampaignClass(status) {
            return status == 'ACTIVE' ? 'badge-success' : 'badge-danger';
        },

        setFieldType(field) {
            let urls = ['embedded_link', 'privacy_policy', 'terms_conditions'];
            return urls.includes(field) ? 'url' : 'text';
        },

        setGatewayStatus(status) {
            return status == 'ACCEPTED' ? 'badge-success' : 'badge-danger';
        },

        setCarrierLogo(carrier) {

            carrier = carrier.toLowerCase();

            //replace & with and
            carrier = carrier.replace('&', '');

            //replace - with space
            carrier = carrier.replace('-', '');

            //replace spaces with underscore
            carrier = carrier.replace(' ', '');

            return 'https://assets.ecourtdate.com/images/carriers/'+carrier+'.webp';
        },

        resetForm() {
            this.form = {
                name: '',
                tax_id: '',
                address_1: '',
                address_2: '',
                city: '',
                state: '',
                zip: '',
                first_name: '',
                last_name: '',
                email: '',
                phone: ''
            };
        },
    
          getBrand() {
            this.customer = {};
            this.processing = true;
            this.$http.get('/brands')
                .then(response => {
                  if(response && response.data) {
                    this.brand = response.data;
                    this.processing = false;

                    if(this.brand && this.brand.referenceId) {
                      this.getCampaigns();
                    }
                  }
                })
          },

          getCustomer() {
            this.customer = {};
            this.processing = true;
            this.$http.get('/agencies/customer')
                .then(response => {
                  if(response && response.data) {
                    this.customer = response.data;
                    this.processing = false;

                    var fields = ['name', 'tax_id', 'address_1', 'address_2', 'city', 'state', 'zip', 'website'];

                    fields.forEach(field => {
                      if(this.customer[field] && this.customer[field] !== '') {
                        this.form[field] = response.data[field];
                      }
                    });
                  }
                })
          },

          getCampaigns() {
            this.processing = true;
            this.$http.get('/campaigns')
                .then(response => {
                  if(response && response.data) {
                    this.campaigns = response.data;
                    this.processing = false;
                  }
                })
          },

          toggleCampaignBuilder() {
            this.builder = !this.builder;

            if(this.builder && !this.use_cases.length) {
              this.getUseCases();
            }

            if(!this.builder) {
              this.campaign = {
                use_case: ''
              };
              this.use_cases = [];
            }
          },

          chooseUseCase(use_case) {
            this.campaign.use_case = use_case;

            this.getCampaignFields();

            this.autoFillForm();
          },

          isMessageField(field_key) {
            return field_key.includes('message') || field_key.includes('sample') || field_key.includes('description');
          },

          getCampaignFields() {
            this.$axios.get(this.$root.assets_url+'js/tcr-fields.json')
                .then(response => {
                  if(response && response.data) {
                    this.campaignFields = response.data;

                    this.campaign = {...this.campaign, ...this.campaignFields};
                  }
                })
          },

          postCampaign() {

            if(!this.brand.brandId) {
                this.$eventBus.$emit('show-alert', {
                    text: 'Please register your brand first.'
                });
                return;
            }

            this.campaign.brand = this.brand.brandId;

            this.processing = true;
            this.$http.post('/campaigns', this.campaign)
                .then(response => {
                  if(response && response.data) {
                    this.campaign = response.data;
                    this.processing = false;

                    if(response.status === 201) {
                        this.$eventBus.$emit('show-alert', {
                            text: 'Campaign created successfully.'
                        });

                        this.getCampaigns();
                        this.use_cases = [];
                        this.toggleCampaignBuilder();
                    }
                  }
                })
          },

          getUseCases() {
            this.$http.get('/campaigns/use_cases')
                .then(response => {
                  if(response && response.data) {
                    this.use_cases = response.data;
                    this.filterUseCases();
                  }
                })
          },

          filterUseCases() {
            let allowed_use_cases = ['2FA', 'ACCOUNT_NOTIFICATION', 'LOW_VOLUME', 'MIXED', 'EMERGENCY', 'UCAAS_HIGH', 'UCAAS_LOW'];
            
            this.use_cases = Object.fromEntries(
                Object.entries(this.use_cases)
                    .filter(([key]) => allowed_use_cases.includes(key))
            );
          },

          getStates() {
            this.$axios.get(this.$root.assets_url+'js/states.json')
            .then(response => {
                if(response && response.data) {
                    this.states = response.data;
                }
            })
        },

        postForm() {
            this.processing = true;

            this.$http.post('/brands', this.form)
                .then(response => {
                  if(response && response.data) {
                    this.brand = response.data;
                    this.processing = false;
                  }
                }
            )},


            autoFillForm() {
                this.getPortal();
            },

            getPortal() {
                this.processing = true;
                this.$http.get('/portals?sort=default&sortDirection=desc&limit=1&fields=link,domain,alias')
                    .then(response => {
                        if(response && response.data) {

                            this.getAutoMessages();

                            var portal = response.data[0] || {};

                            if(!portal.domain) {
                                this.$eventBus.$emit('show-alert', {
                                    text: 'No portal found. Click Admin > Portals to create one or use your own domain.',
                                    type: 'danger'
                                });
                                return;
                            }

                            let link = 'https://'+portal.link+'.'+portal.domain;

                            this.campaign.embedded_link = link;
                            this.campaign.privacy_policy = link+'/privacy';
                            this.campaign.terms_conditions = link+'/terms';


                        }
                    })
            },

            replaceMergeTags() {
                Object.keys(this.campaign).forEach(key => {
                    if(this.campaign[key] && typeof this.campaign[key] === 'string') {
                        this.campaign[key] = this.campaign[key].replace(/\[AgencyName\]/g, this.brand.companyName);

                        if(this.campaign.embedded_link) {
                            this.campaign[key] = this.campaign[key].replace(/\[AgencyWebsite\]/g, this.campaign.embedded_link);
                        }
                    }
                });
            },

            getAutoMessages() {
                this.$http.get('/auto_messages?fields=templates')
                    .then(response => {
                        if (response && response.data) {
                            var auto_messages = response.data;
                            this.processing = false;

                            this.replaceMergeTags();

                            if (!auto_messages.length) {
                                this.$eventBus.$emit('show-alert', {
                                    text: 'No auto messages found. Click Admin > Message Templates to create some or manually fill out the message fields.',
                                    type: 'danger'
                                });
                                return;
                            }

                            auto_messages.forEach((message, index) => {
                                if (!message.templates || !message.templates['en']) {
                                    return;
                                }

                                if (index < 4) {
                                    var field = 'sample_';
                                    this.campaign[field + (index + 1)] = message.templates['en'];
                                }
                            });
                        }
                    });
            },

            chooseEntity(result) {
                this.form.name = result.companyName;
                this.form.city = result.city;
                this.form.state = result.state;
                this.form.zip = result.zipCode;
            },


            lookupEINs() {

                if(!this.form.tax_id) {
                    this.$eventBus.$emit('show-alert', {
                        text: 'Please enter an EIN.',
                        type: 'danger'
                    });
                    return;
                }

                //validate regex
                if(!/^[0-9]{9}$/.test(this.form.tax_id)) {
                    this.$eventBus.$emit('show-alert', {
                        text: 'Please enter a valid EIN.',
                        type: 'danger'
                    });
                    return;
                }

                this.processing = true;

                this.$http.get('/agencies/eins?ein='+this.form.tax_id)
                    .then(response => {
                        if(response && response.data) {
                            this.eins = response.data;
                            this.processing = false;
                        }
                    })
            },

            setValidateClass(key) {

                if(!key) {
                    return;
                }

                if(!this.campaign[key]) {
                    return 'is-invalid';
                }

                if(['optin_message', 'optout_message'].includes(key)) {

                    //check if the message contains the brand name
                    if(!this.campaign[key].includes(this.brand.companyName)) {
                        return 'is-invalid';
                    }

                }

                if(['optin_message'].includes(key)) {

                    if(!this.campaign[key].includes('Reply HELP for help')) {
                        return 'is-invalid';
                    }
                }
                
                return 'is-valid';

            },

        
        }
    }
    </script>