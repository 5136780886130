<template>
    <div>
  
      <page-tabs page="messages" />
  
      <div class="row">
  
        <div class="col-sm-12 col-md-3">

          <div class="card">
            <div class="card-body">

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <p class="card-title">Search</p>
                </div><!--Col-->
                <div class="col-sm-12 col-md-6 text-end">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="resetSearch()"
                    :disabled="processing"
                  >
                    Reset
                  </button>
                </div><!--Col-->
              </div><!--Row-->
    
              <form @submit.prevent="searchRecords()">
  
                <div class="form-group">
                  <label for="search">Search</label>
                  <input id="search" type="search" class="form-control" v-model="queries.search">
                </div>
  
                <div class="form-group">
                  <label for="url">URL</label>
                  <input id="url" type="text" class="form-control" v-model="queries.url">
                </div>
  
                <div class="form-group">
                  <label for="ip_address">IP Address</label>
                  <input id="ip_address" type="text" class="form-control" v-model="queries.ip_address">
                </div>

                <div class="form-group">
                    <label for="latitude">Latitude</label>
                    <input id="latitude" type="text" class="form-control" v-model="queries.latitude">
                </div>

                <div class="form-group">
                    <label for="longitude">Longitude</label>
                    <input id="longitude" type="text" class="form-control" v-model="queries.longitude">
                </div>

                <div class="form-group">
                    <label for="user_agent">User Agent</label>
                    <input id="user_agent" type="text" class="form-control" v-model="queries.user_agent">
                </div>
  
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-check">
                      <input
                        id="archived"
                        v-model="queries.archived"
                        type="checkbox"
                        class="form-check-input"
                        @change="searchRecords"
                        :disabled="processing"
                      >
                      <label
                        class="form-check-label"
                        for="archived"
                      >Archived</label>
                    </div>
                  </div><!--Col-->
  
                  <div class="col-sm-12 col-md-6">
                    <div class="form-check">
                      <input
                        id="trashed"
                        v-model="queries.trashed"
                        type="checkbox"
                        class="form-check-input"
                        @change="searchRecords"
                        :disabled="processing"
                      >
                      <label
                        class="form-check-label"
                        for="trashed"
                      >Trashed</label>
                    </div>
                  </div><!--Col-->
                </div><!--Row-->
  
                <button type="submit" class="btn btn-primary" :disabled="processing">Search</button>
  
              </form>
  
            </div><!--Body-->
          </div><!--Card-->

          <div class="card my-3">
            <div class="card-body">

              <p class="card-title">Geolocate IP Address</p>

              <form @submit.prevent="geolocateIP()">
                
                <div class="form-group">
                  <label for="geo_ip">IP Address</label>
                  <input id="geo_ip" type="text" class="form-control" v-model="geo_ip" required>
                </div>

                <button type="submit" class="btn btn-primary" :disabled="processing">Geolocate</button>

              </form>


            </div><!--Body-->
          </div><!--Card-->
  
  
        </div><!--Col-->
  
        <div id="main" class="col-sm-12 col-md-9">
  
          <div
              id="map"
              class="map-xl mb-3"
          />
  
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                    <p class="card-title">
                      {{ records.length }} of {{ total.toLocaleString() }} {{ $route.meta.title }}s
                    </p>

                </div><!--Col-->
  
                <div class="col-sm-12 col-md-6 text-end">
                  <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                  </div>
                </div><!--Col-->
              </div><!--Row-->
  
              <div class="table-responsive my-3">
                <table class="table table-striped table-hover">
                  <thead>
                    <template v-for="column in columns">
                      <th>
                        <template v-if="column.key">
                          <span
                            class="text-capitalize link-hover"
                            @click="sortColumn(column)"
                          >{{ column.label }}</span>
                        </template>
  
                        <template v-if="!column.key">
                          <span class="text-capitalize">{{ column.label }}</span>
                        </template>
  
                        <template v-if="column.key == queries.sort">
                          <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm ml-1"
                            :disabled="processing"
                            @click="updateSortDirection()"
                          >
                            <template v-if="queries.sortDirection == 'asc'">
                              <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                            </template>
  
                            <template v-if="queries.sortDirection == 'desc'">
                              <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                            </template>
                          </button>
                        </template>
                      </th>
                    </template>
                  </thead>
                  <tbody>
                    <template v-for="record in records">
                      <tr>
                        <td>
                          <a :href="'/'+$route.meta.base_url+'/'+record.uuid"><i class="fa fa-edit"></i> Edit</a>
                        </td>
                        <td>
                            {{ record.url }}
                        </td>
                        <td>{{ record.country }}</td>
                        <td>{{  record.ip_address }}</td>
                        <td>
                            <template v-if="record.latitude && record.longitude">
                                {{ record.latitude }} {{  record.longitude }}
                            </template>

                            <template v-if="record.error && record.error.message">
                              <span class="badge badge-pill badge-danger text-uppercase">
                                {{ record.error.message }}
                              </span>
                            </template>
                        </td>
                        <td>
                            <template v-if="record.network">
                                <template v-if="record.network.type">
                                Type {{ record.network.type }}
                                </template>

                                <template v-if="record.network.downLink">
                                    Downlink {{ record.network.downLink }}mbs
                                </template>

                                <template v-if=record.network.effectiveType>
                                    Effective Type {{ record.network.effectiveType }}
                                </template>

                                <template v-if="record.network.rtt">
                                    Round Trip Time {{ record.network.rtt }}ms
                                </template>

                                <template v-if="record.network.saveData">
                                    Low Data Mode {{ record.network.saveData }}
                                </template>

                            </template>
                        </td>
                        <td>
                            <template v-if="record.device">
                                <template v-if="record.device.language">
                                  Language  {{ record.device.language }}
                                </template>
                                <template v-if="record.device.timezone">
                                    Time Zone {{ record.device.timezone }}
                                </template>
                                <template v-if="record.device.platform">
                                    Platform {{ record.device.platform }}
                                </template>
                                <template v-if="record.device.screen && record.device.screen.width">
                                    Size {{ setDeviceSize(record.device.screen.width) }}
                                </template>
                            </template>
                        </td>
                        <td>{{ record.created_at | datetime }}
                          <template v-if="record.deleted_at">
                          <span class="badge badge-pill badge-danger">
                            Trashed
                          </span>
                          </template>
                          <template v-if="record.archived_at">
                            <span class="badge badge-pill badge-warning">
                              Archived
                            </span>
                          </template>
                        </td>
                        <td>
                          <template v-if="record.updated_at">
                            {{ record.updated_at | datetime }}
                          </template>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div><!--Responsive-->
  
              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No {{ $route.meta.title }}s found.
                </div>
              </template>
  
            </div><!--Body-->
          </div><!--Card-->
  
          <template v-if="records && records.length && total > records.length">
            <div class="row mt-3">
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords()"
                >
                  Load more
                </button>
              </div>
            </div><!--Row-->
          </template>
  
        </div><!--Col-->
  
      </div><!--Row-->
    
    </div>
  </template>
  <script>
  import queries from '../mixins/queries';
  
  export default {
  
      mixins: [ queries ],
  
      data() {
          return {
              records: [],
              processing: true,
              total: 0,
              queries: {},
              columns: [
              { label: 'Edit'},
              { key: 'url', label: 'URL'},
              { key: 'country', label: 'Country'},
              { key: 'ip_address', label: 'IP Address'},
              { label: 'Geo Data'},
              { label: 'Network'},
              { label: 'Device'},
              { key: 'created_at', label: 'Created'},
              { key: 'updated_at', label: 'Updated'}
              ],
              map: {},
              markers: {},
              geo_ip: null
          }
      },
  
      mounted() {
          this.initMap();

          let self = this;

          this.$eventBus.$on(this.$route.meta.base_url, function(data) {
           self.pushRecord(data);
          });
      },
  
      created() {
        this.resetRecords();
        this.resetQueries();
        this.setURLParams();
        this.getRecords();
      },
  
      methods: {
  
          setURLParams() {
              var searchParams = new URLSearchParams(window.location.search);
  
              var self = this;
  
              searchParams.forEach(function(value, key) {
                  self.queries[key] = value;
              });
  
          },

  
          resetQueries() {
            this.queries = {
                limit: 100,
                skip: 0,
                sort: 'created_at',
                sortDirection: 'desc',
                search: null,
                ip_address: null,
                url: null,
                latitude: null,
                longitude: null,
                user_agent: null
            }
          },

          resetSearch() {
            this.resetQueries();
            this.searchRecords();
          },
    
          getTotal() {
              this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
              .then(response => {
                  if(response && response.status) {
                      this.total = response.data;
                      this.setTitle();
                  }
              })
          },
  
          updateURL() {
            history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
          },
  
          setTitle() {
            document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
          },
  
          getRecords() {
  
              this.processing = true;
  
              this.updateURL();
              this.getTotal();
  
              this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
              .then(response => {
                  if(response && response.data) {
                      if(response.data.length) {
  
                        if(!this.records || !this.records.length) {
                          this.records = [];
                        }
  
                        var self = this;
  
                          response.data.forEach(function(data) {

                            data = self.mapRecord(data);

                              self.records.push(data);
                              self.setMarker(data);
                          });
                      }
  
                      this.queries.skip = this.records.length;
                      this.processing = false;
                  }
              })
          },

          pushRecord(data) {

          if(!data || !data.uuid) {
            return;
          }

          if(!this.records || !this.records.length) {
            this.records = [];
          }

          data = this.mapRecord(data);

          let index = _.findIndex(this.records, {'uuid': data.uuid});

          if(index != -1) {
            this.records[index] = data;
            return;
          }


          this.total++;
          this.records.unshift(data);

          this.setTitle();
          },

          mapRecord(record) {

            if(!record.geo_data || !record.geo_data.latitude) {
                return record;
            }

            if(record.geo_data.latitude) {
                record.latitude = record.geo_data.latitude;
            }

            if(record.geo_data.longitude) {
                record.longitude = record.geo_data.longitude;
            }

            if(record.geo_data.accuracy) {
                record.accuracy = record.geo_data.accuracy;
            }

            return record;
          },
  
          resetRecords() {
            this.records = [];
            this.queries.skip = 0;
            this.total = 0;
          },
  
          searchRecords() {
              this.resetRecords();
              this.getRecords();
          },
  
          updateSortDirection() {
  
            if(this.queries.sortDirection == 'asc') {
              this.queries.sortDirection = 'desc';
            } else {
              this.queries.sortDirection = 'asc';
            }
  
            this.resetRecords();
            this.getRecords();
          },
  
          sortColumn(column) {
  
            this.queries.sort = column.key;
  
            this.updateSortDirection();
          },
  
        setMarker(record) {
  
            if(!this.markers) {
              return;
            }

          if(!record.latitude || !record.longitude) {
            return;
          }
  
          var popup = '<p class="m-0"><a href='+'/'+this.$route.meta.base_url+'/'+record.uuid+'>Open Geolocation</a></p>';

          const geoIcon = window.L.divIcon({
            html: '<i class="fa fa-globe text-primary fa-2x"></i>',
            iconSize: [20, 20],
            className: 'map-icon'
            });
  
          var marker = window.L.marker([record.latitude, record.longitude], {icon: geoIcon}).bindPopup(popup);
  
          this.markers.addLayer(marker);
  
        },
  
        initMap() {
  
          this.map = window.L.map("map").setView(this.$root.coordinates, 4);
  
          window.L.tileLayer(this.$root.map_layer).addTo(this.map);
  
          this.markers = window.L.markerClusterGroup({
            spiderfyOnMaxZoom: true,
            showCoverageOnHover: true,
            zoomToBoundsOnClick: true
          });
  
          this.map.addLayer(this.markers);
  
        },

        setDeviceSize(value) {
          if (value <= 640) {
            return "Small Phone";
          } else if (value <= 768) {
            return "Large Phone";
          } else if (value <= 1024) {
            return "Tablet";
          } else if (value <= 1440) {
            return "Small Monitor";
          }
        },

        geolocateIP() {

          if(!this.geo_ip) {
            return;
          }          

          window.location.href = '/ips/'+this.geo_ip;
        }
  
      }
  }
  
  </script>