<template>
  <div>
    <template v-if="$route.meta.requiresAuth">
      <div class="row">
        <div class="col-sm-6 mt-3 mx-auto text-center mb-2">
          <template v-if="$root.current_user && $root.current_user.current_agency">
            <template v-if="$root.current_user.current_agency.settings && $root.current_user.current_agency.settings.footer_text">
              <p class="text-center text-muted mt-3">
                {{ footer_text }}
              </p>
            </template>
            <a
              class="badge badge-pill text-uppercase d-print-none"
              :class="sendModeClass($root.current_user.current_agency.send_mode)"
              href="/settings?search=send"
            >{{ sendMode }} Mode</a>

            <p
              class="m-0"
              :class="{ 'd-none': isHidden('about_agency') }"
            >
              <a
                data-bs-target="#agencyModal"
                data-bs-toggle="modal"
                href="#"
                class="text-decoration-none fw-bold"
              >{{ $root.current_user.current_agency.name }}</a>
            </p>

            <template v-if="$root.current_region && $root.current_region == 'staging'">
              <p class="text-center text-muted mt-3">
                This is a staging environment. All outbound messages are simulated.
              </p>
            </template>

          <ul class="nav justify-content-center d-print-none">

            <li class="nav-item">
              <a class="nav-link" href="/help">Get Help</a>
            </li>

            <li class="nav-item">
              <router-link tag="a" class="nav-link" :to="{name: 'setup.index'}">Agency Setup</router-link>
            </li>

            <template v-if="$root.current_user && $root.current_user.super_admin === 1">

            <template v-if="$root.current_user.current_agency && $root.current_user.current_agency.uuid">
            <li class="nav-item">
              <a class="nav-link" :href="'https://console.ecourtdate.com/agencies/'+$root.current_user.current_agency.uuid" target="_blank">Console</a>
            </li>
            </template>

            </template>

          </ul>

          </template>
        </div>
      </div>


      <template v-if="$root.current_user.current_agency">
        <div
          id="agencyModal"
          class="modal fade"
          tabindex="-1"
          role="dialog"
        >
          <div
            class="modal-dialog modal-xl modal-dialog-scrollable"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <p class="lead modal-title">
                  About This Agency
                </p>

                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                </button>
              </div>

              <div class="modal-body">
                <ul class="list-group mb-3">
                  <template v-if="$root.current_user.current_agency.name">
                    <li class="list-group-item">
                      Name: <span
                        class="link-hover"
                        @click="copyToClipboard($root.current_user.current_agency.name)"
                      >{{ $root.current_user.current_agency.name }}</span> 

                      <a
                        :href="'https://console.ecourtdate.com/agencies/'+$root.current_user.current_agency.uuid"
                        class="btn btn-outline-secondary btn-sm ml-1"
                        target="_blank"><i class="fa fa-link"></i> <span class="sr-only">Open Console</span>
                      </a>
                      <button type="button" class="btn btn-outline-secondary btn-sm" @click="copyToClipboard($root.current_user.current_agency.name)" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>

                    </li>
                  </template>

                  <template v-if="$root.current_user.current_agency.reference">
                    <li
                      class="list-group-item link-hover"
                      @click="copyToClipboard($root.current_user.current_agency.reference)"
                    >
                      Reference: {{ $root.current_user.current_agency.reference }}
                    </li>
                  </template>

                  <template v-if="$root.current_user.current_agency.uuid">
                    <li
                      class="list-group-item link-hover"
                      @click="copyToClipboard($root.current_user.current_agency.uuid)"
                    >
                      UUID: {{ $root.current_user.current_agency.uuid }}
                    </li>
                  </template>

                  <template v-if="$root.current_user.current_agency.customer">
                    <li class="list-group-item">
                      Billing: {{ $root.current_user.current_agency.customer }} 
                      
                      <a
                        :href="'https://billing.ecourtdate.com/dashboard?customer='+$root.current_user.current_agency.customer"
                        class="btn btn-outline-secondary btn-sm ml-1"
                        target="_blank"><i class="fa fa-link"></i> <span class="sr-only">Open Billing</span>
                      </a>

                      <button type="button" class="btn btn-outline-secondary btn-sm" @click="copyToClipboard($root.current_user.current_agency.customer)" :disabled="processing"><i class="fa fa-copy"></i> <span class="sr-only">Copy</span></button>

                    </li>
                  </template>

                  <template v-if="$root.current_user.current_agency.region">
                    <li class="list-group-item">
                      Region: {{ $root.current_user.current_agency.region }}
                    </li>
                  </template>

                  <template v-if="$root.current_user.current_agency.created_at">
                    <li class="list-group-item">
                      Created: {{ $root.current_user.current_agency.created_at | datetime }} - {{ $root.current_user.current_agency.created_at | dateTimezone | timeago }}
                    </li>
                  </template>
                </ul>

              </div>

            </div>
          </div>
        </div><!--Modal-->


      </template>




    </template>
  </div>
</template>
<script>
export default {

    data() {
        return {
            processing: false
        }
    },

    computed: {

        sendMode: function () {
            if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.send_mode) {
                return this.$root.current_user.current_agency.send_mode;
            }

            return '';
        },

        footer_text: function () {
            if (this.$root && this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.footer_text) {
                return this.$root.current_user.current_agency.footer_text
            }
            return null;
        }
    },

    mounted() {

      var self = this;

      this.$eventBus.$on('settings', function (data) {

        if(data.send_mode) {
          self.$root.current_user.current_agency.send_mode = data.send_mode;
        }

      });

    },

    methods: {

        isHidden(navigation) {

            if(!this.$root.current_user || !this.$root.current_user.uuid) {
                return true;
            }

            if(this.$root.current_user.hidden_navigations && this.$root.current_user.hidden_navigations.length > 0) {
                return this.$root.current_user.hidden_navigations.includes(navigation);
            }

            return false;
        },

        sendModeClass(send_mode) {
            return send_mode === 'live' ? 'badge-success' : 'badge-primary';
        },

        copyToClipboard(value) {
            navigator.clipboard.writeText(value);
            this.$eventBus.$emit('show-alert', {
              text: 'Copied to clipboard'
            });
        },


    },

}
</script>
