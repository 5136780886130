<template>
    <div>
      <template v-if="show_training && $root.current_user && $root.current_user.current_agency && $root.current_user.current_agency.name && currentSuggestion()">
          <div class="my-3">
            <p class="m-0 outbound"><i class="fa fa-info-circle"></i> Training Tips: 
            <span v-if="currentSuggestion()">{{ currentSuggestion().description }}</span>
            </p>
            <a href="/training" class="alert-link" target="_blank"><i class="fa fa-list"></i> Click to View Training Progress</a>
          </div>
          
        </template>
    </div>
  </template>
  <script>
  export default {
  
      data() {
          return {
            show_training: false,
            suggestions: [
                {
                    url: 'clients',
                    title: 'Clients',
                    description: 'Use the ADD CLIENT form to create a new client. Once you create a client, you can make changes by clicking EDIT in the clients table.'
                },
                {
                    url: 'events',
                    title: 'Events',
                    description: 'Use the ADD EVENT form to create a new event. Once you create an event, you can make changes by clicking EDIT in the events table. Ensure that you assign the client to the event.'
                },
                {
                    url: 'payments',
                    title: 'Payments',
                    description: 'Use the ADD PAYMENT form to create a new payment. Once you create a payment, you can make changes by clicking EDIT in the payments table.'
                },
                {
                    url: 'cases',
                    title: 'Cases',
                    description: 'Use the ADD CASE form to create a new case. Once you create a case, you can make changes by clicking EDIT in the cases table.'
                },
                {
                    url: 'dashboard',
                    title: 'Dashboard',
                    description: 'Use the SEND A MESSAGE form to send a message to any phone number and/or email address.'
                },
                {
                    url: 'bulk_actions',
                    title: 'Bulk Actions',
                    description: 'Use the ADD BULK ACTION form to create a new bulk action. Once you create a bulk action, you can make changes by clicking EDIT in the bulk actions table.'
                },
                {
                    url: 'flows',
                    title: 'Flows',
                    description: 'Use the ADD FLOW form to create a new flow. Once you create a flow, you can add individual MESSAGES to the flow.'
                },
                {
                    url: 'auto_messages',
                    title: 'Auto Messages',
                    description: 'Use the ADD AUTO MESSAGE form to create a new auto message. Once you create an auto message, you can make changes by clicking EDIT in the auto messages table.'
                },
                {
                    url: 'sample_templates',
                    title: 'Sample Templates',
                    description: 'Click on the IMPORT button in the Auto Message or Flows Sample Templates page to import a sample template.'
                },
                {
                    url: 'locations',
                    title: 'Locations',
                    description: 'Use the ADD LOCATION form to create a new location. Once you create a location, you can make changes by clicking EDIT in the locations table.'
                }
            ]
          }
      },
  
      created() {
        this.setTrainingMode();
      },
  
      methods: {

  
        setTrainingMode() {
            
            //get from local storage
            var training_mode = localStorage.getItem('training_mode');

            if(training_mode && training_mode == 'enabled') {
                this.show_training = true;
            }
        },

        currentSuggestion() {
            return this.suggestions.find(suggestion => '/'+suggestion.url == this.$route.path);
        }


      }
  
  }
  </script>
  