<template>
  <div>

    <page-tabs :page="$route.meta.base_url" />

    <div class="row">
      <div class="col-sm-12 col-md-3 d-print-none">

        <client-search />

        <div class="card">
          <div class="card-body">


            <template v-if="actions && actions.events && actions.events.length > 0">
              <p class="text-success">
                {{ actions.events.length }} selected
              </p>

              <form @submit.prevent="postAction">

              <div class="form-group">
                <label for="action">Action</label>
                <select id="action" v-model="actions.action" class="form-control">
                  <option value="edit">Edit</option>
                  <option value="archive">Archive</option>
                </select>
              </div>

              <template v-if="actions.action == 'edit'">

              <div class="form-group">
                <label for="action_date">Date</label>
                <input id="action_date" v-model="actionsForm.date" type="date" class="form-control">
              </div>

              <div class="form-group">
                <label for="action_time">Time</label>
                <input id="action_time" v-model="actionsForm.time" type="time" class="form-control">
              </div>

              <div class="form-group">
                <label for="action_status">Status</label>
                <select id="action_status" v-model="actionsForm.status" class="form-control">
                  <option value="" />
                  <template v-for="status in field_options['statuses']">
                    <option :value="status.name">{{ status.name }}</option>
                  </template>
                </select>
              </div>

              </template>

              <button type="submit" class="btn btn-success" :disabled="processing || actions.processing">
                <template v-if="actions.action == 'edit'">Update</template>
                <template v-if="actions.action == 'archive'">Archive</template>
              </button>

              </form>


            </template>


            <div class="row" :hidden="actions && actions.events && actions.events.length > 0">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  Create {{ setCustomLabel(title ) }}
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 d-flex justify-content-end">

                
              <button
                class="btn btn-outline-secondary btn-sm m-0"
                type="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Fields
              </button>
              <div class="dropdown-menu">
                <p class="dropdown-header">
                  Fields
                </p>
                <a
                  v-for="availableField in availableFields"
                  class="dropdown-item text-capitalize" 
                  :class="activeField(availableField.key)"
                  @click="toggleField(availableField)"
                >
                  {{ setCustomField('events', availableField.key) }}
                </a>
              </div>

          </div><!--Col-->

            </div><!--Row-->

            <div :hidden="actions && actions.events && actions.events.length > 0">

            <form @submit.prevent="postForm">

              <div class="form-group">
                <label for="date" class="form-label">{{ setCustomField('events', 'date') }}</label>
                <input
                  id="date"
                  v-model="form.date"
                  type="date"
                  class="form-control"
                  required
                >
              </div>

              <template v-if="form.date">
                <div class="form-group">
                  <label for="time" class="form-label">{{ setCustomField('events', 'time') }}</label>
                  <input
                    id="time"
                    v-model="form.time"
                    type="time"
                    class="form-control"
                    required
                    step="60"
                  >
                </div>
              </template>

              <div class="form-group" v-if="shouldShowField('event_reference')">
                <label for="event_reference" class="form-label">{{ setCustomField('events', 'event_reference') }}</label>
                <input
                  id="event_reference"
                  v-model="form.event_reference"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group" v-if="shouldShowField('case_number')">
                <label for="case_number" class="form-label">{{ setCustomField('events', 'case_number') }}</label>
                <input
                  id="case_number"
                  v-model="form.case_number"
                  type="text"
                  class="form-control"
                >
              </div>

              <template v-if="field_options['statuses'] && field_options['statuses'].length > 0">
                <div class="form-group" v-if="shouldShowField('status')">
                  <label for="status" class="form-label">{{ setCustomField('events', 'status') }}</label>
                  <select
                    id="status"
                    v-model="form.status"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="" />
                    <template v-if="field_options['statuses'] && field_options['statuses'].length > 0">
                      <template v-for="status in field_options['statuses']">
                        <option :value="status.name">{{ status.name }}</option>
                      </template>
                    </template>
                  </select>
                </div>
              </template>

              <template v-if="field_options['types'] && field_options['types'].length > 0">
                <div class="form-group" v-if="shouldShowField('type')">
                  <label for="type" class="form-label">{{ setCustomField('events', 'type') }}</label>
                <select
                  id="type"
                    v-model="form.type"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option value="" />
                  <template v-if="field_options['types'] && field_options['types'].length > 0">
                    <template v-for="type in field_options['types']">
                      <option :value="type.type">{{ type.type }}</option>
                    </template>
                  </template>
                  </select>
                </div>
              </template>

              <div class="form-group" v-if="shouldShowField('description')">
                <label for="description" class="form-label">{{ setCustomField('events', 'description') }}</label>
                <input
                  id="description"
                  v-model="form.description"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group" v-if="shouldShowField('flow')">
                <label for="flow" class="form-label">{{ setCustomField('events', 'flow') }}</label>
                <select
                  id="flow"
                  v-model="form.flow"
                  class="form-control"
                >
                  <option value="" />
                  <template v-if="field_options['flows'] && field_options['flows'].length > 0">
                    <template v-for="flow in field_options['flows']">
                      <option :value="flow.uuid">{{ flow.name }}</option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group" v-if="shouldShowField('portal')">
                <label for="portal" class="form-label">{{ setCustomField('events', 'portal') }}</label>
                <select
                  id="portal"
                  v-model="form.portal"
                  class="form-control"
                >
                  <option value="" />
                  <template v-if="field_options['portals'] && field_options['portals'].length > 0">
                    <template v-for="portal in field_options['portals']">
                      <option :value="portal.uuid">{{ portal.name }}</option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group" v-if="shouldShowField('location')">
                <label for="location" class="form-label">{{ setCustomField('events', 'location') }}</label>
                <select
                  id="location"
                  v-model="form.location"
                  class="form-control"
                >
                  <option value="" />
                  <template v-if="field_options['locations'] && field_options['locations'].length > 0">
                    <template v-for="location in field_options['locations']">
                      <option :value="location.uuid">{{ location.name }}</option>
                    </template>
                  </template>
                </select>
              </div>
              
              <template v-if="locationRooms.length > 0">
              <div class="form-group">
                <label for="room" class="form-label">{{ setCustomField('events', 'room') }}</label>
                <select id="room" v-model="form.room" class="form-control" :disabled="processing" @change="setLocationRoom()">
                <option value="" />
              <template v-for="room in locationRooms">
                <option :value="room.uuid">{{ room.name }}</option>
                      </template>
                    </select>
                  </div>
                </template>

              <template v-if="form.location">
              <div class="form-group" v-if="shouldShowField('location_notes')">
                <label for="location_notes" class="form-label">{{ setCustomField('events', 'location_notes') }}</label>
                <input
                  id="location_notes"
                  v-model="form.location_notes"
                  type="text"
                  class="form-control"
                >
                </div>
              </template>

              <template v-if="judges && judges.length > 0">
                <div class="form-group" v-if="shouldShowField('judge_name')">
                  <label for="judge_name" class="form-label">{{ setCustomField('events', 'judge_name') }}</label>
                  <select
                    id="judge_name"
                    v-model="form.judge_name"
                    class="form-control"
                  >
                    <option value="" />
                    <template v-for="judge in judges">
                      <option :value="judge.name">{{ judge.name }}</option>
                    </template>
                  </select>
                </div>
              </template>

              <button type="submit" class="btn btn-success" :disabled="processing">Create</button>

            </form>
            </div>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">
                  Search {{ setCustomLabel(title) }}s
                </p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-end">
                <button
                  type="button"
                  data-tooltip="Reset search fields"
                  class="btn btn-outline-secondary"
                  :disabled="processing"
                  @click="resetSearch"
                >
                  <i class="fa fa-eraser" /> <span class="sr-only">Reset Form</span>
                </button>
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">SEARCH</label>
                <input
                  id="search"
                  v-model="queries.search"
                  type="search"
                  class="form-control"
                  placeholder="Reference"
                >
              </div>

              <div class="form-group">
                <label for="search_event_reference">{{ setCustomField('events', 'event_reference') }}</label>
                <input
                  id="search_event_reference"
                  v-model="queries.event_reference"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_case_number">{{ setCustomField('events', 'case_number') }}</label>
                <input
                  id="search_case_number"
                  v-model="queries.case_number"
                  type="text"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_type">{{ setCustomField('events', 'type') }}</label>
                <select
                  id="search_type"
                  v-model="queries.type"
                  class="form-control"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-if="event_types && event_types.length > 0">
                    <template v-for="event_type in event_types">
                      <option
                        :value="event_type.type"
                      >
                        {{ event_type.type }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_status">{{ setCustomField('events', 'status') }}</label>
                <select
                  id="search_status"
                  v-model="queries.status"
                  class="form-control"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-if="field_options['statuses'] && field_options['statuses'].length > 0">
                    <template v-for="search_status in field_options['statuses']">
                        <option
                          :value="search_status.name"
                        >
                          {{ search_status.name }}
                        </option>
                    </template>
                  </template>
                </select>
              </div>


              <div class="form-group">
                <label for="search_flow">{{ setCustomField('events', 'flow') }}</label>
                <select
                  id="search_flow"
                  v-model="queries.flow"
                  class="form-control"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                  <option value=""></option>
                  <option value="none">
                    No Flow
                  </option>
                  <template v-if="field_options['flows'] && field_options['flows'].length > 0">
                    <template v-for="flow in field_options['flows']">
                      <option
                        :value="flow.uuid"
                      >
                        {{ flow.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_location">{{ setCustomField('events', 'location') }}</label>
                <select
                  id="search_location"
                  v-model="queries.location"
                  class="form-control"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                  <option value=""></option>
                  <option value="none">
                    No Location
                  </option>
                  <template v-if="field_options['locations'] && field_options['locations'].length > 0">
                    <template v-for="location in field_options['locations']">
                      <option
                        :value="location.uuid"
                      >
                        {{ location.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_user">{{ setCustomField('events', 'user') }}</label>
                <select
                    id="search_user"
                    v-model="queries.user"
                    class="form-control"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-if="field_options['users'] && field_options['users'].length > 0">
                    <template v-for="user in field_options['users']">
                      <option
                          :value="user.uuid"
                      >
                        {{ user.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_judge">{{ setCustomField('events', 'judge_name') }}</label>
                <select
                  id="search_judge"
                  v-model="queries.judge"
                  class="form-control"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="judge in judges">
                    <option
                        :value="judge.name"
                    >
                      {{ judge.name }}
                    </option>
                  </template>
                </select>
              </div>


              <div class="form-group">
                <label for="search_from_date" class="form-label">From Date</label>
                <input
                  id="search_from_date"
                  v-model="queries.from_date"
                  type="date"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_to_date" class="form-label">To Date</label>
                <input
                  id="search_to_date"
                  v-model="queries.to_date"
                  type="date"
                  class="form-control"
                >
              </div>
              
              <div class="form-check">
                <input
                  id="search_virtual"
                  v-model="queries.virtual"
                  type="checkbox"
                  class="form-check-input"
                  :disabled="processing"
                >
                <label
                  class="form-check-label"
                  for="search_virtual"
                >Virtual</label>
              </div>

              <div class="form-check">
                <input
                  id="archived"
                  v-model="queries.archived"
                  type="checkbox"
                  class="form-check-input"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                <label
                  class="form-check-label"
                  for="archived"
                >Archived</label>
              </div>

              <div class="form-check">
                <input
                  id="trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="form-check-input"
                  @change="searchRecords()"
                  :disabled="processing"
                >
                <label
                  class="form-check-label"
                  for="trashed"
                >Trashed</label>
              </div>


              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">
 
              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 d-flex justify-content-end">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->



            <template v-if="actions && actions.events && actions.events.length > 0">

                <p class="text-success">
                  {{ actions.events.length }} selected
                </p>

                <div class="row">

                  <div class="col-sm-12 col-md-6">
                    <button type="button" class="btn btn-danger" @click="deselectAll()" :disabled="processing"><i class="fa fa-times"></i> Deselect All</button>
                    <template v-if="actions.events.length < this.total">
                      <button type="button" class="btn btn-primary" @click="selectAll()" :disabled="processing"><i class="fa fa-check"></i> Select All</button>                    
                    </template>
                  </div><!--Col-->

                </div>

                </template>


            <div class="table-responsive my-3">
            <table class="table table-hover table-striped">
              <thead>
                <tr>
                  <th class="d-print-none">
                   EDIT
                  </th>
                  <th @click="toggleSelect()" class="link-hover d-print-none">SELECT</th>
                  <template v-for="column in availableFields">
                    <th :hidden="!shouldShowField(column.key)">

                      <template v-if="column.key">
                        <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ setCustomField('events', column.key) }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.title }}</span>
                      </template>

                      <template v-if="column.key && column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1 d-print-none"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>

              <tbody>
                <template v-for="record in records">
                
                  
                  <tr>

                    <td class="d-print-none">
                      <a :href="'/'+$route.meta.base_url+'/'+record.uuid"><i class="fa fa-edit"></i> Edit</a>
                    </td>

                    <td class="d-print-none">
                          <label :for="'select_'+record.uuid" class="sr-only" aria-label="Select">Select</label>
                          <input
                            :id="'select_'+record.uuid"
                            type="checkbox"
                            :value="record.uuid"
                            v-model="actions.events"
                          />
                    </td>

                    <template v-for="field_row in availableFields">
                      
                      <td :hidden="!shouldShowField(field_row.key)">

                        <template v-if="field_row.type == 'date'">
                          
                          {{ record[field_row.key] | date }}
                        
                        </template>

                        <template v-if="field_row.type == 'time'">
                          
                          {{ record[field_row.key] | time }}

                        </template>

                        <template v-if="field_row.type == 'text'">
                            {{ record[field_row.key] }}
                        </template>

                        <template v-if="field_row.type == 'longtext'">
                          
                          <template v-if="record[field_row.key]">
                          {{ $options.filters.truncateText(record[field_row.key], 50) }}
                          </template>
                    
                        </template>

                        <template v-if="field_row.type == 'list'">
                          
                          <template v-if="record[field_row.key]">
                          <span class="badge bg-secondary">{{ setOptionName(record[field_row.key], field_row.key) }}</span>
                          </template>

                        </template>

                        <template v-if="field_row.type == 'checkbox'">
                          
                          <i v-if="record[field_row.key] == true" class="fa fa-check text-primary" />
                          <i v-else class="fa fa-times text-danger" />

                        </template>

                        <template v-if="field_row.type == 'timestamp'">
                          
                          <template v-if="record[field_row.key]">
                            {{ record[field_row.key] | datetime }}
                          </template>

                        </template>

                        <template v-if="field_row.type == 'client' && record[field_row.key]">
                          <client-button :uuid="record[field_row.key]" />
                        </template>


                      </td>
                    
                    </template>
                  </tr>

                </template>
              </tbody>
            </table>
            </div><!--Responsive-->

              <template v-if="!processing && !records.length">
                <div class="alert alert-warning">
                  No {{ title }}s found.
                </div>
              </template>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

data() {
    return {
        processing: false,
        title: null,
        currentYear: null,
        currentMonth: null,
        records: [],
        queries: {},
        field_options: {},
        event_types: [],
        statuses: [],
        event_results: [],
        flows: [],
        locations: [],
        rooms: [],
        portals: [],
        judges: [],
        users: [],
        total: 0,
        form: {},
        client: {},
        actions: {
          action: 'edit',
          events: [],
          processing: false,
          processed: []
        },
        actionsForm: {},
        availableFields: [
          {title: 'Start Date', key: 'date', type: 'date', show: true},
          {title: 'End Date', key: 'end_date', type: 'date', show: true},
          {title: 'Start Time', key: 'time', type: 'time', show: true},
          {title: 'End Time', key: 'end_time', type: 'time', show: true},
          {title: 'Client', key: 'client', type: 'client', show: true},
          {title: 'Event Reference', key: 'event_reference', type: 'text', show: true},
          {title: 'Case Number', key: 'case_number', type: 'text', show: true},
          {title: 'Status', key: 'status', type: 'list', show: true},
          {title: 'Virtual', key: 'virtual', type: 'checkbox', show: true},
          {title: 'Flow', key: 'flow', type: 'list', show: true},
          {title: 'Location', key: 'location', type: 'list', show: true},
          {title: 'Location Notes', key: 'location_notes', type: 'longtext', show: true},
          {title: 'Portal', key: 'portal', type: 'list', show: true},
          {title: 'Judge', key: 'judge_name', type: 'text', show: true},
          {title: 'Description', key: 'description', type: 'longtext', show: true},
          {title: 'Type', key: 'type', type: 'list', show: true},
          {title: 'Created At', key: 'created_at', type: 'timestamp', show: true},
          {title: 'Updated At', key: 'updated_at', type: 'timestamp', show: false}
        ],
        fields: [],
        unreachables: []
    }
},

computed: {

    locationRooms: function() {
      if(!this.form.location) {
        return [];
      }
      return this.rooms.filter(room => room.location == this.form.location);
    }
    
    },

mounted() {

    if(this.fields.length < 1) {
      this.fields = this.availableFields;
    }

    var self = this;

    this.$eventBus.$on('search_clients', (uuid) => {

      if(uuid) {
        self.queries.client = uuid;
      } else {
        self.queries.client = null;
      }

      self.resetRecords();
      self.getRecords();
      });

    this.$eventBus.$on('preferences_updated', function() {
      self.setEventFields();
    });

    this.setEventFields();


},

created() {

    if(!this.$root.preferences) {
      this.getPreferences();
    }

    this.title = this.setCustomLabel(this.$route.meta.title);
    this.setDocTitle(this.title+'s');
    
    this.resetForm();
    this.resetQueries();
    this.setURLParams();
    this.getRecords();
    this.getStatuses();
    this.getFlows();
    this.getLocations();
    this.getRooms();
    this.getPortals();
    this.getOptions('users');
    this.getUnreachables();
    this.getEventTypes();

    this.getJudges();

},

methods: {


    resetSearch() {
        this.resetQueries();
        this.searchRecords();
    },

    resetQueries() {
        this.queries = {
            date: '',
            from_date: '',
            to_date: '',
            from_time: '',
            to_time: '',
            search: '',
            event_reference: '',
            case_number: '',
            type: '',
            flow: null,
            location: null,
            judge: null,
            user: null,
            client: null,
            virtual: null,
            sort: 'created_at',
            sortDirection: 'desc',
            limit: 10,
            slim: true,
            archived: false,
            trashed: false,
            upload: null
        }
    },

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {

            if(!value || value == 'null') {
              return;
            }

            self.queries[key] = value;
        });
    },


    getEventTypes() {
        this.$http.get('/event_types')
        .then(response => {
            if(response && response.data) {
                this.event_types = response.data;

                if(this.event_types.length) {
                  this.field_options['types'] = this.event_types;
                }
            }
        })
    },

    getStatuses() {

      this.field_options['statuses'] = [];
      this.field_options['statuss'] = [];

        this.$http.get('/statuses?limit=1000&slim=true&fields=name&sort=name&sortDirection=asc&type=event')
        .then(response => {
            if(response && response.data) {

                if(!response.data.length) {
                  return;
                }

                var results = response.data;

                results = results.map(function(result) {
                  return {
                    name: result.name,
                    uuid: result.name
                  }
                });

                this.field_options['statuses'] = results;
                this.field_options['statuss'] = results;
            }
        })
    },

    getLocations() {
      this.$http.get('/locations?limit=100&slim=true&fields=name,uuid,default&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
         var locations = response.data;

          if(!locations.length) {
            locations = [];
          }

          this.field_options['locations'] = locations;

          let defaultLocation = locations.find(location => location.default);

          if(defaultLocation && !this.form.location) {
            this.form.location = defaultLocation.uuid;
          }

        }
      })
    },

    getRooms() {
      this.$http.get('/rooms?limit=1000&slim=true&fields=name,uuid,location&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.rooms = response.data;
        }
      })
    },

    setLocationRoom() {
      if(!this.form.room) {
        this.form.location_notes = null;
        return;
      }

      var notes = this.rooms.find(room => room.uuid == this.form.room).name || null;

      if(!notes) {
        return;
      }

      notes = notes.replace(/^Room: /, '');

      this.form.location_notes = 'Room: '+notes;
    },

    getPortals() {
      this.$http.get('/portals?limit=100&slim=true&fields=name,uuid,default&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.field_options['portals'] = response.data;

          let defaultPortal = response.data.find(portal => portal.default);

          if(defaultPortal && !this.form.portal) {
            this.form.portal = defaultPortal.uuid;
          }
        }
      })
    },

    getFlows() {
      this.$http.get('/flows?limit=100&slim=true&fields=name,uuid&sort=default&sortDirection=desc&type=event')
      .then(response => {
        if(response && response.data) {
          var flows = response.data;

          flows = flows.filter(flow => !flow.type || flow.type == 'event');
          // this.flows = this.flows.filter(flow => !flow.type || flow.type == 'event');

          if(!flows.length) {
            flows = [];
          }

          if(flows.length && !this.form.flow) {
            this.form.flow = flows[0].uuid;
          }

          flows.sort((a, b) => a.name.localeCompare(b.name));

          this.field_options['flows'] = flows;
        }
      })
    },

    getOptions(key) {
      this.$http.get('/'+key+'?limit=100&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
          .then(response => {
            if(response && response.data) {
              this.field_options[key] = response.data;
            }
          })
    },

    getUnreachables() {
        this.$axios.get(this.$root.assets_url+'js/unreachables.json')
        .then(response => {
            if(response && response.data) {
                this.unreachables = response.data;
            }
        })
    },

    setOptionName(record, key) {

      var field_key = 'uuid';

      if(key == 'reached' && this.unreachables && this.unreachables[record]) {
        return this.unreachables[record];
      }

      if(key == 'status') {
        field_key = 'name';
      }

      var options = this.field_options[key+'s'];

      var option = _.find(options, { 'name': record });

      if(option && option.name) {
        return option.name;
      }

      var option = _.find(options, { 'uuid': record });

      if(option && option.name) {
        return option.name;
      }

      return record;

    },

    searchRecords() {
        this.resetRecords();
        this.getRecords();
    },

    updateCalendar() {
      this.resetRecords();
      this.getRecords();
    },

    resetRecords() {
      this.queries.skip = 0;
      this.total = 0;
      this.records = [];
    },

    getTotal() {
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
            if(response && response.status === 200) {
                this.total = response.data;
            }
        })
    },

    updateURL() {
        history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords() {

        this.processing = true;

        this.updateURL();
        this.getTotal();

        this.queries.slim = true;

        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
            if(response && response.data) {

                if(response.data.length) {

                    var self = this;

                    response.data.forEach(function(data) {
                        self.records.push(data);
                    });

                }

                this.queries.skip = this.records.length;

                this.processing = false;
            }
        })
    },

    toggleArchivedEvents() {

        if(this.queries.archived === true) {
            this.queries.archived = false;
        } else {
            this.queries.archived = true;
        }

        this.getRecords();
    },

    toggleTrashedEvents() {

        if(this.queries.trashed === true) {
            this.queries.trashed = false
        } else {
            this.queries.trashed = true
        }

        this.getRecords();
    },

    getJudges () {
      this.$http.get('/judges?slim=true&fields=name&sort=name&sortDirection=asc&limit=100&fields=name,uuid')
      .then(response => {
        if(response && response.data) {
          this.judges = response.data;
        }
      })
    },

    getUsers() {
        this.$http.get('/users?slim=true&fields=name,uuid&sort=name&sortDirection=desc&limit=100')
        .then(response => {
            if(response && response.data) {
                this.users = response.data;
            }
        })
    },

    updateSortDirection() {

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();
      
      this.getRecords();
    },

    sortColumn(column) {

      this.queries.sort = column.key;

      this.updateSortDirection();
    },


  activeField(field) {

    var activeField = _.find(this.fields, { 'key': field });

    if(activeField && activeField.show && activeField.show === true) {
      return 'active';
    }
  },

  toggleField(field) {

    var fields = this.fields;

    if(!fields || !fields.length) {
      fields = this.availableFields;
    }

    var activeField = _.find(fields, { 'key': field.key });

    if(!activeField) {
      field.show = false;
      fields.push(field);
      activeField = field;
    }

    if(activeField.show) {
      activeField.show = false;
    } else {
      activeField.show = true;
    }

    this.fields = _.map(this.fields, function(f) {
      if(f.key == field.key) {
        f.show = activeField.show;
      }
      return f;
    });

    this.$eventBus.$emit('updateEventFields', this.fields);
  },

  setFieldTitle(field) {

    if(field.title) {
      return field.title;
    }

    var fields = localStorage.getItem('fields');

    if(!fields || !fields.length) {
      return field.title;
    }

    var search = field.key;

    var title = _.find(JSON.parse(fields), { 'key': 'events.'+search });

    if(!title || !title.name) {
      return field.title;
    }

    return title.name;
  },

  setEventFields() {

    this.fields = this.availableFields;

    if(this.$root.preferences && this.$root.preferences.event_fields) {
      this.fields = this.$root.preferences.event_fields;
    }
  },

  shouldShowField(field) {

    if(!this.fields || !this.fields.length) {
      return true;
    }

    var activeField = _.find(this.fields, { 'key': field });

    return activeField && activeField.show;
  },

  resetForm() {
    this.form = {
      client: null,
      date: null,
      time: null,
      event_reference: null,
      case_number: null,
      flow: null,
      location: null,
      location_notes: null,
      description: null,
      type: null,
      status: null,
      appeared: null,
      virtual: 0,
      judge_name: null,
      internal_notes: null,
      url: null
    }

    if(!this.form.date) {
      this.form.date = this.$moment().tz(this.timezone).add(1, 'day').format('YYYY-MM-DD');
    }

    if(!this.form.time) {

      var time = '08:00';

      if(this.settings && this.settings.business_from_time) {
        time = this.settings.business_from_time;
      }

      this.form.time = time;
    }
  },

  postForm() {

    if(this.queries.client && this.$options.filters.isValidUUID(this.queries.client)) {
      this.form.client = this.queries.client;
    }

    this.form.skip_sync = true;

    this.processing = true;

    this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
          if(response && response.data) {

            if(response.data.uuid) {
              this.$eventBus.$emit('show-alert', {
                text: this.title + ' created'
              });
              this.records.unshift(response.data);
              this.total++;
              this.resetForm();
              this.$http.post('events/'+response.data.uuid+'/process')
              this.getCreateCase(response.data);
            }

            this.processing = false;

          }
        })
  },

  getCreateCase(record) {
    if(!record.case_number) {
      return;
    }

    this.$http.get('/cases/total?case_number='+record.case_number)
    .then(response => {
      if(response && response.status === 200) {
        
        if(response.data >= 1) {
          return;
        }

        this.$http.post('/cases', {
          case_number: record.case_number,
          client: this.queries.client || null
        })
      }
    })
  },

  editField(field, event) {

    var label = event.target.innerText;

    if(!label) {
      return;
    }

    if(!field.key) {
      return;
    }

    if(field.title == label) {
      return;
    }

    this.fields = _.map(this.fields, function(f) {
      if(f.key == field.key) {
        f.title = label;
      }
      return f;
    });

    this.$eventBus.$emit('updateEventFields', this.fields);
  },

  dragEnd(event) {
    this.$eventBus.$emit('updateEventFields', this.fields);
  },

  editRow(record, field, event) {

    var value = event.target.innerText;

    if(!value) {
      return;
    }

    if(!field.key) {
      return;
    }

    if(record[field.key] == value) {
      return;
    }

    record[field.key] = value;

    this.$http.patch('/'+this.$route.meta.base_url+'/'+record.uuid, record)
        .then(response => {
          if(response && response.status === 200) {
            this.$eventBus.$emit('show-alert', {
              text: this.title + ' updated'
            });
          }
        })
    },

      resetActionsForm() {
          this.actions = {
            action: null,
            events: []
          }
        },

  deselectAll() {
    this.actions.events = [];
  },

  selectAll() {
    this.actions.events = this.records.map(function(record) {
      return record.uuid;
    });
  },

  toggleSelect() {
    if(this.actions.events.length == this.total) {
      this.deselectAll();
      } else {
        this.selectAll();
      }
    },


    postAction() {
      if(!this.actions.events || !this.actions.events.length) {
        return;
      }


      if(this.actions.action == 'edit') {
        this.editEvents();
      }

      if(this.actions.action == 'archive') {
        this.archiveEvents();
      }
    },

    editEvents() {
      this.actions.processing = true;

      this.actions.events.forEach(event => {

        this.$http.patch('/events/'+event, this.actionsForm)
        .then(response => {
          if(response && response.status === 200) {
            this.$eventBus.$emit('show-alert', {
              text: 'Event updated'
            });

            // update event in records
            var index = this.records.findIndex(record => record.uuid == event);

            if(index > -1) {
              this.records[index] = response.data;
            }

            this.actions.events = this.actions.events.filter(e => e != event);

            if(!this.actions.events.length) {
              this.actions.processing = false;
            }
            
          }
        })
      });
    },

    archiveEvents() {

      this.actions.processing = true;

      this.actions.events.forEach(event => {
        this.$http.put('/events/'+event+'/archive')
        .then(response => {
          if(response && response.status === 200) {

            this.$eventBus.$emit('show-alert', {
              text: 'Event archived'
            });

            // update event in records
            var index = this.records.findIndex(record => record.uuid == event);

            if(index > -1) {
              this.records[index].archived_at = new Date().toISOString();
            } 

            this.actions.events = this.actions.events.filter(e => e != event);

            if(!this.actions.events.length) {
              this.actions.processing = false;
            }

          }
        })
      });
    },

}

}
</script>