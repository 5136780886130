<template>
  <div>
    <div class="position-relative">
      <!-- Search Icon Button -->
      <button 
        v-if="!isSearchVisible"
        type="button" 
        class="btn btn-link text-dark d-print-none" 
        @click="showSearch"
        aria-label="Open search"
      >
        <i class="fas fa-search"></i>
      </button>

      <!-- Search Form -->
      <form
        v-show="isSearchVisible"
        @submit.prevent="handleSubmit"
        class="transition"
      >
        <div class="position-relative">
          <label
            class="visually-hidden"
            for="app_search"
            aria-label="Search"
          >Search</label>
          <div class="input-group">
            <input
              id="app_search"
              ref="app_search"
              v-model="search"
              type="search"
              class="form-control form-control-lg"
              aria-label="Search"
              placeholder="Search"
              @input="postSearch"
              @blur="handleBlur"
              @keydown.down.prevent="navigateDown"
              @keydown.up.prevent="navigateUp"
              @keydown.enter.prevent="handleSubmit"
              @keydown.esc="clearSearch"
              role="combobox"
              aria-expanded="true"
              aria-controls="search-results"
              :aria-activedescendant="activeDescendant"
            >
          </div>

          <!-- Search Results Dropdown -->
          <div
            v-if="show_results"
            id="search-results"
            class="position-absolute top-100 start-0 end-0 bg-white border rounded mt-1 shadow-sm"
            style="z-index: 1050;"
            role="listbox"
          >
            <div class="px-3 py-2">
              <div class="row align-items-center">
                <div class="col">
                  <p class="mb-0">
                    <template v-if="!processing">
                      {{ total }} Results
                    </template>

                    <template v-if="processing">
                      Searching <template v-if="search">
                        <mark>{{ search }}</mark>
                      </template>
                    </template>
                  </p>
                </div>

                <div class="col-auto">
                  <button
                    type="button"
                    class="btn-close"
                    :disabled="processing"
                    @click="clearSearch"
                    aria-label="Close search"
                  ></button>
                </div>
              </div>
            </div>

            <template v-for="(result, index) in results">
              <template v-if="result.url">
                <template v-if="result.result">
                  <div
                    :id="'result_'+index"
                    class="px-3 py-2"
                    :class="{ 'bg-primary text-white': selectedIndex === index }"
                    role="option"
                    :aria-selected="selectedIndex === index"
                  >
                    <a 
                      :href="result.url"
                      class="text-decoration-none d-block"
                      :class="{ 'text-active': selectedIndex === index, 'text-body': selectedIndex !== index }"
                      @mouseenter="selectedIndex = index"
                      ref="searchResults"
                    >{{ result.result }}</a>
                  </div>
                </template>
              </template>
            </template>

            <template v-if="!processing && no_results && search.length > 1">
              <div class="px-3 py-2">
                <span class="text-muted">
                  No results found for <mark>{{ search }}</mark>.<br>
                </span>
              </div>
            </template>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  data() {
    return {
      isSearchVisible: false,
      search: '',
      total: 0,
      results: [],
      processing: false,
      search_results: false,
      no_results: false,
      show_results: false,
      selectedIndex: -1
    }
  },

  computed: {
    activeDescendant() {
      return this.selectedIndex >= 0 ? `result_${this.selectedIndex}` : '';
    }
  },

  mounted() {
    this._keyListener = function(e) {
      if (e.key === "/" && e.ctrlKey) {
        e.preventDefault();
        this.showSearch();
      }

      if(e.key === "Escape") {
        e.preventDefault();
        this.clearSearch();
      }
    };

    document.addEventListener('keydown', this._keyListener.bind(this));
  },

  methods: {
    showSearch() {
      this.isSearchVisible = true;
      // Wait for the DOM to update before focusing
      this.$nextTick(() => {
        this.$refs.app_search.focus();
      });
    },

    handleBlur(event) {
      // Check if the related target is within the search results
      if (!event.relatedTarget?.closest('.search-results')) {
        // Only hide the search if it's empty and there are no results showing
        if (!this.search && !this.show_results) {
          this.isSearchVisible = false;
        }
      }
    },

    navigateDown() {
      if (this.results.length) {
        this.selectedIndex = (this.selectedIndex + 1) % this.results.length;
        this.scrollToSelected();
      }
    },

    navigateUp() {
      if (this.results.length) {
        this.selectedIndex = this.selectedIndex <= 0 ? this.results.length - 1 : this.selectedIndex - 1;
        this.scrollToSelected();
      }
    },

    scrollToSelected() {
      this.$nextTick(() => {
        const selectedElement = this.$refs.searchResults?.[this.selectedIndex];
        if (selectedElement) {
          selectedElement.scrollIntoView({ block: 'nearest' });
        }
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      if (this.selectedIndex >= 0 && this.results[this.selectedIndex]) {
        window.location.href = this.results[this.selectedIndex].url;
      }
    },

    clearSearch() {
      this.search = '';
      this.results = [];
      this.total = 0;
      this.search_results = false;
      this.show_results = false;
      this.isSearchVisible = false;
      this.selectedIndex = -1;
    },

    postSearch: _.debounce(function () {
      this.searchIndex()
    }, 600),
    searchIndex () {

      if(!this.search) {
        return;
      }

      // this.results = []
      this.total = 0
      this.show_results = true;

      this.processing = true;

      this.$http.get('/search?limit=5&search='+this.search)
      .then(response => {
        if(response && response.data && this.search) {
          this.results = response.data
          this.total = response.data.length
          this.search_results = true;

          if(this.total >= 1) {
            this.no_results = false;
          } else {
            this.no_results = true;
          }
        }
        this.processing = false
      })

    }
  }
}
</script>

<style scoped>
.transition {
  transition: all 0.3s ease;
}

.text-active a {
  color: #fff !important;
}

.text-active a:hover {
  color: #fff !important;
}
</style>
