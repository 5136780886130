<template>
  <code
    class="link-hover small text-muted"
    @click="copyToClipboard()"
  >{{ merge_tag }}</code>
</template>

<script>

export default {

props: ['merge_tag'],

methods: {

    copyToClipboard() {
        navigator.clipboard.writeText(this.merge_tag);
        this.$eventBus.$emit('show-alert', {
            title: 'Merge Tag Copied',
            text: 'Merge Tag ' + this.merge_tag + ' copied to clipboard',
            type: 'success'
        });
    },

}
}

</script>