<template>
  <div>
    <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="title"
    />
        
    <div class="row">
      <div class="col-sm-12 col-md-8">
          <h1 id="title">
            <template v-if="form.name">
              {{ form.name }}
            </template>
            <template v-else>
              {{ form.value }}
            </template>
          </h1>
          <p class="text-capitalize">
            <router-link :to="{name: 'clients.index'}">Clients</router-link> /
            <router-link :to="{name: $route.meta.base_url+'.index'}">{{ title }}s</router-link> / Edit {{ title }}
          </p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4 text-end">
        <div class="btn-group">
          <template v-if="form.uuid && !form.optin">
            <button
              type="button"
              class="btn btn-success"
              :disabled="processing"
              @click="optIn"
            >
              Opt In
            </button>
          </template>
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">
                <template v-if="form.optin == 1">
                  <a
                    class="dropdown-item"
                    @click="optOut"
                  >Opt Out</a>
                </template>

                <a
                  v-if="!form.archived_at && !form.deleted_at"
                  class="dropdown-item"
                  @click="archiveRecord(form)"
                >Archive</a>
                <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord(form)"
                >Restore</a>
                <a
                  v-if="!form.deleted_at && !form.archived_at"
                  class="dropdown-item"
                  @click="trashRecord(form)"
                >Trash</a>
              </div>
            </div>
            
          <button
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="updateRecord"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <p class="card-title">
                  Edit {{ title }}
                </p>
              </div><!--Col-->
              <div class="col-sm-12 col-md-6 text-end">
                <template v-if="form.valid == 1">
                  <span id="valid_status" class="badge badge-success me-1">VALID</span>
                </template>

                <template v-if="form.valid == 0">
                  <span id="valid_status" class="badge badge-danger me-1">INVALID</span>
                </template>

                <template v-if="form.notify == 1 && form.optin != 0">
                  <span id="notify_status" class="badge badge-success me-1">NOTIFY</span>
                </template>

                <template v-if="form.notify == 0">
                  <span id="notify_status" class="badge badge-danger me-1">INACTIVE</span>
                </template>

                <template v-if="form.primary == 1">
                  <span id="primary_status" class="badge badge-success me-1">PRIMARY</span>
                </template>

                <template v-if="form.optin == 1">
                  <span id="optin_status" class="badge badge-success me-1">OPTED-IN</span>
                </template>

                <template v-if="form.optin == 0">
                  <span id="optin_status" class="badge badge-danger me-1">OPTED-OUT</span>
                </template>

              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="updateRecord()">
              <div class="form-group mt-3">
                <label for="name">Name</label>
                <input
                  id="name"
                  v-model="form.name"
                  class="form-control"
                >
              </div>

              <div class="form-group mt-3">
                <label for="value">Contact</label>
                <input
                  id="value"
                  v-model="form.value"
                  class="form-control"
                  required
                >
              </div>

              <div class="form-group">
                <label for="type">Type</label>
                <select
                  id="type"
                  v-model="form.type"
                  class="form-control"
                  :disabled="processing">
                  <option value=""></option>
                  <template v-for="contact_type in contact_types">
                    <option
                      :value="contact_type"
                    >
                      {{ contact_type }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="channel">Channel</label>
                <select
                  id="channel"
                  v-model="form.channel"
                  class="form-control"
                >
                  <template v-for="channel in options.channels">
                    <option :value="channel">
                      {{ channel }}
                    </option>
                  </template>
                </select>
              </div>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="processing"
              >
                Save
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

        <audit-logs :form="form" />
      </div><!--Col-->
      <div class="col-sm-12 col-md-4">

        <template v-if="form && form.uuid">
          <lookup-card :contact="form" />
          <opts-card :contact="form.uuid" />
        </template>

        <template v-if="form.client">
          <client-card
            :client="form.client"
            class="mb-3"
          />
        </template>
        
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

        <template v-if="form.valid && form.optin">

        <div class="card mb-3">
          <div class="card-body">
            <p class="card-title">
              Send a Message
            </p>

            <form @submit.prevent="postMessage">
              <div class="form-group">
                <label for="from">From
                <template v-if="form.channel == 'email'">
                  Email
                </template>
                  <template v-else>
                    Phone
                  </template>
                </label>
                <select
                  id="from"
                  v-model="messageForm.from"
                  class="form-control"
                >
                  <template v-for="from in agency_froms">
                    <template v-if="from.phone_number">
                      <option
                        :value="from.phone_number"
                      >
                      <template v-if="from.name == from.phone_number">
                        {{ from.phone_number }}
                      </template>
                      <template v-else>
                        {{ from.name }} - {{ from.phone_number }}
                      </template>
                      </option>
                    </template>
                    <template v-if="from.email">
                      <option
                        :value="from.email"
                      >
                      <template v-if="from.name == from.email">
                        {{ from.email }}
                      </template>
                      <template v-else>
                        {{ from.name }} - {{ from.email }}
                      </template>
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <template v-if="form.channel === 'email'">
                <div class="form-group">
                  <label for="subject">Subject</label>
                  <input
                    id="subject"
                    v-model="messageForm.subject"
                    type="text"
                    class="form-control"
                  >
                </div>
              </template>

              <div class="form-group">
                <label for="message">Message</label>
                <textarea
                  id="message"
                  v-model="messageForm.content"
                  class="form-control"
                  rows="3"
                />
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form-group vbDatePicker">
                    <label for="send_date">Send Date</label>
                    <input
                      id="send_date"
                      v-model="messageForm.date"
                      placeholder="mm/dd/yyyy"
                      type="date"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->
                <div class="col-6">
                  <div class="form-group vbTimePicker">
                    <label for="send_time">Send Time</label>
                    <input
                      id="send_time"
                      v-model="messageForm.time"
                      placeholder="hh:mm AM"
                      type="time"
                      class="form-control"
                    >
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <div class="form-check">
                <input
                  id="test"
                  v-model="messageForm.test"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for="test"
                >Simulate</label>
              </div>

              <div class="row">

                <div class="col-6">

                    <button
                      type="submit"
                      class="btn btn-success"
                      :disabled="processing"
                    >
                      Send Message
                    </button>

                </div><!--Col-->

                <div class="col-6 text-end">

                  <div class="btn-group" :hidden="!messageForm.content">
                    <button 
                    type="button" 
                    class="btn btn-outline-success dropdown-toggle" 
                    data-bs-toggle="dropdown" 
                    aria-expanded="false"
                    >
                      <i class="fa fa-language"></i> Auto Translate
                    </button>
                    <div class="dropdown-menu">

                      <p class="dropdown-header">Choose Target Language</p>

                      <template v-for="language in languages">

                      <a 
                      @click="autoTranslate(language.key)" class="dropdown-item text-capitalize"
                      :hidden="language.key == 'en'"
                      >

                      {{ language.label }}

                      </a>

                      </template>

                    </div>
                  </div>


                </div><!--Col-->

              </div><!--Row-->


            </form>
          </div><!--Body-->
        </div><!--Card-->

        </template>

        <template v-if="!direction_filter">
        <p id="total_messages" class="card-title">
          {{ messages.length }} of {{ total_messages }} messages
        </p>
        </template>

        <template v-if="direction_filter">
          <p id="total_messages" class="card-title">
            <a :href="setDirectionURL()" target="_blank">
              {{ messages.length }} of {{ total_messages }} messages
            </a>
          </p>
        </template>

        <div class="btn-group mb-3">
          <button 
            type="button" 
            class="btn btn-outline-secondary"
            :class="{ 'active': !direction_filter }"
            @click="filterDirection(null)"
            :disabled="processing || !direction_filter"
          >
            All
          </button>
          <button 
            type="button" 
            class="btn btn-outline-secondary"
            :class="{ 'active': direction_filter === 'outbound' }"
            @click="filterDirection('outbound')"
            :disabled="processing || direction_filter == 'outbound'"
          >
            Outbound
          </button>
          <button 
            type="button" 
            class="btn btn-outline-secondary"
            :class="{ 'active': direction_filter === 'inbound' }"
            @click="filterDirection('inbound')"
            :disabled="processing || direction_filter == 'inbound'"
          >
            Inbound
          </button>
        </div>

        <template v-if="!messages || !messages.length">
          <template v-if="form.uuid && !searching">
            <div class="alert alert-danger">
              No messages found.
            </div>
          </template>
        </template>

        <template v-for="message in messages">
          <message
            :message="message"
          />
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';

export default {
    
    mixins: [ queries ],

    data () {
        return {
            processing: false,
            title: null,
            searching: false,
            queries: {
                slim: true,
                archived: true,
                trashed: true
            },
            form: {},
            messageForm: {},
            messages: [],
            total: 0,
            total_messages: 0,
            agency_froms: [],
            contact_types: ['mobile', 'home', 'work', 'school', 'fax', 'other'],
            direction_filter: null
        }
    },

    created () {

        this.title = this.setCustomLabel(this.$route.meta.title);

        this.resetMessageForm();

        this.setURLParams();
        this.getRecord();

        this.getOptions();

    },

    methods: {

        setURLParams() {
            var searchParams = new URLSearchParams(window.location.search);

            var self = this;

            searchParams.forEach(function(value, key) {
                self.queries[key] = value;
            });
        },

        getRecord () {
            this.processing = true;
            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url + '/' + this.$route.params.id, this.queries))
            .then(response => {
                if (response && response.data) {
                    this.form = response.data;
                    this.processing = false;
                    this.getMessages();

                    if(response.data.value) {
                        document.title = response.data.value + ' | eCourtDate.com';
                    }

                    this.getAgencyFroms();
                }
            })
        },

      setDirectionURL() {
        if(this.direction_filter == 'outbound') {
          return '/messages?to='+this.form.value;
        }

        if(this.direction_filter == 'inbound') {
          return '/messages?from='+this.form.value;
        }

        return '/messages';
      },

        getInboundMessages(queries) {

          if(this.direction_filter && this.direction_filter != 'inbound') {
            return;
          }
            
            queries['from'] = this.form.value;
            queries['to'] = null;

            this.getTotalMessages(queries);

            this.$http.get(this.buildQueries('/messages', queries))
            .then(response => {
                if (response && response.data && response.data.data) {

                    var self = this;

                    response.data.data.forEach(function(data) {
                        self.messages.push(data);
                    });

                }
            });
        },

        getOutboundMessages(queries) {

          if(this.direction_filter && this.direction_filter != 'outbound') {
            return;
          }

            queries['to'] = this.form.value;
            queries['from'] = null;

            this.getTotalMessages(queries);

            this.$http.get(this.buildQueries('/messages', queries))
            .then(response => {
                if (response && response.data && response.data.data) {
                    
                    var self = this;

                    response.data.data.forEach(function(data) {
                        self.messages.push(data);
                    });

                }
            })
        },

        getTotalMessages(queries) {
          this.$http.get(this.buildQueries('/messages/total', queries))
          .then(response => {
            if(response && response.data >= 1) {
              this.total_messages = this.total_messages + response.data;
            }
          })
        },

        getMessages () {

            const queries = {
                "limit": 10,
                "sort": "scheduled_at",
                "sortDirection": "desc",
                "slim": true,
                "fields": "uuid,content,scheduled_at,last_status,to,from,subject,test,client,direction,last_open"
            }

            this.searching = true;
            this.total_messages = 0;
            this.messages = [];

            this.getInboundMessages(queries);
            this.getOutboundMessages(queries);
        },

        optOut() {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid+'/optout')
            .then(response => {
                if(response && response.data) {

                    this.processing = false;

                    if(response.status === 200) {
                        this.$eventBus.$emit('show-alert', {
                          text: 'Opting-out '+this.title
                        });
                        this.form.optin = 0;
                    }

                }
            })
        },

        optIn() {
            this.processing = true;
            this.form.notify = 1;
            this.$http.put('/'+this.$route.meta.base_url + '/' + this.form.uuid+'/optin')
            .then(response => {
                if(response && response.data) {

                    this.processing = false;

                    if(response.status === 200) {
                        this.$eventBus.$emit('show-alert', {
                          text: 'Opting-in '+this.title
                        });
                        this.form.optin = 1;
                    }

                }
            })
        },

        updateRecord() {
            this.processing = true;
            this.$http.patch('/'+this.$route.meta.base_url + '/' + this.form.uuid, this.form)
            .then(response => {
                if(response && response.data) {

                    if(response.data.updated_at) {
                        this.$eventBus.$emit('show-alert', {
                          text: this.title + ' updated'
                        });
                        this.form.updated_at = response.data.updated_at;
                    }
                    
                    this.processing = false;
                }
            })
        },

        resetMessageForm() {
            this.messageForm = {
                to: null,
                from: null,
                content: null,
                subject: null,
                client: null,
                channel: null,
                test: 0,
                date: '',
                time: ''
            }
        },

        getAgencyFroms() {

            if(!this.form.channel) {
                return;
            }

            var queries = {
                limit: 10,
                sort: 'created_at',
                sortDirection: 'desc',
                slim: true,
                fields: 'uuid,phone_number,name'
            }

            var url = '/agency_phones';

            if(this.form.channel === 'email') {
                url = '/agency_emails';
                queries['fields'] = 'uuid,email,name';
            }

            this.$http.get(this.buildQueries(url, queries))
            .then(response => {
                if(response && response.data) {
                    this.agency_froms = response.data;

                    if(!this.messageForm.from && this.agency_froms[0]) {

                      if(this.agency_froms[0]['email']) {
                        this.messageForm.from = this.agency_froms[0]['email'];
                      }

                      if(this.agency_froms[0]['phone_number']) {
                        this.messageForm.from = this.agency_froms[0]['phone_number'];
                      }

                    }
                }
            })
        },

        postMessage() {

            if(!this.form.value) {
                return;
            }

            this.processing = true;

            this.messageForm.to = this.form.value;

            if(this.form.client) {
                this.messageForm.client = this.form.client;
            }

            if(this.form.channel) {
              this.messageForm.channel = this.form.channel;
            }

            this.$http.post('/messages/oneoffs', this.messageForm)
            .then(response => {
                if(response && response.data) {
                    this.processing = false;

                    if(response.status === 201) {
                        this.resetMessageForm();
                        this.$eventBus.$emit('show-alert', {
                          text: 'Message created'
                        });
                    }
                }
            })

        },

        autoTranslate(language) {

          this.processing = true;

          var translator = {
            source_language: 'en',
            target_language: language,
            content: this.messageForm.content
          }

          this.$http.post('/translator', translator)
          .then(response => {
            if(response && response.data) {

              if(response.data.content) {
                this.messageForm.content = this.messageForm.content + ' (AUTO-TRANSLATION) ' + response.data.content;
                this.$eventBus.$emit('show-alert', {
                  text: 'Added translation'
                });
              }

              this.processing = false;
            }
          })
        },

        filterDirection(direction) {
            this.direction_filter = direction;
            this.getMessages();
        }

    }
}
</script>
