<template>
    <div>
    
      <archived-trashed-status
      :form="form"
      :processing="processing"
      :title="$route.meta.title"
    />
            
    <div class="row">
      <div class="col-sm-12 col-md-4">
          <h1>{{ form.description }} {{ form.color }}</h1>
          <p>
            <router-link :to="{name: 'events.index'}">Events</router-link> /
            <router-link :to="{name: $route.meta.base_url+'.index'}">{{ $route.meta.title }}s</router-link> / Edit {{ $route.meta.title }}</p>
      </div><!--Col-->

      <div class="col-sm-12 col-md-4">

    
      </div><!--Col-->


      <div class="col-sm-12 col-md-4 text-end">
        <div class="btn-group">

            <div class="btn-group">
              <button
                type="button"
                class="btn btn-outline-primary dropdown-toggle radius-right-0"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tools
              </button>
              <div class="dropdown-menu">
                <a
                  v-if="!form.archived_at && !form.deleted_at"
                  class="dropdown-item"
                  @click="archiveRecord(form)"
                >Archive</a>
                <a
                  v-if="form.archived_at || form.deleted_at"
                  class="dropdown-item"
                  @click="restoreRecord(form)"
                >Restore</a>
                <a
                  v-if="!form.deleted_at && !form.archived_at"
                  class="dropdown-item"
                  @click="trashRecord(form)"
                >Trash</a>
              </div>
          </div><!--BtnGroup-->
            
          <button
            v-if="!form.archived_at && !form.deleted_at"
            type="button"
            class="btn btn-success"
            :disabled="processing"
            @click="postForm()"
          >
            Save
          </button>

          <router-link
            :to="{ name: $route.meta.base_url+'.index' }"
            class="btn btn-danger"
          >
            Close
          </router-link>

        </div>
      </div><!--Col-->
    </div><!--Row-->
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <div class="card">
            <div class="card-body">

                <p class="card-title">
                Edit {{ $route.meta.title }}
                </p>

                <template v-if="error">
                  <div class="alert alert-danger" role="alert">
                    {{ error }}
                  </div>
                </template>
  
              <form @submit.prevent="postForm">
  
                <div class="form-group">
                  <label for="color">Color</label>
                  <select
                    id="color"
                    v-model="form.color"
                    class="form-control text-uppercase"
                    :disabled="processing"
                  >
                    <option value=""></option>
                    <template v-for="color in colorOptions">
                      <option
                        :value="color"
                      >
                        {{ color }}
                      </option>
                    </template>
                  </select>
                </div>
  
                <div class="form-group">
                  <label for="description">Description</label>
                  <textarea
                      id="description"
                      v-model="form.description"
                      class="form-control"
                  />
                </div>

                <div class="form-group">
                    <label for="group">Client Group</label>
                    <select
                        id="group"
                        v-model="form.group"
                        class="form-control"
                        :disabled="processing"
                    >
                        <option value="" />
                        <template v-for="clientGroup in clientGroups">
                        <option
                            :value="clientGroup"
                        >
                            {{ clientGroup }}
                        </option>
                        </template>
                    </select>
                </div>
  
                <div class="form-group mt-3">
                  <label for="start_date">Start Date</label>
                  <input
                    id="start_date"
                    v-model="form.start_date"
                    type="date"
                    class="form-control"
                    required
                    :disabled="processing"
                  >
                </div>
  
                <div class="form-group mt-3">
                  <label for="end_date">End Date</label>
                  <input
                    id="end_date"
                    v-model="form.end_date"
                    type="date"
                    class="form-control"
                    required
                    :disabled="processing"
                  >
                </div>
  
                <div class="form-group">
                  <label for="start_time">Start Time</label>
                  <input
                    id="start_time"
                    v-model="form.start_time"
                    type="time"
                    class="form-control"
                    :disabled="processing"
                  >
                </div>
  
                <div class="form-group">
                  <label for="end_time">End Time</label>
                  <input
                    id="end_time"
                    v-model="form.end_time"
                    type="time"
                    class="form-control"
                    :disabled="processing"
                  >
                </div>
  
                <div class="form-group">
                  <label for="location">Location</label>
                  <select
                      id="location"
                      v-model="form.location"
                      class="form-control"
                      :disabled="processing"
                  >
                    <option value="" />
                    <template v-for="location in locations">
                      <option
                          :value="location.uuid"
                      >
                        {{ location.name }}
                      </option>
                    </template>
                  </select>
                </div>
  

                <button
                    type="submit"
                    class="btn btn-success"
                    :disabled="processing"
                >
                    Save
                </button>
                
              </form>
            </div><!--Body-->
          </div><!--Card-->
  
          <audit-logs
            :form="form"
            class="mt-3"
          />

  
  
        </div><!--Col-->

        <div class="col-sm-12 col-md-6">
          <p>{{ records.length }} Related Schedules</p>

          <ul class="list-group">
            <template v-for="record in records">

              <template v-if="record.uuid !== form.uuid">
              <li class="list-group-item">
                <router-link
                  :to="{ name: $route.meta.base_url+'.edit', params: { id: record.uuid } }"
                >
                  {{ record.description }} {{ record.color }} <template v-if="!record.description && !record.color">Edit</template>
                  {{ record.start_date }} - {{ record.end_date }}
                  <br>{{ record.start_time }} - {{ record.end_time }}
                </router-link>
              </li>
              </template>

            </template>
          </ul>

        </div><!--Col-->
  
        <div class="col-sm-12 col-md-3">
          <template v-if="messages && messages.length > 0">
            <p>{{ messages.length }} of {{ total_messages }} 
              <a :href="buildQueries('messages', setMessageQueries())" class="text-decoration-none" target="_blank">Inbound Messages</a>
            </p>
            <template v-for="message in messages">
              <message :message="message" />
            </template>
          </template>
          <template v-if="!processing && (!messages || messages.length === 0)">
            <div class="alert alert-info" role="alert">
              No messages found
            </div>
          </template>
        </div><!--Col-->
      </div><!--Row-->
    </div>
  </template>
  
  <script>
  import queries from '../mixins/queries';
  import _ from "lodash";
  
  export default {
  
      mixins: [ queries ],
  
      data() {
          return {
              processing: false,
              form: {},
              colorOptions: ['aquamarine', 'beige', 'black', 'blue', 'brown', 'cyan', 'fuchsia', 'gray', 'green', 'lime', 'orange', 'pink', 'purple', 'red', 'turquoise', 'violet', 'white', 'yellow'],
              portal: {},
              locations: [],
              clientGroups: [],
              error: '',
              messages: [],
              total_messages: 0,
              records: []
          }
      },
  
      created() {  
        this.resetForm();
        this.getRecord();
        this.getPortal();
        this.getLocations();
        this.setClientGroups();
      },
  
      methods: {
  
          resetForm() {
            this.form = {
              color: null,
              start_date: null,
              end_date: null,
              start_time: '00:00',
              end_time: '18:00',
              description: null,
              location: null,
              group: null
            }
        },

          getRecord() {
              this.processing = true;
              this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
              .then(response => {
                  if(response && response.data) {
                      this.form = response.data;
                      this.processing = false;
                      this.getRelatedRecords();
                      this.getMessages();
                  }
              })
          },

          getRelatedRecords() {
             
            let queries = {
              color: this.form.color,
              description: this.form.description,
              limit: 100
            }

            this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
            .then(response => {
              if(response && response.data) {
                this.records = response.data;
              }
            })

          },

          setMessageQueries() {
            return {
              direction: 'inbound',
              channel: 'voice',
              from_date: this.form.start_date,
              from_time: this.form.start_time,
              to_date: this.form.end_date,
              to_time: this.form.end_time,
              timezone: this.timezone || this.settings.timezone,
              sort: 'scheduled_at',
              sortDirection: 'desc',
            }
          },


          getMessages() {

            let queries = this.setMessageQueries();

            this.getTotalMessages(queries);

            this.$http.get(this.buildQueries('/messages', queries))
            .then(response => {
              if(response && response.data && response.data.data) {
                this.messages = response.data.data;
              }
            })
          },

          getTotalMessages(queries) {
            this.$http.get(this.buildQueries('/messages/total', queries))
            .then(response => {
              if(response && response.status === 200) {
                this.total_messages = response.data;
              }
            })
          },
    
  
          postForm () {

            if(!this.form.color && !this.form.description) {
              this.error = 'Please enter a color or description';
                return;
            }

            if(!this.form.start_date || !this.form.end_date) {
              this.error = 'Please enter a start and end date';
                return;
            }

            if(!this.form.start_time || !this.form.end_time) {
              this.error = 'Please enter a start and end time';
                return;
            }

              this.error = '';
  
              this.processing = true;
    
             this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
              .then(response => {
                  if(response && response.status) {
  
                      this.processing = false;
  
                      if(response.status === 201) {
                          this.$eventBus.$emit('show-alert', {
                            text: 'Saved '+this.$route.meta.title
                          });
                          this.form = {...this.form, ...response.data};
                      }
                  }
              })
          },
  

        getPortal() {
            this.$http.get('/portals?limit=1&sort=default&sortDirection=desc')
                .then(response => {
                  if(response && response.data && response.data.length > 0) {
                    this.portal = response.data[0];
                  }
                })
        },
  
        getLocations() {
            this.$http.get('/locations?limit=100&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
                .then(response => {
                  if(response && response.data && response.data.length > 0) {
                    this.locations = response.data;
                  }
                })
        },
  
        setLocationName(record) {
  
          if(!record.location) {
            return;
          }
  
          var location = _.find(this.locations, {'uuid': record.location});
  
          if(!location || !location.name) {
            return;
          }
  
          return location.name;
        },
  
        setClientGroups() {
          if(!this.settings || !this.settings.default_groups) {
            return;
          }

          this.clientGroups = this.settings.default_groups.split(',');
        }

      }
  }
  </script>