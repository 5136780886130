import { render, staticRenderFns } from "./HelpIndex.vue?vue&type=template&id=72cf3d16&scoped=true"
import script from "./HelpIndex.vue?vue&type=script&lang=js"
export * from "./HelpIndex.vue?vue&type=script&lang=js"
import style0 from "./HelpIndex.vue?vue&type=style&index=0&id=72cf3d16&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72cf3d16",
  null
  
)

export default component.exports