<template>
  <div>
        <button
          id="portals"
          type="button"
          class="btn btn-outline-primary dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          v-if="validURL()"
          :disabled="processing"
        >
          <i class="fa fa-link"></i> Portals
        </button>
        <div
          class="dropdown-menu"
          for="portals"
        >
          <template v-for="record in records">
            <a 
              :href="setURL(record)"
              :title="'Preview on Portal '+record.name"
              class="dropdown-item"
              target="_blank"
            >
            {{ record.name }} <template v-if="!record.name">{{ record.link }}</template>
            </a>
          </template>
        </div>
  </div>
</template>
<script>
export default {

props: ['url'],

data() {
    return {
        processing: false,
        records: [],
        base_url: '/portals'
    }
},

created() {
    this.getRecords();
},

methods: {

    setURL(record) {

        if(!record.domain) {
            record.domain = 'ecase.io';
        }

        if(record.domain) {
            return 'https://'+record.link+'.'+record.domain+'/'+this.url;
        }
    },

    getRecords() {
        this.processing = true;
        this.$http.get(this.base_url+'?slim=true&limit=1000&fields=name,link,domain&sort=name&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
              this.records = response.data;
              this.processing = false;
            }
        })
    },

    validURL() {
        
        if(!this.url) {
            return false;
        }

        return !this.url.includes("http");
    }

}

}
</script>