export default {

data() {

  return {

    downloading: false,
    processing: false,
    downloads: [],
    queries: {},
    languages: [],
    options: [],
    error_codes: [],
    timezones: [],
    errors: null,
    timezone: '',

  }

},

computed: {
  settings: function() {
    let settings = localStorage.getItem('settings') || null;

    if(settings) {
      settings = JSON.parse(settings);
    }

    return settings;
  },

  isPrivacyMode: function() {

    if(!this.$root.preferences || !this.$root.preferences.privacy_mode) {
      return false;
    }

      return true;
  },

},

mounted() {
  let self = this;

  this.$eventBus.$on('errors', function (errors) {
        self.errors = errors.data || errors;
        self.processing = false;
  });

},

created() {

  if(!this.$route.meta.requiresAuth) {
    return;
  }

  this.getSettings();
  
  this.setUserTimezone();
},

methods: {

setURLParams() {

  var searchParams = new URLSearchParams(window.location.search);

  var self = this;

  searchParams.forEach(function(value, key) {
    self.queries[key] = value;
  });
},

updateURL() {
  history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
},


buildQueries(url, queries) {

    var filters = "";

    for(const query in queries) {

        if(queries[query]) {
            filters += encodeURIComponent(query) + "=" + encodeURIComponent(queries[query]) + "&";
        }
        
    }

    if(filters.length) {
        filters = filters.substring(0, filters.length-1);
    }

    return url + "?" + filters
},

    setCustomLabel(label) {

      label = label.toLowerCase();

      if(!this.settings || !this.settings['labels']) {
        return label;
      }

      return this.settings['labels'][label] || label;

    },

    setDocTitle(title) {

    var title = title || this.$route.meta.title;

    title = title.toUpperCase();

    if(this.settings && this.settings.name) {
      title = title + ' | ' + this.settings.name;
    } else if(this.$root.current_user && this.$root.current_user.current_agency && this.$root.current_user.current_agency.name) {
      title = title + ' | ' + this.$root.current_user.current_agency.name;
    }

    document.title = title;
    },

    isUUID(uuid) {

      const regex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

      return regex.test(uuid);
    },

    setUserTimezone() {
      var tz = localStorage.getItem('timezone') || this.$moment.tz.guess();

      localStorage.setItem('timezone', tz);

      this.timezone = tz;
    },

    getSettings() {
      if(this.settings && this.settings.timezone) {
        return;
      }

      this.$http.get('/settings')
          .then(response => {
            if(response && response.data) {
              localStorage.setItem('settings', JSON.stringify(response.data));

              if(response.data.icon) {
                document.getElementById('favicon').href = response.data.icon;
              }
            }
          })
    },

    async downloadPDF() {
      //print screen
      window.print();
      },
    
      downloadCSV () {
        this.downloading = true;
    
        var queries = this.queries;
    
        queries.limit = this.total;
    
        queries.skip = this.downloads.length;
    
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, queries))
            .then(response => {
              if(response && response.data && response.data) {
                this.downloads = this.downloads.concat(response.data.data || response.data);
    
                var csvFile, data, link;
    
                data = this.convertCSV(this.downloads);
    
                var file = this.$route.meta.base_url+'-'+this.downloads.length+'.csv';
    
                csvFile = new Blob([data], {type: "text/csv"});
                if (window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveBlob(csvFile, file);
                } else {
                  link = document.createElement("a");
                  link.download = file;
                  link.href = window.URL.createObjectURL(csvFile);
                  link.style.display = "none";
                  document.body.appendChild(link);
                  link.click();
                }
    
                this.downloading = false;
    
              } else {
                this.downloadCSV();
              }
    
            });
      },
    
      convertCSV(records) {

        if(!records || !records.length) {
          return;
        }
    
        var results = [];
    
        if(records[0] && records[0]['uuid']) {
          var headers = Object.keys(records[0]);
        }
    
        results += headers.join(',')
    
        results += '\n';

        var self = this;
    
        records.forEach(function(item) {
          let record = '';
    
          if(!item) {
            return;
          }
    
          Object.keys(item).forEach(function(key) {

            if(item[key] && typeof item[key] === 'object') {
              item[key] = JSON.stringify(item[key]);
              item[key] = item[key].replace(/"/g, '""');
            }

            if(item[key] && ['created_at', 'updated_at', 'deleted_at', 'archived_at', 'completed_at'].includes(key)) {
                item[key] = self.$options.filters.datetime(item[key]);
            }

            record += '"' + (item[key] ? item[key] : '') + '",';
          });
    
          results += record;
          results += '\n';
        });
    
        return results
      },


      scrollUp() {
        window.scrollTo(0,0);
      },


    
  copyToClipboard(value) {
    navigator.clipboard.writeText(value);
    this.$eventBus.$emit('show-alert', {
      text: 'Copied to clipboard'
    });
  },

  setLanguage(language) {

    if(!language) {
      return;
    }

    var result = this.$_.find(this.languages, {'key': language});

    if(!result) {
      return;
    }

    return result.label;

  },

  getPreferences() {
    this.$eventBus.$emit('refreshPreferences', {});
  },

  getLanguages () {

    if(this.languages && this.languages.length) {
      return;
    }

    this.$axios.get(this.$root.assets_url+'js/languages.json')
        .then(response => {
          if(response && response.data) {
            this.languages = response.data;
          }
        })
  },

  getOptions () {
    this.$axios.get(this.$root.assets_url+'js/options.json')
    .then(response => {
      if(response && response.data) {
          this.options = response.data;
      }
    })
  },

  getErrorCodes() {
    this.$axios.get(this.$root.assets_url+'js/error_codes.json')
    .then(response => {
        if(response && response.data) {
            this.error_codes = response.data;
        }
    })
  },

  getCountries() {
    this.$axios.get(this.$root.assets_url+'js/country_codes.json')
    .then(response => {
        if(response && response.data) {
            this.countries = response.data;
        }
    })
  },

  getStates () {
    this.$axios.get(this.$root.assets_url+'js/states.json')
    .then(response => {
      if (response && response.data) {
        this.states = response.data;
      }
    })
  },

 getTimezones() {
      this.$axios.get(this.$root.assets_url+'js/timezones.json')
      .then(response => {
          if(response && response.data) {
              this.timezones = response.data;
          }
      })
  },

  getMergeTags () {
    this.$axios.get(this.$root.assets_url+'js/mergetags.json')
        .then(response => {
          if(response && response.data) {
            this.merge_tags = response.data;
          }
        })
  },

  setLayout(layout) {
    this.$eventBus.$emit('updatePreferences', {'default_layout': layout})
    this.layout = layout;
  },

  genRandomCode(length) {
    
    const randomBytes = new Uint8Array(length);
    window.crypto.getRandomValues(randomBytes);

    let base64String = btoa(String.fromCharCode.apply(null, randomBytes));

    let urlSafeString = base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

    urlSafeString = urlSafeString.replace(/[^a-zA-Z0-9]/g, '');

    return urlSafeString.substring(0, 24);
  },

  sanitizeContent(content) {
        
    if(!content) {
      return;
    }
    
    return this.$DOMPurify.sanitize(content);
  },

  setCustomField(key, field) {

    var fields = localStorage.getItem('fields');

    if(!fields || !fields.length) {
      return this.$options.filters.rmDashes(field);
    }


    var title = _.find(JSON.parse(fields), { 'key': key+'.'+field });

    if(!title || !title.name) {
      return this.$options.filters.rmDashes(field).toUpperCase();
    }

   return title.name.toUpperCase();
},

        archiveRecord (record) {
            this.processing = true;
            this.$http.put('/'+this.$route.meta.base_url + '/' + record.uuid + '/archive')
            .then(response => {
                if (response && response.data) {

                    this.processing = false;

                    var title = this.title || this.$route.meta.title;

                    if(response.status === 200) {
                        this.$eventBus.$emit('show-alert', {
                          text: title + ' archived'
                        });
                        this.form.archived_at = new Date();
                    }

                }
                
            })
        },

        restoreRecord (record) {
          this.processing = true;
          this.$http.put('/'+this.$route.meta.base_url + '/' + record.uuid + '/restore')
          .then(response => {
              if (response && response.data) {

                  this.processing = false;

                  var title = this.title || this.$route.meta.title; 

                  if(response.status === 200) {
                      this.$eventBus.$emit('show-alert', {
                        text: title + ' restored'
                      });
                      this.form.archived_at = null;
                      this.form.deleted_at = null;
                  }
                  
              }
          })
      },

      trashRecord (record) {
        this.processing = true;
        this.$http.delete('/'+this.$route.meta.base_url + '/' + record.uuid)
        .then(() => {
            this.processing = false;

            var title = this.title || this.$route.meta.title;

            this.$eventBus.$emit('show-alert', {
              text: title + ' trashed',
              type: 'danger'
            });
            this.form.deleted_at = new Date();
        })
    },

    syncRecord (record) {
      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url+'/' + record.uuid + '/sync')
      .then(response => {
        if(response && response.status === 202) {
          this.$eventBus.$emit('show-alert', {
            text: 'Syncing messages'
          });
          this.processing = false;
        }
      })
    },

    getGroups () {
      this.$http.get('/client_groups?limit=100')
      .then(response => {
        if(response && response.data) {
          // this.client_groups = response.data;

    
          var groups = [];
    
          if(this.settings && this.settings.default_groups) {
            groups = this.settings.default_groups;
    
            groups = groups.split(",");
    
            groups = groups.map(g => g.trim());
          }
    
          var client_groups = response.data;
    
          if(client_groups && client_groups.length > 0) {
            client_groups = client_groups.map(r => r.group);
    
            client_groups.forEach(g => {
              if(!groups.includes(g)) {
                groups.push(g);
              }
            })
          }
    
          this.groups = groups.map(g => g.trim());
        }
      })
    }

}

}