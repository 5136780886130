<template>
  <div>

    <template v-if="!processing && !records.length">
      <div class="alert alert-warning">
        No messages found.
      </div>
    </template>

    <div id="messages-feed" class="messages-feed">
      <template v-for="record in records">
        <div
        class="row mb-1"
        :class="isPrivacyMode ? 'privacy-mode' : ''"
        >
      <div class="col-12">
        <router-link
          tag="p"
          class="m-0 link-hover"
          :class="getClass(record)"
          :to="{'name': 'messages.edit', 'params': {'id': record.uuid}}"
        >
          <template v-if="record.subject">
            <em>{{ record.subject }}</em><br>
          </template>

          <template v-if="record.channel && record.content">
            <span v-html="filterContent(record.channel, record.content)"></span>
          </template>

        </router-link>
      </div><!--Col-->
    </div><!--Row-->

    <div
      class="row mb-3"
    >
      <div class="col-sm-12">

        <template v-if="record.last_status && !record.error_code">
          <a
            :href="'/messages/'+record.uuid"
            :class="setStatusClass(record.last_status)"
            class="badge-pill text-uppercase link-hover me-1"
          >
            {{ record.last_status }}
          </a>
        </template>

          <template v-if="record.last_status && record.error_code">
            <span class="badge badge-pill badge-danger me-1"><span class="text-uppercase">{{ record.last_status }}</span> {{ setErrorCode(record.error_code) }}</span>
          </template>


        <template v-if="record.uuid && !record.last_status && record.direction == 'outbound'">
          <output
              class="spinner-grow spinner-grow-sm text-primary"
            >
              <span class="sr-only">Loading...</span>
          </output>
        </template>

        <template v-if="record.test === 1">
          <span class="badge badge-pill badge-primary me-1">SIMULATED</span>
        </template>
                            
        <template v-if="record.mms && record.mms > 0">
          <span class="badge badge-primary me-1"><i class="fa fa-photo-video" /> MEDIA</span>
        </template>

        <template v-if="record.last_open">
            <span class="badge badge-pill badge-success">                                    
              <i class="fa fa-eye"></i>
              OPENED
              {{ record.last_open | dateTimezone | timeago }}
            </span>
        </template>

        <strong class="me-1">From</strong>

        <template v-if="record.direction == 'outbound' && record.channel == 'email'">
          <a :href="'/agency_emails?search='+record.from" class="me-1">{{ record.from }}</a>
        </template>

        <template v-if="record.direction == 'outbound' && record.channel != 'email'">
          <a :href="'/agency_phones?search='+record.from" class="me-1" 
          >{{ record.from }}</a>
        </template>

        <template v-if="record.direction == 'inbound'">
          <a :href="'/messages?search_field=from&search='+record.from" class="me-1">{{ record.from }}</a>
        </template>

                                
        <strong class="me-1">To</strong>

        <template v-if="record.direction == 'inbound' && record.channel == 'email'">
          <a :href="'/agency_emails?search='+record.to" class="me-1">{{ record.to }}</a>
        </template>

        <template v-if="record.direction == 'inbound' && record.channel != 'email'">
          <a :href="'/agency_phones?search='+record.to" class="me-1">{{ record.to }}</a>
        </template>

        <template v-if="record.direction == 'outbound'">
          <a :href="'/messages?search_field=to&search='+record.to" class="me-1"          
          :class="isPrivacyMode ? 'privacy-mode' : ''"
          >{{ record.to }}</a>
        </template>

        {{ record.scheduled_at | dateTimezone | timeago }}

        <template v-if="$route.name != 'clients.edit'">
          <template v-if="record.first_name && record.last_name">
            <a :href="'/clients/'+record.client.uuid" class="me-1"
            :class="isPrivacyMode ? 'privacy-mode' : ''"
            >{{ record.client_reference }} {{ record.first_name }} {{ record.last_name }}</a>
          </template>
          <template v-else-if="record.client">
            <client-button :uuid="record.client" />
          </template>
        </template>
      </div><!--Col-->
    </div><!--Row-->     

      </template>
    </div>

    <template v-if="records && records.length && total > records.length">
      <div class="row">
        <div class="col-12 text-center">
          <button
            type="button"
            ref="loadMoreButton"
            class="btn btn-outline-primary mt-3"
            :disabled="processing"
            @click="loadMore"
          >
            Load more
          </button>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {

    mixins: [ queries ],

    props: ['client_uuid', 'event_uuid', 'payment_uuid'],

    data() {
        return {
            processing: false,
            base_url: '/messages',
            total: 0,
            records: [],
            queries: {},
            clients: [],
            search_fields: ['from', 'to', 'status', 'type', 'subject', 'content', 'channel', 'error_code', 'language', 'direction'],
            sorts: [
              {key: 'updated_at', direction: 'asc', title: 'oldest'},
              {key: 'updated_at', direction: 'desc', title: 'latest'}
            ]
        }
    },

    computed: {

        searchTitle: function() {
            if(this.total >= 0 && !this.processing) {
                return 'Search ' + this.total.toLocaleString() + ' messages';                
            }

            if(this.processing) {
                return 'Searching...';
            }

            return '';
        },

    },

    mounted() {

      var self = this;

      this.$eventBus.$on('messages', function(data) {
          
          if(!data.uuid) {
            return;
          }

          self.pushRecord(data);

      });

      this.$eventBus.$on('searchMessages', function(data) {
        self.queries = data;
        self.searchRecords();
      });

    },

    created() {

    this.resetQueries();

    this.setURLParams();
        
    this.getRecords();

    this.getErrorCodes();

    },

    methods: {

        resetQueries() {
          this.queries = {
                sort: 'scheduled_at',
                sortDirection: 'desc',
                search_field: 'to',
                search: '',
                limit: 20,
                client: null,
                event: null,
                payment: null,
                slim: true,
                total: false,
                status: null
          }
        },

      setURLParams() {

        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
          self.queries[key] = value;
        });
      },

      setStatusClass(status) {

        status = status.toLowerCase();

        if(_.includes(['undelivered', 'failed', 'duplicate', 'bounced', 'missed', 'invalid', 'busy', 'timeout', 'cancel', 'rejected'], status)) {
            return 'badge badge-danger';
        }
        if(_.includes(['delivered', 'opened', 'answered', 'hangup'], status)) {
            return 'badge badge-success';
        }
        if(_.includes(['dispatched', 'sent'], status)) {
            return 'badge badge-primary';
        }
        return 'badge badge-primary';
        }, 

        addrecord(record) {
            this.$http.get(this.base_url + '/' + record.record)
            .then(response => {
                if(response && response.data) {
                    this.records.unshift(response.data);
                }
            })
        },

        resetRecords() {
            this.records = [];
            this.queries.skip = 0;
            this.total = 0;
        },

        updateQueries() {
            if(this.client_uuid) {
                this.queries.client = this.client_uuid;
            }

            if(this.event_uuid) {
              this.queries.event = this.event_uuid;
            }

            if(this.payment_uuid) {
              this.queries.payment = this.payment_uuid;
            }
        },

        getClients() {

          var user = localStorage.getItem('user') || null;

          if(!user) {
            return;
          }

            this.$http.get('/clients?user='+user+'&fields=uuid&slim=true')
            .then(response => {
                if(response && response.data) {
                    this.clients = response.data;

                    if(this.clients.length > 0) {
                      this.queries.clients = this.clients.map(function(client) {
                        return client.uuid;
                      });
                    }

                    this.getRecords();
                  }
            })
        },

        getRecords() {

          if(this.settings && this.settings.filter_clients_data && this.settings.filter_clients_data == true) {
            if(this.$root.current_user.super_admin != true) {
              this.getClients();
              return;
            }
          }

          this.processing = true;

          this.updateQueries();

          this.getTotal();
        },

        getMessages() {

          this.queries.sent = 1;

          this.$http.get(this.buildQueries(this.base_url, this.queries))
          .then(response => {
              if(response && response.status === 200) {

                  this.processing = false;

                  var self = this;

                  if(response.data && response.data.data && response.data.data.length) {
                      response.data.data.forEach(function(data) {
                          self.records.push(data);
                      });
                  }
              }
          })
        },

        getTotal() {

          this.queries.sent = 1;

            this.$http.get(this.buildQueries(this.base_url+'/total', this.queries))
            .then(response => {
                if(response && response.status === 200) {
                    this.total = response.data;
                    this.getMessages();
                }
            })
        },

        loadMore() {

          if(!this.records || !this.records.length) {
            return;
          }

            this.processing = true;
            this.queries.skip = this.records.length;
            this.$http.get(this.buildQueries(this.base_url, this.queries))
                .then(response => {
                  if(response && response.data) {

                    this.processing = false;

                    if(response.data.data && response.data.data.length) {
                      response.data.data.forEach(r => {
                        this.records.push(r);
                      });
                    }
                  }
                  
                });
        },

        searchRecords() {

          if(this.queries.status == 'all') {
            this.queries.status = '';
          }

          if(this.processing) {
            return;
          }

          this.resetRecords();
          this.getRecords();
        },

        isActiveSort(sort) {
          if(this.queries.sort === sort.key && this.queries.sortDirection === sort.direction) {
            return true;
          }

          return false;
        },


        setSort(sort) {

          if(sort.key == this.queries.sort) {
            this.queries.sort = null;
          } else {
            this.queries.sort = sort.key;
            this.queries.sortDirection = sort.direction;
          }

          this.resetRecords();
          this.getRecords();
        },

        sortRecords() {

            if(!this.records) {
              return;
            }

            var sort = this.queries.sortDirection || 'asc';

            this.records = _.orderBy(this.records, ['scheduled_at'], [sort]);
        },

        pushRecord(data) {

          if(this.settings && this.settings.filter_clients_data && this.settings.filter_clients_data == true) {

                if(!data.client) {
                  return;
                }
                
                if(this.clients.length > 0) {
                  var index = _.findIndex(this.clients, {'uuid': data.client});

                  if(index == -1) {
                    return;
                  }
                }
          }
          
          if(this.client_uuid) {
            if(this.client_uuid != data.client) {
              return;
            }
          }

          if(this.event_uuid) {
            if(this.event_uuid != data.event) {
              return;
            }
          }

          if(this.payment_uuid) {
            if(this.payment_uuid != data.payment) {
              return;
            }
          }

          if(!data.last_status || data.last_status == '' || data.last_status == 'scheduled') {
            if(data.direction == 'outbound') {
              this.$eventBus.$emit('messageScheduled', data);
              return;
            }
          }

          var index = _.findIndex(this.records, {'uuid': data.uuid});

          if(index != -1) {
            return;
          }


          this.total++;
          this.records.unshift(data);
        },

      setStatusClass(status) {

        status = status.toLowerCase();

        if(_.includes(['undelivered', 'failed', 'duplicate', 'bounced', 'missed', 'invalid', 'busy', 'timeout', 'cancel', 'rejected'], status)) {
            return 'badge badge-danger';
        }
        if(_.includes(['delivered', 'opened', 'answered', 'hangup'], status)) {
            return 'badge badge-success';
        }
        if(_.includes(['dispatched', 'sent'], status)) {
            return 'badge badge-primary';
        }
        return 'badge badge-primary';
        }, 

        getClass(record) {
        if(record && record.direction) {
            return record.direction
        } 
        return 'outbound';
        },

 
        setErrorCode(code) {

        if(!code || !this.error_codes) {
        return;
        }

        return this.error_codes[code] || code;
        },

        filterContent(channel, content) {
        
        if(!content) {
          return;
        }

        content = content.replace(/<style([\s\S]*?)<\/style>/gi, '');

        if(channel == 'email') {
          content = content.replace(/<[^>]*>/g, '');
        }

        return this.$DOMPurify.sanitize(content);
        }

    }
}
</script>
