<template>
  <div>

<page-tabs page="message_templates" />

      <div class="row" id="main">
        <div class="col-sm-12 col-md-3">

          <div class="card">
            <div class="card-body">

              <div class="row">
                <div class="col-sm-12 col-md-9">
                  <h5 class="card-title">{{ merge_tags.length }} Merge Tags</h5>
                </div><!--Col-->

                <div class="col-sm-12 col-md-3 text-end">
                  <loader :is-loading="processing" />
                </div><!--Col-->

              </div><!--Row-->

              <div class="form-group">
                <label for="search_tags" class="sr-only">Search Merge Tags</label>
                <input type="text" class="form-control" id="search_tags" v-model="search_tags" placeholder="Search merge tags">
              </div>

              <template v-if="merge_tags && merge_tags.length">
                <ul class="list-group mb-3">
                  <template v-for="tag in merge_tags">
                    <button
                      type="button"
                      class="list-group-item list-group-item-action"
                      :class="isActiveTag(tag) ? 'active' : ''"
                      @click="genPreview(tag)"
                      :disabled="processing"
                      :hidden="isHiddenTag(tag)"
                    >
                      {{ tag }}
                    </button>
                  </template>
                </ul>
              </template>


            </div><!--Body-->

        </div><!--Card-->

        </div><!--Col-->

        <div class="col-sm-12 col-md-9">
          <div class="card">
            <div class="card-body">
              <p class="card-title">Merge Tags Overview</p>
              <p class="card-text lead">
                Merge tags are placeholders that allow you to personalize content. When you use merge tags, the tag will be replaced with the value when the message is scheduled.
                If the tag is not found, it will be removed from the message.
              </p>
              <p class="card-text">
                <ul class="list-unstyled">
                <li><i class="fa fa-check text-success"></i> Use merge tags in Flows and Auto Messages to personalize templates.</li>
                <li><i class="fa fa-check text-success"></i> Use merge tags when sending one-off and bulk messages.</li>
                <li><i class="fa fa-check text-success"></i> Use merge tags to personalize inbound voice responses (IVR) when clients call in.</li>
                <li><i class="fa fa-check text-success"></i> Use merge tags in web portals to personalize client-facing content.</li>
                <li><i class="fa fa-check text-success"></i> Use %% to prefix a label before the merge tag value ex: <code>[%Case#% CaseNumber]</code>.</li>
                </ul>
              </p>

              <p>Click on a merge tag to see a preview of how it will look in a message.</p>

              <div class="form-group">
                <label for="content">Content</label>
                <textarea class="form-control" id="content" rows="3" v-model="content"></textarea>
              </div>

              <button type="button" class="btn btn-success" @click="postPreview" :disabled="processing">
                <i class="fa fa-eye"></i> Preview
              </button>

            </div><!--Body-->
          </div><!--Card-->
          
          <template v-if="preview.content">
            <p class="outbound mb-0 mt-3">
            {{ preview.content }}
            </p>
            <template v-if="content && content.includes('Client')">
              <p class="text-muted small mb-3 mt-0">Clients are simulated and do not represent real data.</p>            
            </template>
          </template>


          <template v-if="preview.pre_merge">
            <p class="inbound my-3">
            {{ preview.pre_merge }}
            </p>
          </template>

        </div><!--Col-->
        
      </div><!--Row-->
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data() {
    return {
        processing: false,
        search_tags: '',
        merge_tags: [],
        content: '[ClientName] you have an important message from [AgencyName]. Reply STOP to opt out.',
        preview: {}
    }
},



created() {
  this.getMergeTags();
},

methods: {


    getMergeTags () {
      this.$axios.get(this.$root.assets_url+'js/mergetags.json')
      .then(response => {
          if (response && response.data) {
            this.merge_tags = response.data.map(tag => { return tag.value });
            this.setTitle();
            this.postPreview();
          }
      })
    },

    setTitle() {
      window.document.title = this.merge_tags.length + ' ' + this.$route.meta.title+'s';
    },

    postPreview() {

      if(!this.content) {
        return;
      }

      if(this.processing == true) {
        return;
      }

      this.processing = true;

      var langs = ['en', 'es', 'fr', 'pt', 'ru', 'zh'];

      var post = {
        content: this.content,
        client_name: this.$faker.person.fullName(),
        client_reference: this.$faker.string.alphanumeric(),
        group: this.$faker.lorem.word(),
        language: langs[this.getRandomIndex(langs.length)],
        case_number: this.$faker.finance.accountNumber(),
        event_date: this.$faker.date.future(),
        event_time: '09:00'
      }
    

      this.$http.post('/preview', post)
      .then(response => {
        if(response && response.data) {

          if(response.data[0] && response.data[0].content) {
            this.preview = response.data[0];
          }

          this.processing = false;
        }
      })
    },

    getRandomIndex(arrayLength) {
       return Math.floor(Math.random() * arrayLength);
    },

    isActiveTag(tag) {
      if(!this.content) {
        return false;
      }
      return this.content.includes(tag);
    },

    isHiddenTag(tag) {
      if(!this.search_tags) {
        return false;
      }

      var tag = tag.toLowerCase();

      var search = this.search_tags.toLowerCase();

      return !tag.includes(search);
    },

    genPreview(tag) {
      
      if(this.content.includes(tag)) {
        this.content = this.content.replace(tag, '');
      } else {
        this.content += ' ' + tag;
      }

      this.postPreview();
    },

    updateContent(e) {
      this.content = e.target.innerHTML;
    }
  

}

}
</script>