<template>
  <div>

    <template v-if="client && client.uuid">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-sm-12 col-md-9">
                  <p class="card-title">
                    Client <router-link :to="{ 'name': 'clients.edit', 'params': {'id': client.uuid} }">
                      {{ client.first_name }} {{ client.middle_name }} {{ client.last_name }} {{ client.client_reference }}
                    </router-link>
                  </p>
                </div><!--Col-->

                <div class="col-sm-12 col-md-3 text-end">
                  <button
                    type="submit"
                    class="btn btn-danger btn-sm m-0"
                    :disabled="processing"
                    @click="closeClient"
                  >
                    <i class="fa fa-times" /> <span class="sr-only">Close</span>
                  </button>
                </div><!--Col-->
              </div><!--Row-->

              <ul class="list-group">

                <template v-if="client.language">
                  <li class="list-group-item">
                    Language {{ client.language }}
                  </li>
                </template>

                <template v-if="client.type">
                  <li class="list-group-item">
                    Type {{ client.type }}
                  </li>
                </template>

                <template v-if="client.group">
                  <li class="list-group-item">
                    Group {{ client.group }}
                  </li>
                </template>

                <template v-if="client.status">
                  <li class="list-group-item">
                    Status {{ client.status }}
                  </li>
                </template>

              </ul>
            </div><!--Body-->
          </div><!--Card-->
    </template>

    <template v-else>
      <form @submit.prevent>
        <div class="input-group mb-3">
          <input
          v-model="search_clients"
          type="text"
          class="form-control text-lowercase"
          aria-label="Client"
          aria-describedby="client"
          placeholder="Search clients..."
        >
      </div>

      <template v-if="records && records.length > 0">
        <ul class="list-group mb-3">
          <template v-for="record in records">
            <button
              type="button"
              class="list-group-item list-group-item-action"
              @click="setSearchResult(record)"
            >
              {{ record.result }}
            </button>
          </template>
        </ul>
      </template>
    </form>
    </template>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      processing: false,
      search_clients: '',
      records: [],
      client: {}
    }
  },

  watch: {
    search_clients: {
      handler: _.debounce(function(newValue) {
        if (newValue && newValue.length >= 2) {
          this.searchClients();
        } else {
          this.records = [];
        }
      }, 300),
      immediate: false
    }
  },

  mounted() {
    //check if there is a client parameter in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const clientParam = urlParams.get('client');
    if(clientParam) {
      this.getClient(clientParam);
    }
  },

  methods: {


    searchClients() {
      this.processing = true;
      this.$http.get('/search?search='+this.search_clients)
        .then(response => {
          if(response && response.data) {
            this.processing = false;
            this.records = response.data;
          }
        })
        .catch(() => {
          this.processing = false;
        });
    },

    setSearchResult(record) {
      if(!record.client_uuid) {
        return;
      }

      this.$eventBus.$emit('search_clients', record.client_uuid);
      this.records = [];
      this.search_clients = '';

      this.getClient(record.client_uuid);
    },

    closeClient() {
      this.client = {};

      // Remove client parameter from URL when closing
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete('client');
      window.history.replaceState({}, '', newUrl.toString());

      this.$eventBus.$emit('search_clients', null);
    },

    getClient(uuid) {
      this.$http.get('/clients/'+uuid)
        .then(response => {
          if(response && response.data) {
            this.client = response.data;
            // Update URL without triggering navigation
            const newUrl = new URL(window.location.href);
            newUrl.searchParams.set('client', uuid);
            window.history.replaceState({}, '', newUrl.toString());
          }
        });
    }
  }
}
</script>
