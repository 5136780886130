<template>
  <div>
    <div class="card">
      <div class="card-body">

          <p class="card-title fw-bold">
            {{ setCustomLabel('client') }}
          </p>
          
          <template v-if="!form.uuid">
            <p class="card-text">No {{ setCustomLabel('client') }} attached</p>
          </template>

          <template v-if="form.uuid">

          <ul class="list-group">

            <li class="list-group-item" v-for="field in fields">
              {{ setCustomField('clients', field) }} 
              <template v-if="field == 'language'">
                {{ setLanguage(form[field]) }}
              </template>
              <template v-if="field == 'dob'">
                <template v-if="form[field]">
                  {{ form[field] | date }}
                </template>
              </template>
              <template v-else>
                {{ form[field] }}
              </template>
            </li>


            </ul>

            <a :href="base_url+'/'+form.uuid" class="btn btn-outline-primary w-100 mt-3 text-capitalize">View {{ setCustomLabel('client') }}</a>
          </template>

      </div><!--Body-->
    </div><!--Card-->
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

mixins: [ queries ],

props: ['client'],

data() {
    return {
        form: {},
        base_url: '/clients',
        fields: ['name', 'first_name', 'middle_name', 'last_name', 'client_reference', 'language', 'type', 'group', 'status', 'dob', 'gender']
    }
},



created() {
    this.getLanguages();
    this.getRecord();
},

methods: {

        getRecord() {
            
            if(!this.client) {
                return;
            }

            this.form = {};

            this.$http.get(this.base_url+'/'+this.client)
            .then(response => {
                if(response && response.data && response.data.uuid) {

                    if(response.data.uuid) {
                      this.form = response.data;
                    }
                }
            })
        }
}

}
</script>