<template>
  <div>
    <div class="container-fluid p-0">
      <!-- Current Agency Section -->
      <div class="row mb-4">
        <div class="col-12">
          <div class="card shadow-sm border-0 rounded-lg">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h5 class="card-title m-0 text-primary">
                  <i class="fa fa-building me-2"></i>Current Agency
                </h5>
                <span class="badge bg-light bg-dark px-3 py-2 rounded-pill">
                  <i class="fa fa-globe me-1"></i>
                  {{ $root.current_user && $root.current_user.current_agency ? $root.current_user.current_agency.region : 'No Region' }}
                </span>
              </div>

              <p class="card-text">Each agency is an isolated tenant (subaccount) with its own settings, data, and users.</p>

              <template v-if="$root.current_user && !$root.current_user.current_agency">
                <div class="alert alert-danger d-flex align-items-center">
                  <i class="fa fa-exclamation-triangle me-2"></i>
                  <span>No agencies found.</span>
                </div>
              </template>

              <template v-if="$root.current_user && $root.current_user.current_agency">
                <h3 class="fw-bold mb-3">{{ $root.current_user.current_agency.name }}</h3>
              </template>

              <template v-if="settings && settings.name">
                <h6 class="text-uppercase text-muted mb-3 border-bottom pb-2">Agency Settings</h6>
                
                <div class="row">
                  <template v-for="field in fields">
                    <template v-if="settings[field]">
                      <div class="col-md-4 col-sm-6 mb-3">
                        <div class="d-flex align-items-center">
                          <span class="text-uppercase text-muted small me-2">{{ field }}:</span>
                          <span class="badge bg-primary rounded-pill">{{ settings[field] }}</span>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <!-- Search and Agency List Section -->
      <div class="row">
        <div class="col-md-3 mb-4">
          <div class="card shadow-sm border-0 rounded-lg sticky-top" style="top: 20px; z-index: 100;">
            <div class="card-body">
              <h5 class="card-title text-primary mb-3">
                <i class="fa fa-search me-2"></i> Search Agencies
              </h5>
              
              <form @submit.prevent="searchRecords" class="mb-3">
                <div class="input-group mb-3">
                  <input 
                    type="text" 
                    class="form-control" 
                    v-model="queries.search" 
                    placeholder="Search agencies..."
                    aria-label="Search agencies"
                  >
                  <button 
                    class="btn btn-primary" 
                    type="submit" 
                    :disabled="processing"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </form>
              
              <div class="d-flex justify-content-between align-items-center">
                <span class="badge bg-light text-dark px-3 py-2 rounded-pill">
                  {{ records.length }} of {{ total }} Agencies
                </span>
                <button 
                  class="btn btn-outline-secondary btn-sm" 
                  @click="resetSearch" 
                  :disabled="processing || !queries.search"
                >
                  <i class="fa fa-times me-1"></i>Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        
        <div class="col-md-9">
          <div class="row">
            <template v-for="record in records">
              <div class="col-lg-4 col-md-6 mb-4">
                <div 
                  class="card h-100 shadow-sm border-0 rounded-lg agency-card" 
                  :class="[isCurrentRecord(record), {'agency-card-current': isCurrentRecord(record)}]"
                >
                  <div class="card-body d-flex flex-column">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                      <h5 class="card-title fw-bold m-0">{{ record.name }}</h5>
                      <span class="badge bg-secondary rounded-pill">{{ record.region }}</span>
                    </div>
                    
                    <template v-if="record.reference">
                      <span class="small text-muted">Reference: {{ record.reference }}</span>
                    </template>
                    
                    <div class="mt-auto">
                      <template v-if="!isCurrentRecord(record)">
                        <template v-if="record.region === $root.current_region">
                          <button 
                            @click="switchAgency(record)" 
                            class="btn btn-success w-100" 
                            :disabled="processing"
                          >
                            <i class="fa fa-exchange-alt me-1"></i>Switch to Agency
                          </button>
                        </template>
                        <template v-else>
                          <a 
                            :href="'https://'+record.region+'.ecourtdate.com'" 
                            target="_blank" 
                            class="btn btn-primary w-100"
                          >
                            <i class="fa fa-external-link-alt me-1"></i>Go to {{ record.region }}
                          </a>
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          
          <!-- Empty State -->
          <template v-if="!processing && records.length === 0">
            <div class="text-center py-5">
              <i class="fa fa-building fa-3x text-muted mb-3"></i>
              <h4>No agencies found</h4>
              <p class="text-muted">Try adjusting your search criteria</p>
            </div>
          </template>
          
          <!-- Loading State -->
          <template v-if="processing">
            <div class="text-center py-5">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <p class="mt-2">Loading agencies...</p>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.agency-card {
  transition: all 0.3s ease;
  border-left: 4px solid transparent;
}

.agency-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1) !important;
}

.agency-card-current {
  border-left: 4px solid #0d6efd;
}

.sticky-top {
  position: sticky;
  top: 20px;
}
</style>

<script>
import queries from '../mixins/queries';

export default {
    mixins: [ queries ],

    data () {
        return {
            processing: false,
            base_url: '/agencies',
            records: [],
            total: 0,
            queries: {
                search: null,
                sort: 'name',
                sortDirection: 'asc',
                slim: true,
                fields: 'uuid,name,reference,region'
            },
            fields: ['name', 'reference', 'address', 'address_2', 'city', 'state', 'zip', 'phone', 'email', 'website']
        }
    },

    created () {
        this.setDocTitle(this.$route.meta.title);
        this.setURLParams();
        this.getRecords();
    },

    methods: {

        isCurrentRecord(record) {
          if(!this.$root.current_user || !this.$root.current_user.current_agency) {
            return '';
          }

          if(this.$root.current_user.current_agency.uuid === record.uuid) {
            return 'bg-primary text-white';
          }
          
          return '';
        },

        searchRecords() {
          this.resetRecords();
          this.getRecords();
        },
        
        resetSearch() {
          this.queries.search = null;
          this.resetRecords();
          this.getRecords();
        },

        resetRecords() {
          this.total = 0;
          this.records = [];
        },

        getTotal() {
          this.$http.get(this.buildQueries(this.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
            }
          })
        },

        getRecords () {

          this.getTotal();

          this.processing = true;
          this.$http.get(this.buildQueries(this.base_url, this.queries))
          .then(response => {
              if(response && response.data) {
                this.records = response.data;
                this.processing = false;
              }
          })
          .catch(() => {
            this.processing = false;
          });
        },

        switchAgency(agency) {
          this.processing = true;

          this.$http.post('switch_agency/'+agency.uuid+'?region='+agency.region)
          .then(response => {
            if (response && response.status === 200) {
              this.clearAgencySession();
              this.processing = false;
              location.reload();
            }
          })
          .catch(() => {
            this.processing = false;
          });
        },

        clearAgencySession() {
          var fields = ['fields', 'timezone', 'agency', 'send_mode', 'settings'];

          fields.forEach(field => {
            localStorage.removeItem(field);
          });
        }
    }
}
</script>   