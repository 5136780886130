<template>
<div>
  <page-tabs page="setup" />

  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12 mb-4">
        <div class="card shadow-sm border-0 rounded-lg">
          <div class="card-body text-center py-4">
            <h1 class="display-5 fw-bold text-primary mb-3">Grow With eCourtDate</h1>
            <p class="lead mb-0">Refer agencies and publish press releases to earn free message credits</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Referral Program Card -->
      <div class="col-md-6 mb-4">
        <div class="card shadow-sm border-0 rounded-lg h-100">
          <div class="card-header bg-primary text-white py-3">
            <h3 class="card-title m-0">
              <i class="fa fa-share-nodes me-2"></i>Referral Program
            </h3>
          </div>
          <div class="card-body d-flex flex-column">
            <template v-if="$root.current_user && $root.current_user.current_agency">
              <div class="mb-4">
                <h5 class="border-bottom pb-2 mb-3">What Your Agency Gets:</h5>
                <div class="d-flex align-items-center mb-2">
                  <div class="me-3 text-success">
                    <i class="fa fa-check-circle fa-lg"></i>
                  </div>
                  <div>
                    <strong>1,000 Message Credits</strong> when your referral activates a trial
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="me-3 text-success">
                    <i class="fa fa-check-circle fa-lg"></i>
                  </div>
                  <div>
                    <strong>10,000 Message Credits</strong> when your referral activates a paid plan
                  </div>
                </div>
              </div>

                <h5 class="border-bottom pb-2 mb-3">What Your Referral Gets:</h5>
                <div class="d-flex align-items-center mb-2">
                  <div class="me-3 text-success">
                    <i class="fa fa-check-circle fa-lg"></i>
                  </div>
                  <div>
                    <strong>Free extended trial</strong>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="me-3 text-success">
                    <i class="fa fa-check-circle fa-lg"></i>
                  </div>
                  <div>
                    <strong>10,000 bonus message credits</strong> with any subscription plan
                  </div>
                </div>

              <div class="mt-auto">
                <h5 class="border-bottom pb-2 mb-3">Your Unique Referral Link:</h5>
                <div class="input-group mb-3">
                  <input 
                    type="text" 
                    class="form-control" 
                    :value="referralLink" 
                    readonly
                    aria-label="Referral link"
                  >
                  <button 
                    class="btn btn-primary" 
                    type="button" 
                    @click="copyToClipboard(referralLink)" 
                    :disabled="processing"
                  >
                    <i class="fa fa-copy me-1"></i> Copy
                  </button>
                </div>
                <div class="d-flex justify-content-between">
                  <a href="https://billing.ecourtdate.com/referrals" target="_blank" class="btn btn-outline-secondary">
                    <i class="fa fa-chart-line me-1"></i> Track Referrals
                  </a>
                  <a href="https://ecourtdate.com/pricing" target="_blank" class="btn btn-outline-primary">
                    <i class="fa fa-external-link me-1"></i> View Plans
                  </a>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <!-- Press Release Card -->
      <div class="col-md-6 mb-4">
        <div class="card shadow-sm border-0 rounded-lg h-100">
          <div class="card-header bg-success text-white py-3">
            <h3 class="card-title m-0">
              <i class="fa fa-newspaper me-2"></i>Press Release Program
            </h3>
          </div>
          <div class="card-body d-flex flex-column">
            <div class="mb-4">
              <h5 class="border-bottom pb-2 mb-3">How It Works:</h5>
              <ol class="list-group list-group-numbered mb-4">
                <li class="list-group-item border-0 d-flex">
                  <div class="ms-2">
                    Generate a customized press release template about your agency's use of eCourtDate
                  </div>
                </li>
                <li class="list-group-item border-0 d-flex">
                  <div class="ms-2">
                    Publish the press release on your website or submit to local media
                  </div>
                </li>
                <li class="list-group-item border-0 d-flex">
                  <div class="ms-2">
                    Send us the link to your published press release
                  </div>
                </li>
                <li class="list-group-item border-0 d-flex">
                  <div class="ms-2">
                    Receive <strong>10,000 free message credits</strong> once verified
                  </div>
                </li>
              </ol>
            </div>

            <div class="form-group">
              <label for="press_release_prompt">What do you want the press release to focus on?</label>
              <textarea id="press_release_prompt" class="form-control" v-model="press_release_prompt" rows="3"></textarea>
            </div>

            <div class="mt-auto">
              <button 
                type="button" 
                class="btn btn-success w-100" 
                @click="genPressRelease()" 
                :disabled="processing"
              >
                <i class="fa fa-file-lines me-1"></i> Generate Press Release Template
              </button>
              
              <div class="text-center mt-3">
                <small class="text-muted">
                  Already published? Send the press release link to <a href="mailto:press@ecourtdate.com?subject=Press%20Release%20Verification" target="_blank">press@ecourtdate.com</a>.
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
</template>

<script>
import queries from '../mixins/queries';
export default {
  mixins: [queries],

  data() {
    return {
      processing: false,
      form: {},
      customer: {},
      press_release_prompt: 'Focus on how we are using eCourtDate to reduce failure-to-appear rates and improve outcomes for our clients.'
    }
  },

  computed: {
    referralLink: function() {
      if(!this.$root.current_user) {
        return;
      }

      let ref = this.$root.current_user.current_agency.reference;

      if(this.customer && this.customer['referral_code']) {
        ref = this.customer['referral_code'];
      }

      return 'https://ecourtdate.com/sign-up?r='+ref;
    }
  },

  created() {
    this.resetForm();
    this.getCustomer();
  },


  methods: {
    resetForm() {
      this.form = {
        referral_code: null
      }
    },

    getCustomer() {
      this.customer = {};
      this.processing = true;
      this.$http.get('/agencies/customer')
        .then(response => {
          if(response && response.data) {
            this.customer = response.data;
            this.processing = false;
          }
        })
    },

    genPressRelease() {
      this.processing = true;
      this.$http.post('/agencies/press_release', {
        prompt: this.press_release_prompt
      }, {responseType: 'blob'})
        .then(response => {
          if(response && response.data) {
            this.processing = false;

            const blob = new Blob([response.data], { type: 'application/pdf' });

            const filename = 'press_release_template.pdf';

            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.click();
          }
        })
    },

    submitPublishedPressRelease() {
      // Reset form fields
      this.pressRelease.url = '';
      this.pressRelease.notes = '';
      
      // Show the modal
      this.submitPressReleaseModal.show();
    },

    submitPressReleaseForm() {
      if (!this.pressRelease.url) {
        this.$eventBus.$emit('show-alert', {
          text: 'Please enter the URL to your published press release',
          type: 'danger'
        });
        return;
      }
      
      this.processing = true;
      
      // Submit the press release URL to the server
      this.$http.post('/agencies/press-release', {
        url: this.pressRelease.url,
        notes: this.pressRelease.notes
      })
      .then(response => {
        this.processing = false;
        this.submitPressReleaseModal.hide();
        
        this.$eventBus.$emit('show-alert', {
          text: 'Thank you! Your press release has been submitted for verification.',
          type: 'success'
        });
      })
      .catch(error => {
        this.processing = false;
        
        this.$eventBus.$emit('show-alert', {
          text: 'There was an error submitting your press release. Please try again.',
          type: 'danger'
        });
      });
    }
  }
}
</script>

<style scoped>
.card {
  transition: all 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1) !important;
}

.list-group-numbered > li::before {
  background-color: #198754;
  color: white;
  width: 24px;
  height: 24px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
</style>