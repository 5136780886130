<template>
    <div class="card mb-3">
  <div class="card-body">

    <p class="card-title">Run Lookup</p>

    <template v-if="form && form.uuid">
        <template v-if="form.valid">
          <span class="badge badge-success">VALID</span>
        </template>
        <template v-else>
          <span class="badge badge-danger">INVALID</span>
        </template>

      <ul class="list-group my-3">

        <li class="list-group-item">Status {{ form.status }}</li>
        <li class="list-group-item">Created {{ form.created_at | dateTimezone | timeago }}</li>

      <template v-if="form.data && form.data.carrier">

        <template v-if="form.data.carrier.type">
          <li class="list-group-item">Type {{ form.data.carrier.type }}</li>
        </template>

        <template v-if="form.data.carrier.name">
          <li class="list-group-item">Name {{ form.data.carrier.name }}</li>
        </template>

      </template>

        <template v-if="form.data && form.data.caller_name && form.data.caller_name.caller_name">
          <li class="list-group-item">
            Caller Name {{ form.data.caller_name.caller_name }}
          </li>
        </template>

        <template v-if="form.type == 'email'">

            <li class="list-group-item">
            Disposable Email
            <template v-if="form.data.disposable_email">
                Yes
            </template>
            <template v-else>
                No
            </template>
            </li>

            <li class="list-group-item">
            Is Free Email
            <template v-if="form.data.is_free_email">
            Yes
            </template>
            <template v-else>
                No
            </template>
            </li>

            <template v-if="form.data.isp_name">
            <li class="list-group-item">
                ISP {{ form.data.isp_name }}
            </li>
            </template>

            </template>

      </ul>


    </template>

    <template v-if="!processing && !form.uuid && lookup">
          <p class="card-text">Run a lookup to get more information about this contact.</p>
                    
      <button type="button" class="btn btn-success sm" @click="runLookup()" :disabled="processing">Run Lookup</button>
    </template>

    <template v-if="form.uuid">
        <a :href="base_url+'/'+form.uuid" target="_blank" class="btn btn-outline-primary w-100 mt-3">View Lookup</a>
    </template>
  </div>
</div>
</template>

<script>
import queries from '../mixins/queries';

export default {
    
    mixins: [ queries ],

    props: {
        message: {
            type: Object,
            required: false
        },
        contact: {
            type: Object,
            required: false
        }
    },

    data () {
        return {
            processing: false,
            base_url: '/lookups',
            lookup: null,
            form: {}
        }
    },

    mounted() {
        if(this.message && this.message.direction == 'outbound' && this.message.to) {
            this.lookup = this.message.to;
        }

        if(this.message && this.message.direction == 'inbound' && this.message.from) {
            this.lookup = this.message.from;
        }

        if(this.contact && this.contact.value) {
            this.lookup = this.contact.value;
        }

        this.getLookup();
    },

    methods: {
        runLookup () {
            this.processing = true;

            this.$http.post(this.base_url, {lookup: this.lookup})
            .then(response => {
                if(response && response.status) {
                this.form = response.data;
                this.processing = false;
                }
            })
        },


        getLookup() {

            if(!this.lookup) {
                return;
            }

            this.processing = true;

            this.$http.get(this.base_url+'?limit=1&sort=created_at&sortDirection=desc&lookup='+this.lookup)
            .then(response => {
                if(response && response.status) {

                    if(response.data && response.data[0]) {
                        this.form = response.data[0];
                    }

                    this.processing = false;
                }
            })
        },
    }
}
</script>