<template>
    <div>
    <template v-if="supervisions && supervisions.length">
      <div class="card">
        <div class="card-body">
          <p class="card-title m-0">
            Assign {{ setCustomLabel('supervision') }}s
          </p>
                  
          <template v-if="!supervision.name">
          <form @submit.prevent="postSupervision">
            <div class="form-group">
              <label for="supervision">Choose {{ setCustomLabel('supervision') }}</label>
              <select class="form-control" v-model="new_supervision">
                <option v-for="supervision in supervisions" :value="supervision.uuid" :disabled="supervision.uuid == form.supervision">{{ supervision.name }}</option>
              </select>
                </div>

                <button type="submit" class="btn btn-success" :disabled="processing">Assign</button>
              </form>
            </template>

          <div v-if="supervision && supervision.name">
            <p class="lead">Supervision: <a :href="'/supervisions/'+supervision.uuid" target="_blank">{{ supervision.name }}</a></p>

            <template v-if="supervision.description">
                <p class="text-muted small">{{ supervision.description }}</p>
            </template>


            <template v-if="supervision.monitoring">
                <span class="badge badge-pill badge-success">Monitoring: 
                    <template v-if="supervision.monitoring_interval">
                        {{ supervision.monitoring_interval }}X
                    </template>
                    {{ supervision.monitoring }}
                </span>
            </template>

            <template v-if="supervision.risk">
                <span class="badge badge-pill badge-danger">Risk: {{ supervision.risk }}</span>
            </template>

            <template v-if="supervision.conditions && supervision.conditions.length">
                <ul class="list-group mt-3">
                    <template v-for="condition in supervision.conditions">
                        <li class="list-group-item">{{ condition }}</li>
                    </template>
                </ul>
            </template>

            <template v-if="supervision.created_by">
                <p class="text-muted small m-0">Created by {{ supervision.created_by }}</p>
            </template>

            <template v-if="supervision.created_at">
                <p class="text-muted small m-0">Created at {{ supervision.created_at | datetime }}</p>
            </template>

            <button @click="deleteSupervision" class="btn btn-danger btn-sm w-100" :disabled="processing">Delete</button>
          </div>

        </div><!--Body-->
      </div><!--Card-->
    </template>
    </div>
  </template>
  <script>
  import queries from '../mixins/queries';
  
  export default {
  
      mixins: [ queries ],
      
      props: ['client_uuid'],
  
      data () {
          return {
              processing: false,
              supervisions: [],
              new_supervision: null,
              form: {
                supervision: null
              },
              supervision: {}
          }
      },
  
      created () {
        this.resetSupervision();
        this.getSupervisions();
      },
  
      methods: {

        resetSupervision() {
            this.supervision = {
                name: null,
                uuid: null
            };
        },

        getSupervisions() {
            this.$http.get('/supervisions?fields=name,uuid&slim=true&sort=name&sortDirection=asc&limit=100')
            .then(response => {
                if(response && response.data) {
                    this.supervisions = response.data;

                    if(this.supervisions && this.supervisions.length) {
                        this.getClientSupervision();
                    }
                }
            })
        },

        getClientSupervision() {
            this.processing = true;
            this.$http.get('/clients/'+this.client_uuid+'/supervisions')
            .then(response => {
                if(response && response.status) {

                    this.processing = false;

                    if(response.data && response.data.uuid) {
                        this.form = response.data;
                        this.getSupervision(response.data.supervision);
                    }
                }
            })
        },

        getSupervision(uuid) {
            this.processing = true;
            this.$http.get('/supervisions/'+uuid)
            .then(response => {
                if(response && response.data) {
                    this.supervision = response.data;
                    this.processing = false;
                }
            })
        },


        postSupervision() {
            this.processing = true;
            this.$http.post('/clients/'+this.client_uuid+'/supervisions', { supervision: this.new_supervision })
            .then(response => {
                if(response && response.data && response.data.uuid) {
                    this.form = response.data;
                    this.new_supervision = null;
                    this.getSupervision(response.data.supervision);
                    this.processing = false;
                }
            })
        },

        deleteSupervision() {
            this.processing = true;
            this.$http.delete('/clients/'+this.client_uuid+'/supervisions')
            .then(response => {
                if(response && response.status) {
                    this.resetSupervision();
                    this.processing = false;
                }
            })
        }

      }
  }
  </script>
  