<template>
  <div>

    <page-tabs page="payments" />

    <!-- Payments Notification Promotion Banner -->
    <div v-if="showPromotion" class="card mb-3 border-primary promotion-card" hidden>
      <div class="card-body p-0">
        <div class="row g-0 align-items-center">
          <div class="col-md-1 text-center py-3 d-none d-md-block">
            <i class="fa fa-gavel text-primary fa-3x pulse-icon"></i>
          </div>
          <div class="col-md-9 py-3 px-4">
            <h5 class="card-title mb-1">
              One-Time Offer: 10,000 FREE Payment Notifications
              <span class="badge bg-danger ms-2">LIMITED TIME</span>
            </h5>
            <div class="row mt-2">
              <div class="col-md-4">
                <p class="mb-1"><i class="fa fa-check-circle text-success me-1"></i> <strong>60% faster collections</strong> for courts & law enforcement</p>
                <p class="mb-1"><i class="fa fa-check-circle text-success me-1"></i> <strong>45% reduction</strong> in outstanding payments</p>
              </div>
              <div class="col-md-4">
                <p class="mb-1"><i class="fa fa-check-circle text-success me-1"></i> <strong>Reduced staff workload</strong> handling payment inquiries</p>
                <p class="mb-1"><i class="fa fa-check-circle text-success me-1"></i> <strong>Automated pay plan reminders</strong> for clients</p>
              </div>
              <div class="col-md-4">
                <p class="mb-md-3 mb-1">Sign up within <strong class="text-danger">{{ promotionDaysLeft }} days</strong> for this exclusive offer.</p>
                <p class="mb-0 small text-muted">Promotion ends {{ promotionEndDate }}. Code: PAYMENTS100K</p>
              </div>
            </div>
          </div>
          <div class="col-md-2 text-center py-3">
            <a href="https://govpayplan.com" class="btn btn-success mb-2">Learn More</a>
            <div>
              <button class="btn btn-outline-secondary btn-sm" @click="dismissPromotion">Dismiss</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-3 d-print-none">

        <client-search />
        
        <div class="card">
          <div class="card-body">

          <p class="card-title">
            Create {{ title }}
          </p>

          <p>Create a one-off outstanding payment.</p>

    <form @submit.prevent="postForm()">

          <div class="form-group">
            <label for="payment_amount">{{ setCustomField('payments', 'amount') }}</label>
            <div class="input-group">
              <span
                class="input-group-text"
                style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
              >$</span>
              <input
                id="payment_amount"
                v-model="form.amount"
                type="number"
                class="form-control"
                min="0"
                step="0.01"
                required
              >
            </div>
          </div>

          <div class="form-group">
            <label for="payment_reference">{{ setCustomField('payments', 'payment_reference') }}</label>
            <input
              id="payment_reference"
              v-model="form.payment_reference"
              type="text"
              class="form-control"
            >
          </div>

          <div class="form-group">
            <label for="due_at">{{ setCustomField('payments', 'due_at') }}</label>
            <input
              id="due_at"
              v-model="form.due_at"
              type="date"
              class="form-control"
            >
          </div>

          <div class="form-group">
            <label for="flow">Flow</label>
            <select
              id="flow"
              v-model="form.flow"
              class="form-control"
              :disabled="processing"
            >
              <option value="" />
              <template v-for="flow in flows">
                <option :value="flow.uuid">{{ flow.name }}</option>
              </template>
            </select>
          </div>


      <button
        type="submit"
        class="btn btn-success"
        :disabled="processing"
      >
        Create
      </button>
    </form>

          </div><!--Body-->
        </div><!--Card-->

        <div class="card mt-3">
          <div class="card-body">

            <p class="card-title">
              Search {{ title }}s
            </p>                     

            <form @submit.prevent="searchRecords()">
              <div class="form-group">
                <label
                  for="search"
                  class="sr-only"
                >Search {{ title }}s</label>
                <input
                  id="search"
                  v-model="queries.search"
                  type="search"
                  class="form-control"
                  placeholder="Search by payment reference"
                >
              </div>

              <div class="form-group">
                <label for="payment_type">Payment Type</label>
                <select
                  id="payment_type"
                  v-model="queries.type"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="payment_type in payment_types">
                    <option
                      :value="payment_type"
                    >
                      {{ payment_type }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="payment_descriptions">Payment Description</label>
                <select
                  id="payment_descriptions"
                  v-model="queries.description"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="" />
                  <template v-for="payment_description in payment_descriptions">
                    <option
                      :value="payment_description"
                    >
                      {{ payment_description }}
                    </option>
                  </template>
                </select>
              </div>

                <div class="form-group">
                  <label for="search_status">Status</label>
                  <select
                    id="search_status"
                    v-model="queries.status"
                    class="form-control"
                    :disabled="processing"
                  >
                    <option
                      value=""
                    >
                      Select status
                    </option>
                    <option
                      v-for="status in statuses"
                      :value="status.name"
                    >
                      {{ status.name }}
                    </option>
                  </select>
                </div>

              <div class="form-group">
                <label for="search_case_number">Case Number</label>
                <input id="search_case_number" type="text" class="form-control" v-model="queries.case_number">
              </div>

              <div class="form-group">
                <label for="search_citation">Citation</label>
                <input id="search_citation" type="text" class="form-control" v-model="queries.citation">
              </div>

              <div class="form-group">
                <label for="search_contact">Contact</label>
                <input id="search_contact" type="text" class="form-control" v-model="queries.search_contact">
              </div>

              <div class="form-group">
                <label for="location">Location</label>
                <select
                  id="location"
                  v-model="queries.location"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <option value="none">
                    No Location
                  </option>
                  <template v-if="locations && locations.length > 0">
                    <template v-for="(location, index) in locations">
                      <option
                        :value="location.uuid"
                      >
                        {{ location.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>


              <div class="form-group">
                <label for="portal">Portal</label>
                <select
                  id="portal"
                  v-model="queries.portal"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <option value="none">
                    No Portal
                  </option>
                  <template v-if="portals && portals.length > 0">
                    <template v-for="(portal, index) in portals">
                      <option
                        :value="portal.uuid"
                      >
                        {{ portal.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>


              <div class="form-group">
                <label for="flow">Flow</label>
                <select
                  id="flow"
                  v-model="queries.flow"
                  class="form-control"
                  :disabled="processing"
                >
                  <option value="">
                    Any
                  </option>
                  <option value="none">
                    No Flow
                  </option>
                  <template v-if="flows && flows.length > 0">
                    <template v-for="flow in flows">
                      <option
                        :value="flow.uuid"
                      >
                        {{ flow.name }}
                      </option>
                    </template>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="search_issued_from">Issued From</label>
                <input
                  id="search_issued_from"
                  type="date"
                  class="form-control"
                  v-model="queries.issued_from"
                >
              </div>

              <div class="form-group">
                <label for="search_issued_to">Issued To</label>
                <input
                  id="search_issued_to"
                  type="date"
                  class="form-control"
                  v-model="queries.issued_to"
                >
              </div>

              <div class="form-group">
                <label for="search_due_from">Due From</label>
                <input
                  id="search_due_from"
                  type="date"
                  class="form-control"
                  v-model="queries.due_from"
                >
              </div>

              <div class="form-group">
                <label for="search_due_to">Due To</label>
                <input
                  id="search_due_to"
                  type="date"
                  class="form-control"
                  v-model="queries.due_to"
                >
              </div>

              <div class="form-group">
                <label for="created_by">Creator</label>
                <select
                    id="created_by"
                    v-model="queries.created_by"
                    class="form-control"
                    :disabled="processing"
                >
                  <option value="" />
                  <template v-for="(creator, index) in users">
                    <option
                        :value="creator.email"
                    >
                      {{ creator.name }} {{ creator.email }}
                    </option>
                  </template>
                </select>
              </div>


              <div class="row">
                <div class="col-md-12 col-lg-6">
                  <div class="form-check">
                    <input
                      id="archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="form-check-input"
                      :disabled="processing"
                    >
                    <label
                      class="form-check-label"
                      for="archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-md-12 col-lg-6">
                  <div class="form-check">
                    <input
                      id="trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="form-check-input"
                      :disabled="processing"
                    >
                    <label
                      class="form-check-label"
                      for="trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button
                type="submit"
                class="btn btn-primary"
                :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->

      </div><!--Col-->


      <div class="col-sm-12 col-md-9">

        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-sm-12 col-md-6">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-end">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">

            <table class="table table-striped table-hover">
              <sort-columns
                :columns="columns"
                :queries="queries"
                :processing="processing"
                :base_url="$route.meta.base_url"
              />

              <tbody>
                <template v-for="record in records">
                  <tr>
                    <td>
                      <a :href="'/'+$route.meta.base_url+'/'+record.uuid" class="d-print-none">
                        <i class="fa fa-edit"></i> Edit
                      </a>
                    </td>
                    <td>
                          {{ record.payment_reference }}
                    </td>
                    <td>{{ record.formatted_amount || record.amount }}</td>
                    <td>
                      <template v-if="record.status">
                        <span class="badge badge-pill " :class="setStatusClass(record.status)">{{ record.status }}</span>
                      </template>
                    </td>
                    <td>
                      <template v-if="record && record.client">
                        <client-button :uuid="record.client" />
                      </template>
                    </td>
                    <td>{{ record.contact }}</td>
                    <td>{{ record.type }}</td>
                    <td>{{ record.description }}</td>
                    <td>{{ record.issued_at | date }}</td>
                    <td>{{ record.due_at | date }}</td>
                    <td>{{ record.case_number }}</td>
                    <td>{{ record.citation }}</td>
                    <td>
                    <template v-if="record.location">
                      <a :href="'/locations/'+record.location">{{ setLocationName(record) }}</a>
                    </template>
                    </td>
                    <td>
                    <template v-if="record.portal">
                      <a :href="'/portals/'+record.portal">{{ setPortalName(record) }}</a>
                    </template>
                    </td>
                    <td>
                    <template v-if="record.flow">
                      <a :href="'/flows/'+record.flow">{{ setFlowName(record) }}</a>
                    </template>
                    </td>
                    <td>{{ record.created_by }}</td>
                    <td>{{ record.created_at | datetime }}
                      <span v-if="record.deleted_at" class="badge badge-pill badge-danger">TRASHED</span>
                    </td>
                    <td>{{ record.updated_at | datetime }}
                      <span v-if="record.archived_at" class="badge badge-pill badge-warning">ARCHIVED</span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>

            </div><!--Table responsive-->

            <template v-if="!processing && records && !records.length">
              <div class="alert alert-warning">
                No {{ title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords()"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>

<script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {

mixins: [ queries ],

data() {
    return {
        processing: false,
        downloading: false,
        show_load: false,
        title: null,
        form: {},
        records: [],
        downloads: [],
        columns: [
            { label: 'Edit'},
            {key: 'payment_reference', label: 'Payment Reference'},
            {key: 'amount', label: 'Amount'},
            {key: 'status', label: 'Status'},
            {key: 'client', label: 'Client'},
            {key: 'contact', label: 'Contact'},
            {key: 'type', label: 'Type'},
            {key: 'description', label: 'Description'},
            {key: 'issued_at', label: 'Issued At'},
            {key: 'due_at', label: 'Due At'},
            {key: 'case_number', label: 'Case Number'},
            {key: 'citation', label: 'Citation'},
            {key: 'location', label: 'Location'},
            {key: 'portal', label: 'Portal'},
            {key: 'flow', label: 'Flow'},
            {key: 'created_by', label: 'Creator'},
            {key: 'created_at', label: 'Created'},
            {key: 'updated_at', label: 'Updated'}
        ],
        options: [],
        queries: {},
        total: 0,
        payment_types: [],
        payment_descriptions: [],
        locations: [],
        portals: [],
        flows: [],
        auto_message: {},
        users: [],
        statuses: [],
        showPromotion: false,
        promotionDaysLeft: 0,
        promotionEndDate: ''
    }
},

mounted() {

    var self = this;

    this.$eventBus.$on('paymentCreated', function (data) {
        self.records.unshift(data);
        self.total++;
    });

    this.$eventBus.$on('refreshRecords', function(view) {

        if(view !== self.$route.meta.base_url) {
            return;
        }

        self.resetRecords();

        self.getRecords();

    });

    this.$eventBus.$on('search_clients', (uuid) => {

    if(uuid) {
      self.queries.client = uuid;
    } else {
      self.queries.client = null;
    }

    self.resetRecords();
    self.getRecords();
    });

    this.$eventBus.$on('payments', function (data) {
      self.pushRecord(data);
    });

},

created() {
      
    this.resetQueries();
    this.setURLParams();

    this.title = this.setCustomLabel(this.$route.meta.title);
    this.setDocTitle(this.title+'s');
    this.getOptions();

    this.getRecords();

    this.resetForm();

    if(this.record && this.record.uuid) {
        this.form = this.record;
    }

    this.getTypes();
    this.getDescriptions();

    this.getLocations();
    this.getPortals();

    this.getFlows();

    this.getStatuses();

    this.getUsers();

    this.checkPromotion();

},

methods: {

    resetQueries() {
      this.queries = {
          sortDirection: 'desc',
          sort: 'created_at',
          skip: 0,
          limit: 10,
          archived: false,
          trashed: false,
          upload: null,
          description: null,
          type: null,
          created_by: null,
          client: null,
          recurring: null,
          location: null,
          portal: null,
          flow: null,
          citation: null,
          case_number: null,
          issued_from: null,
          issued_to: null,
          due_from: null,
          due_to: null
      }
    },

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    setStatusClass(status) {
      if(status == 'outstanding') {
        return 'badge-danger';
      }

      if(status == 'paid') {
        return 'badge-success';
      }

      return 'badge-primary';
    },

    resetRecords() {
        this.records = [];
        this.queries.skip = 0;
        this.total = 0;
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    updateURL() {
        history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords() {

        this.processing = true;
        
        this.updateURL();
        this.getTotal();

        this.processing = true;
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
        .then(response => {
            if(response && response.data) {

                if(response.data.length) {

                    var self = this;

                    response.data.forEach(function(data) {
                        self.records.push(data);
                    });
                
                }

                this.queries.skip = this.records.length;

                this.processing = false;
            }
        })
    },


    getTotal() {
        this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
        .then(response => {
            if(response && response.status) {
                this.total = response.data;
            }
        })
    },

    resetForm() {
        this.form = {
            client: null,
            flow: null,
            contact: null,
            amount: 0,
            payment_reference: null,
            auto_message: null,
            due_at: null,
            type: 'fee'
        }
    },

    postForm() {

      if(this.queries.client && this.$options.filters.isValidUUID(this.queries.client)) {
        this.form.client = this.queries.client;
      }

        if(!this.form.status) {
            this.form.status = 'outstanding';
        }

        this.form.skip_sync = true;

        this.processing = true;

        this.$http.post('/'+this.$route.meta.base_url, this.form)
        .then(response => {
            if(response && response.data) {

                if(response.data.uuid) {
                    this.$eventBus.$emit('show-alert', {
                      text: this.title + ' created'
                    });
                    this.$eventBus.$emit('paymentCreated', response.data);
                    this.resetForm();
                    this.$http.post('/'+this.$route.meta.base_url+'/'+response.data.uuid+'/process')
                }

                this.processing = false;
          
            }
        })
    },

    pushRecord(data) {

      if(this.queries.client && !data.client) {
        return;
      }

      if(this.queries.client && data.client) {
        if(this.queries.client != data.client) {
          return;
        }
      }

      var count = this.records.length;

      if(this.records.length > count) {
        this.records.splice(-1);
      }

      var index = _.findIndex(this.records, {'uuid': data.uuid});

      if(index == -1) {
        // this.records.unshift(data);
        // this.total++;
        return;
      }

      var records = [];

      this.records.forEach(function(record) {

        if(record.uuid == data.uuid) {
          record = {...record, ...data};
        }

        records.push(record);


      })

      this.records = records;
    },

    getTypes() {
      this.$http.get('/'+this.$route.meta.base_url+'/uniques?field=type&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.payment_types = response.data;
        }
      })

    },

    getDescriptions() {
      this.$http.get('/'+this.$route.meta.base_url+'/uniques?field=description&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.payment_descriptions = response.data;
        }
      })

    },

    setFlowName(record) {

      if(!record.flow) {
        return;
      }

      var flow = _.find(this.flows, {'uuid': record.flow});

      if(!flow || !flow.name) {
        return;
      }

      return flow.name;
    },

    setLocationName(record) {

      if(!record.location) {
        return;
      }

      var location = _.find(this.locations, {'uuid': record.location});

      if(!location || !location.name) {
        return;
      }

      return location.name;
    },

    setPortalName(record) {

      if(!record.portal) {
        return;
      }

      var portal = _.find(this.portals, {'uuid': record.portal});

      if(!portal || !portal.name) {
        return;
      }

      return portal.name;
    },

    getFlows() {
        this.$http.get('/flows?limit=1000&slim=true&fields=name,uuid&sort=default&sortDirection=desc&type=payment')
        .then(response => {
            if(response && response.data) {
                this.flows = response.data;

                if(!this.form.flow && this.flows.length) {
                  this.form.flow = this.flows[0].uuid;
                }
            }
        })
    },

    getLocations() {
        this.$http.get('/locations?limit=1000&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
                this.locations = response.data;
            }
        })
    },

    getPortals() {
        this.$http.get('/portals?limit=1000&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
        .then(response => {
            if(response && response.data) {
                this.portals = response.data;
            }
        })
    },

    getAutoMessage() {

      this.auto_message = {};

      this.$http.get('/auto_messages?slim=true&fields=uuid&limit=1&trigger=payment_created')
      .then(response => {
        if(response && response.data && response.data[0]) {
          this.auto_message = response.data[0];
        }
      })
    },


  setLanguageName(record) {

    if(!record.language) {
      return;
    }

    var language = _.find(this.languages, {'key': record.language});

    if(!language || !language.label) {
      return record.language;
    }

    return language.label;
  },

  getUsers () {
    this.$http.get('users?limit=1000&slim=y&fields=name,email,uuid&sort=name&sortDirection=asc')
        .then(response => {
          if(response && response.data) {
            this.users = response.data;
          }
        })
  },

    getStatuses() {
      this.$http.get('/statuses?type=payment&fields=name&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.statuses = response.data;

          if(!this.statuses || !this.statuses.length) {
            this.statuses = this.options.payment_statuses;
            this.statuses.forEach(function(status) {
              status.name = status.label;
            })
            return;
          }
        }
      })
    },

    checkPromotion() {
      // Check if user has already dismissed the promotion
      const dismissedUntil = localStorage.getItem('payment_promo_dismissed_until');
      if (dismissedUntil && parseInt(dismissedUntil) > Date.now()) {
        this.showPromotion = false;
        return;
      }

      // Set promotion end date (30 days from today for this example)
      const today = new Date();
      const promotionEndDateObj = new Date();
      promotionEndDateObj.setDate(today.getDate() + 30); // 1 month from today
      
      // Format the end date for display
      this.promotionEndDate = this.$options.filters.date(promotionEndDateObj);
      
      // Calculate days left
      const timeDiff = promotionEndDateObj.getTime() - today.getTime();
      this.promotionDaysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
      
      // Only show if there are days left
      this.showPromotion = this.promotionDaysLeft > 0;
    },
    
    dismissPromotion() {
      // Track promotion dismissal
      this.trackPromotionEngagement('dismiss');
      
      // Hide the promotion
      this.showPromotion = false;
      
      // Store dismissal preference for 7 days (or until promotion ends)
      const today = new Date();
      let dismissUntil = new Date();
      
      // Set to whichever is sooner: 7 days from now or end of promotion
      const sevenDaysFromNow = new Date(today);
      sevenDaysFromNow.setDate(today.getDate() + 7);
      
      const promotionEndDateObj = new Date();
      promotionEndDateObj.setDate(today.getDate() + this.promotionDaysLeft);
      
      dismissUntil = (sevenDaysFromNow < promotionEndDateObj) ? sevenDaysFromNow : promotionEndDateObj;
      
      localStorage.setItem('payment_promo_dismissed_until', dismissUntil.getTime().toString());
    },
    
    trackPromotionEngagement(action) {
      // Track user engagement with the promotion
      try {
        // Send engagement data to the server
        this.$http.post('/analytics/promotion', {
          promotion_id: 'payments_100k',
          action: action,
          days_left: this.promotionDaysLeft
        }).catch(() => {
          // Silent fail - analytics should not interrupt user experience
        });
        
        // For a more comprehensive analytics implementation, you might also want to track:
        // - User role/permissions
        // - Agency details
        // - Current payment volume
        // - etc.
      } catch (e) {
        // Silent catch to prevent analytics from disrupting user experience
        console.error('Error tracking promotion engagement:', e);
      }
    },

    trackActivation() {
      // Track user activation
      this.trackPromotionEngagement('activate');
    }

}

}
</script>

<style scoped>
/* Promotion styles */
.promotion-card {
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.15);
  transition: all 0.3s ease;
  animation: slide-in-top 0.5s ease-out forwards;
  border-width: 2px;
}

.promotion-card:hover {
  box-shadow: 0 6px 16px rgba(0, 123, 255, 0.25);
  transform: translateY(-2px);
}

.pulse-icon {
  animation: pulse 2s infinite;
}

.fa-check-circle {
  color: #28a745;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>