<template>
  <div>

    <page-tabs page="uploads" />

    <template v-if="records && records.length > 0">
      <p class="card-title">{{ records.length }} {{ $route.meta.title }}s</p>
    </template>

    <div class="row">

      <template v-for="record in records">

        <div class="col-sm-12 col-md-4">

        <div class="card mb-3">
          <div class="card-body">

            <div class="row mb-3">

              <div class="col-sm-12 col-md-6">
                <h4 class="text-uppercase">{{ record.name }}</h4>
              </div><!--Col-->

              <div class="col-sm-12 col-md-6 text-end">
                <div class="btn-group">
                  <button type="button" class="btn btn-success btn-sm m-0" @click="importRecord(record)" :disabled="processing"><i class="fa fa-download"></i> Import</button>
                  <button type="button" class="btn btn-primary btn-sm m-0" @click="generateSampleFile(record)" :disabled="processing"><i class="fa fa-file-csv"></i> Sample</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <template v-if="record.description">
              <p class="text-muted">{{ record.description }}</p>
            </template>

            <template v-if="record.fields">

            <p class="fw-bold">{{ Object.keys(record.fields).length }} Mapped Fields</p>

            <ul class="list-group" style="max-height: 400px; overflow-y: auto;">

              <template v-for="(field, field_index) in record.fields">

                <li class="list-group-item list-group-item-action" v-if="field"><code>{{ field }}</code>
                  <br><span class="badge badge-secondary">{{ field_index }}</span>
                </li>

              </template>

            </ul>

            </template>

          </div><!--Body-->
        </div><!--Card-->

        </div><!--Col-->

      </template>

    </div><!--Row--> 

  </div>
</template>
<script>
import queries from '../mixins/queries';

export default {
  
  mixins: [ queries ],

  data () {
    return {
      processing: false,
      records: [],
      queries: {},
      triggers: [],
      templates: [],
      form: {}
    }
  },

  created () {
    this.resetQueries();
    this.resetForm();
    this.getRecords();
  },

  methods: {

    resetQueries() {
      this.queries = {
        slim: false
      }
    },

    resetForm() {
      this.form = {
        name: null 
      }
    },

    getRecords() {
      this.processing = true;
      this.$axios.get('https://assets.ecourtdate.com/demo/upload_templates.json')
      .then(response => {
        if(response && response.data) {
          this.records = response.data;
          this.processing = false;
        }
      })
    },

    generateSampleFile(record) {
      if (!record.fields) {
        return;
      }

      // Extract field names as headers
      const headers = Object.values(record.fields);

      // Create CSV content
      const csvContent = headers.join(",") + "\n";

      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      // Create a link element
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // Set the URL using the Blob
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${record.name}_sample.csv`);
        link.style.visibility = "hidden";

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up and remove the link
        document.body.removeChild(link);
      }
    },

    importRecord(record) {

      record.unique_client = [
        {
          "name": "Client ID",
          "key": "clients.client_reference"
        },
      ];

      record.method = 'update_fields';
      record.date_format = 'Auto';
      record.time_format = 'Auto';
      record.file_format = 'csv';
      record.delimter = ',';
      record.separator = ',';
      record.map_index = 0;
      record.processing_order = 'desc';
      record.disable_notify_contacts = 0;
      record.notify_assigned_users = 0;
      record.load_replace_contacts = 0;
      record.load_replace_clients = 0;
      record.auto_create_statuses = 1;

      this.processing = true;
      this.$http.post('/'+this.$route.meta.base_url, record)
      .then(response => {
        if(response && response.data) {
          
          this.processing = false;

          if(response.data.uuid) {
            this.$router.push('/'+this.$route.meta.base_url+'/'+response.data.uuid);
          }

        }
      })
    },

  }
}
</script>
