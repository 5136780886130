<template>
    <div>
  
      <page-tabs page="events" />

      <template v-if="form.participants && hasMinParticipants">
        <div class="alert alert-success">
          <i class="fa fa-thumbs-up"></i> You have met the minimum number of participants for this event.
        </div>
      </template>
      <template v-else>
        <div class="alert alert-danger">
         <i class="fa fa-thumbs-down"></i> You have not met the minimum number of participants for this event.
        </div>
      </template>
      
      <div class="row">
        <div class="col-sm-12 col-md-3">

          <div class="card">
            <div class="card-body">
              <p class="card-title">Book Clients</p>

              <p class="card-text">Book a designated number of clients for an event.</p>

              <form @submit.prevent="postForm">

              <div class="form-group">
                <label for="description">Description</label>
                <input type="text" class="form-control" id="description" v-model="form.description" required>
              </div>

              <div class="form-group">
                <label for="group">Group</label>
                <select
                    id="group"
                    v-model="form.group"
                    class="form-control"
                    :disabled="processing"
                    @change="getClients"
                >
                  <option value="" />
                  <template v-for="group in clientGroups">
                    <option
                        :value="group"
                    >
                      {{ group }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group mt-3">
                <label for="start_date">Start Date</label>
                <input
                  id="start_date"
                  v-model="form.start_date"
                  type="date"
                  class="form-control"
                  required
                  :disabled="processing"
                >
              </div>

              <div class="form-group mt-3">
                <label for="end_date">End Date</label>
                <input
                  id="end_date"
                  v-model="form.end_date"
                  type="date"
                  class="form-control"
                  required
                  :disabled="processing"
                >
              </div>

              <div class="form-group">
                <label for="start_time">Start Time</label>
                <input
                  id="start_time"
                  v-model="form.start_time"
                  type="time"
                  class="form-control"
                  required
                  :disabled="processing"
                >
              </div>

              <div class="form-group">
                <label for="end_time">End Time</label>
                <input
                  id="end_time"
                  v-model="form.end_time"
                  type="time"
                  class="form-control"
                  required
                  :disabled="processing"
                >
              </div>

              <div class="form-group">
                <label for="location">Location</label>
                <select
                    id="location"
                    v-model="form.location"
                    class="form-control"
                    :disabled="processing"
                    required
                >
                  <option value="" />
                  <template v-for="location in locations">
                    <option
                        :value="location.uuid"
                    >
                      {{ location.name }}
                    </option>
                  </template>
                </select>
              </div>

              <div class="form-group">
                <label for="participants">Required Participants</label>
                <input type="number" class="form-control" id="min_participants" v-model="form.participants" step="1" required>
              </div>

              <button class="btn btn-success" :disabled="processing">Save</button>

              </form>

            </div><!--Body-->
          </div><!--Card-->


        </div><!--Col-->

        <div class="col-sm-12 col-md-3">

          <div class="row">
            <div class="col-sm-12 col-md-6">
              <p class="card-title">
                  {{  clients.length }}
                  Available Clients
                </p>
            </div><!--Col-->
            <div class="col-sm-12 col-md-6">
              <template v-if="clients && clients.length">
                <button class="btn btn-secondary btn-sm float-end" @click="bookAll()" :disabled="processing">Book All</button>
              </template>
            </div><!--Col-->
          </div><!--Row-->

             <template v-for="client in clients">

              <li class="list-group-item">
                    <p>{{ client.name }}</p>
                    <template v-if="client.group">
                      <span class="badge badge-secondary float-end">{{ client.group }}</span>
                    </template>
                    <button class="btn btn-success" @click="postBooker(client)" :disabled="processing">Book</button>
              </li>

              </template>

        </div><!--Col-->

        <div class="col-sm-12 col-md-3">

          <div class="row">
            <div class="col-sm-12 col-md-6">
              <p class="card-title">
                  {{  bookings.length }}
                  Booked Clients
                </p>
            </div><!--Col-->
            <div class="col-sm-12 col-md-6">
              <button class="btn btn-secondary btn-sm float-end" @click="getBookings" :disabled="processing">Sync</button>
            </div><!--Col-->
          </div><!--Row-->

                <template v-for="booking in bookings">

                  <li class="list-group-item">
                    <p>{{ booking.name }}</p>
                    <template v-if="booking.status">
                      <span class="badge text-uppercase float-end" :class="setBookingClass(booking.status)">{{ booking.status }}</span>
                    </template>
                    
                    <div class="btn-group my-3">
                      
                    <a :href="'/clients/'+booking.client" class="btn btn-secondary btn-sm" target="_blank">View Client</a>
                    
                    <template v-if="portal && portal.link && portal.domain && booking.url">
                      <a :href="setBookingURL(booking)" class="btn btn-secondary btn-sm" target="_blank">View Booking</a>
                    </template>

                    </div>
                  </li>

                  </template>


            </div><!--Col-->

            <div class="col-sm-12 col-md-3">

              <div class="card">
                <div class="card-body">
                  <p class="card-title">Message Clients</p>

                  <p class="card-text">Message clients about this booking.</p>

                  <form @submit.prevent="postMessage">

                  <div class="form-group">
                    <label for="status">Status</label>
                    <select
                        id="status"
                        v-model="messageForm.status"
                        class="form-control"
                        :disabled="processing"
                    >
                    <option value="" />
                    <option value="invited">Invited</option>
                    <option value="confirmed">Confirmed</option>
                    <option value="declined">Declined</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="subject">Subject</label>
                    <input type="text" class="form-control" id="subject" v-model="messageForm.subject" required>
                  </div>

                  <div class="form-group">
                    <label for="content">Content</label>
                    <textarea class="form-control" id="content" v-model="messageForm.content" required></textarea>
                  </div>

                  <button class="btn btn-success" :disabled="processing">Send</button>

                  </form>

                </div><!--Body-->
              </div><!--Card-->

            </div><!--Col-->



      </div><!--Row-->


    </div>
  </template>
  <script>
import queries from '../mixins/queries';
  
  export default {
      mixins: [ queries ],
      data() {
          return {
              processing: false,
              form: {},
              locations: [],
              clientGroups: [],
              clients: [],
              bookings: [],
              portal: {},
              messageForm: {
                status: "",
                subject: "",
                content: ""
              }
          }
      },
  
      created() {
        this.resetForm();
        this.getLocations();
        this.setClientGroups();
        this.getRecord();
        this.getPortal();
      },
  
      computed: {
        
        hasMinParticipants() {
          if(!this.form.participants || this.form.participants < 1) {
            return false;
          }

          if(!this.bookings || this.bookings.length < this.form.participants) {
            return false;
          }

          //filter only confirmed bookings
          let confirmed = this.bookings.filter(booking => booking.status === 'confirmed');

          if(confirmed.length < this.form.participants) {
            return false;
          }

          return true;
        }
      },

      methods: {

        resetForm() {
          this.form = {
            group: "",
            start_date: "",
            end_date: "",
            start_time: "",
            end_time: "",
            location: "",
            participants: 0
          }
        },

        getLocations() {
          this.$http.get('/locations?limit=100&slim=true&fields=name,uuid&sort=name&sortDirection=asc')
              .then(response => {
                if(response && response.data && response.data.length > 0) {
                  this.locations = response.data;
                }
              })
        },

        setClientGroups() {
          if(!this.settings || !this.settings.default_groups) {
            return;
          }

          this.clientGroups = this.settings.default_groups.split(',');
        },

        postForm() {
          this.processing = true;
          this.$http.patch('/'+this.$route.meta.base_url+'/'+this.form.uuid, this.form)
              .then(response => {
                this.processing = false;
                
                this.form = {...this.form, ...response.data};

                this.getBookings();
                this.getClients();
              })
        },

        getRecord() {
          this.processing = true;
          this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
              .then(response => {
                if(response && response.data) {
                  this.form = response.data;
                  this.processing = false;
                  this.getBookings();
                  this.getClients();
                }
              })
        },

        getClients() {

          let queries = {
            slim: true,
            limit: 100,
            fields: 'name,uuid,group'
          };

          if(this.form.group) {
            queries.groups = this.form.group;
          }

          this.$http.get(this.buildQueries('/clients', queries))
          .then(response => {
            if(response && response.data) {
              let clients = response.data;

              // Filter out clients that are already booked
              if(this.bookings && this.bookings.length > 0) {
                clients = clients.filter(client => {
                  return !this.bookings.find(booking => booking.client === client.uuid);
                })
              }

              this.clients = clients;
            }
          })
        },

        getPortal() {
          this.$http.get('/portals?limit=1&sort=default&sortDirection=desc')
          .then(response => {
            if(response && response.data && response.data.length > 0) {
              this.portal = response.data[0];
            }
          })
        },

        setBookingClass(status) {
          if(status === 'invited') {
            return 'badge-primary';
          } else if(status === 'confirmed') {
            return 'badge-success';
          } else if(status === 'declined') {
            return 'badge-danger';
          }
        },

        setBookingURL(booking) {
          if(this.portal && this.portal.link && this.portal.domain && booking.url) {
            return 'https://'+this.portal.link+'.'+this.portal.domain+'/bookings/'+booking.url;
          }
        },

        getBookings() {
          this.$http.get('/booker?booking='+this.form.uuid)
          .then(response => {
            if(response && response.data) {
              this.bookings = response.data;
            }
          })
        },

        postBooker(client) {

          let form = {
            booking: this.form.uuid,
            client: client.uuid,
            name: client.name,
            status: 'invited'
          }

          this.processing = true;

          this.$http.post('/booker', form)
          .then(response => {
            if(response && response.data) {

              if(response.data.uuid) {
                if(!this.bookings) {
                  this.bookings = [];
                }

                this.bookings.unshift(response.data);

                // Remove client from available clients
                this.clients = this.clients.filter(c => c.uuid !== client.uuid);

                this.notifyClient(client, response.data);
              }
              this.processing = false;
            }
          })
        },

        notifyClient(client, booking) {

          let form = {
            client: client.uuid,
            subject: 'You have been booked for an event on '+this.form.start_date,
            content: 'You have been booking for an event on '+this.form.start_date+' at '+this.form.start_time+'. Click the link to confirm attendance.',
          }

          if(this.setBookingURL(booking)) {
            form.content += ' Confirm attendance: '+this.setBookingURL(booking);
          }

          if(this.form.description) {
            form.content += ' Event Description: '+this.form.description;
          }

          this.$http.post('/messages', form)
          .then(response => {
            if(response && response.data) {
            }
          })
        },

        bookAll() {
          this.clients.forEach(client => {
            this.postBooker(client);
          })
        },

        postMessage() {
          this.processing = true;

          let bookings = this.bookings;

          if(this.messageForm.status) {
            bookings = bookings.filter(booking => booking.status === this.messageForm.status);
          }

          bookings.forEach(booking => {
            let form = {
              client: booking.client,
              subject: this.messageForm.subject,
              content: this.messageForm.content
            }

            this.$http.post('/messages', form)
            .then(response => {
              if(response && response.data) {
                this.$eventBus.$emit('show-alert', {
                  text: 'Message sent to '+booking.name
                });
              }
            })
          })
        }
      }
  }
  </script>