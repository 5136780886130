<template>
  <div>

    <page-tabs page="portals" />

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="card">
          <div class="card-body">
            <p class="card-title">
              Create
            </p>

            <form @submit.prevent="postForm()">

              <div class="form-group">
                <label for="title">Title</label>
                <input
                  id="title"
                  v-model="form.title['en']"
                  class="form-control"
                  required
                >
              </div>

                <button
                  type="submit"
                  class="btn btn-success"
                  :disabled="processing"
                >
                  Add
                </button>

            </form>
          </div><!--Body-->
        </div><!--Card-->

        <div class="card my-3">
          <div class="card-body">
            <p class="card-title">Search</p>

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search">Search</label>
                <input
                  id="search"
                  v-model="queries.search"
                  class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="search_status">Status</label>
                <select
                  id="search_status"
                  v-model="queries.status"
                  class="form-control"
                >
                  <option value="">All</option>
                  <option value="draft">Draft</option>
                  <option value="private">Private</option>
                  <option value="published">Published</option>
                </select>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="archived"
                      v-model="queries.archived"
                      type="checkbox"
                      class="form-check-input"
                      @change="searchRecords"
                      :disabled="processing || queries.trashed"
                    >
                    <label
                      class="form-check-label"
                      for="archived"
                    >Archived</label>
                  </div>
                </div><!--Col-->

                <div class="col-sm-12 col-md-6">
                  <div class="form-check">
                    <input
                      id="trashed"
                      v-model="queries.trashed"
                      type="checkbox"
                      class="form-check-input"
                      @change="searchRecords"
                      :disabled="processing || queries.archived"
                    >
                    <label
                      class="form-check-label"
                      for="trashed"
                    >Trashed</label>
                  </div>
                </div><!--Col-->
              </div><!--Row-->

              <button type="submit" class="btn btn-primary" :disabled="processing">
                Search
              </button>

            </form>

        </div><!--Body-->
      </div><!--Card-->

      </div><!--Col-->

      <div class="col-sm-12 col-md-9">

            <div class="row mb-3">

            <div class="col-sm-12 col-md-6">

              <p class="card-title">
                {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
              </p>

            </div><!--Col-->

            <div class="col-sm-12 col-md-6 text-end">

              <div class="btn-group d-print-none">
                <button type="button" class="btn btn-secondary btn-sm m-0" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                <button type="button" class="btn btn-secondary btn-sm m-0" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
              </div>
            </div><!--Col-->

            </div><!--Row-->


                <template v-if="records && records.length > 0">
                  <div class="row">
                  <template v-for="record in records">
                    <div class="col-sm-12 col-md-4 mb-3">
                      <div class="card">
                        <div class="card-body" style="max-height:460px;overflow-y:auto">
                            
                          <h5 class="card-title"><i class="fa fa-edit"></i> <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                            <template v-if="record.title && record.title['en']">
                              {{ record.title['en'] }}
                            </template>
                            <template v-if="!record.title || !record.title['en']">
                              Untitled
                            </template>
                          </a></h5>

                          <template v-if="record.summary && record.summary['en']">
                            <p class="card-text">{{ record.summary['en'] }}</p>                          
                          </template>

                          <template v-if="record.status">                          
                            <span class="badge text-uppercase" :class="setStatusClass(record)">{{ record.status }}</span>
                          </template>

                          <p class="card-text">
                            <small class="text-muted">
                              <i class="fa fa-globe"></i> {{ setLanguageName(record) }}
                            </small>
                          </p>

                          <template v-if="record.portal">
                          <p class="card-text">
                            <small class="text-muted">
                              <i class="fa fa-browser"></i> {{ setPortalName(record) }}
                            </small>
                          </p>
                          </template>

                          <template v-if="!record.portal">
                            <div class="alert alert-danger">Assign a Portal</div>
                          </template>

                          <template v-if="record.location">
                          <p class="card-text">
                            <small class="text-muted">
                              <i class="fa fa-map"></i> {{ setLocationName(record) }}
                            </small>
                          </p>
                          </template>

                          <p class="card-text">
                            <small class="text-muted">
                              <i class="fa fa-user"></i> {{ record.created_by }}
                            </small>
                          </p>

                        </div><!--Body-->
                        <div class="card-footer">
                          <small class="text-muted">
                            <i class="fa fa-clock"></i> Created {{ record.created_at | datetime }}
                          </small>

                          <template v-if="record.updated_at">
                            <small class="text-muted">
                              <i class="fa fa-clock"></i> Updated {{ record.updated_at | datetime }}
                            </small>
                          </template>

                          <template v-if="record.archived_at">
                            <small class="text-muted">
                              <i class="fa fa-archive"></i> Archived {{ record.archived_at | datetime }}
                            </small>
                          </template>

                          <template v-if="record.deleted_at">
                            <small class="text-muted">
                              <i class="fa fa-trash"></i> Trashed {{ record.deleted_at | datetime }}
                            </small>
                          </template>

                        </div><!--Footer-->
                      </div><!--Card-->
                    </div>
                  </template>
                  </div><!--Row-->
                </template>

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

        <template v-if="records && records.length && total > records.length">

        <div class="row mt-3">
          <div class="col-12 text-center">

              <button
                type="button"
                class="btn btn-outline-primary"
                :disabled="processing"
                @click="getRecords"
              >
                Load more
              </button>

          </div><!--Col-->
        </div><!--Row-->

        </template>

      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import _ from "lodash";
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data () {
    return {
      processing: false,
      validating: false,
      validated: false,
      total: 0,
      queries: {},
      records: [],
      form: {},
      columns: [
      { key: 'title', label: 'Title'},
      { key: 'language', label: 'Language'},
      { key: 'status', label: 'Status'},
      { key: 'portal', label: 'Portal'},
      { key: 'location', label: 'Location'},
      { key: 'created_by', label: 'Created'},
      { key: 'created_at', label: 'Created'},
      { key: 'updated_at', label: 'Updated'}
      ],
      locations: [],
      portals: []
    }
  },

  created() {
    this.resetForm();
    this.resetQueries();
    this.resetRecords();
    this.setURLParams();
    this.getRecords();
    this.getPortals();
    this.getLocations();
  },

  methods: {


    setURLParams() {

        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {
            self.queries[key] = value;
        });
    },

    setStatusClass(record) {

      if(record.status == 'private') {
        return 'badge-danger';
      }

      if(record.status == 'draft') {
        return 'badge-secondary';
      }

      return 'badge-success';
    },

    resetQueries() {
      this.queries = {
        limit: 10,
        slim: false,
        search: null,
        status: null,
        sort: 'created_at',
        sortDirection: 'desc'
      }
    },

    resetForm() {
      this.form = {
        title: {
          'en': null
        },
        summary: {
          'en': null
        },
        description: {
          'en': null
        },
        language: 'en',
        status: 'draft',
        comments: true,
        social_share: true
      }
    },

    searchRecords() {
      this.resetRecords();
      this.getRecords();
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    updateSortDirection() {

      if(this.processing) {
        return;
      }

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      if(this.processing) {
        return;
      }

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    getRecords() {

      this.processing = true;

      this.updateURL();
      this.getTotal();

      this.$http.get(this.buildQueries(this.$route.meta.base_url, this.queries))
      .then(response => {
        if(response && response.data) {
            
            if(response.data.length) {

                var self = this;

                response.data.forEach(function(data) {
                    self.records.push(data);
                });
            }
            
            this.queries.skip = this.records.length;

            this.processing = false;
        }
      })
    },

    getTotal() {
      this.$http.get(this.buildQueries(this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
          this.setTitle();
        }
      })
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries(this.$route.meta.base_url, this.queries));
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    postForm() {
      this.processing = true;
      this.$http.post(this.$route.meta.base_url, this.form)
      .then(response => {
        if(response && response.data) {

          this.processing = false;

          if(response.status === 201) {
            this.resetForm();
            this.total++;
            this.records.unshift(response.data);
            this.$eventBus.$emit('show-alert', {
              text: this.$route.meta.title + ' created'
            });
          }

        }
      })
    },

    setPortalName(record) {

      if(!record.portal) {
        return;
      }

      var portal = _.find(this.portals, {'uuid': record.portal});

      if(!portal || !portal.name) {
        return;
      }

      return portal.name;
    },

    setLocationName(record) {

      if(!record.location) {
        return;
      }

      var location = _.find(this.locations, {'uuid': record.location});

      if(!location || !location.name) {
        return;
      }

      return location.name;
    },

    setLanguageName(record) {

      if(!record.language) {
        return;
      }

      var language = _.find(this.languages, {'key': record.language});

      if(!language || !language.label) {
        return record.language;
      }

      return language.label;
    },

    getLocations () {
      this.$http.get('/locations?slim=true&limit=1000&sort=name&sortDirection=asc&fields=uuid,name')
          .then(response => {
            if (response && response.data) {
              this.locations = response.data;
            }
          })
    },

    getPortals () {
      this.$http.get('/portals?slim=true&limit=1000&sort=name&sortDirection=asc&fields=uuid,name,link')
          .then(response => {
            if (response && response.data) {
              this.portals = response.data;
            }
          })
    },
   

  },
}
</script>