<template>
<div>
  <div class="card">
    <div class="card-body" style="max-height:400px;overflow-y: auto">

      <p class="card-title">Copy a Merge Tag</p>

        <template v-for="(merge_tag, index) in merge_tags">
            <a
              class="dropdown-item"
              @click="copyTag(merge_tag.value)"
            >{{ merge_tag.value }}</a>
        </template>

      </div><!--Body-->
    </div><!--Card-->

</div>
</template>
<script>
export default {
  
  data() {
      return {
        merge_tags: []
      }
  },

  mounted () {
    this.getMergeTags();
  },

  methods: {

    copyTag (value) {
        navigator.clipboard.writeText(value);
        this.$eventBus.$emit('show-alert', {
            title: 'Merge Tag Copied',
            text: 'Merge Tag ' + value + ' copied. Paste it into the desired template.',
            type: 'success'
        });
    },

    getMergeTags () {
      this.$axios.get(this.$root.assets_url+'js/mergetags.json')
      .then(response => {
          if(response && response.data) {
            this.merge_tags = response.data;
          }
      })
    }
  }
}
</script>
