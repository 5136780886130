<template>
    <div class="row">
      <div class="col-md-3 col-sm-12">
  
          <div class="card mb-3">
            <div class="card-body">
              <p class="card-title">
                Agency
              </p>
  

              <template v-if="$root.current_user && $root.current_user.current_agency">
                <h4>{{ $root.current_user.current_agency.name }}</h4>
  
                <p class="m-0"><strong>Region:</strong> {{ $root.current_user.current_agency.region }}</p>

                <div class="btn-group">

                <template v-if="record && record.ticket_url">
                    <a :href="record.ticket_url" target="_blank" class="btn btn-primary btn-sm">Report URL</a>
                </template>

                <a :href="'https://console.ecourtdate.com/agencies/'+$root.current_user.current_agency.uuid" target="_blank" class="btn btn-primary btn-sm">View in Console</a>

                    <a :href="'https://help.ecourtdate.com/tickets/'+$route.params.id" target="_blank" class="btn btn-primary btn-sm">View in Help Center</a>

                </div>
  
              </template>
               
            </div><!--Body-->
          </div><!--Card-->

          <template v-if="record.created_by">
            <div class="card my-3">
              <div class="card-body">
                  <p class="card-title">User</p>

                <p class="m-0"><strong>Email:</strong> {{ record.created_by }}</p>

                <template v-if="record.updated_by">
                    <p class="m-0"><strong>Updated By:</strong> {{ record.updated_by }}</p>
                </template>

                <a :href="'https://console.ecourtdate.com/users?email='+record.created_by" target="_blank" class="btn btn-sm btn-primary">View in Console</a>
                

            </div><!--Body-->
          </div><!--Card-->
          </template>
          
        </div><!--Col-->

        <div class="col-md-9 col-sm-12">

            <template v-if="record && record.uuid">

                <div class="card">
                    <div class="card-body">

                        <template v-if="record.title">
                            <h4>{{ record.title }}</h4>
                        </template>

                        <template v-if="record.type">
                            <p class="m-0"><strong>Type:</strong> {{ record.type }}</p>
                        </template>

                        <template v-if="record.description">
                            <p class="lead">{{ record.description }}</p>
                        </template>

                        <template v-if="record.status">
                            <p class="m-0"><strong>Status:</strong> <span class="badge badge-pill text-uppercase" :class="setStatusClass(record.status)">{{ record.status }}</span></p>
                        </template>

                        <template v-if="record.priority">
                            <p class="m-0"><strong>Priority:</strong> <span class="badge badge-pill text-uppercase" :class="setPriorityClass(record.priority)">{{ record.priority }}</span></p>
                        </template>

                        <template v-if="record.created_at">
                            <p class="m-0"><strong>Created:</strong> {{ record.created_at | datetime }}</p>
                        </template>

                    </div><!--Body-->
                </div><!--Card-->

                <div class="card my-3">
                    <div class="card-body">
                        <form @submit.prevent="postComment">
                            
                        <div class="form-group">
                            <label for="comment">Create a Comment</label>
                            <textarea v-model="comment" class="form-control" rows="3" required></textarea>
                        </div>

                        <button type="submit" class="btn btn-success mt-3" :disabled="processing">Create</button>
                        
                        </form>
                    </div>
                </div>

                <h6 class="mt-3">{{ record.comments.length }} Comments</h6>
                <template v-for="comment in record.comments">
                    <p class="outbound m-0">{{ comment.content }}</p>
                    <p class="m-0"><strong>Created By:</strong> {{ comment.created_by }}
                        <span class="float-end"><strong>Created At:</strong> {{ comment.created_at | datetime }}</span>
                    </p>
                    
                </template>

                
            </template>
            
          </div><!--Col-->
  
        </div><!--Row-->
    </div>
  </template>
  <script>
  import queries from '../mixins/queries';
  
  export default {
  
      mixins: [ queries ],
  
      data () {
          return {
              processing: false,
              comment: '',
              record: {}
            }
      },
  
  
      created () {
          this.getRecord();
      },
  
      methods: {

          getRecord () {
  
              this.processing = true;
              this.$http.get('/'+this.$route.meta.base_url+'/'+this.$route.params.id)
              .then(response => {
                  if(response && response.data) {
  
                      this.record = response.data;

                      if(!this.record.comments) {
                          this.record.comments = [];
                      }
    
                      this.processing = false;
    
                  }
              })
          },

          setStatusClass(status) {
            let statuses = {
                'reported': 'badge-danger',
                'investigating': 'badge-warning',
                'engineering': 'badge-primary',
                'testing': 'badge-success',
                'resolved': 'badge-success',
                'backlog': 'badge-secondary',
                'canceled': 'badge-danger'
            }

            return statuses[status];
        },

          setPriorityClass(priority) {

            let priorities = {
                'high': 'badge-danger',
                'low': 'badge-success',
                'standard': 'badge-primary'
            }

            return priorities[priority];
        },


        postComment() {
            this.processing = true;

            this.$http.post('/'+this.$route.meta.base_url+'/'+this.$route.params.id+'/comments', {
                content: this.comment
            })
            .then(response => {
                if(response && response.data) {
                    this.processing = false;

                    this.comment = '';

                    if(!this.record.comments) {
                        this.record.comments = [];
                    }

                    if(response.data.uuid) {
                        this.record.comments.unshift(response.data);
                    }
                }
            })
        }
      }
  }
  </script>   