<template>
  <div>
    <div
      class="card"
      style="overflow-x:auto"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <template v-if="downloads && downloads.length > 0">
              <div class="alert alert-success">
                Downloaded {{ downloads.length }} of {{ total.toLocaleString() }}
              </div>
            </template>

            <h4 class="text-capitalize m-0">
              {{ form.name }}
            </h4>

            <p class="m-0">
              {{ records.length }} of {{ total.toLocaleString() }} results
            </p>

            <template v-if="form.filters && form.filters.length > 0">
              <p class="m-0 small text-muted">
                {{ form.filters.length }} filters
              </p>
            </template>

            <template v-if="form.deleted_at">
              <span class="badge badge-danger">TRASHED</span>
            </template>
          </div><!--Col-->

          <div class="col-sm-12 col-md-6 text-end d-print-none">

            <template v-if="!form.deleted_at && !report.deleted_at">
              <div class="btn-group">


                <button
                  type="button"
                  class="btn btn-success btn-sm"
                  data-bs-toggle="modal"
                  :data-bs-target="'#'+modalName"
                >
                  EDIT
                </button>

                <button
                  type="button"
                  class="btn btn-secondary btn-sm"
                  @click="duplicateDataset"
                >
                  DUPLICATE
                </button>


                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  @click="exportRecords(form.uuid)"
                >
                  CSV
                </button>

                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  @click="trashDataset"
                >
                  TRASH
                </button>
              </div>
            </template>
          </div><!--Col-->
        </div><!--Row-->
        <div class="horizontal-scroll">
          <table class="table table-striped table-hover">
            <thead>
              <template v-if="headers && headers.length > 0">
                <tr>
                  <template v-for="field in headers">
                    <th
                      scope="col"
                    >
                      {{ field }}
                    </th>
                  </template>
                </tr>
              </template>
            </thead>

            <template v-if="records && records.length">
              <tbody>
                <template v-if="!processing">
                  <template v-for="record in records">
                    <tr>
                      <template v-for="data in record">
                        <th>
                          {{ data }}
                        </th>
                      </template>
                    </tr>
                  </template>
                </template>
              </tbody>
            </template>
          </table>
        </div>

        <template v-if="show_load && !loading && !processing && records && records.length > limit">
          <button
            type="button"
            class="btn btn-outline-primary d-print-none"
            @click="loadMore"
          >
            Load more
          </button>
        </template>

        <template v-if="loading || processing">
          <loader :is-loading="true" />
        </template>

        <timestamps :form="form" />

        <template v-if="form.created_by">
          Creator: {{ form.created_by }}
        </template>
      </div><!--Body-->
    </div><!--Card-->


    <div
      :id="modalName"
      class="modal fade"
      tabindex="-1"
      role="dialog"
    >
      <div
        class="modal-dialog modal-xl modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-capitalize">
              Edit Dataset
            </h5>

            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="dataset_name">Dataset Name</label>
              <input
                id="dataset_name"
                v-model="form.name"
                type="text"
                class="form-control"
              >
            </div>

            <div class="form-group">
              <label for="fields">Dataset Fields</label><br>
              <multi-select
                id="fields"
                v-model="form.fields" 
                :options="fieldOptions"
                placeholder="Choose fields"
                label="name"
                track-by="key"
                :multiple="true"
                :close-on-select="false"
              />
            </div>

            <h4>Customize Fields</h4>

            <ul class="list-group">
              <draggable
                v-model="form.fields"
                group="fields"
                @start="drag=true"
                @end="drag=false"
              >
                <li
                  v-for="field in form.fields"
                  class="list-group-item"
                >
                  <div class="row">
                    <div class="col-6">
                      <input
                        v-model="field.name"
                        type="text"
                        class="form-control"
                      >
                    </div><!--Col-->

                    <div class="col-6">
                      <button type="button" class="btn btn-outline-secondary"><i class="fa fa-bars"></i> {{ field.key }}</button>
                    </div><!--Col-->
                  </div><!--Row-->
                </li>
              </draggable>
            </ul>

            

            <div class="row mt-3">
              <div class="col-9">
                <h4>Dataset Filters</h4>
              </div><!--Col-->

              <div class="col-3 text-end">
                <template v-if="editingFilter">
                  <button
                    type="button"
                    class="btn btn-outline-danger btn-sm"
                    @click="closeFilter"
                  >
                    Close Filter
                  </button>
                </template>
              </div><!--Col-->
            </div><!--Row-->


            <form @submit.prevent="postFilter(filterForm)">
              <div class="form-group">
                <label for="filter_field">Field</label>
                <select
                  id="filter_field"
                  v-model="filterForm.field"
                  class="form-control"
                >
                  <option
                    v-for="fieldOption in fieldOptions"
                    :value="fieldOption.key"
                  >
                    {{ fieldOption.name }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="operator">Operator</label>
                <select
                  id="operator"
                  v-model="filterForm.operator"
                  class="form-control"
                >
                  <option value="=">
                    Equals
                  </option>
                  <option value="!=">
                    Does Not Equal
                  </option>
                  <option value=">">
                    Greater Than
                  </option>
                  <option value=">=">
                    Greater Than Equal
                  </option>
                  <option value="<">
                    Less Than
                  </option>
                  <option value="<=">
                    Less Than Equal
                  </option>
                  <option value="between">
                    Between
                  </option>
                  <option value="like">
                    Like
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="condition">Condition</label>
                <input
                  id="condition"
                  v-model="filterForm.condition"
                  type="text"
                  class="form-control"
                  required
                >
              </div>

              <template v-if="filterForm.field && filterForm.condition && filterForm.condition.length > 0">
                <button
                  type="submit"
                  class="btn btn-primary mb-3"
                  @click.prevent="postFilter(filterForm)"
                >
                  <i
                    v-if="editingFilter === false"
                    class="fa fa-plus"
                  />
                  <i
                    v-if="editingFilter === true"
                    class="fa fa-check-circle"
                  />
                </button>
              </template>
            </form>


            <template v-if="form.filters && form.filters.length > 0">
              <template v-for="filter in form.filters">
                <li
                  class="list-group-item"
                >
                  {{ filter.field }} {{ filter.operator }}

                  {{ filter.condition }}

                  <div class="btn-group float-end">
                    <button
                      type="button"
                      class="btn btn-success btn-sm"
                      @click="editFilter(filter)"
                    >
                      <i class="fa fa-edit" /> Edit
                    </button>

                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      @click="removeFilter(filter)"
                    >
                      <i class="fa fa-trash" /> Trash
                    </button>
                  </div>
                </li>
              </template>
            </template><!--Filters-->

            <template v-if="form.filters && !form.filters.length">
              <div class="alert alert-warning">
                No filters found.
              </div>
            </template>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success"
              data-bs-dismiss="modal"
              @click="updateDataset"
            >
              Update Dataset
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import jsonParse from '../mixins/jsonParse';
import draggable from 'vuedraggable';

export default {

components: { draggable },
mixins: [jsonParse],
props: ['dataset', 'from_date', 'to_date', 'report'],


data() {
  return {
    processing: false,
    loading: false,
    show_load: false,
    base_url: 'datasets',
    records: [],
    form: {},
    filterForm: {
      operator: '='
    },
    editingFilter: false,
    total: 0,
    datasetFields: [],
    headers: [],
    downloads: [],
    limit: 10
  }
},

computed: {

  modalName: function() {
    return 'modal-'+this.dataset.uuid;
  },

  fieldOptions: function () {
      return _.orderBy(this.datasetFields, ['name']);
  },


},

mounted() {

  this.getDataset();

  this.getDatasetFields();

  var self = this

  this.$eventBus.$on('reportUpdated', function () {
      self.getDataset()
  });

  this.$eventBus.$on('searchFilters', function(filters) {
    self.form.filters = filters
  });

},


methods: {

    getDatasetFields() {
        this.$axios.get(this.$root.assets_url+'js/dataset_fields.json')
        .then(response => {
            if(response && response.data) {
                this.datasetFields = response.data;
            }
        })
    },

    updateDataset() {
      this.processing = true;

      this.form.report = this.$route.params.id;

      this.$http.patch(this.base_url + '/' + this.form.uuid, this.form)
      .then(response => {
        if(response && response.data) {
          this.skip = 0
          this.getDatasetFields()
          this.getDataset();
        }
      })
    },


    getDataset() {
      this.processing = true;
      this.$http.get(this.base_url + '/' + this.dataset.uuid)
      .then(response => {
        if(response && response.data) {
          this.form = response.data;
          this.getRecords();
        }
      })
    },

    getRecords() {
      this.processing = true;
      this.form.from_date = this.from_date
      this.form.to_date = this.to_date

      this.$http.post(this.base_url + '/search', this.form)
      .then(response => {
        if(response && response.data && response.data.data) {

          if(response.data.data.length < this.limit) {
            this.show_load = false
          } else {
            this.show_load = true
          }

          this.processing = false
          this.loading = false

          this.records = response.data.data

          this.total = response.data.total
          this.headers = response.data.fields
        } else {
          this.processing = false
          this.show_load = false
          this.loading = false
        }
      })
    },

    loadMore() {
      this.loading = true
      this.$http.post(this.base_url + '/search?skip='+this.records.length, this.form)
        .then(response => {
          if(response && response.data && response.data.data) {

              if(response.data.data.length < this.limit) {
                this.show_load = false
              } else {
                this.show_load = true
              }

              this.total = response.data.total

              if(response.data.data.length > 0) {
                response.data.data.forEach(r => {  
                    this.records.push(r)
                })                
              }

              this.loading = false
              this.processing = false
          } else {
            this.show_load = false
          }
        });
    },

    trashDataset() {
      this.processing = true
      this.$http.delete(this.base_url + '/' + this.dataset.uuid)
      .then(response => {
        if(response && response.status === 204) {
        this.form = {};
        this.processing = false;
        this.$eventBus.$emit('datasetDeleted', this.dataset.uuid);
        this.$eventBus.$emit('show-alert', {
          text: 'Dataset Deleted',
          type: 'danger'
        });
        }
      })
    },

    postFilter(filter) {
        if(this.editingFilter === true) {
            this.editingFilter = false;
        } else {

            if(this.form.filters && this.form.filters.length > 0) {
              this.form.filters.push(filter);
            } else {
              let filters = [];
              filters.unshift(filter);
              this.form.filters = filters;          
            }
        }

        this.$eventBus.$emit('searchFilters', this.form.filters);

        this.filterForm = {};
    },

    removeFilter(filter) {
        this.form.filters = _.remove(this.form.filters, function (f) {
            return !_.isEqual(f, filter)
        })

        this.$eventBus.$emit('searchFilters', this.form.filters);
    },

    editFilter(filter) {
        if(_.isEqual(filter, this.filterForm)) {
            this.closeFilter()
        } else {
            this.filterForm = filter
            this.editingFilter = true            
        }
    },

    closeFilter() {
        this.editingFilter = false;
        this.filterForm = {};
    },

    duplicateDataset() {
      this.processing = true;
      this.form.report = this.report.uuid
      this.$http.post(this.base_url + '/duplicate/' + this.form.uuid)
      .then(response => {
        if(response && response.data) {

          if(response.status === 201) {
            this.$eventBus.$emit('datasetCreated', response.data)
            this.$eventBus.$emit('show-alert', {
              text: 'Dataset Duplicated'
            });
          }
          this.processing = false
        }
      })     
    },

    getExportData (uuid) {
      this.processing = true;
      var limit = 100;
      this.$http.post(this.base_url + '/search?limit=' + limit + '&skip=' + this.downloads.length, this.form)
      .then(response => {
        if(response && response.data && response.data.data && response.data.data.length) {
          this.downloads = this.downloads.concat(response.data.data);
          if (response.data.data.length <= limit) {
            this.processing = false;
            this.downloadCSV();
          } else {
            this.processing = true;
            this.getExportData(uuid)
          }
        }
      })
    },
    exportRecords(uuid) {
      this.form.uuid = uuid;
      this.downloads = [];
      this.getExportData(uuid);
    },

    downloadCSV() {

      var csvFile, data, link;

      data = this.convertCSV(this.downloads)

      csvFile = new Blob([data], {type: "text/csv"});
      let fileName = this.form.name + '.csv';
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(csvFile, fileName);
      } else {
        link = document.createElement("a");
        link.download = fileName;
        link.href = window.URL.createObjectURL(csvFile);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
      }

      this.downloads = []
    },

    convertCSV(records) {
        var results = [];

        results += this.headers.join(',')
        results += '\n';
        records.forEach(function(item) {
          var record = ''
          Object.keys(item).forEach(function(key) {
            record += '"' + (item[key] ? item[key] : '') + '",';
          });
          results += record
          results += '\n';
        });
        return results
    },
}

}

</script>