<template>
  <div>

    <page-tabs page="clients" />

    <div class="row">
      <div class="col-12 col-lg-3 mb-3 d-print-none">


        <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-3">

          <p class="card-title">
            Create {{ setCustomLabel($route.meta.title) }}
          </p>

          <div class="input-group-append text-end">
            <button
              class="btn btn-outline-secondary btn-sm m-0"
              type="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Fields
            </button>
            <div class="dropdown-menu">
              <p class="dropdown-header">
                Fields
              </p>
              <a
                v-for="availableField in availableFields"
                class="dropdown-item text-capitalize" 
                :class="activeField(availableField.key)"
                @click="toggleField(availableField)"
              >{{ availableField.title }}</a>
            </div>
          </div>
        </div>

        <form
          ref="addClient"
          @submit.prevent="postForm()"
        >
          <template v-for="client_field in availableFields">

            <div v-if="shouldShowField(client_field.key)">

              <template v-if="!['tags', 'group', 'created_at', 'updated_at', 'created_by', 'updated_by', 'language', 'type', 'status', 'gender'].includes(client_field.key)">

              <div class="form-group">
                <label :for="client_field.key">{{ setCustomField('clients', client_field.key) }}</label>
                <input
                  :id="client_field.key"
                  v-model="form[client_field.key]"
                  :type="client_field.type || 'text'"
                  class="form-control"
                >
              </div>

              </template>


              <template v-if="client_field.type == 'tags'">

              <div class="form-group">
                <label for="phones">PHONES (comma-separated)</label>
                <input
                  id="phones"
                  type="text"
                  class="form-control"
                  @keydown.enter="addValue"
                  @keydown.188="addValue"
                />
                <template v-if="phones && phones.length">
                <template v-for="(phone, phone_index) in phones">
                <span class="badge badge-pill badge-primary link-hover" @click="removeValue(phone_index)">
                  {{ phone }}
                  x
                </span>
                </template>
                </template>
              </div>

            </template>

            <template v-if="client_field.key == 'group'">

              <template v-if="groups && groups.length">
                
                <div class="form-group">
                  <label for="group">{{ setCustomField('clients', client_field.key) }}</label>
                  <select
                    id="group"
                    v-model="form.group"
                    class="form-control"
                  >
                  <option value=""></option>
                    <option
                      v-for="group in groups"
                      :value="group"
                    >{{ group }}</option>

                </select>
                </div>

              </template>

              <template v-else>
                <div class="form-group">
                  <label for="group">{{ setCustomField('clients', client_field.key) }}</label>
                  <input
                    id="group"
                    v-model="form.group"
                    type="text"
                    class="form-control"
                  >
                </div>
              </template>

            </template>


            <template v-if="client_field.key == 'language'">

              <div class="form-group">
                <label for="language">{{ setCustomField('clients', client_field.key) }}</label>
                <select
                  id="language"
                  v-model="form.language"
                  class="form-control"
                >
                <option value=""></option>
                <template v-for="language in languages">
                  <option :value="language.key">{{ language.label }}</option>
                </template>
                </select>
              </div>

            </template>

            <template v-if="client_field.key == 'type'">

              <div class="form-group">
                <label for="type">{{ setCustomField('clients', client_field.key) }}</label>
                <select
                  id="type"
                  v-model="form.type"
                  class="form-control"
                >
                <option value=""></option>
                <template v-for="client_type in client_types">
                  <option :value="client_type">{{ client_type }}</option>
                </template>
                </select>
              </div>

            </template>

            <template v-if="client_field.key == 'status'">

              <div class="form-group">
                <label for="status">{{ setCustomField('clients', client_field.key) }}</label>
                <select
                  id="status"
                  v-model="form.status"
                  class="form-control"
                >
                <option value=""></option>
                <template v-for="status in statuses">
                  <option :value="status.name">{{ status.name }}</option>
                </template>
                </select>
              </div>

            </template>

            <template v-if="client_field.key == 'gender'">

              <div class="form-group">
                <label for="gender">{{ setCustomField('clients', client_field.key) }}</label>
                <select
                  id="gender"
                  v-model="form.gender"
                  class="form-control"
                >
                <option value=""></option>
                <option value="m">Male</option>
                <option value="f">Female</option>
                <option value="u">Unspecified</option>
                </select>
              </div>

            </template>



            </div>
            </template>

          <button
            type="submit"
            class="btn btn-success"
            :disabled="processing"
          >
            Create
          </button>
        </form>
      </div><!--Body-->
    </div><!--Card-->


    <div class="card mt-3">
      <div class="card-body">

        <form @submit.prevent="searchRecords">

            <p class="card-title">
              Search {{ title }}s
            </p>

          <div class="input-group mb-3">
              <button class="btn btn-primary dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <template v-if="queries.search_field">
                  {{ queries.search_field }}
                </template>
                <template v-else>
                  <i class="fa fa-search"></i>
                </template>
              </button>
              <div class="dropdown-menu">
                <button type="button" class="dropdown-item" @click="queries.search_field = ''" :disabled="processing">Any</button>
                <template v-for="search_field in search_fields">
                <button type="button" class="dropdown-item" @click="queries.search_field = search_field" :disabled="processing">{{ setCustomField('clients', search_field) }}</button>
                </template>
              </div>
            <input type="search" class="form-control" aria-label="Search messages" v-model="queries.search">
          </div>

          <template v-if="clientGroups && clientGroups.length">

          <p class="form-label">{{ chosen_groups.length }} of {{ clientGroups.length}} Client Groups</p>

          <template v-for="client_group in clientGroups">
            <span
              :value="client_group.group"
              class="badge badge-pill me-1 mb-3 link-hover"
              :class="setGroupClass(client_group)"
              @click="toggleClientGroup(client_group)"
            >
              {{ client_group }}
            </span>
          </template>

          </template>

          <template v-if="organizations && organizations.length > 0">
            <div class="form-group">
              <label for="search_organization">Organization</label>
              <select
                id="search_organization"
                v-model="queries.organization"
                class="form-control"
                @change="searchRecords"
                :disabled="processing"
              >
                <option value="" />
                <template v-for="organization in organizations">
                  <option
                    :value="organization.uuid"
                  >
                    {{ organization.name }}
                  </option>
                </template>
              </select>
            </div>
          </template>

          <div class="form-group">
            <label for="search_type">Client Type</label>
            <select
              id="search_type"
              v-model="queries.type"
              class="form-control"
              @change="searchRecords"
              :disabled="processing"
            >
              <option value="" />
              <template v-for="client_type in client_types">
                <option
                  :value="client_type"
                >
                  {{ client_type }}
                </option>
              </template>
            </select>
          </div>

          <div class="form-group">
            <label for="search_language">Preferred Language</label>
            <select
              id="search_language"
              v-model="queries.language"
              class="form-control text-capitalize"
              @change="searchRecords"
              :disabled="processing"
            >
              <option value="" />
              <template v-for="language in languages">
                <option
                  :value="language.key"
                >
                  {{ language.label }}
                </option>
              </template>
            </select>
          </div>

          <div class="form-group">
              <label for="search_status">Status</label>
              <select
                id="search_status"
                v-model="queries.status"
                class="form-control"
                @change="searchRecords"
                :disabled="processing"
                >
                <option value=""></option>
                <template v-for="status in statuses">
                  <option
                    :value="status.name"
                  >{{ status.name }}</option>
                </template>
              </select>
            </div>

            <template v-if="!processing && !statuses.length">
                <a href="/statuses?type=client" target="_blank" class="alert-link">Manage client statuses</a>
            </template>

          <div class="form-group">
            <label for="search_user">Assigned User</label>
            <select id="search_user" class="form-control" v-model="queries.user" 
            @change="searchRecords"
            :disabled="processing">
              <option value=""></option>
              <template v-for="user in users">
                <option :value="user.uuid">{{ user.name }} {{ user.email }}</option>
              </template>
            </select>
          </div>

          <div class="form-group">
            <label for="search_created_by">Creator</label>
            <select
              id="search_created_by"
              v-model="queries.created_by"
              class="form-control"
              @change="searchRecords"
              :disabled="processing"
            >
              <option value="" />
              <template v-for="creator in users">
                <option
                  :value="creator.email"
                >
                  {{ creator.name }} {{ creator.email }}
                </option>
              </template>
            </select>
          </div>

          <div class="row">

            <div class="col-sm-12 col-md-6">

              <div class="form-check">
                <input
                  id="is_archived"
                  v-model="queries.archived"
                  type="checkbox"
                  class="form-check-input"
                  @change="searchRecords"
                  :disabled="processing"
                >
                <label
                  class="form-check-label"
                  for="is_archived"
                >Archived</label>
              </div>

            </div><!--Col-->

            <div class="col-sm-12 col-md-6">

              <div class="form-check">
                <input
                  id="is_trashed"
                  v-model="queries.trashed"
                  type="checkbox"
                  class="form-check-input"
                  @change="searchRecords"
                  :disabled="processing"
                >
                <label
                  class="form-check-label"
                  for="is_trashed"
                >Trashed</label>
              </div>

            </div><!--Col-->

          </div><!--Row-->

          <button
            type="submit"
            id="search_button"
            class="btn btn-primary"
            :disabled="processing"
          >
            Search
          </button>
        </form>
      </div><!--Body-->
    </div><!--Card-->
      </div><!--Col-->

      <div class="col-12 col-lg-9">
        <div class="card">
          <div class="card-body">

            <div class="row">

            <div class="col-sm-12 col-md-6">

              <p class="card-title">
                {{ records.length }} of {{ total.toLocaleString() }} {{  title }}s
              </p>

            </div><!--Col-->

            <div class="col-sm-12 col-md-6 d-flex justify-content-end">

              <div class="btn-group d-print-none">
                <button type="button" class="btn btn-secondary btn-sm" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                <button type="button" class="btn btn-secondary btn-sm" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
              </div>
            </div><!--Col-->

            </div><!--Row-->

            <template v-if="actions && actions.records && actions.records.length > 0">

              <p class="text-success">
                {{ actions.records.length }} selected
              </p>

              <div class="row">

                <div class="col-sm-12 col-md-6">
                  <div class="btn-group">
                  <button type="button" class="btn btn-danger" @click="deselectAll()" :disabled="processing"><i class="fa fa-times"></i> Deselect All</button>
                  <template v-if="actions.records.length < this.total">
                    <button type="button" class="btn btn-primary" @click="selectAll()" :disabled="processing"><i class="fa fa-check"></i> Select All</button>                    
                  </template>
                  <button type="button" class="btn btn-outline-danger" @click="archiveSelected()" :disabled="processing" :hidden="queries.archived || queries.trashed"><i class="fa fa-archive"></i> Archive</button>
                  <button type="button" class="btn btn-outline-danger" @click="trashSelected()" :disabled="processing" :hidden="queries.archived || queries.trashed"><i class="fa fa-trash"></i> Trash</button>
                  <button type="button" class="btn btn-outline-success" @click="restoreSelected()" :disabled="processing" :hidden="!queries.archived && !queries.trashed"><i class="fa fa-undo"></i> Restore</button>
                  </div>

                </div><!--Col-->

              </div>

              <div class="row my-3" v-if="actions.action">

                <div class="col-sm-12 col-md-6">

                  <form @submit.prevent="postAction()">

                    <template v-if="actions.action">

                      <p class="text-danger">
                        Are you sure you want to {{ actions.action }} {{ actions.records.length }} {{ title }}s?
                      </p>

                    </template>

            
                <button type="button" class="btn btn-secondary" @click="resetActionsForm()" :disabled="processing">Cancel</button>


                <button type="submit" class="btn btn-success" :disabled="processing">Confirm</button>

                </form>


                </div><!--Col-->

              </div>

              </template>

            <div class="table-responsive my-3">

            <table class="table table-hover table-striped">
              <thead>
                <tr>
                  <th>EDIT</th>
                  <th @click="toggleSelect()" class="link-hover">SELECT</th>
                  <template v-for="column in availableFields">
                    <template v-if="shouldShowField(column.key) && !['phones', 'phone', 'email'].includes(column.key)">
                      <th>
                        <template v-if="column.key">
                          <span
                          class="text-capitalize link-hover"
                          @click="sortColumn(column)"
                        >{{ setCustomField('clients', column.key) }}</span>
                      </template>

                      <template v-if="!column.key">
                        <span class="text-capitalize">{{ column.label }}</span>
                      </template>

                      <template v-if="column.key == queries.sort">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm ml-1"
                          :disabled="processing"
                          @click="updateSortDirection()"
                        >
                          <template v-if="queries.sortDirection == 'asc'">
                            <i class="fa fa-arrow-up" /> <span class="sr-only">Asc</span>
                          </template>

                          <template v-if="queries.sortDirection == 'desc'">
                            <i class="fa fa-arrow-down" /> <span class="sr-only">Desc</span>
                          </template>
                        </button>
                      </template>
                      </th>
                    </template>
                  </template>
                </tr>
              </thead>
              <tbody>
                <template v-for="record in records">
                      <tr>
                        
                    <td class="d-print-none">
                        <router-link :to="{ 'name': $route.meta.base_url+'.edit', 'params': {'id': record.uuid} }">
                        <i class="fa fa-edit"></i> EDIT
                      </router-link>
                    </td>
                    <td class="d-print-none">
                      <label
                        class="sr-only"
                        :for="'record-'+record.uuid"
                        aria-label="Select"
                      >SELECT</label>
                      <input
                        :id="'record-'+record.uuid"
                        type="checkbox"
                        :value="record.uuid"
                        v-model="actions.records"
                      />
                    </td>

                    <template v-for="field_row in availableFields">

                      <template v-if="!['phones', 'phone', 'email'].includes(field_row.key) && shouldShowField(field_row.key)">
                      
                      <td>

                        <template v-if="field_row.type == 'date'">
                          
                          {{ record[field_row.key] | date }}
                        
                        </template>

                        <template v-if="field_row.type == 'time'">
                          
                          {{ record[field_row.key] | time }}

                        </template>

                        <template v-if="field_row.type == 'text'">
                          {{ record[field_row.key] }}
                        </template>

                        <template v-if="field_row.type == 'longtext'">
                          
                          <template v-if="record[field_row.key]">
                          {{ $options.filters.truncateText(record[field_row.key], 50) }}
                          </template>
                    
                        </template>

                        <template v-if="field_row.type == 'datetime'">
                          
                          <template v-if="record[field_row.key]">
                            {{ record[field_row.key] | datetime }}
                          </template>

                        </template>

                        <template v-if="field_row.type == 'client' && record[field_row.key]">
                          <client-button :uuid="record[field_row.key]" />
                        </template>


                      </td>

                      </template>
                    
                    </template>



                      </tr>
                </template><!--Row-->
              </tbody>
            </table>
            </div><!--TableResponsive-->

            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ title }}s found.
              </div>
            </template>
          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
        <div class="row mt-3">
          <div class="col-12 text-center">
            <button
              type="button"
              id="loadMoreButton"
              ref="loadMoreButton"
              class="btn btn-outline-primary"
              :disabled="processing"
              @click="getRecords"
            >
              Load more
            </button>
          </div><!--Col-->
        </div><!--Row-->
        </template><!--LoadMore-->


      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import queries from '../mixins/queries';
import _ from 'lodash';

export default {
  
  mixins: [ queries ],

  data () {
    return {
      processing: false,
      downloading: false,
      title: null,
      records: [],
      total: 0,
      queries: {},
      statuses: [],
      client_groups: [],
      chosen_groups: [],
      client_types: [],
      organizations: [],
      users: [],
      actions: {
        action: null,
        records: []
      },
      form: {},
      availableFields: [
          {title: 'Client Reference', key: 'client_reference', show: false, type: 'text'},
          {title: 'First Name', key: 'first_name', show: true, type: 'text'},
          {title: 'Middle Name', key: 'middle_name', show: false, type: 'text'},
          {title: 'Last Name', key: 'last_name', show: true, type: 'text'},
          {title: 'Aliases', key: 'aliases', show: false, type: 'text'},
          {title: 'Full Name', key: 'name', show: false, type: 'text'},
          {title: 'Phone', key: 'phone', show: true, type: 'tel'},
          {title: 'Phones', key: 'phones', show: false, type: 'tags'},
          {title: 'Email', key: 'email', show: true, type: 'email'},
          {title: 'Client Group', key: 'group', show: false, type: 'text'},
          {title: 'Client Type', key: 'type', show: false, type: 'text'},
          {title: 'Status', key: 'status', show: true, type: 'text'},
          {title: 'Date of Birth', key: 'dob', show: false, type: 'date'},
          {title: 'Client Gender', key: 'gender', show: false, type: 'text'},
          {title: 'Language', key: 'language', show: true, type: 'text'},
          {title: 'Created By', key: 'created_by', show: true, type: 'text'},
          {title: 'Created At', key: 'created_at', show: true, type: 'datetime'},
          {title: 'Updated At', key: 'updated_at', show: true, type: 'datetime'}
      ],
      fields: [],
      errors: null,
      phones: [],
      groups: [],
      search_fields: ['first_name', 'last_name', 'middle_name', 'name', 'client_reference']
    }

  },

  computed: {

    clientGroups: function() {

      if(!this.$root.current_user || !this.$root.current_user.current_agency) {
        return;
      }

      var groups = [];

      if(this.settings && this.settings.default_groups) {
        groups = this.settings.default_groups;

        groups = groups.split(",");

        groups = groups.map(g => g.trim());
      }

      var client_groups = [];

      if(this.client_groups && this.client_groups.length > 0) {
        client_groups = this.client_groups.map(r => r.group);

        client_groups.forEach(g => {
          if(!groups.includes(g)) {
            groups.push(g);
          }
        })
      }

      if(!groups) {
        return [];
      }

      return groups.map(g => g.trim());
    }

  },
  
  created () {
    this.resetQueries();
    this.resetRecords();
    this.setURLParams();
    
    this.title = this.setCustomLabel(this.$route.meta.title);
    this.setDocTitle(this.title+'s');

    this.setClientFields();
    this.setClientGroups();

    this.getLanguages();

    this.getRecords();
    this.getStatuses();
    this.getOrganizations();
    this.getUsers();
    this.getClientGroups();
    this.getClientTypes();
  },

  methods: {

    setURLParams() {
        var searchParams = new URLSearchParams(window.location.search);

        var self = this;

        searchParams.forEach(function(value, key) {

            if(!value || value == 'null') {
              return;
            }

            self.queries[key] = value;
        });
    },

    resetSearch() {
      this.resetQueries();
      this.updateURL();
      this.resetRecords();
      this.getRecords();
    },

    resetQueries() {
      this.queries = {
        sort: 'created_at',
        sortDirection: 'desc',
        search_field: '',
        search: '',
        skip: 0,
        limit: 20,
        total: false,
        slim: true,
        status: null,
        upload: null,
        groups: [],
        user: null,
        language: null,
        type: null,
        created_by: null,
        archived: false,
        trashed: false
      }

      this.chosen_groups = [];

      this.setClientSort();
    },

    setClientSort() {
      if(this.settings && this.settings.client_sort_by) {
        this.queries.sort = this.settings.client_sort_by;
      }

      if(this.settings && this.settings.client_sort_direction) {
        this.queries.sortDirection = this.settings.client_sort_direction;
      }
    },

    resetRecords() {
      this.records = [];
      this.total = 0;
      this.queries.skip = 0;
    },

    setGroupClass(group) {

      if(this.chosen_groups && this.chosen_groups.includes(group)) {
        return 'badge-primary';
      }

      return 'badge-secondary';
    },

    toggleClientGroup(group) {

      if(this.chosen_groups && !this.chosen_groups.includes(group)) {
        this.chosen_groups.push(group);

        this.searchRecords();
        return;
      }

      this.chosen_groups.splice(this.chosen_groups.findIndex((obj => obj === group)), 1);

      this.searchRecords();
    },

    getRecords() {

      if(this.chosen_groups) {
        this.queries.groups = this.chosen_groups;
        this.queries.groups.join(',');
      }

      this.setAssignedUser();

      this.getTotal();

      this.updateURL();

      this.processing = true;

      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
      .then(response => {
        if(response && response.data) {
          if(response.data.length) {

              var self = this;

              response.data.forEach(function(data) {
                  self.records.push(data);
              });
          }

          this.queries.skip = this.records.length;
          this.processing = false;
        }
      })
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
      .then(response => {
        if(response && response.status === 200) {
          this.total = response.data;
        }
      })
    },

    setAssignedUser() {
      if(!this.$root.current_user || !this.$root.current_user) {
        return;
      }

      if(this.$root.current_user.permissions && this.$root.current_user.permissions.length > 0) {
          if(!this.queries.user && this.$root.current_user.permissions.includes('only_assigned_clients')) {
            this.queries.user = this.$root.current_user.uuid;
          }
      }
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    addNewRecord(data) {

      var index = _.findIndex(this.records, {'uuid': data.uuid});

      if(index == -1) {
        this.total++;
        this.records.unshift(data);
        return;
      }

      var records = [];

      this.records.forEach(function(record) {

        if(record.uuid == data.uuid) {
          record = {...record, ...data};
        }

        records.push(record);


      })

      this.records = records;

    },

    updateSortDirection() {

      if(this.queries.sortDirection == 'asc') {
        this.queries.sortDirection = 'desc';
      } else {
        this.queries.sortDirection = 'asc';
      }

      this.resetRecords();

      this.getRecords();
    },

    sortColumn(column) {

      this.queries.sort = column.key;

      this.updateSortDirection();
    },

    searchRecords () {
      this.resetRecords();
      this.getRecords();

      this.$eventBus.$emit('show-alert', {
        text: 'Searching '+this.title+'s'
      });

    },

    getUsers () {
      this.$http.get('users?limit=1000&slim=y&fields=name,email,uuid&sort=name&sortDirection=asc')
      .then(response => {
        if(response && response.data) {
          this.users = response.data;
        }
      })
    },

    getClientGroups () {
      this.$http.get('/client_groups?limit=100')
      .then(response => {
        if(response && response.data) {
          this.client_groups = response.data;
        }
      })
    },

    getClientTypes () {
      this.$http.get('/client_types?limit=20')
      .then(response => {
        if(response && response.data && response.data.length) {
          this.client_types = response.data.map((r) => r.type);
        }
      })
    },

    getOrganizations() {
      this.$http.get('/organizations')
      .then(response => {
        if(response && response.data) {
          this.organizations = response.data;
        }
      })
    },

      resetActionsForm() {
        this.actions = {
          action: null,
          records: []
        }
      },

      deselectAll() {
        this.actions.records = [];
      },

      selectAll() {
        this.actions.records = this.records.map(function(record) {
          return record.uuid;
        });
      },

      toggleSelect() {
        if(this.actions.records.length == this.total) {
          this.deselectAll();
          return;
        }

        this.selectAll();
      },

      postAction() {

        if(!this.actions.action) {
          return;
        }

        if(!this.actions.records || !this.actions.records.length) {
          return;
        }

        this.processing = true;

        var self = this;

        this.actions.records.forEach(function(uuid) {

          if(self.actions.action == 'trash') {
            self.postTrash(uuid);
            return;
          }

          if(['archive', 'restore'].includes(self.actions.action)) {
            self.putAction(uuid, self.actions.action);
          }

        })

      },

      removeResetActions(uuid) {
        if(this.actions.records.includes(uuid)) {
          this.actions.records.splice(this.actions.records.indexOf(uuid), 1);
        }

        var index = _.findIndex(this.records, {'uuid': uuid});

        if(index > -1) {
          this.records.splice(index, 1);
          if(this.total > 0) {
            this.total--;
          }
        }

        if(this.actions.records.length == 0) {
          this.processing = false;
          this.resetActionsForm();
        }
      },

      putAction(uuid, path) {
        this.$http.put('/'+this.$route.meta.base_url+'/'+uuid+'/'+path)
        .then(response => {
          if(response && response.status === 200) {
            this.removeResetActions(uuid);
          }
        })
      },

      postTrash(uuid) {
        this.$http.delete('/'+this.$route.meta.base_url+'/'+uuid)
        .then(response => {
          if(response && response.status === 204) {
            this.removeResetActions(uuid);
          }
        })
      },

      trashSelected() {
        this.actions.action = 'trash';
      },

      archiveSelected() {
        this.actions.action = 'archive';
      },

      restoreSelected() {
        this.actions.action = 'restore';
      },

      getStatuses() {
      this.$http.get('/statuses?limit=100&type=client&slim=true&sort=name&sortDirection=asc&fields=name')
      .then(response => {
        if(response && response.data) {
          this.statuses = response.data;
        }
      })
    },

    setClientFields() {

      this.fields = this.availableFields;

        if(this.$root.preferences && this.$root.preferences.client_fields && this.$root.preferences.client_fields.length > 0) {
          this.fields = this.$root.preferences.client_fields;
        }
      },

      setClientGroups() {
      if(!this.$root.current_user || !this.$root.current_user.current_agency) {
      return;
      }

      var groups = [];

      if(this.settings && this.settings.default_groups) {
      groups = this.settings.default_groups;

      groups = groups.split(",");

      groups = groups.map(g => g.trim());
      }

      this.groups = groups;
      },

      postForm() {

      this.processing = true;

      if(this.phones && this.phones.length) {
        this.form.phones = this.phones.toString();
      }

      this.$http.post(this.$route.meta.base_url, this.form)
      .then(response => {
          if(response && response.data) {

              this.resetForm();
              
              if(response.data.uuid) {
                this.addNewRecord(response.data);
                this.$eventBus.$emit('show-alert', {
                  text: this.$route.meta.title + ' added'
                });
              }

              this.processing = false;
          }
      })

      },

      resetForm() {
      this.form = {};
      this.errors = null;
      this.phones = [];
      },

      activeField(field) {

      var activeField = _.find(this.fields, { 'key': field });

      if(activeField && activeField.show && activeField.show === true) {
          return 'active';
      }
      },

      toggleField(field) {

      var fields = this.fields;

      if(!fields || !fields.length) {
        fields = this.availableFields;
      }

      var activeField = _.find(fields, { 'key': field.key });

      if(!activeField) {
        field.show = false;
        fields.push(field);
        activeField = field;
      }

      if(activeField.show) {
        activeField.show = false;
      } else {
        activeField.show = true;
      }

      this.fields = _.map(this.fields, function(f) {
        if(f.key == field.key) {
          f.show = activeField.show;
        }
        return f;
      });

      this.$eventBus.$emit('updateClientFields', this.fields);
      },

      shouldShowField(field) {
        if(!field) {
          return false;
        }

        if(!this.fields || !this.fields.length) {
          return false;
        }

        var activeField = _.find(this.fields, { 'key': field });
        return activeField && activeField.show;
      },

      addValue(event) {

      if(!this.phones) {
        this.phones = [];
      }

      event.preventDefault();

      let val = event.target.value.trim();

      if(!val) {
        return;
      }

      if(this.phones.includes(val)) {
        return;
      }

      this.phones.unshift(val);

      event.target.value = '';
      },

      removeValue(index) {
      this.phones.splice(index, 1);
      }

  }
}
</script>