<template>
  <div>
    
    <onboarding-alert />

    <!-- Welcome Overlay for New Agencies -->
    <div class="welcome-overlay" v-if="showWelcomeOverlay" @keydown.esc="closeWelcomeOverlay" tabindex="-1" role="dialog" aria-labelledby="welcomeModalLabel" aria-modal="true">
      <div class="welcome-modal" role="document">
        <div class="welcome-header">
          <h2 id="welcomeModalLabel"><i class="fa fa-hand-wave me-2"></i> Welcome to eCourtDate!</h2>
          <button type="button" class="btn-close" @click="closeWelcomeOverlay" aria-label="Close"></button>
        </div>
        
        <div class="welcome-body">
          <div class="row">
            
            <div class="col-md-12 text-center">
              <h4>Let's get your agency set up for success.</h4>
              <p class="lead">eCourtDate helps courts, law enforcement, and other government agencies communicate effectively with clients.</p>
            </div>
            
                  <div class="ratio ratio-16x9">
                    <video src="https://assets.ecourtdate.com/videos/how-to-set-up-new-agency.mp4" poster="https://assets.ecourtdate.com/videos/how-to-set-up-new-agency.png" controls playsinline></video>
                  </div>
            
          </div>
        </div>
        
        <div class="welcome-footer">
          <div class="d-flex justify-content-between align-items-center">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="doNotShowAgain" v-model="doNotShowAgain">
              <label class="form-check-label" for="doNotShowAgain">
                Don't show this again
              </label>
            </div>
            <div>
              <button type="button" class="btn btn-secondary me-2" @click="closeWelcomeOverlay">Close</button>
              <a href="/setup" class="btn btn-primary">
                <i class="fa fa-rocket me-2"></i> Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-xl-3">

        <messages-form />

        <div class="card mb-3">
            <div class="card-body">

              <p class="card-title">Search Messages</p>

              <form @submit.prevent="searchRecords">

                  <div class="input-group mb-3">
                      <button class="btn btn-primary dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ queries.search_field }}
                      </button>
                      <div class="dropdown-menu">
                        <template v-for="search_field in search_fields">
                        <button type="button" class="dropdown-item" @click="setSearchField(search_field)" :disabled="processing">{{ search_field }}</button>
                        </template>
                      </div>
                    <input type="search" class="form-control" aria-label="Search messages" v-model="queries.search">
                  </div>


                <div class="form-group">
                  <label for="channel">Channel</label>
                  <select
                    id="channel"
                    v-model="queries.channel"
                    class="form-control"
                    @change="searchRecords()"
                    :disabled="processing"
                  >
                    <option value="">All</option>
                    <template v-for="channel in channels">
                      <option :value="channel">{{ channel }}</option>
                    </template>
                  </select>
                </div>

                <div class="form-group">
                  <label for="type">Type</label>
                  <select
                    id="type"
                    v-model="queries.type"
                    class="form-control"
                    @click="getMessageTypes"
                    @change="searchRecords"
                  >
                    <option value="" />
                    <template v-for="message_type in message_types">
                      <option
                        :value="message_type"
                      >
                        {{ message_type }}
                      </option>
                    </template>
                  </select>
                </div>

                <div class="form-group vbDatePicker">
                  <label for="from_date">From Date</label>
                  <input
                    id="from_date"
                    v-model="queries.from_date"
                    placeholder="mm/dd/yyyy"
                    type="date"
                    class="form-control"
                  >
                </div>

                <div class="form-group vbTimePicker" v-if="queries.from_date">
                  <label for="from_time">From Time</label>
                  <input
                    id="from_time"
                    v-model="queries.from_time"
                    placeholder="hh:mm AM"
                    type="time"
                    class="form-control"
                  >
                </div>

                <div class="form-group vbDatePicker">
                  <label for="to_date">To Date</label>
                  <input
                    id="to_date"
                    v-model="queries.to_date"
                    placeholder="mm/dd/yyyy"
                    type="date"
                    class="form-control"
                  >
                </div>

                <div class="form-group vbTimePicker" v-if="queries.to_date">
                  <label for="to_time">To Time</label>
                  <input
                    id="to_time"
                    v-model="queries.to_time"
                    placeholder="hh:mm AM"
                    type="time"
                    class="form-control"
                  >
                </div>

                <div class="form-check">
                <input
                    id="simulate"
                    v-model="queries.simulate"
                    type="checkbox"
                    class="form-check-input"
                    @change="searchRecords()"
                    :disabled="processing"
                >
                <label
                    class="form-check-label"
                    for="simulate"
                >Simulate Mode</label>
              </div>


                <button
                  type="submit"
                  class="btn btn-primary mt-3"
                  :disabled="processing"
                >
                  Search
                </button>
              </form>
            </div><!--Body-->
          </div><!--Card-->

          <messages-scheduled class="mb-3" />
      </div><!--Col-->
      <div class="col-sm-12 col-md-6 col-xl-9">

        <div class="row">
          <div class="col-sm-12 col-md-9">

            <div class="row">

              <div class="col-sm-12 col-md-9">

                <ul class="nav nav-tabs mb-3">
                    <li class="nav-item">
                      <button class="nav-link text-uppercase text-primary"
                      :class="isActiveDirection('all')"
                      @click="toggleDirection('all')"
                      >
                        All
                      </button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link text-uppercase text-primary"
                      :class="isActiveDirection('outbound')"
                      @click="toggleDirection('outbound')"
                      >
                      Outbound
                      <template v-if="totals.outbound">
                        <span class="badge badge-pill badge-primary">{{ totals.outbound }}</span>
                      </template>  
                      </button>
                    </li>
                    <li class="nav-item">
                      <button class="nav-link text-uppercase text-primary"
                      :class="isActiveDirection('inbound')"
                      @click="toggleDirection('inbound')"
                      >
                      Inbound
                      <template v-if="totals.inbound">
                        <span class="badge badge-pill badge-secondary">{{ totals.inbound }}</span>
                      </template>  
                      </button>
                    </li>
                </ul>

                </div><!--Col-->

                <div class="col-sm-12 col-md-3">


                </div><!--Col-->

                </div><!--Row-->

                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-check">
                      <input
                          id="failed"
                          v-model="queries.failed"
                          type="checkbox"
                          class="form-check-input"
                          @change="searchRecords()"
                          :disabled="processing"
                      >
                      <label
                          class="form-check-label"
                          for="failed"
                      >Failed</label>
                    </div>
                    <template v-if="queries.failed">
                      <div class="form-group">
                        <label for="error_code" class="sr-only">Error Code</label>
                        <select
                          id="error_code"
                          v-model="queries.error_code"
                          class="form-control"
                          @change="searchRecords()"
                          :disabled="processing"
                        >
                          <option value="">Any error</option>
                        <template v-for="(error_code, error_index) in error_codes">
                            <option :value="error_index">{{  error_index }} {{ error_code }}</option>
                          </template>
                        </select>
                        <template v-if="queries.failed && queries.error_code">
                          <p class="text-muted form-text m-0">
                            {{ setErrorDescription(queries.error_code) }}
                          </p>
                        </template>
                      </div>
                    </template>
                  </div><!--Col-->
                  <div class="col-sm-12 col-md-3">
                    <div class="form-check">
                      <input
                          id="mms"
                          v-model="queries.mms"
                          type="checkbox"
                          class="form-check-input"
                          @change="searchRecords()"
                          :disabled="processing"
                      >
                      <label
                          class="form-check-label"
                          for="mms"
                      >Media</label>
                    </div>
                  </div><!--Col-->
                </div>

          </div><!--Col-->

        </div><!--Row-->

          <messages-feed />

      </div><!--Col-->
    </div><!--Row-->

  </div>
</template>
<script>
import queries from '../mixins/queries';
export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      layout: 'grid',
      queries: {},
      search_fields: ['to', 'from', 'content', 'subject', 'status'],
      channels: ['text', 'email', 'voice'],
      error_codes: [],
      error_reasons: [],
      message_types: [],
      users: [],
      totals: {
        inbound: 0,
        outbound: 0
      },
      showWelcomeOverlay: false,
      doNotShowAgain: false
    }
  },

  created() {
    this.setTitle();
    this.getErrorCodes();
    this.getErrorReasons();
    this.resetQueries();
    this.setURLParams();
    this.getTotals();
    this.checkIfNewAgency();

    // Add document click listener for clicking outside modal
    document.addEventListener('click', this.handleOutsideClick);
    // Add keydown listener for ESC key
    document.addEventListener('keydown', this.handleKeyDown);
  },

  beforeDestroy() {
    // Clean up event listeners
    document.removeEventListener('click', this.handleOutsideClick);
    document.removeEventListener('keydown', this.handleKeyDown);
  },

  methods: {

    resetQueries() {
      this.queries = {
        channel: null,
        from: null,
        mms: null,
        simulate: null,
        search_field: 'to',
        status: '',
        direction: null,
        error_code: '',
        failed: false,
        sortDirection: 'desc',
        sort: 'scheduled_at'
      }
    },

    setURLParams() {

      let params = new URLSearchParams(window.location.search);

      const self = this;

      params.forEach(function(value, key) {
        self.queries[key] = value;
      });
    },

    searchRecords() {

      if(this.queries.direction == 'all') {
        this.queries.direction = '';
      }

      this.$eventBus.$emit('searchMessages', this.queries);
    },

    toggleDirection(direction) {
      this.queries.direction = direction;
      this.searchRecords();
    },

    toggleChannel(channel) {

      this.queries.channel = channel;
      
      if(this.queries.channel == 'all') {
        this.queries.channel = '';
      }

      this.queries.skip = 0;
      
      this.searchRecords();


    },

    toggleStatus(status) {
      this.queries.status = status;

      if(!this.queries.error_code) {
        this.queries.error_code = '';
      }

      if(this.queries.status == 'all') {
        this.queries.status = '';
      }

      if(this.queries.status == 'sent') {
        this.queries.direction = 'outbound';
        this.queries.sent = 1;
      }

      if(this.queries.status == 'received') {
        this.queries.direction = 'inbound';
      }

      if(this.queries.status == 'failed') {
        this.queries.direction = 'outbound';
        this.queries.sent = 1;
        this.queries.error_code = 1;
      }

      this.queries.skip = 0;

      this.searchRecords();
    },

    isActiveDirection(direction) {
      if(!this.queries.direction && direction == 'all') {
        return 'active';
      }

      return this.queries.direction == direction ? 'active' : '';
    },

    setSearchField(search_field) {
      this.queries.search_field = search_field;
    },

    getTotals() {
      this.getTotalInbound();
      this.getTotalOutbound();
    },

    getTotalInbound() {
      this.$http.get('/messages/total?direction=inbound')
      .then(response => {
        if(response && response.status) {
          this.totals.inbound = response.data;
        }
      })
    },

    getTotalOutbound() {
      this.$http.get('/messages/total?direction=outbound&sent=1')
      .then(response => {
        if(response && response.status) {
          this.totals.outbound = response.data;
        }
      })
    },

    getTotalFailed() {
      this.$http.get('/messages/total?sent=1&error_code=1&direction=outbound')
      .then(response => {
        if(response && response.status) {
          this.totals.failed = response.data;
        }
      })
    },

    setTitle() {

      let title = 'Dashboard';


      if(this.settings && this.settings.name) {
        title += ' - '+this.settings.name;
      }

      document.title = title;
    },

    getErrorReasons() {
      this.$axios.get('https://assets.ecourtdate.com/app/js/error_reasons.json')
      .then(response => {
        if(response && response.data) {
          this.error_reasons = response.data;
        }
      })
    },

    setErrorDescription(code) {
      
      if(!code) {
        return;
      }

      if(!this.error_reasons) {
        return;
      }

      if(this.error_reasons[code]) {
        return this.error_reasons[code];
      }
    },

    getMessageTypes() {
        this.$http.get('/message_types')
        .then(response => {
            if(response && response.data) {
              this.message_types = response.data;
              this.message_types.unshift('auto-reply');
              this.message_types.unshift('one-off');

              this.message_types = [...new Set(this.message_types)];

            }
        })
    },

    checkIfNewAgency() {
      if(!this.settings || !this.settings.created_at) {
        return;
      }

      const createdDate = new Date(this.settings.created_at);
      const now = new Date();
      const daysSinceCreation = Math.floor((now - createdDate) / (1000 * 60 * 60 * 24));
      
      const welcomeShown = localStorage.getItem('welcome_overlay_shown');
      
      if (daysSinceCreation < 3 && !welcomeShown) {
        this.showWelcomeOverlay = true;
        
        // Set focus to the modal after it's shown
        this.$nextTick(() => {
          const modalElement = document.querySelector('.welcome-overlay');
          if (modalElement) {
            modalElement.focus();
            
            // Trap focus inside modal
            this.setupFocusTrap(modalElement);
          }
        });
      }
    },
    
    closeWelcomeOverlay() {
      this.showWelcomeOverlay = false;
      
      if (this.doNotShowAgain) {
        localStorage.setItem('welcome_overlay_shown', 'true');
      }
      
      // Return focus to the document body
      document.body.focus();
    },
    
    setupFocusTrap(modalElement) {
      // Get all focusable elements
      const focusableElements = modalElement.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      
      if (focusableElements.length) {
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];
        
        // Add keydown event to trap focus
        modalElement.addEventListener('keydown', function(e) {
          if (e.key === 'Tab') {
            // Shift + Tab
            if (e.shiftKey) {
              if (document.activeElement === firstElement) {
                lastElement.focus();
                e.preventDefault();
              }
            } 
            // Tab only
            else {
              if (document.activeElement === lastElement) {
                firstElement.focus();
                e.preventDefault();
              }
            }
          }
        });
      }
    },

    // New methods for handling modal events
    handleOutsideClick(event) {
      if (this.showWelcomeOverlay) {
        const modal = document.querySelector('.welcome-modal');
        if (modal && !modal.contains(event.target)) {
          this.closeWelcomeOverlay();
        }
      }
    },

    handleKeyDown(event) {
      if (this.showWelcomeOverlay && event.key === 'Escape') {
        this.closeWelcomeOverlay();
      }
    }

  }
}
</script>

<style scoped>
/* Welcome Overlay Styles */
.welcome-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 20px;
  outline: none; /* Remove outline but keep focusable */
}

.welcome-modal {
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  max-width: 900px;
  max-height: 90vh;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: modalFadeIn 0.3s ease-out forwards;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.welcome-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.welcome-header h2 {
  margin: 0;
  color: #495057;
}

.welcome-body {
  padding: 20px;
  overflow-y: auto;
}

.welcome-footer {
  padding: 15px 20px;
  background-color: #f8f9fa;
  border-top: 1px solid #dee2e6;
}

.welcome-banner {
  max-height: 150px;
  object-fit: contain;
}

.help-link {
  display: block;
  color: #495057;
  text-decoration: none;
  padding: 6px 10px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.help-link:hover {
  background-color: #f8f9fa;
  color: #0d6efd;
}

/* Improve accessibility for keyboard users */
.btn:focus, .btn-close:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  outline: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .welcome-modal {
    max-width: 95%;
    max-height: 95vh;
  }
}
</style>