<template>
  <div>

  <template v-if="!processing && record.uuid">

    <div class="card">
      <div class="card-body">

          <p class="card-title fw-bold">
            {{ setCustomLabel('contact') }}
          </p>

          <template v-if="record.optin == 0">
            <span class="badge badge-danger me-1">OPTED-OUT</span>
          </template>

          <template v-if="record.valid == 0">
            <span class="badge badge-danger me-1">INVALID</span>
          </template>

          <template v-if="record.valid == 1">
            <span class="badge badge-success me-1">VALID</span>
          </template>

        <template v-if="record.channel == 'voice'">
          <span class="badge badge-pill badge-secondary"><i class="fa fa-phone"></i> Voice</span>
        </template>

        <template v-if="record.channel == 'text'">
          <span class="badge badge-pill badge-secondary"><i class="fa fa-comment"></i> Text</span>
        </template>

        <template v-if="record.channel == 'email'">
          <span class="badge badge-pill badge-secondary"><i class="fa fa-envelope"></i> Email</span>
        </template>

        <a :href="base_url+'/'+record.uuid" class="btn btn-outline-primary w-100 mt-3">View {{ setCustomLabel('contact') }}</a>

      </div><!--Body-->
    </div><!--Card-->

  </template>

  </div>
</template>
<script>
export default {

props: ['contact'],

data() {
    return {
        processing: false,
        base_url: '/contacts',
        record: {}
    }
},

created() {
    this.getRecord();
},

methods: {

        getRecord() {
            
            if(!this.contact) {
                return;
            }

            this.processing = true;
            this.record = {};

            this.$http.get(this.base_url+'?contact='+this.contact+'&limit=1&slim=true&fields=uuid,name,optin,valid,value,channel')
            .then(response => {
                if(response && response.data) {
                    this.processing = false;

                    if(response.data && response.data[0]) {
                        this.record = response.data[0];
                    }
                }
            })
        },

}

}
</script>