<template>
  <div>

    <page-tabs page="events" />

    <div class="row">
      <div class="col-sm-12 col-md-3">

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-9">
                <p class="card-title">
                  Search {{ $route.meta.title }}s
                </p>
              </div><!--Col-->

              <div class="col-sm-12 col-md-3 text-end">
                <button
                    type="button"
                    data-tooltip="Reset search fields"
                    class="btn btn-outline-secondary"
                    :disabled="processing"
                    @click="resetSearch()"
                >
                  <i class="fa fa-eraser" /> <span class="sr-only">Reset Form</span>
                </button>
              </div><!--Col-->
            </div><!--Row-->

            <form @submit.prevent="searchRecords()">

              <div class="form-group">
                <label for="search_name">Search by Name</label>
                <input
                    id="search_name"
                    v-model="queries.name"
                    type="search"
                    class="form-control"
                >
              </div>


              <div class="form-group">
                <label for="search_email">Search by Email</label>
                <input
                    id="search_email"
                    v-model="queries.email"
                    type="search"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="created_from">Created From</label>
                <input
                    id="created_from"
                    v-model="queries.created_from"
                    type="date"
                    class="form-control"
                >
              </div>

              <div class="form-group">
                <label for="created_to">Created To</label>
                <input
                    id="created_to"
                    v-model="queries.created_to"
                    type="date"
                    class="form-control"
                >

              </div>

              <div class="form-group">
                <label for="search_status">Status</label>
                <select
                    id="search_status"
                    v-model="queries.status"
                    class="form-control"
                    :disabled="processing"
                    @change="searchRecords()"
                >
                  <option value=""></option>
                  <option
                      v-for="status in $root.checkin_statuses"
                      :value="status"
                  >
                    {{ status }}
                  </option>
                </select>
              </div>


              <div class="form-check">
                <input
                    id="search_archived"
                    v-model="queries.archived"
                    type="checkbox"
                    class="form-check-input"
                    :disabled="processing"
                    @change="searchRecords()"
                >
                <label
                    class="form-check-label"
                    for="search_archived"
                >Archived</label>
              </div>

              <div class="form-check">
                <input
                    id="search_trashed"
                    v-model="queries.trashed"
                    type="checkbox"
                    class="form-check-input"
                    :disabled="processing"
                    @change="searchRecords()"
                >
                <label
                    class="form-check-label"
                    for="search_trashed"
                >Trashed</label>
              </div>


              <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="processing"
              >
                Search
              </button>
            </form>
          </div><!--Body-->
        </div><!--Card-->
      </div><!--Col-->

      <div class="col-sm-12 col-md-9">


        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-sm-12 col-md-4">

                <p class="card-title">
                  {{ records.length }} of {{ total.toLocaleString() }} {{  $route.meta.title }}s
                </p>

              </div><!--Col-->

              <div class="col-sm-12 col-md-4">
                <template v-if="current_portal && current_portal.link">
                  <a
                  :href="'https://'+current_portal.link+'.'+current_portal.domain+'/supervisions'"
                  target="_blank"
                  class="btn btn-outline-primary"
                      :disabled="processing"
                  >
                    <i class="fa fa-link"></i> Open Portal
                  </a>
                </template>

              </div><!--Col-->

              <div class="col-sm-12 col-md-4 text-end">

                <div class="btn-group d-print-none">
                  <button type="button" class="btn btn-secondary btn-sm m-0" @click="downloadPDF()" :disabled="processing || downloading"><i class="fa fa-file-pdf"></i> PDF </button>
                  <button type="button" class="btn btn-secondary btn-sm m-0" @click="downloadCSV()" :hidden="!total" :disabled="processing || downloading"><i class="fa fa-file-csv"></i> CSV</button>
                </div>
              </div><!--Col-->

            </div><!--Row-->

            <div class="table-responsive my-3">
              <table class="table table-hover table-striped">
                <sort-columns
                    :columns="columns"
                    :queries="queries"
                    :processing="processing"
                    :base_url="$route.meta.base_url"
                />
                <tbody>
                <template v-if="records && records.length > 0">
                  <template v-for="record in records">
                    <tr>
                      <td>
                        <a :href="'/'+$route.meta.base_url+'/'+record.uuid">
                          <i class="fa fa-edit"></i> Edit
                        </a>
                      </td>
                      <td>
                          {{ record.name }}
                      </td>
                      <td>
                        {{ record.email }}
                      </td>
                      <td>
                        {{ record.status }}
                      </td>
                      <td>
                        {{ record.comments }}
                      </td>
                      <td>
                        {{ record.created_by }}
                      </td>
                      <td>
                        {{ record.created_at }}
                      </td>
                      <td>
                        {{ record.updated_at }}
                      </td>
                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div><!--Responsive-->


            <template v-if="!processing && !records.length">
              <div class="alert alert-warning">
                No {{ $route.meta.title }}s found.
              </div>
            </template>

          </div><!--Body-->
        </div><!--Card-->

        <template v-if="records && records.length && total > records.length">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <button
                  type="button"
                  class="btn btn-outline-primary"
                  :disabled="processing"
                  @click="getRecords"
              >
                Load more
              </button>
            </div><!--Col-->
          </div><!--Row-->
        </template>
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
import _ from 'lodash';
import queries from '../mixins/queries';

export default {

  mixins: [ queries ],

  data() {
    return {
      processing: false,
      searched: false,
      records: [],
      queries: {},
      total: 0,
      form: {},
      columns: [
        {label: 'Edit'},
        {label: 'Name', key: 'name'},
        {label: 'Email', key: 'email'},
        {label: 'Status', key: 'status'},
        {label: 'Comments', key: 'comments'},
        {label: 'Creator', key: 'created_by'},
        {label: 'Created', key: 'created_at'},
        {label: 'Updated', key: 'updated_at'}
      ],
      portals: [],
      current_portal: {}
    }
  },

  mounted() {

    let self = this;

    this.$eventBus.$on('refreshRecords', function(view) {

      if(view !== self.$route.meta.base_url) {
        return;
      }

      self.searchRecords();

    });


    this.$eventBus.$on(this.$route.meta.base_url, function(data) {
        self.pushRecord(data);
    });

  },

  created() {

    this.resetForm();
    this.resetQueries();
    this.setURLParams();
    this.resetRecords();
    this.getRecords();
    this.getPortals();
  },

  methods: {

    resetSearch() {
      this.resetQueries();
      this.searchRecords();
    },

    resetQueries() {
      this.queries = {
        search: '',
        status: null,
        client: null,
        sort: 'created_at',
        sortDirection: 'desc',
        limit: 10,
        archived: false,
        trashed: false,
        skip: 0,
        created_from: null,
        created_to: null,
        phone: null,
        email: null
      }
    },

    setURLParams() {
      var searchParams = new URLSearchParams(window.location.search);

      var self = this;

      searchParams.forEach(function(value, key) {

        if(!value || value == 'null') {
          return;
        }

        self.queries[key] = value;
      });
    },

    searchRecords() {
      this.searched = true;

      this.resetRecords();
      this.getRecords();
    },

    resetRecords() {
      this.queries.skip = 0;
      this.total = 0;
      this.records = [];
    },

    getTotal() {
      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url+'/total', this.queries))
          .then(response => {
            if(response && response.status === 200) {
              this.total = response.data;
              this.setTitle();
            }
          })
    },

    setTitle() {
      document.title = this.total + ' ' + this.$route.meta.title + 's | eCourtDate.com';
    },

    updateURL() {
      history.pushState({}, null, this.buildQueries('/'+this.$route.meta.base_url, this.queries));
    },

    getRecords() {

      this.processing = true;

      this.updateURL();
      this.getTotal();



      this.$http.get(this.buildQueries('/'+this.$route.meta.base_url, this.queries))
          .then(response => {
            if(response && response.data) {

              if(response.data.length) {

                var self = this;

                response.data.forEach(function(data) {
                  self.records.push(data);
                });

              }

              this.queries.skip = this.records.length;

              this.processing = false;
            }
          })
    },

    resetForm() {
      this.form = {
        name: null,
        status: 0,
        comments: null,
      }

    },

    postForm() {

      this.processing = true;

      this.$http.post('/'+this.$route.meta.base_url, this.form)
          .then(response => {
            if(response && response.data) {

              if(response.data.uuid) {
                this.$eventBus.$emit('show-alert', {
                  text: this.$route.meta.title + ' created'
                });

                if(!this.records || !this.records.length) {
                  this.records = [];
                }

                this.records.unshift(response.data);
                this.resetForm();
                window.scrollTo(0,0);
                window.location.href = '/'+this.$route.meta.base_url+'/'+response.data.uuid;
              }

              this.processing = false;

            }
          })
    },

    setStatusClass(status) {
          if(!status) {
            return 'badge-secondary';
          }

          if(status == 'waiting') {
            return 'badge-danger';
          }

          if(status == 'ready') {
            return 'badge-success';
          }

          if(status == 'in-progress') {
            return 'badge-primary';
          }

          if(status == 'complete') {
            return 'badge-success';
          }

          return 'badge-secondary';
    },

    pushRecord(data) {

      if(!data || !data.uuid) {
        return;
      }

      if(this.processing) {
        return;
      }

      if(!this.records || !this.records.length) {
        this.records = [];
      }

      var index = _.findIndex(this.records, {'uuid': data.uuid});

      if(index != -1) {
        this.records[index] = data;
        return;
      }


      this.total++;
      this.records.unshift(data);

      this.setTitle();
      },

      getPortals() {
        this.current_portal = {};
        this.portals = [];
        this.$http.get('/portals?limit=1&sort=default&sortDirection=desc&slim=true&fields=uuid,name,link,domain&domain=ecase.io')
            .then(response => {
              if(response && response.data) {
                this.portals = response.data;
                
                if(this.portals.length > 0 && !this.current_portal.uuid) {
                  this.current_portal = this.portals[0];
                }
              }
            })
      },



  }

}
</script>