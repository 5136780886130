<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h5 class="card-title">
          <template v-if="form && form.uuid">
            Edit
          </template>
          <template v-else>
            Add
          </template>

          {{ title }}
        </h5>
      </div><!--Col-->

      <div class="col-sm-12 col-md-6 text-end">
        <loader :is-loading="processing" />
      </div><!--Col-->
    </div><!--Row-->
  </div>
</template>
<script>
export default {

props: ['title', 'processing', 'form']

}
</script>