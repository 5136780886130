<template>
    <div>

        <div
            class="mobile-device s5"
        >
            <div class="notch">
              <div class="camera" />
              <div class="speaker" />
            </div>
            <div class="top-bar" />
            <div class="sleep" />
            <div class="bottom-bar" />
            <div class="volume" />
            <div class="overflow">
              <div class="shadow shadow--tr" />
              <div class="shadow shadow--tl" />
              <div class="shadow shadow--br" />
              <div class="shadow shadow--bl" />
            </div>
            <div class="inner-shadow" />
            <div
              class="screen"
              style="overflow-y:auto"
              :data-bs-theme="isDarkMode ? 'dark' : 'light'"
            >

            <!--Email Preview-->

                <div class="preview-header text-white text-center p-1">
                    <template v-if="form.header || $root.current_user">
                        <h1 contenteditable="true">{{ form.header || $root.current_user.current_agency.name }}</h1>
                    </template>
                </div>
                <div class="preview-body">
                    <div class="p-3">

                        <template v-if="setLogoURL()">
                            <div class="text-center mt-1 mb-3"><img :src="setLogoURL()" width="100" :alt="form.name"></div>
                        </template>

                        <h4 class="text-center preview-text" contenteditable="true">{{ form.name }}</h4>
                        <p class="lead preview-text" contenteditable="true">Message Content Here</p>

                        <p class="preview-muted small" contenteditable="true">
                            <template v-if="form.signature">
                                <span v-html="form.signature"></span>
                            </template>
                            <template v-else>
                                Email Signature Here
                            </template>
                        </p>
                        
                    </div>
                </div>
                <div class="preview-footer text-center p-2">
                    <p class="m-0 small" contenteditable="true">
                    <template v-if="form.footer">
                        <span v-html="form.footer"></span>
                    </template>
                    <template v-else>
                        Email Footer Here
                    </template>
                    </p>
                </div>

            <!--/Email Preview-->

            
            </div>
          </div>

    </div>
  </template>
  <script>
  export default {
  
  props: ['form'],
  
  computed: {
    isDarkMode() {
      // Check if user prefers dark mode
      return document.documentElement.getAttribute('data-bs-theme') === 'dark';
    }
  },
  
  methods: {

    setLogoURL() {

        if(this.form && this.form.logo) {
            return this.form.logo;
        }

        var settings = localStorage.getItem('settings');

        if(settings) {
        settings = JSON.parse(settings);
        }

        if(settings && settings['logo']) {
            return settings['logo'];
        }

        return '/images/logo-blue.png';
    },
  
  }
  
  }
  </script>