<template>
<div>

<page-tabs page="setup" />

      <div class="row">
        <div class="col-6 mx-auto text-center">
          <div class="card">
            <div class="card-body">

              <h2>Agency Score</h2>

              <template v-if="results.score">
                <h3>😐 Your agency score is {{ results.score }}</h3>
                <p>Your score is based on a combination of your agency's activity and the success of your messages.</p>
            </template>

                <div class="row">
                    <template v-for="(result, result_key) in results.stats">
                            <div class="col-sm-12 col-md-6 mb-3">
                            <div class="card">
                                <div class="card-body text-center">
                                <h4 class="text-uppercase">{{ result_key | rmDashes }}</h4>
                                <p class="fs-2">{{ result }}</p>
                                </div>
                                </div>
                            </div>
                    </template>
                </div>


             <template v-if="results.created_at">
                <p>Score last generated on {{ results.created_at | datetime }}</p>
             </template>
             <template v-else>
                <p>Score needs to be generated</p>
             </template>

             <button type="button" class="btn btn-success" @click="generateScore" :disabled="processing">Generate Score</button>

            </div><!--Body-->
          </div><!--Card-->


        </div>
    </div>
</div>
</template>
<script>
export default {

    data() {
        return {
          processing: false,
          results: {
            stats: {},
            created_at: null
          }
        }
    },

 
    created () {
        this.getScore();
    },

    methods: {

        generateScore() {

            if(this.$root.current_region != 'staging') {
                return;
            }

            this.processing = true;
            this.$http.post('/agencies/score')
                .then(response => {
                    if(response && response.status) {
                        this.$eventBus.$emit('show-alert', {
                            text: 'Generating score...'
                        });
                    }
                    this.processing = false;
                })
        },

        getScore() {

            if(this.$root.current_region != 'staging') {
                return;
            }

            this.processing = true;
            this.$http.get('/agencies/score')
                .then(response => {
                    if(response && response.status) {
                        this.results = response.data;
                    }
                    this.processing = false;
                })
        }


    }

}
</script>